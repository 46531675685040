import {useTranslation} from 'react-i18next'
import {
    StyledAgreementsTitle,
    StyledInformationWrapper,
    StyledItemDescription,
    StyledItemTitle,
    StyledAgreementsWrapper,
    StyledCustomerLink
} from './style'
import {formatCurrency, round} from '@/utilities/helpers'
import {PlusIcon} from '@components/ui/icon'
import Button from '@components/ui/button/Button.tsx'
import {generatePath, useOutletContext} from 'react-router-dom'
import {ProjectDetails} from '@/features/project/types.ts'
import {useInternalDailyRate} from '@/features/project/services/queries/useInternalDailyRate.ts'
import {routes} from '@/utilities/constants'
import RichTextAreaView from '@components/commons/rich-textarea/rich-textarea-view/RichTextAreaView.tsx'

interface RevenueProps {
    setIsInvoiceModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const RevenuesHeader = ({setIsInvoiceModalOpen}: RevenueProps) => {
    const {t} = useTranslation()
    const {project} = useOutletContext<{project: ProjectDetails}>()
    const {internalDailyRate} = useInternalDailyRate()
    const onClickAddInvoice = () => setIsInvoiceModalOpen(true)

    return (
        <section>
            <StyledInformationWrapper>
                <div>
                    <StyledItemTitle>{t('project:details:customer')}</StyledItemTitle>
                    <StyledCustomerLink
                        to={generatePath(routes.EDIT_CUSTOMERS.path, {
                            id: project.customer.id
                        })}
                        target="_blank"
                    >
                        <StyledItemDescription>{project.customer.businessName}</StyledItemDescription>
                    </StyledCustomerLink>
                </div>
                <div>
                    <StyledItemTitle>{t('project:details:accountingName')}</StyledItemTitle>
                    <StyledItemDescription>{project.customer.fullnameAccounting}</StyledItemDescription>
                </div>
                <div>
                    <StyledItemTitle>{t('project:details:accountingEmail')}</StyledItemTitle>
                    <StyledItemDescription>{project.customer.email}</StyledItemDescription>
                </div>
                <div>
                    <StyledItemTitle>{t('project:details:budget')}</StyledItemTitle>
                    <StyledItemDescription>{formatCurrency(project.budget, 'EUR')}</StyledItemDescription>
                </div>
                <div>
                    <StyledItemTitle>{t('project:details:hours')}</StyledItemTitle>
                    <StyledItemDescription>{project.hours}</StyledItemDescription>
                </div>
                <div>
                    <StyledItemTitle>{t('project:details:dailyRate')}</StyledItemTitle>
                    <StyledItemDescription>{formatCurrency(project.dailyRate, 'EUR')}</StyledItemDescription>
                </div>
                <div>
                    <StyledItemTitle>{t('project:details:markup')}</StyledItemTitle>
                    <StyledItemDescription>
                        {internalDailyRate
                            ? `${round(((project.dailyRate - internalDailyRate) / internalDailyRate) * 100)}%`
                            : `0%`}
                    </StyledItemDescription>
                </div>
            </StyledInformationWrapper>
            <StyledAgreementsWrapper>
                <div>
                    <StyledAgreementsTitle>{t('project:details:masterAgreements')}</StyledAgreementsTitle>
                    <RichTextAreaView value={project.masterAgreements} />
                </div>
                <div>
                    {
                        <Button variant="primary" onClick={onClickAddInvoice}>
                            <PlusIcon />
                            {t('project:revenues:add_invoice')}
                        </Button>
                    }
                </div>
            </StyledAgreementsWrapper>
        </section>
    )
}

export default RevenuesHeader

RevenuesHeader.displayName = 'RevenuesHeader'
