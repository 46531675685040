import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {AlertTriangleIcon} from '@/components/ui/icon'
import ProjectForm from '@/features/project/components/project-form/ProjectForm'
import {ProjectSectionLoader} from '@/features/project/components/project-form/ProjectSectionLoader'
import {adaptProjectToForm} from '@/features/project/components/project-form/utils'
import {useInternalDailyRate} from '@/features/project/services/queries/useInternalDailyRate'
import {ProjectDetails} from '@/features/project/types'
import {useTranslation} from 'react-i18next'
import {useOutletContext} from 'react-router-dom'

export const ProjectInfo: React.FC = () => {
    const {t} = useTranslation()
    const {project} = useOutletContext<{project: ProjectDetails}>()
    const {internalDailyRate, isError, isPending} = useInternalDailyRate()

    if (!internalDailyRate || isPending) {
        return <ProjectSectionLoader />
    }

    if (isError || !internalDailyRate) {
        return <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
    }

    return (
        <ProjectForm
            internalDailyRate={internalDailyRate}
            defaultValues={adaptProjectToForm(project)}
            projectId={project.id}
        />
    )
}

ProjectInfo.displayName = 'ProjectInfo'
