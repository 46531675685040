import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Dispatch, FC, SetStateAction} from 'react'
import {
    StyledLoggedHour,
    StyledLoggedHourProgress,
    StyledLoggedHours,
    StyledLoggedHoursWrapper,
    StyledPhasesHeader,
    StyledSwitchConvertCosts
} from './style'
import {Progress} from '@/components/ui/progress/Progress'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {Link03Icon} from '@/components/ui/icon'
import {useTranslation} from 'react-i18next'
import {ProjectDetails} from '@/features/project/types'
import {FloatSyncButton} from '@/features/project-phases/components/float-syc-button/FloatSyncButton'
import {useOutletContext} from 'react-router-dom'
import {round} from '@utilities/helpers.ts'
import {CostStatus, ProjectCost} from '@/features/project-costs/types.ts'
import {Phase} from '@/features/project-phases/types.ts'

interface PhasesHeaderProps {
    setIsConvertCosts: Dispatch<SetStateAction<boolean>>
    costs: ProjectCost[]
    isConvertCosts: boolean
    phases: Phase[]
}
type GetHoursCostsHeader = {
    hours: number
    costStatus: CostStatus
}

export const PhasesHeader: FC<PhasesHeaderProps> = ({setIsConvertCosts, costs, isConvertCosts, phases}) => {
    const {project} = useOutletContext<{project: ProjectDetails}>()
    const {t} = useTranslation()
    const getHoursCostsHeader = ({hours, costStatus}: GetHoursCostsHeader) => {
        if (isConvertCosts) {
            const hourlyCost = round(project.dailyRate / 8, 2)
            const phasesCosts = round(
                costs.filter(cost => cost.status === costStatus).reduce((acc, val) => acc + val.amount, 0),
                2
            )

            const costToHours = round(phasesCosts / hourlyCost, 2)
            const total = hours + costToHours
            return round(total, 2)
        } else {
            return hours
        }
    }
    const loggedHours = () =>
        getHoursCostsHeader({
            hours: phases ? phases.reduce((acc, value) => acc + value.loggedAmount, 0) : 0,
            costStatus: CostStatus.Enum.received
        })

    const futureScheduledHours = () =>
        getHoursCostsHeader({
            hours: phases ? phases.reduce((acc, value) => acc + value.futureSchedulesAmount, 0) : 0,
            costStatus: CostStatus.Enum.expected
        })

    return (
        <StyledPhasesHeader justify="space-between" align="end">
            <StyledLoggedHoursWrapper>
                <StyledLoggedHours gap={8}>
                    <StyledLoggedHour gap={2} direction="column">
                        <h4>{t('project:phases:logged_label')}</h4>
                        <StyledLoggedHourProgress>
                            <Progress
                                total={project.hours}
                                progress={loggedHours()}
                                label={t('project:phases:hours_label')}
                                showValues={true}
                            />
                        </StyledLoggedHourProgress>
                    </StyledLoggedHour>

                    <StyledLoggedHour gap={2} direction="column">
                        <h4>{t('project:phases:logged_future_label')}</h4>
                        <StyledLoggedHourProgress>
                            <Progress
                                total={project.hours}
                                progress={loggedHours()}
                                secondaryProgress={futureScheduledHours()}
                                label={t('project:phases:hours_label')}
                                showValues={true}
                            />
                        </StyledLoggedHourProgress>
                    </StyledLoggedHour>
                </StyledLoggedHours>
                <StyledSwitchConvertCosts
                    label={t('project:phases:convert_costs_label')}
                    onChange={value => setIsConvertCosts(value)}
                    defaultChecked={isConvertCosts}
                />
            </StyledLoggedHoursWrapper>

            <Flexbox align="end" gap={9}>
                {project?.floatUrl ? (
                    <ButtonLink target="_blank" size={'sm'} variant={'tertiary'} to={project.floatUrl} padding={0}>
                        <Link03Icon />
                        <span>{t('project:phases:float_link')}</span>
                    </ButtonLink>
                ) : null}

                <FloatSyncButton
                    projectId={project.id}
                    syncStatus={project.syncStatus}
                    lastSyncAt={project.lastSyncAt}
                />
            </Flexbox>
        </StyledPhasesHeader>
    )
}
