import {z} from 'zod'

export const CostStatus = z.enum(['received', 'expected'])
export type CostStatus = z.infer<typeof CostStatus>

export const ProjectCost = z.object({
    id: z.coerce.number(),
    projectId: z.coerce.number(),
    description: z.string(),
    date: z.string(),
    status: CostStatus,
    phaseId: z.number().nullable(),
    phase: z
        .object({
            name: z.string()
        })
        .nullish(),
    amount: z.number()
})
export type ProjectCost = z.infer<typeof ProjectCost>

export const CostsURLParams = z.object({
    projectId: z.coerce.number().int()
})
export type CostsURLParams = z.infer<typeof CostsURLParams>

export const CostsSearchParams = z.object({
    orderBy: z.enum(['name']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional()
})
export type CostsSearchParams = z.infer<typeof CostsSearchParams>
