import {FC, ReactNode, useState} from 'react'
import {useDatePicker} from '@rehookify/datepicker'
import {CalendarsWrapper, StyledContainer} from '@components/commons/calendar/style.ts'
import DateSelector from '@components/commons/calendar/DateSelector.tsx'
import YearSelector from '@components/commons/calendar/YearSelector.tsx'
import MonthSelector from '@components/commons/calendar/MonthSelector.tsx'

export type CalendarProps = {
    numMonths: number
    selectedDates: Date[]
    onDatesChange: (dates: Date[]) => void
    mode: 'single' | 'multiple' | 'range'
    toggle?: boolean
    currentDay?: Date
    minDate?: Date
    maxDate?: Date
    locale?: string
}
const Calendar: FC<CalendarProps> = ({
    numMonths,
    selectedDates,
    currentDay,
    onDatesChange,
    mode,
    toggle = false,
    minDate,
    maxDate,
    locale = 'en'
}) => {
    const {
        data: {weekDays, calendars, months, years},
        propGetters: {
            dayButton,
            nextMonthButton,
            previousMonthButton,
            monthButton,
            yearButton,
            nextYearsButton,
            previousYearsButton
        }
    } = useDatePicker({
        selectedDates,
        onDatesChange,
        dates: {toggle, mode, minDate, maxDate},
        calendar: {
            startDay: 0,
            offsets: [...Array(numMonths).keys()].slice(1)
        }
    })

    const [viewState, setViewState] = useState<'date' | 'month' | 'year'>('date')

    const calendarContentByState: Record<'date' | 'month' | 'year', ReactNode> = {
        date: calendars.map((calendar, index) => (
            <DateSelector
                mode={mode}
                setViewState={numMonths === 1 ? setViewState : undefined}
                locale={locale}
                key={`${calendar.month}-${calendar.year}`}
                currentDay={currentDay}
                calendar={calendar}
                dayButton={dayButton}
                weekDays={weekDays}
                previousMonthButton={index === 0 ? previousMonthButton : undefined}
                nextMonthButton={index === calendars.length - 1 ? nextMonthButton : undefined}
            />
        )),
        month: (
            <MonthSelector
                setViewState={setViewState}
                locale={locale}
                months={months}
                monthButton={monthButton}
                currentMonth={calendars[0].month}
            />
        ),
        year: (
            <YearSelector
                setViewState={setViewState}
                locale={locale}
                years={years}
                yearButton={yearButton}
                nextYearsButton={nextYearsButton}
                previousYearsButton={previousYearsButton}
                currentYear={calendars[0].year}
            />
        )
    }

    return (
        <StyledContainer>
            <CalendarsWrapper>{calendarContentByState[viewState]}</CalendarsWrapper>
        </StyledContainer>
    )
}

export default Calendar
