import ReactSelect, {ActionMeta, MultiValue, SingleValue} from 'react-select'
import {customStyles, StyledContainer} from './style.ts'
import {useTheme} from 'styled-components'
import {customComponents} from '@components/commons/select/commons.tsx'
import {DefaultNamespace} from 'i18next'
import {SelectValue} from '@/components/commons/select/Select.tsx'
import AsyncSelect from 'react-select/async'
import {useMemo} from 'react'

export interface SelectProps {
    className?: string
    name?: string
    options?: SelectValue[]
    placeholder?: string | DefaultNamespace
    defaultValue?: SelectValue | []
    isSearchable?: boolean
    isClearable?: boolean
    defaultOptions?: boolean
    disabled?: boolean
    isAsync?: boolean
    loadOptions?: (inputText?: string) => Promise<SelectValue[]>
    onChange?: (
        newValue: SingleValue<SelectValue> | MultiValue<SelectValue> | SelectValue[],
        actionMeta: ActionMeta<SelectValue>
    ) => void
    value?: SelectValue | SelectValue[]
}

const TableSelect = ({
    className,
    name,
    options = [],
    placeholder,
    defaultValue,
    isAsync = false,
    defaultOptions = true,
    isSearchable = false,
    isClearable,
    loadOptions,
    disabled,
    onChange,
    ...rest
}: SelectProps) => {
    const theme = useTheme()

    const selectProps = {
        options,
        name,
        isClearable,
        defaultOptions,
        isDisabled: disabled,
        placeholder,
        isSearchable,
        disabled,
        defaultValue,
        loadOptions,
        onChange,
        ...rest
    }

    const selectComponentProps = {
        components: customComponents,
        ...selectProps,
        styles: customStyles({theme})
    }

    const Component = useMemo(() => {
        if (isAsync) return AsyncSelect
        return ReactSelect
    }, [isAsync])

    return (
        <StyledContainer className={className}>
            <Component {...selectComponentProps} />
        </StyledContainer>
    )
}

export default TableSelect

TableSelect.displayName = 'TableSelect'
