import * as pages from '@/pages'

export const routes = {
    LOGIN: {
        path: '/login',
        element: <pages.Login />
    },
    SSO_REDIRECT: {
        path: '/redirect',
        element: <pages.SsoRedirect />
    },
    HOME: {
        path: '/',
        element: <pages.Projects />
    },

    FORM_SHOWCASE: {
        path: '/form-showcase',
        element: <pages.FormShowcase />
    },

    SALS: {
        path: '/sal',
        element: <pages.Sals />
    },

    PROJECTS: {
        path: '/projects',
        element: <pages.Projects />
    },
    NEW_PROJECT: {
        path: '/new-project',
        element: <pages.NewProject />
    },
    PROJECT_DETAIL: {
        path: '/projects/:id',
        element: <pages.ProjectDetails />
    },
    PROJECT_DETAIL_EDIT: {
        path: 'info',
        element: <pages.ProjectInfo />
    },
    PROJECT_DETAILS_PHASES: {
        path: 'phases',
        element: <pages.ProjectPhases />
    },
    PROJECT_DETAILS_SALS: {
        path: 'sal',
        element: <pages.ProjectSals />
    },
    PROJECT_DETAILS_REVENUES: {
        path: 'revenues',
        element: <pages.ProjectRevenues />
    },
    PROJECT_DETAILS_COSTS: {
        path: 'costs',
        element: <pages.ProjectCosts />
    },

    CUSTOMERS: {
        path: '/customers',
        element: <pages.Customers />
    },
    NEW_CUSTOMERS: {
        path: '/new-customer',
        element: <pages.NewCustomer />
    },
    EDIT_CUSTOMERS: {
        path: '/customers/:id',
        element: <pages.EditCustomer />
    },

    INVOICES: {
        path: '/invoices',
        element: <pages.Invoices />
    },
    COSTS: {
        path: '/costs',
        element: <pages.Cost />
    },
    EMPLOYEES: {
        path: '/employees',
        element: <pages.Employees />
    },
    NEW_EMPLOYEES: {
        path: '/new-employee',
        element: <pages.NewEmployee />
    },
    EDIT_EMPLOYEES: {
        path: '/employee/:id',
        element: <pages.EditEmployee />
    },
    NOT_FOUND: {
        path: '*',
        element: <pages.NotFound />
    }
}
