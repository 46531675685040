import {ChangeEvent, CSSProperties, forwardRef, ReactElement, ReactNode, useState} from 'react'
import {StyledInput, StyledInputTrigger, StyledInputWrapper} from './style'
import {EyeIcon, EyeOffIcon} from 'src/components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {DefaultNamespace} from 'i18next'
import Label from '@components/ui/label/Label.tsx'
import InputStatusIcon from '@components/ui/input-status-icon/InputStatusIcon.tsx'
import InputHelpText from '@components/ui/input-help-text/InputHelpText.tsx'

export interface InputNumberProps {
    className?: string
    label?: DefaultNamespace | ReactNode
    name?: string
    typeIcon?: ReactNode
    touched?: boolean
    /**
     * how to use errorMessage with translations
     *  errorMessage={t(errors.text?.message || '')} this avoid undefined value problems
     */
    errorMessage?: string | DefaultNamespace
    visibilityToggle?: boolean
    helpText?: string | DefaultNamespace
    placeholder?: string
    inputSize?: 'sm' | 'md' | 'lg'
    width?: CSSProperties['width']
    disabled?: boolean
    thousandSeparator?: string
    decimalSeparator?: string
    decimalScale?: number
    allowNegative?: boolean
    customInputTrigger?: ReactElement
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    value?: string
}

const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
    (
        {
            label,
            name,
            typeIcon,
            touched = false,
            errorMessage,
            helpText,
            className,
            disabled,
            visibilityToggle = false,
            inputSize = 'md',
            width = '100%',
            placeholder,
            thousandSeparator,
            decimalSeparator,
            decimalScale,
            allowNegative,
            customInputTrigger,
            onChange,
            value,
            ...rest
        },
        ref
    ) => {
        const [eyeVisible, setEyeVisible] = useState(false)

        return (
            <Flexbox className={className} width={width} direction="column" gap={1.5}>
                {label && <Label htmlFor={name}>{label}</Label>}
                <Flexbox align="center" width="100%">
                    <StyledInputWrapper
                        touched={touched}
                        hasError={!!errorMessage}
                        size={inputSize}
                        isInputTrigger={visibilityToggle || !!customInputTrigger}
                    >
                        {typeIcon}
                        <StyledInput
                            thousandSeparator={thousandSeparator}
                            decimalSeparator={decimalSeparator}
                            decimalScale={decimalScale}
                            placeholder={placeholder}
                            getInputRef={ref}
                            onChange={onChange}
                            disabled={disabled}
                            value={value}
                            allowNegative={allowNegative}
                            {...rest}
                        />
                        <InputStatusIcon touched={touched} hasError={!!errorMessage} />
                    </StyledInputWrapper>

                    {/*input Action toggle*/}
                    {visibilityToggle && (
                        <StyledInputTrigger
                            size={inputSize}
                            disabled={disabled}
                            onClick={() => setEyeVisible(!eyeVisible)}
                        >
                            {eyeVisible ? <EyeIcon size={20} data-color /> : <EyeOffIcon size={20} data-color />}
                        </StyledInputTrigger>
                    )}

                    {!visibilityToggle && customInputTrigger && (
                        <StyledInputTrigger size={inputSize}>{customInputTrigger}</StyledInputTrigger>
                    )}
                </Flexbox>
                <InputHelpText error={errorMessage} helpText={helpText} />
            </Flexbox>
        )
    }
)

export default InputNumber

InputNumber.displayName = 'InputNumber'
