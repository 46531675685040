import {CostStatus} from '@/features/project-costs/types.ts'
import {round} from '@utilities/helpers.ts'
import {Phase} from '@/features/project-phases/types.ts'

export const PHASE_SERVICE_LINE = [
    {
        value: 'digital_experience',
        label: 'project:phases:service_line:digital_experience'
    },
    {
        value: 'web_app',
        label: 'project:phases:service_line:web_app'
    },
    {
        value: 'mobile_app',
        label: 'project:phases:service_line:mobile_app'
    },
    {
        value: 'others',
        label: 'project:phases:service_line:others'
    }
]

type GetHoursCosts = {
    hours: number
    phaseId: number | null
    costStatus: CostStatus
}
export const totalHours = (data: Phase[]) => {
    let amount = 0
    data.forEach(phase => {
        if (phase.hours) amount += phase.hours
    })
    return amount
}
export const totalUsed = (data: Phase[], getHoursCosts: ({hours, phaseId, costStatus}: GetHoursCosts) => number) => {
    let amount = 0
    data.forEach(phase => {
        const hours = getHoursCosts({
            hours: phase.loggedAmount,
            phaseId: phase?.id || null,
            costStatus: CostStatus.Enum.received
        })
        amount += hours
    })
    return round(amount, 2)
}
export const totalScheduled = (
    data: Phase[],
    getHoursCosts: ({hours, phaseId, costStatus}: GetHoursCosts) => number
) => {
    let amount = 0
    data.forEach(phase => {
        const hours = getHoursCosts({
            hours: phase.futureSchedulesAmount,
            phaseId: phase?.id,
            costStatus: CostStatus.Enum.expected
        })
        amount += hours
    })
    return round(amount, 2)
}
export const totalLeft = (data: Phase[], getHoursCosts: ({hours, phaseId, costStatus}: GetHoursCosts) => number) => {
    let amount = 0
    data.forEach(phase => {
        const logged = getHoursCosts({
            hours: phase.loggedAmount,
            phaseId: phase?.id,
            costStatus: CostStatus.Enum.received
        })

        const scheduled = getHoursCosts({
            hours: phase.futureSchedulesAmount,
            phaseId: phase?.id,
            costStatus: CostStatus.Enum.expected
        })

        const hours = (phase?.hours || 0) - (logged + scheduled)
        amount += hours
    })
    return round(amount, 2)
}
