import {useProjectPhasesQuery} from '@/features/project-phases/services/queries/useProjectPhases'
import {useProjectCostsQuery} from '@/features/project-costs/services/queries/useProjectCosts'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {PhasesURLParams, PhasesSearchParams} from '@/features/project-phases/types'
import {debounce} from '@/utilities/helpers'

export const useProjectPhasesTable = (params: PhasesURLParams) => {
    const multiParams = [] as const satisfies ReadonlyArray<keyof PhasesSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, PhasesSearchParams)
    const phasesQuery = useProjectPhasesQuery({...params, ...searchParams})
    const costsQuery = useProjectCostsQuery(params)

    const search = debounce((value: string) => {
        setSearchParams({search: value})
    }, 750)

    const sort = (sorter: {
        orderBy: PhasesSearchParams['orderBy']
        orderDirection: PhasesSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        phases: phasesQuery.data,
        costs: costsQuery,
        isLoading: phasesQuery.isLoading || costsQuery.isLoading,
        isSuccess: phasesQuery.isSuccess || costsQuery.isSuccess,
        isError: phasesQuery.isError || costsQuery.isError,
        search,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
