import {z} from 'zod'
import {PaginationParams, ResponseType} from '@/types/commons.ts'
import {Team} from '@/features/teams/types.ts'

export const Scope = z.object({
    id: z.coerce.number(),
    name: z.string()
})
export type Scope = z.infer<typeof Scope>

export const EmployeeRole = z.enum(['admin', 'team_lead', 'employee', 'success_manager', 'accounting'])
export type EmployeeRole = z.infer<typeof EmployeeRole>

export const EmployeeType = z.enum(['full_time', 'part_time', 'external_fixed', 'external'])
export type EmployeeType = z.infer<typeof EmployeeType>

export const Employee = z.object({
    id: z.coerce.number(),
    email: z.string().email(),
    role: EmployeeRole,
    scopeId: z.coerce.number(),
    canAccess: z.boolean(),
    fullName: z.string(),
    weeklyHours: z.number(),
    employeeType: EmployeeType,
    isActive: z.boolean(),
    floatExternalId: z.number().nullable(),
    scope: Scope.nullish(),
    team: Team.nullish(),
    startDate: z.string().nullable(),
    endDate: z.string().nullable()
})
export type Employee = z.infer<typeof Employee>

export const EmployeesSearchParams = z.object({
    orderBy: z.enum(['fullName']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional(),
    roles: EmployeeRole.array().optional(),
    linkedToFloat: z.boolean().optional(),
    isActive: z.boolean().optional()
})
export type EmployeesSearchParams = z.infer<typeof EmployeesSearchParams>

export const HttpEmployeesParams = z.object({
    ...EmployeesSearchParams.shape,
    ...PaginationParams.shape,
    responseType: ResponseType
})
export type HttpEmployeesParams = z.infer<typeof HttpEmployeesParams>
