import styled, {css, DefaultTheme} from 'styled-components'

type StyledBtnProps = {
    iconOnly?: boolean
    width?: 'fullWidth' | 'wide' | 'auto'
    size: 'sm' | 'md' | 'lg' | 'xl'
    variant: 'primary' | 'primaryDanger' | 'primaryWarning' | 'secondary' | 'tertiary' | 'ghost' | 'tertiaryInfo'
}

const iconWidthBySize = {
    sm: 36,
    md: 40,
    lg: 44,
    xl: 60
} as const

type IconWidth = keyof typeof iconWidthBySize

//TODO: change font size
export const StyledButton = styled.button<StyledBtnProps>`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 2}px;
        border: none;
        border-radius: 8px;
        transition: ease-in-out 200ms;
        cursor: pointer;
        font-weight: 600;
        white-space: nowrap;
        & svg {
            width: 20px;
            height: 20px;
        }
    `}
    ${({variant, theme}) => ColorStyles(theme)[variant]};
    ${({size, iconOnly, theme}) => SizeStyles({theme, iconOnly})[size]};
    ${({iconOnly, size, width}) => WidthStyles({iconOnly, size})[width || 'auto']};
`

const ColorStyles = ({palette, shadows, spacing}: DefaultTheme) => ({
    primary: css`
        color: ${palette.neutral.white};
        background: ${palette.primary['600']};
        border: 1px solid ${palette.primary['600']};
        box-shadow: ${shadows.xs};
        &:not(:disabled):hover {
            background: ${palette.primary['700']};
            border: 1px solid ${palette.primary['700']};
        }
        &:not(:disabled):focus {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.neutral['100']}`};
        }
        &:disabled {
            background: ${palette.primary['200']};
            border: 1px solid ${palette.primary['200']};
            cursor: not-allowed;
        }
    `,
    primaryDanger: css`
        color: ${palette.neutral.white};
        background: ${palette.danger['600']};
        border: 1px solid ${palette.danger['600']};
        box-shadow: ${shadows.xs};
        &:not(:disabled):hover {
            background: ${palette.danger['700']};
            border: 1px solid ${palette.danger['700']};
        }
        &:not(:disabled):focus {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.neutral['100']}`};
        }
        &:disabled {
            background: ${palette.danger['200']};
            border: 1px solid ${palette.danger['200']};
            cursor: not-allowed;
        }
    `,
    primaryWarning: css`
        color: ${palette.neutral.white};
        background: ${palette.warning['600']};
        border: 1px solid ${palette.warning['600']};
        box-shadow: ${shadows.xs};
        &:not(:disabled):hover {
            background: ${palette.warning['700']};
            border: 1px solid ${palette.warning['700']};
        }
        &:not(:disabled):focus {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.neutral['100']}`};
        }
        &:disabled {
            background: ${palette.warning['200']};
            border: 1px solid ${palette.warning['200']};
            cursor: not-allowed;
        }
    `,
    secondary: css`
        background: ${palette.neutral.white};
        color: ${palette.neutral['700']};
        border: 1px solid ${palette.neutral['600']};
        box-shadow: ${shadows.xs};
        &:not(:disabled):hover {
            background: ${palette.neutral['50']};
        }

        &:not(:disabled):focus {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.neutral['100']}`};
        }

        &:disabled {
            background: ${palette.neutral[200]};
            color: ${palette.neutral['300']};
            cursor: not-allowed;
        }
    `,
    ghost: css`
        background: transparent;
        color: ${palette.neutral['700']};
        &:not(:disabled):hover {
            background: ${palette.neutral['50']};
        }

        &:disabled {
            background: transparent;
            color: ${palette.neutral['300']};
            cursor: not-allowed;
        }
    `,
    tertiary: css`
        background: transparent;
        color: ${palette.neutral['600']};
        font-weight: 500;
        text-decoration: underline;
        text-underline-offset: ${spacing}px;
        &:not(:disabled):hover {
            background: transparent;
        }
        &:disabled {
            background: ${palette.neutral.white};
            color: ${palette.neutral['300']};
            cursor: not-allowed;
        }
    `,
    tertiaryInfo: css`
        color: ${palette.neutral.white};
        background: ${palette.tertiary['600']};
        border: 1px solid ${palette.tertiary['600']};
        box-shadow: ${shadows.xs};
        &:not(:disabled):hover {
            background: ${palette.tertiary['700']};
            border: 1px solid ${palette.tertiary['700']};
        }
        &:not(:disabled):focus {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.tertiary['100']}`};
        }
        &:disabled {
            background: ${palette.tertiary['200']};
            border: 1px solid ${palette.tertiary['200']};
            cursor: not-allowed;
        }
    `
})

type SizesProps = {
    iconOnly?: boolean
    theme: DefaultTheme
}

const SizeStyles = ({theme: {spacing, typography}, iconOnly}: SizesProps) => ({
    sm: css`
        height: 36px;
        ${!iconOnly &&
        css`
            padding: 0 ${spacing * 3.5}px;
        `};
        ${typography.textSm}
    `,
    md: css`
        height: 40px;
        ${!iconOnly &&
        css`
            padding: 0 ${spacing * 4}px;
        `};
        ${typography.textSm}
    `,
    lg: css`
        height: 44px;
        ${iconOnly &&
        css`
            width: 44px;
        `}
        ${!iconOnly &&
        css`
            padding: 0 ${spacing * 4.5}px;
        `};
        ${typography.textMd}
    `,
    xl: css`
        height: 60px;
        ${!iconOnly &&
        css`
            padding: 0 ${spacing * 7}px;
        `};
        ${typography.textLg}
    `
})

type WidthStylesProps = {
    iconOnly?: boolean
    size: IconWidth
}

const WidthStyles = ({iconOnly, size}: WidthStylesProps) => ({
    fullWidth: css`
        width: ${iconOnly ? `${iconWidthBySize[size]}px` : '100%'};
    `,
    wide: css`
        width: ${iconOnly ? `${iconWidthBySize[size]}px` : '150px'};
    `,
    auto: css`
        width: ${iconOnly ? `${iconWidthBySize[size]}px` : 'auto'};
    `
})
