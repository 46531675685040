import {createBrowserRouter} from 'react-router-dom'
import PrivateRoute from '@route-guards/private-route/PrivateRoute.tsx'
import PublicRoute from '@route-guards/public-route/PublicRoute.tsx'
import PublicLayout from '@layouts/public-layout/PublicLayout.tsx'
import PrivateLayout from '@layouts/private-layout/PrivateLayout.tsx'
import {routes} from '@utilities/constants'
import {ErrorBoundary} from './ErrorBoundary'
import EntityRoute from './route-guards/entity-route/EntityRoute'

const router = createBrowserRouter(
    [
        {
            ...routes.NOT_FOUND
        },
        {
            element: <PrivateRoute />,
            errorElement: <ErrorBoundary />,
            children: [
                {
                    element: <PrivateLayout withHeader />,
                    children: [
                        {...routes.FORM_SHOWCASE}, //TODO: to remove here only for the starting phase
                        {...routes.HOME},
                        {...routes.PROJECTS},
                        {...routes.SALS},
                        {...routes.NEW_PROJECT},
                        {
                            element: <EntityRoute />,
                            children: [
                                {
                                    ...routes.PROJECT_DETAIL,
                                    children: [
                                        {...routes.PROJECT_DETAIL_EDIT},
                                        {...routes.PROJECT_DETAILS_PHASES},
                                        {...routes.PROJECT_DETAILS_SALS},
                                        {...routes.PROJECT_DETAILS_REVENUES},
                                        {...routes.PROJECT_DETAILS_COSTS}
                                    ]
                                }
                            ]
                        },
                        {...routes.CUSTOMERS},
                        {...routes.NEW_CUSTOMERS},
                        {
                            element: <EntityRoute />,
                            children: [{...routes.EDIT_CUSTOMERS}]
                        },
                        {
                            ...routes.INVOICES
                        },
                        {
                            ...routes.COSTS
                        },
                        {
                            ...routes.EMPLOYEES
                        },
                        {
                            ...routes.NEW_EMPLOYEES
                        },
                        {
                            ...routes.EDIT_EMPLOYEES
                        }
                    ]
                }
            ]
        },

        {
            element: <PublicRoute />, // anonymous user layout route
            errorElement: <ErrorBoundary />,
            children: [
                {
                    element: <PublicLayout />,
                    children: [{...routes.LOGIN}, {...routes.SSO_REDIRECT}]
                }
            ]
        }
        /*{
            path: '*', // Not found route
            element: <pages.NotFound />,
        },*/
    ],
    {
        basename: '/'
    }
)

export default router
