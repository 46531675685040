import Table, {TableSorter} from '@/components/commons/table/Table'
import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {generatePath, Link, useNavigate} from 'react-router-dom'
import {Employee} from '@/features/employee/types.ts'
import {TableDataCell} from '@/components/commons/table/TableDataCell/TableDataCell'
import 'dayjs/locale/en'
import {Chip} from '@/components/ui/chip/Chip'
import {
    CheckIcon,
    DotsVerticalIcon,
    Edit01Icon,
    Link03Icon,
    LinkBroken01Icon,
    PlusIcon,
    SearchLgIcon,
    Trash01Icon,
    XCloseIcon
} from '@/components/ui/icon'
import {EmployeesSearchParams} from '../../types.ts'
import {routes} from '@utilities/constants'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import DropdownMenu from '@components/ui/dropdown-menu/DropdownMenu.tsx'

interface EmployeesTableProps {
    data: Employee[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    searchValue?: string
    onChangePage?: () => void
    onResetSearchCb?: () => void
    sorter?: TableSorter<EmployeesSearchParams['orderBy']> | undefined
    setEmployeeToDelete: Dispatch<SetStateAction<Employee | null>>
}

export const EmployeesTable: FC<EmployeesTableProps> = ({
    data,
    isLoading,
    sorter,
    searchValue,
    onChangePage,
    onResetSearchCb,
    setEmployeeToDelete
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])
    const navigate = useNavigate()

    return (
        <Table
            isMobile={isMobile}
            data={data}
            isLoading={isLoading}
            sorter={sorter}
            searchValue={searchValue}
            onChangePage={onChangePage}
            columns={[
                {
                    label: t('employee:table:labels:fullname'),
                    width: '15%',
                    sortName: 'fullName',
                    cellRender: employee => (
                        <TableDataCell isBold={true} size="sm" title="">
                            <Link
                                title={employee.fullName}
                                to={generatePath(routes.EDIT_EMPLOYEES.path, {
                                    id: employee.id
                                })}
                            >
                                {employee.fullName}
                            </Link>
                        </TableDataCell>
                    )
                },
                {
                    label: t('employee:table:labels:email'),
                    width: '20%',
                    cellRender: employee => (
                        <TableDataCell size={'sm'} title={employee.email}>
                            <p>{employee.email}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('employee:table:labels:role'),
                    width: '10%',
                    cellRender: employee => (
                        <TableDataCell title={t(`employee:roles:${employee.role}`)} size="xs">
                            <p>{t(`employee:roles:${employee.role}`)}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('employee:table:labels:scope'),
                    width: '10%',
                    cellRender: employee => (
                        <TableDataCell title={employee.scope ? t(`scopes:${employee.scope.name}`) : ''} size="xs">
                            <p>{employee.scope ? t(`scopes:${employee.scope.name}`) : '-'}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('employee:table:labels:team'),
                    width: '11%',
                    cellRender: employee => (
                        <TableDataCell size="xs" title={employee.team ? employee.team.name : ''}>
                            <p>{employee.team ? employee.team.name : '-'}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('employee:table:labels:weekly_hours'),
                    width: '10%',
                    alignment: 'center',
                    cellRender: employee => (
                        <TableDataCell size="xs">
                            <p>{employee.weeklyHours}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('employee:table:labels:status'),
                    width: '10%',
                    overflow: 'visible',
                    cellRender: employee => (
                        <TableDataCell size="xs">
                            <Chip
                                id={employee.isActive ? 'active' : 'disabled'}
                                label={employee.isActive ? t('employee:status:active') : t('employee:status:disabled')}
                                variant={employee.isActive ? 'success' : 'default'}
                                icon={employee.isActive ? <CheckIcon size={12} /> : <XCloseIcon size={12} />}
                                title={employee.isActive ? t('employee:status:active') : t('employee:status:disabled')}
                                size={12}
                            />
                        </TableDataCell>
                    )
                },
                {
                    label: t('employee:table:labels:float'),
                    width: '10%',
                    overflow: 'visible',
                    cellRender: employee => (
                        <TableDataCell size="xs">
                            <Chip
                                id={employee.floatExternalId ? employee.floatExternalId.toString() : 'unlinked'}
                                title={
                                    employee.floatExternalId
                                        ? t('employee:floatStatus:linked')
                                        : t('employee:floatStatus:unlinked')
                                }
                                size={12}
                                variant={employee.floatExternalId ? 'success' : 'warning'}
                                label={
                                    employee.floatExternalId
                                        ? t('employee:floatStatus:linked')
                                        : t('employee:floatStatus:unlinked')
                                }
                                icon={
                                    employee.floatExternalId ? <Link03Icon size={12} /> : <LinkBroken01Icon size={12} />
                                }
                            />
                        </TableDataCell>
                    )
                },
                {
                    label: ' ',
                    width: '5%',
                    alignment: 'right',
                    cellRender: employee => (
                        <TableDataCell>
                            <DropdownMenu
                                sideOffset={8}
                                trigger={<DotsVerticalIcon />}
                                actions={[
                                    {
                                        component: 'Edit',
                                        icon: <Edit01Icon size={16} />,
                                        onClickCb: () =>
                                            navigate(
                                                generatePath(routes.EDIT_EMPLOYEES.path, {
                                                    id: employee.id
                                                })
                                            )
                                    },
                                    {
                                        component: 'Delete',
                                        icon: <Trash01Icon size={16} />,
                                        onClickCb: () => {
                                            employee.isActive ? setEmployeeToDelete(employee) : null
                                        },
                                        disabled: employee.isActive === false
                                    }
                                ]}
                            />
                        </TableDataCell>
                    )
                }
            ]}
            emptyStateComponent={
                <EmptyResults
                    title={t('employee:table:empty:title')}
                    subtitle={t('employee:table:empty:subtitle')}
                    cta={{
                        path: routes.NEW_EMPLOYEES.path,
                        text: t('employee:new_employee'),
                        icon: <PlusIcon />
                    }}
                />
            }
            emptySearchStateComponent={
                <EmptySearch
                    title={t('commons:no_results')}
                    icon={<SearchLgIcon />}
                    onClearSearch={() => onResetSearchCb && onResetSearchCb()}
                />
            }
        />
    )
}
