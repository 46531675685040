import {RouterProvider} from 'react-router-dom'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import {getAppTheme, GlobalStyles} from '@/theme'
import router from './router.tsx'
import {Toaster} from 'react-hot-toast'
import {GoogleOAuthProvider} from '@react-oauth/google'
import {envVars} from './envVars.ts'

function App() {
    const themeName = 'light' //get it from the app reducer

    return (
        <GoogleOAuthProvider clientId={envVars.VITE_GOOGLE_APP_ID}>
            <ThemeProvider theme={getAppTheme({name: themeName})}>
                <StyleSheetManager>
                    <>
                        <Toaster />
                        <GlobalStyles />
                        <RouterProvider router={router} />
                    </>
                </StyleSheetManager>
            </ThemeProvider>
        </GoogleOAuthProvider>
    )
}

export default App
