import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledValueIndicatorWrapper = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        position: relative;
        background: ${palette.neutral['100']};
        border: 1px solid ${palette.neutral['300']};
        border-radius: ${spacing * 2}px;
        width: 100%;
        ${({size, theme}: {size: 'sm' | 'md' | 'lg'; theme: DefaultTheme}) => ValueIndicatorSizeStyles(theme)[size]};
    `
)

const ValueIndicatorSizeStyles = ({spacing}: DefaultTheme) => ({
    sm: css`
        height: 40px;
        padding: 0 ${spacing * 9}px 0 ${spacing * 3}px;
    `,
    md: css`
        height: 44px;
        padding: 0 ${spacing * 9}px 0 ${spacing * 3.5}px;
    `,
    lg: css`
        height: 48px;
        padding: 0 ${spacing * 9}px 0 ${spacing * 4}px;
    `
})

export const StyledValueIndicator = styled.div(
    ({theme: {palette, typography}}) => css`
        ${typography.textMd}
        cursor: not-allowed;
        color: ${palette.neutral['400']};
    `
)
