import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {useCostsQuery} from './useCosts'
import {CostsSearchParams} from '../types'
import {useInputSearch} from '@/hooks/useInputSearch'
import {ResponseType} from '@/types/commons'
import {useEffect} from 'react'

export const useCostsTable = () => {
    const multiParams = [] as const satisfies ReadonlyArray<keyof CostsSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, CostsSearchParams)
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch(searchParams.search)
    const resultsQuery = useCostsQuery({
        ...searchParams,
        limit: 20,
        responseType: ResponseType.enum.extended
    })

    useEffect(() => {
        setSearchParams({search: searchValue})
    }, [searchValue])

    const toggleReceivedHandler = (received: boolean) => {
        setSearchParams({received: received ? 'true' : 'false'})
    }

    const sort = (sorter: {
        orderBy: CostsSearchParams['orderBy']
        orderDirection: CostsSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...resultsQuery,
        toggleReceivedHandler,
        searchParams,
        setSearchParams,
        searchValue,
        searchError,
        onSearch,
        onResetSearch,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
