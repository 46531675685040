import {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {useEmployeesQuery} from '@/features/employee/services/queries/useEmployees'
import {useProjectTypesQuery} from '../../services/queries/useProjectTypes'
import {EmployeeRole} from '@/features/employee/types.ts'
import {ResponseType} from '@/types/commons'
import {SelectValue} from '@/components/commons/select/Select'
import {ProjectsFiltersParams, ProjectsSearchParams} from '../../types'
import {FilterLinesIcon} from '@/components/ui/icon'
import {StyledProjectFiltersOverlay, StyledProjectsFilters, StyledProjectsFiltersTrigger} from './style'
import {PROJECT_STATUSES, getActiveFilters} from '../../utils'
import {ProjectsFiltersValidationSchema} from './utils'
import {ProjectsFiltersForm} from './ProjectsFiltersForm'
import {useCustomersQuery} from '@/features/customer/services/queries/useCustomers'

export const ProjectsFilters = () => {
    const {t} = useTranslation()
    const multiParams = [
        'projectTypeIds',
        'successManagerIds',
        'teamLeadIds',
        'productLeadIds',
        'status',
        'customerIds'
    ] as const satisfies ReadonlyArray<keyof ProjectsSearchParams>

    const [visibility, setVisibility] = useState(false)

    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, ProjectsSearchParams)

    // Get data for Select options
    const projectTypeQuery = useProjectTypesQuery()

    const successManagersQuery = useEmployeesQuery({
        limit: 100,
        responseType: ResponseType.Enum.small,
        roles: [EmployeeRole.Enum.success_manager, EmployeeRole.Enum.admin],
        isActive: true
    })

    const teamLeadsQuery = useEmployeesQuery({
        limit: 100,
        roles: [EmployeeRole.Enum.team_lead],
        responseType: ResponseType.Enum.small,
        linkedToFloat: true
    })
    const productLeadsQuery = useEmployeesQuery({
        limit: 100,
        responseType: ResponseType.Enum.small,
        isActive: true
    })

    const customersQuery = useCustomersQuery({
        limit: 100,
        responseType: ResponseType.Enum.small
    })

    // Get options from queries
    const projectTypeOptions = useMemo<SelectValue[]>(
        () =>
            projectTypeQuery.remappedData.map(
                item => ({value: item.id.toString(), label: t(`project:types:${item.name}`)}) as SelectValue
            ),
        [projectTypeQuery.remappedData]
    )

    const successManagerOptions = useMemo<SelectValue[]>(
        () =>
            successManagersQuery.remappedData.map(
                item => ({value: item.id.toString(), label: item.fullName}) as SelectValue
            ),
        [successManagersQuery.remappedData]
    )

    const teamLeadOptions = useMemo<SelectValue[]>(
        () =>
            teamLeadsQuery.remappedData.map(item => ({value: item.id.toString(), label: item.fullName}) as SelectValue),
        [teamLeadsQuery.remappedData]
    )

    const productLeadOptions = useMemo<SelectValue[]>(
        () =>
            productLeadsQuery.remappedData.map(
                item => ({value: item.id.toString(), label: item.fullName}) as SelectValue
            ),
        [productLeadsQuery.remappedData]
    )
    const customerOptions = useMemo<SelectValue[]>(
        () =>
            customersQuery.remappedData.map(
                item => ({value: item.id.toString(), label: item.businessName}) as SelectValue
            ),
        [customersQuery.remappedData]
    )

    // Get active filters
    const activeFilterKeys = getActiveFilters({
        projectTypeIds: searchParams?.projectTypeIds || [],
        successManagerIds: searchParams?.successManagerIds || [],
        teamLeadIds: searchParams?.teamLeadIds || [],
        productLeadIds: searchParams?.productLeadIds || [],
        status: searchParams?.status || [],
        customerIds: searchParams?.customerIds || []
    })

    // Get filters values
    const filtersUrlValues = useMemo<ProjectsFiltersValidationSchema>(
        () => ({
            type: projectTypeOptions.filter(item => searchParams.projectTypeIds?.includes(parseInt(item.value))),
            successManager: successManagerOptions.filter(
                item => searchParams.successManagerIds?.includes(parseInt(item.value))
            ),
            teamLead: teamLeadOptions.filter(item => searchParams.teamLeadIds?.includes(parseInt(item.value))),
            productLead: productLeadOptions.filter(item => searchParams.productLeadIds?.includes(parseInt(item.value))),
            customer: customerOptions.filter(item => searchParams.customerIds?.includes(parseInt(item.value))),
            status:
                searchParams?.status?.map(item => ({value: item, label: t(`project:status:${item}`)}) as SelectValue) ||
                []
        }),
        [searchParams]
    )

    const onSubmitFilters = (filters: ProjectsFiltersParams) => {
        setSearchParams(filters)
        setVisibility(false)
    }

    return (
        <>
            <StyledProjectsFilters>
                {visibility && <StyledProjectFiltersOverlay onClick={() => setVisibility(false)} />}

                <StyledProjectsFiltersTrigger variant={'secondary'} size={'md'} onClick={() => setVisibility(true)}>
                    <span>
                        {t('commons:filter_label')}
                        {activeFilterKeys.length ? <b>({activeFilterKeys.length})</b> : null}
                    </span>
                    <FilterLinesIcon />
                </StyledProjectsFiltersTrigger>

                {visibility && (
                    <ProjectsFiltersForm
                        hideModal={() => setVisibility(false)}
                        onSubmitFilters={onSubmitFilters}
                        filtersUrlValues={filtersUrlValues}
                        filtersOptions={{
                            type: projectTypeOptions,
                            successManager: successManagerOptions,
                            teamLead: teamLeadOptions,
                            productLead: productLeadOptions,
                            customer: customerOptions,
                            status: PROJECT_STATUSES.map(item => ({value: item.value, label: t(item.label)}))
                        }}
                    />
                )}
            </StyledProjectsFilters>
        </>
    )
}
