import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledInvoicedModal = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
    `
)

export const StyledFooter = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 8}px;
        justify-content: flex-end;
    `
)

export const StyledTitle = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textLg}
        font-weight: bold;
        color: ${palette.neutral['900']};
        display: block;
    `}
`

export const StyledSeparator = styled.div`
    ${({theme: {spacing, palette}}) => css`
        margin-top: ${spacing * 8}px;
        margin-bottom: ${spacing * 6}px;
        border-top: solid 1px ${palette.neutral['300']};
    `}
`

export const StyledInformationWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 4}px;
    `}
`

export const StyledResumeWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 4}px;
    `}
`

export const StyledRowWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px;
        border: solid 1px ${palette.neutral['300']};
        border-radius: 8px;
        margin: ${spacing * 6}px 0px;
    `}
`

export const StyledRowAmountsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: ${spacing * 4}px;
    `}
`

export const StyledRowDescriptionsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 4}px;
        margin: ${spacing * 4}px 0px;
    `}
`
export const StyledRowDescriptionsRoWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 4}px;
        margin: ${spacing * 4}px 0px;
    `}
`

export const StyledRowActionsWrapper = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 2}px;
    `}
`

export const StyledRowsActionsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 4}px;
    `}
`

export const StyledSkeletonWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        gap: ${spacing * 8}px;
        display: grid;
        padding: ${spacing * 6}px ${spacing * 4}px ${spacing * 6}px ${spacing * 4}px;
    `}
`
