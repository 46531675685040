import {QueryClient, UseInfiniteQueryResult} from '@tanstack/react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'online',
            retry: 1,
            retryOnMount: true,
            retryDelay: 1000,
            staleTime: 600000, //10 min
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    }
})

export const QUERY_KEYS = {
    USERS: 'users',
    INTERNAL_DAILY_RATE: 'internal-daily-rate',
    PROJECT_DETAILS: 'project-details',
    PROJECT_TYPES: 'project-types',
    PROJECT_REVENUES_DATA: 'project-revenues-data',
    PROJECT_REVENUES: 'project-revenues',
    PROJECTS: 'projects',
    PROJECT_PHASES: 'project-phases',
    PROJECT_SALS: 'project-sals',
    PROJECT_COSTS: 'project-costs',
    INVOICES: 'invoices',
    INVOICE_DETAILS: 'invoice-details',
    COSTS: 'costs',
    EMPLOYEES: 'employees',
    EMPLOYEE_DETAILS: 'employee-details',
    SCOPES: 'scopes',
    CUSTOMERS: 'customers',
    CUSTOMER_DETAILS: 'customer-details',
    VAT: 'vat',
    BANK: 'bank',
    BANK_DETAILS: 'bank-details',
    TEAMS: 'teams',
    SALS: 'sals'
} as const

export const MUTATION_KEYS = {
    DELETE_USER: 'delete-users',
    CREATE_CUSTOMER: 'create-customer',
    DELETE_CUSTOMER: 'delete-customer',
    UPDATE_CUSTOMER: 'update-customer',
    CREATE_EMPLOYEE: 'create-employee',
    UPDATE_EMPLOYEE: 'update-employee',
    DELETE_EMPLOYEE: 'delete-employee',
    CREATE_PROJECT: 'create-project',
    UPDATE_PROJECT: 'update-project',
    DELETE_PROJECT: 'delete-project',
    UPDATE_PHASE_SERVICE_LINE: 'update-phase-service-line',
    CREATE_PROJECT_COST: 'create-project-cost',
    UPDATE_PROJECT_COST: 'update-project-cost',
    CREATE_PROJECT_SAL: 'create-project-sal',
    UPDATE_PROJECT_SAL: 'update-project-sal',
    DELETE_PROJECT_SAL: 'delete-project-sal',
    UPDATE_COST: 'update_cost',
    DELETE_COST: 'delete-cost',
    FLOAT_SYNC: 'float-sync',
    CREATE_INVOICE: 'create-invoice',
    UPDATE_INVOICE: 'update-invoice',
    DELETE_INVOICE: 'delete-invoice'
} as const

//rq-helpers
export const infiniteQueryFetchNextPage = async (infiniteQuery: UseInfiniteQueryResult) => {
    if (infiniteQuery.hasNextPage && !infiniteQuery.isFetching) {
        await infiniteQuery.fetchNextPage()
    }
}
