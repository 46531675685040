export function isValidRichString(value: string) {
    if (value.includes('{"root":{"children":[],"direction":null,"format":"","indent":0,"type":"root","version":1}}')) {
        return null
    }
    if (!value.includes('{"root":{"children":')) {
        const newValue = `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"${value.replace(
            '\n',
            ' '
        )}","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`
        return newValue
    }
    return value
}
