import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledErrorMessage} from '@components/commons/table/ErrorMessage/style.ts'

const ErrorMessage: FC = () => {
    const {t} = useTranslation()

    return <StyledErrorMessage>{t('errors:default')}</StyledErrorMessage>
}

ErrorMessage.displayName = 'DesktopTableErrorMessage'
export default ErrorMessage
