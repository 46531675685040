import {StyledSmall, StyledSmallError} from '@components/ui/input-help-text/style.ts'
import {DefaultNamespace} from 'i18next'

interface InputHelpTextProps {
    error?: string | DefaultNamespace
    helpText?: string | DefaultNamespace
}
const InputHelpText = ({error, helpText}: InputHelpTextProps) => {
    return (
        <>
            {error && <StyledSmallError>{error}</StyledSmallError>}
            {helpText && !error && <StyledSmall>{helpText}</StyledSmall>}
        </>
    )
}

export default InputHelpText

InputHelpText.displayName = 'InputHelpText'
