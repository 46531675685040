import axios from 'axios'
import {envVars} from '@/envVars.ts'
import {useAuthStore} from '@/store/AuthStore'
import {errorHandler} from '@utilities/genericErrorHandler.ts'

const axiosConfig = {
    baseURL: envVars.VITE_API_ENDPOINT,
    headers: {
        common: {
            Authorization: useAuthStore.getState().accessToken
                ? `Bearer ${useAuthStore.getState().accessToken}`
                : false,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        post: {
            'Access-Control-Allow-Origin': '*'
        }
    }
}

// create axios custom instance with custom config
const axiosInstance = axios.create(axiosConfig)

const attemptRefresh = async (refreshToken: string) => {
    const url = '/auth/refresh'
    try {
        const {data} = await axiosInstance.post(url, {token: refreshToken})
        useAuthStore.getState().setAccessToken(data.accessToken)
        useAuthStore.getState().setRefreshToken(data.refreshToken)
        return data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            errorHandler(error)
        } else {
            console.error(error)
        }
    }
}

axiosInstance.interceptors.request.use(request => {
    const token = useAuthStore.getState().accessToken
    if (token) request.headers['Authorization'] = `Bearer ${token}`
    return request
})

const endpointToIgnore = ['/login']

axiosInstance.interceptors.response.use(
    response => {
        // pass response without change
        return response
    },
    async error => {
        // get error info
        const statusCode = error?.response?.status
        const originalRequest = error.config
        console.log('error', error)
        switch (statusCode) {
            case 401: {
                const refreshToken = useAuthStore.getState().refreshToken
                useAuthStore.getState().setRefreshToken(null)
                if (refreshToken && !originalRequest._retry && !endpointToIgnore.includes(error.config.url)) {
                    originalRequest._retry = true // prevent infinite retry loop
                    await attemptRefresh(refreshToken)

                    return axiosInstance.request(originalRequest)
                }
                useAuthStore.getState().reset()
                return Promise.reject(error)
            }
            default:
                return Promise.reject(error)
        }
    }
)

export default axiosInstance
