import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND} from '@lexical/list'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Bold01Icon, ListIcon, OrderedList} from '@components/ui/icon'
import {$getSelection, $isRangeSelection} from 'lexical'
import {StyledHeaderRichTextAreaButton} from '@components/commons/rich-textarea/rich-textarea/style.ts'
import {useEffect, useState} from 'react'

export const ToolbarPlugin = () => {
    const [editor] = useLexicalComposerContext()
    const [isBold, setIsBold] = useState(false)

    type ListTag = 'ol' | 'ul'
    const onClick = (tag: ListTag) => {
        if (tag === 'ol') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
            return
        } else {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
        }
    }
    const onClickBold = (): void => {
        editor.update(() => {
            const selection = $getSelection()
            if ($isRangeSelection(selection)) {
                selection.formatText('bold')
            }
        })
    }

    useEffect(() => {
        editor.update(() => {
            const selection = $getSelection()
            if ($isRangeSelection(selection)) {
                setIsBold(selection.hasFormat('bold'))
            }
        })
    }, [editor, onClickBold, onClick])

    return (
        <Flexbox align={'center'} gap={4}>
            <StyledHeaderRichTextAreaButton
                variant={'ghost'}
                onClick={onClickBold}
                key={'bold'}
                iconOnly={true}
                isActive={isBold}
            >
                <Bold01Icon />
            </StyledHeaderRichTextAreaButton>
            <StyledHeaderRichTextAreaButton variant={'ghost'} onClick={() => onClick('ul')} key={'ul'} iconOnly={true}>
                <ListIcon />
            </StyledHeaderRichTextAreaButton>
            <StyledHeaderRichTextAreaButton variant={'ghost'} onClick={() => onClick('ol')} key={'ol'} iconOnly={true}>
                <OrderedList />
            </StyledHeaderRichTextAreaButton>
        </Flexbox>
    )
}
