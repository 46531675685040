import {QUERY_KEYS} from '@/queryClient'
import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {HttpSalsParams, HttpSalsResponse, httpGetSals} from '@/features/project-sals/services/projectSal.http'

export const useProjectSalsQuery = (
    params: HttpSalsParams,
    options?: Omit<UseQueryOptions<HttpSalsResponse>, 'queryFn' | 'queryKey'>
) => {
    const query = useQuery({
        ...options,
        queryKey: [QUERY_KEYS.PROJECT_SALS, params.projectId, params.search, params.orderBy, params.orderDirection],
        queryFn: () => httpGetSals(params)
    })

    return query
}
