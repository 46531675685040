import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledSyncButtonWrapper = styled(Flexbox)(
    ({theme: {typography}}) => css`
        text-align: center;
        p {
            font-style: italic;
            font-weight: 400;
            ${typography.textSm};
            text-align: center;
            white-space: nowrap;
        }
    `
)
