import Divider from '@/components/ui/divider/Divider'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import Skeleton from '@/components/ui/skeleton/Skeleton'
import {StyledSkeletonWrapper} from './style'

export const InvoicesFormLoadingSkeleton: React.FC = () => {
    return (
        <StyledSkeletonWrapper>
            <Flexbox gap={10}>
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
            </Flexbox>
            <Flexbox gap={10}>
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
            </Flexbox>

            <Divider topSpacing={20} bottomSpacing={20} />

            <Flexbox gap={10}>
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
            </Flexbox>

            <Flexbox gap={10}>
                <Skeleton height={50} />
                <Skeleton height={50} />
            </Flexbox>

            <Divider topSpacing={20} bottomSpacing={20} />

            <Flexbox gap={10}>
                <Skeleton height={50} />
                <Skeleton height={50} />
                <Skeleton height={50} />
            </Flexbox>

            <Flexbox justify={'end'} width={'100%'} gap={4}>
                <Skeleton height={50} width={150} />
                <Skeleton height={50} width={150} />
            </Flexbox>
        </StyledSkeletonWrapper>
    )
}
