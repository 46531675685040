import axios from '@/axiosInstance.ts'
import {z} from 'zod'
import {PaginationParams, ResponseType} from '@/types/commons.ts'
import {Invoice} from '@/features/invoice/types.ts'

export const HttpGetRevenuesParams = z.object({
    projectId: z.coerce.number().int(),
    responseType: ResponseType
})
export type HttpGetRevenuesParams = z.infer<typeof HttpGetRevenuesParams>

export const HttpRevenuesParams = z.object({
    ...HttpGetRevenuesParams.shape,
    ...PaginationParams.shape
})
export type HttpRevenuesParams = z.infer<typeof HttpRevenuesParams>

// Response
export const HttpGetRevenuesResponse = z.array(Invoice)
export type HttpGetRevenuesResponse = z.infer<typeof HttpGetRevenuesResponse>

export const httpGetProjectRevenues = async (params: HttpRevenuesParams): Promise<HttpGetRevenuesResponse> => {
    const result = await axios.get(`/projects/${params.projectId}/invoices`, {params})
    return HttpGetRevenuesResponse.parse(result.data)
}
export const HttpGetRevenueDetailsParams = z.object({
    responseType: ResponseType
})
export type HttpGetRevenueDetailsParams = z.infer<typeof HttpGetRevenueDetailsParams>
export const httpGetProjectRevenueDetails = async (params: HttpGetRevenueDetailsParams, id: number) => {
    const result = await axios.get(`/invoices/${id}`, {params})
    return Invoice.parse(result.data)
}
