import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {LoadingScreen} from '@/components/commons/loading-screen/LoadingScreen'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {AlertTriangleIcon} from '@/components/ui/icon'
import {PageTitle} from '@/components/ui/page-title/PageTitle'
import {useProjectDetails} from '@/features/project/services/queries/useProjectDetails'
import {EntityDetailsURLParams} from '@/route-guards/entity-route/EntityRoute'
import {ResponseType} from '@/types/commons'
import {StyledContainer, StyledNavLink, StyledTabsWrapper, StyledTabContent} from '@pages/project-details/style.ts'
import {useTranslation} from 'react-i18next'
import {Outlet, useOutletContext} from 'react-router-dom'

export const projectTabs = [
    {label: 'Details', path: 'info'},
    {label: 'Phases', path: 'phases'},
    {label: 'SAL', path: 'sal'},
    {label: 'Revenues', path: 'revenues'},
    {label: 'Costs', path: 'costs'}
] as const

export const ProjectDetails = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: EntityDetailsURLParams}>()
    const projectQuery = useProjectDetails({
        projectId: urlParams.id,
        responseType: ResponseType.enum.extended
    })

    if (projectQuery.isLoading) {
        return <LoadingScreen />
    }

    if (projectQuery.isError || !projectQuery.data) {
        return <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
    }

    return (
        <StyledContainer fullHeight fullWidth>
            <PageTitle text={`#${projectQuery.data.id} ${projectQuery.data.name}`} />

            <StyledTabsWrapper justify="space-between" align="center">
                <Flexbox gap={4}>
                    {projectTabs.map(tab => {
                        // if project's type is not billable don't show SAL tab
                        if (tab.path === 'sal' && !projectQuery.data.type.isBillable) {
                            return null
                        }
                        return (
                            <StyledNavLink to={tab.path} key={tab.path}>
                                {tab.label}
                            </StyledNavLink>
                        )
                    })}
                </Flexbox>
            </StyledTabsWrapper>
            <StyledTabContent>
                <Outlet context={{project: projectQuery.data}} />
            </StyledTabContent>
        </StyledContainer>
    )
}

ProjectDetails.displayName = 'ProjectDetails'
