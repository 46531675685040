import {Invoice, InvoiceStatus} from '@/features/invoice/types.ts'
import DropdownMenu from '@components/ui/dropdown-menu/DropdownMenu.tsx'
import {
    Copy01Icon,
    DotsVerticalIcon,
    Edit01Icon,
    EyeIcon,
    Link03Icon,
    Trash01Icon,
    UploadCloud01Icon
} from '@components/ui/icon'
import {Dispatch, SetStateAction} from 'react'
import toast from 'react-hot-toast'
import i18next from 'i18next'
interface RevenueDropdownProps {
    invoice: Invoice
    setRevenueToDelete: Dispatch<SetStateAction<number | null>>
    setRevenueIdToEdit: Dispatch<SetStateAction<number | null>>
    setRevenueIdToDuplicate: Dispatch<SetStateAction<number | null>>
    setRevenueIdToView: Dispatch<SetStateAction<number | null>>
    setRevenueToUpload: Dispatch<SetStateAction<Invoice | null>>
}
export const RevenueDropdown: React.FC<RevenueDropdownProps> = ({
    invoice,
    setRevenueToDelete,
    setRevenueIdToEdit,
    setRevenueIdToDuplicate,
    setRevenueIdToView,
    setRevenueToUpload
}) => {
    if (invoice.status === InvoiceStatus.enum.sent && invoice.ficExternalId) {
        return (
            <DropdownMenu
                sideOffset={8}
                trigger={<DotsVerticalIcon />}
                actions={[
                    {
                        component: 'View',
                        icon: <EyeIcon size={16} />,
                        onClickCb: () => setRevenueIdToView(invoice.id)
                    },
                    {
                        component: 'Delete',
                        icon: <Trash01Icon size={16} />,
                        disabled: true
                    },
                    {
                        component: 'Synced',
                        icon: <Link03Icon size={16} />,
                        onClickCb: () => {
                            invoice.ficViewUrl
                                ? window.open(invoice.ficViewUrl)
                                : toast.error(i18next.t('errors:invalid_link'))
                        }
                    },
                    {
                        component: 'Duplicate',
                        icon: <Copy01Icon size={16} />,
                        onClickCb: () => {
                            setRevenueIdToDuplicate(invoice.id)
                        }
                    }
                ]}
            />
        )
    }
    if (invoice.status === InvoiceStatus.enum.expected || invoice.status === InvoiceStatus.Enum.forecast) {
        return (
            <DropdownMenu
                sideOffset={8}
                trigger={<DotsVerticalIcon />}
                actions={[
                    {
                        component: 'Edit',
                        icon: <Edit01Icon size={16} />,
                        onClickCb: () => setRevenueIdToEdit(invoice.id)
                    },
                    {
                        component: 'Delete',
                        icon: <Trash01Icon size={16} />,
                        onClickCb: () => {
                            setRevenueToDelete(invoice.id)
                        }
                    },
                    {
                        component: 'Sync',
                        icon: <UploadCloud01Icon size={16} />,
                        disabled: true
                    },
                    {
                        component: 'Duplicate',
                        icon: <Copy01Icon size={16} />,
                        onClickCb: () => {
                            setRevenueIdToDuplicate(invoice.id)
                        }
                    }
                ]}
            />
        )
    }
    if (invoice.status === InvoiceStatus.enum.sent && invoice.customer.ficExternalId && !invoice.ficExternalId) {
        return (
            <>
                <DropdownMenu
                    sideOffset={8}
                    trigger={<DotsVerticalIcon />}
                    actions={[
                        {
                            component: 'Edit',
                            icon: <Edit01Icon size={16} />,
                            onClickCb: () => setRevenueIdToEdit(invoice.id)
                        },
                        {
                            component: 'Delete',
                            icon: <Trash01Icon size={16} />
                        },
                        {
                            component: 'Sync',
                            icon: <UploadCloud01Icon size={16} />,
                            onClickCb: () => setRevenueToUpload(invoice)
                        },
                        {
                            component: 'Duplicate',
                            icon: <Copy01Icon size={16} />,
                            onClickCb: () => {
                                setRevenueIdToDuplicate(invoice.id)
                            }
                        }
                    ]}
                />
            </>
        )
    }
    if (invoice.status === InvoiceStatus.enum.sent && !invoice.customer.ficExternalId) {
        return (
            <>
                <DropdownMenu
                    sideOffset={8}
                    trigger={<DotsVerticalIcon />}
                    actions={[
                        {
                            component: 'View',
                            icon: <EyeIcon size={16} />,
                            onClickCb: () => setRevenueIdToView(invoice.id)
                        },
                        {
                            component: 'Delete',
                            icon: <Trash01Icon size={16} />,
                            disabled: true
                        },
                        {
                            component: 'Sync',
                            icon: <UploadCloud01Icon size={16} />,
                            disabled: true
                        },
                        {
                            component: 'Duplicate',
                            icon: <Copy01Icon size={16} />,
                            onClickCb: () => {
                                setRevenueIdToDuplicate(invoice.id)
                            }
                        }
                    ]}
                />
            </>
        )
    }
    return null
}
