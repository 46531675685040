import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpCreateCustomer, HttpGetCustomerDetailsResponse} from '../customer.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {errorHandler} from '@/utilities/genericErrorHandler'

type UseCreateCustomerProps = {
    onSuccess?: (data: HttpGetCustomerDetailsResponse) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useCreateCustomer = (options?: UseCreateCustomerProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_CUSTOMER],
        mutationFn: httpCreateCustomer,
        onSuccess: async data => {
            await client.invalidateQueries({queryKey: [QUERY_KEYS.CUSTOMERS]})
            await client.invalidateQueries({queryKey: [QUERY_KEYS.CUSTOMER_DETAILS, data.id]})
            options?.onSuccess?.(data)
        },
        onError: (error: AxiosError<ErrorResponseData>) => errorHandler(error)
    })
}
