import dayjs from 'dayjs'
import {
    HttpCreateProjectParams,
    HttpGetProjectDetailsResponse,
    HttpUpdateProjectParams
} from '../../services/project.http'
import {ProjectStatus} from '../../types'
import {ProjectFormSchema, ProjectValidationSchema} from './ProjectFormModel'
import {t} from 'i18next'
import {z} from 'zod'
import {formatCurrency, removeCurrencyFormat} from '@/utilities/helpers'

type FormInputData = z.input<typeof ProjectFormSchema>

export const adaptProjectToForm = (project: HttpGetProjectDetailsResponse): FormInputData => {
    return {
        name: project.name ?? '',
        type: {
            label: t(`project:types:${project.type?.name}`),
            value: project.type?.id.toString() ?? ''
        },
        status: {
            label: t(`project:status:${project.status}`),
            value: project.status
        },
        startDate: project.startDate,
        endDate: project.endDate,
        brief: project.brief,
        successManager: {
            label: project.successManager?.fullName ?? '',
            value: project.successManager?.id.toString() ?? ''
        },
        teamLead: {
            label: project.teamLead?.fullName ?? '',
            value: project.teamLead?.id.toString() ?? ''
        },
        productLead: {
            label: project.productLead?.fullName ?? '',
            value: project.productLead?.id.toString() ?? ''
        },
        customer: {
            label: project.customer?.businessName ?? '',
            value: project.customer?.id.toString() ?? ''
        },
        budget: formatCurrency(project.budget, 'EUR'),
        hours: project.hours.toString(),
        dailyRate: formatCurrency(project.dailyRate, 'EUR'),
        masterAgreements: project.masterAgreements,
        salesFolder: project.salesFolder,
        operationFolder: project.operationFolder
    }
}

export const adaptProjectToCreate = (data: ProjectValidationSchema): HttpCreateProjectParams => {
    return {
        data: {
            name: data.name,
            status: data.status.value as ProjectStatus,
            typeId: data.type.value ? parseInt(data.type.value) : null,
            startDate: data.startDate ? dayjs(data.startDate).format('YYYY-MM-DD') : null,
            endDate: data.endDate ? dayjs(data.endDate).format('YYYY-MM-DD') : null,
            successManagerId: data.successManager ? parseInt(data.successManager.value) : null,
            teamLeadId: data.teamLead ? parseInt(data.teamLead.value) : null,
            productLeadId: data.productLead ? parseInt(data.productLead.value) : null,
            customerId: data.customer ? parseInt(data.customer.value) : null,
            budget: parseFloat(removeCurrencyFormat(data.budget)),
            hours: parseInt(data.hours),
            dailyRate: parseFloat(removeCurrencyFormat(data.dailyRate)),
            masterAgreements: data.masterAgreements ? data.masterAgreements : null,
            salesFolder: data?.salesFolder ?? null,
            operationFolder: data?.operationFolder ?? null,
            brief: data.brief ?? null
        }
    }
}

export const adaptProjectToUpdate = (projectId: number, data: ProjectValidationSchema): HttpUpdateProjectParams => {
    return {
        projectId,
        data: {
            name: data.name,
            status: data.status.value as ProjectStatus,
            typeId: data.type.value ? parseInt(data.type.value) : null,
            startDate: data.startDate ? dayjs(data.startDate).format('YYYY-MM-DD') : null,
            endDate: data.endDate ? dayjs(data.endDate).format('YYYY-MM-DD') : null,
            successManagerId: data.successManager ? parseInt(data.successManager.value) : null,
            teamLeadId: data.teamLead ? parseInt(data.teamLead.value) : null,
            productLeadId: data.productLead ? parseInt(data.productLead.value) : null,
            customerId: data.customer ? parseInt(data.customer.value) : null,
            budget: parseFloat(removeCurrencyFormat(data.budget)),
            hours: parseInt(data.hours),
            dailyRate: parseFloat(removeCurrencyFormat(data.dailyRate)),
            masterAgreements: data.masterAgreements ? data.masterAgreements : null,
            salesFolder: data?.salesFolder ? data.salesFolder : null,
            operationFolder: data?.operationFolder ? data.operationFolder : null,
            brief: data?.brief ? data.brief : null
        }
    }
}
