import styled, {DefaultTheme, css} from 'styled-components'
import {GroupBase, StylesConfig} from 'react-select'
import {SelectValue} from '@components/commons/select/Select.tsx'

type CustomStyleProps = {
    theme: DefaultTheme
}

type StyledPhaseDataCellProps = {
    isBold?: boolean
}

export const StyledContainer = styled.div`
    position: relative;
    display: inline-flex;
    width: fit-content;
    margin-left: auto;
    flex-direction: column;
`

export const StyledCostsTHead = styled.span(
    ({theme: {palette}}) => css`
        color: ${palette.tertiary[600]};
    `
)

export const StyledPhaseDataCell = styled.div<StyledPhaseDataCellProps>(
    ({isBold}) => css`
        font-weight: ${isBold ? '700' : '400'};
    `
)

//the following code should be jss for react select custom styling
export const customStyles = ({
    theme
}: CustomStyleProps): StylesConfig<SelectValue, boolean, GroupBase<SelectValue>> => ({
    control: (baseStyles, state) => ({
        ...baseStyles,
        width: '100%',
        background: 'transparent',
        border: 'none',
        minHeight: 'unset',
        boxShadow: `0px 0px 0px 0px transparent!important`,
        borderRadius: '8px',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer'
    }),
    placeholder: provided => ({
        ...provided,
        //text/md regular
        fontSize: `${theme.typography.textSm}px`,
        fontWeight: 400,
        margin: 0,
        color: theme.palette.neutral['400']
    }),
    valueContainer: baseStyles => ({
        ...baseStyles,
        padding: 0,
        textAlign: 'start',
        //text/md medium,
        fontSize: `${theme.typography.textSm}px`,
        fontWeight: 400,
        color: theme.palette.neutral['900']
    }),
    indicatorsContainer: baseStyles => ({
        ...baseStyles,
        padding: `0 0 0 ${theme.spacing}px`,
        '& svg': {
            fill: `${theme.palette.neutral['500']}`
        }
    }),

    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        padding: 0,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.2s ease'
    }),

    indicatorSeparator: baseStyles => ({
        ...baseStyles,
        display: 'none'
    }),

    menu: baseStyles => ({
        ...baseStyles,
        borderRadius: `${theme.spacing * 2}px`,
        overflow: 'hidden',
        marginTop: theme.spacing,
        boxShadow: theme.shadows.lg,
        padding: `0 ${theme.spacing}px`,
        width: 'fit-content',
        right: 0,
        top: '100%',
        border: `1px solid ${theme.palette.neutral['200']}`
    }),

    menuList: baseStyles => ({
        ...baseStyles,
        padding: `${theme.spacing}px 0`,
        cursor: 'pointer'
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        textAlign: 'start',
        cursor: 'pointer',
        //text/md medium,
        fontSize: `${theme.typography.textSm}px`,
        fontWeight: 400,
        textTransform: 'capitalize',
        color: theme.palette.neutral['900'],
        borderRadius: `${theme.spacing * 1.5}px`,
        margin: `${theme.spacing}px 0`,
        background:
            state.isFocused && !state.isSelected
                ? theme.palette.neutral['50']
                : state.isSelected
                  ? theme.palette.primary['100']
                  : 'transparent',
        '&:active': {
            background: 'transparent'
        }
    }),
    input: baseStyles => ({
        ...baseStyles,
        margin: 0
    })
})
