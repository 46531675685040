import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {httpCreateEmployee, HttpEmployeeRequest} from '@/features/employee/services/employee.http.ts'
import {errorHandler} from '@utilities/genericErrorHandler.ts'
import toast from 'react-hot-toast'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons.ts'
import {Employee} from '@/features/employee/types.ts'
import {useTranslation} from 'react-i18next'

type UseCreateEmployeeProps = {
    onSuccess?: (data: Employee) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}
export const useCreateEmployee = (options?: UseCreateEmployeeProps) => {
    const queryClient = useQueryClient()
    const {t} = useTranslation()

    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_EMPLOYEE],
        mutationFn: (data: HttpEmployeeRequest) => httpCreateEmployee(data),
        onSuccess: data => {
            toast.success(t('commons:creation_completed', {entity: t('employee:singular')}))
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EMPLOYEES]})
            options?.onSuccess?.(data)
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            options?.onError?.(error)
            errorHandler(error)
        }
    })
}
