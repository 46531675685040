import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledValueIndicator, StyledValueIndicatorWrapper} from './style'
import {FC, HTMLAttributes, ReactNode} from 'react'
import {DefaultNamespace} from 'i18next'
import Label from '@/components/ui/label/Label'

interface ValueIndicatorProps extends HTMLAttributes<HTMLElement> {
    label?: DefaultNamespace | ReactNode
    value: number | string
    typeIcon?: ReactNode
    size?: 'sm' | 'md' | 'lg'
}

export const ValueIndicator: FC<ValueIndicatorProps> = ({label, value, typeIcon, size = 'md', ...rest}) => {
    return (
        <Flexbox gap={1.5} direction="column">
            {label && <Label>{label}</Label>}
            <StyledValueIndicatorWrapper size={size} align="center">
                {typeIcon}
                <StyledValueIndicator {...rest}>{value}</StyledValueIndicator>
            </StyledValueIndicatorWrapper>
        </Flexbox>
    )
}
