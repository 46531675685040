import InputText from '@/components/commons/input-text/InputText'
import {SearchLgIcon} from '@/components/ui/icon'
import {Switch} from '@/components/ui/switch/Switch'
import {CostsTable} from '@/features/costs/components/CostsTable'
import {PageLayout} from '@/layouts/page-layout/PageLayout'
import {FC, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useCostsTable} from '@/features/costs/services/queries/useCostsTable'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

export const Cost: FC = () => {
    const {t} = useTranslation()
    const {onSearch, onResetSearch, toggleReceivedHandler, searchValue, searchParams, setSearchParams} = useCostsTable()
    const searchInputRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        setSearchParams({orderBy: 'date', orderDirection: 'desc'})
    }, [])

    return (
        <PageLayout
            title={t('costs:title')}
            sideHeading={
                <Flexbox gap={4} align="center">
                    <InputText
                        ref={searchInputRef}
                        onChange={e => {
                            onSearch(e.currentTarget.value)
                        }}
                        defaultValue={searchValue}
                        type={'text'}
                        placeholder={t('commons:search_placeholder')}
                        typeIcon={<SearchLgIcon size={18} />}
                        inputSize="sm"
                    />
                    <Switch
                        label={t('costs:filter:label:status')}
                        onChange={value => {
                            toggleReceivedHandler(value)
                        }}
                        defaultChecked={searchParams.received === 'true'}
                    />
                </Flexbox>
            }
        >
            <CostsTable
                searchValue={searchInputRef.current ? searchInputRef.current.value : ''}
                handleClearSearch={() => onResetSearch(searchInputRef)}
            />
        </PageLayout>
    )
}
