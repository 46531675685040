import {useProjectSalsQuery} from '@/features/project-sals/services/queries/useProjectSals'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {SalsURLParams, SalsSearchParams} from '@/features/project-sals/types'
import {debounce} from '@/utilities/helpers'
import {ResponseType} from '@/types/commons'

export const useProjectSalsTable = (params: SalsURLParams) => {
    const multiParams = [] as const satisfies ReadonlyArray<keyof SalsSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, SalsSearchParams)
    const salsQuery = useProjectSalsQuery({...params, ...searchParams, responseType: ResponseType.enum.extended})

    const search = debounce((value: string) => {
        setSearchParams({search: value})
    }, 750)

    const sort = (sorter: {
        orderBy: SalsSearchParams['orderBy']
        orderDirection: SalsSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        sals: salsQuery.data,
        isLoading: salsQuery.isLoading,
        isSuccess: salsQuery.isSuccess,
        isError: salsQuery.isError,
        search,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
