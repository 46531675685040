import styled, {css} from 'styled-components'

export const StyledContainer = styled.div`
    position: relative;
    display: inline-flex;
    width: fit-content;
    margin-left: auto;
    flex-direction: column;
`

export const StyledPhaseDataCostsTHead = styled.span(
    ({theme: {palette}}) => css`
        color: ${palette.tertiary[600]};
    `
)
