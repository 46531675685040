import {Modal} from '@components/ui/modal/Modal.tsx'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {APP_LANGUAGES} from '@/translations/i18n.ts'
import {
    LangOption,
    StyledContent,
    StyledLangModal,
    StyledOptionsContainer
} from '@components/commons/lang-selection-modal/style.ts'

export const LangSelectionModal: FC<{closeCallback: () => void}> = ({closeCallback}) => {
    const {
        t,
        i18n,
        i18n: {language}
    } = useTranslation()
    const onChangeLang = async (lang: string) => {
        console.log(i18n)
        await i18n.changeLanguage(lang)
        //here you may need to process other actions
    }

    return (
        <Modal width={390} closeIconOffset="sm" onClose={closeCallback}>
            <StyledLangModal>
                <h3>{t('commons:select_lang_title')}</h3>
                <StyledContent>
                    <StyledOptionsContainer>
                        {APP_LANGUAGES.map(lang => (
                            <LangOption
                                isSelected={lang.value === language}
                                key={lang.value}
                                onClick={() => onChangeLang(lang.value)}
                            >
                                <p>{lang.label}</p>
                                <img
                                    src={`https://flagcdn.com/w20/${lang.flagCode}.png`}
                                    width="20"
                                    height="14"
                                    alt={lang.label}
                                />
                            </LangOption>
                        ))}
                    </StyledOptionsContainer>
                </StyledContent>
            </StyledLangModal>
        </Modal>
    )
}

LangSelectionModal.displayName = 'LangSelectionModal'
