import Divider from '@/components/ui/divider/Divider'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import Skeleton from '@/components/ui/skeleton/Skeleton'

export const ProjectCostLoader = () => {
    return (
        <>
            <Flexbox direction="column" gap={2} padding={6}>
                <Flexbox>
                    <div>
                        <Skeleton height={30} width={200} />
                        <Skeleton height={50} width={460} />
                    </div>
                    <div>
                        <Skeleton height={30} width={200} />
                        <Skeleton height={50} width={460} />
                    </div>
                </Flexbox>

                <Divider topSpacing={20} bottomSpacing={20} />

                <Skeleton height={30} width={200} />
                <Flexbox gap={6}>
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                </Flexbox>

                <Flexbox justify={'end'} width={'100%'} padding={6} gap={2}>
                    <Skeleton height={50} width={150} />
                    <Skeleton height={50} width={150} />
                </Flexbox>
            </Flexbox>
        </>
    )
}

ProjectCostLoader.displayName = 'ProjectCostLoader'
