import Table from '@/components/commons/table/Table'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {useTranslation} from 'react-i18next'
import {StyledInvoiceNumber} from './style'
import {CalendarIcon, CheckIcon, ClockIcon, PlusIcon} from '@/components/ui/icon'
import {Dispatch, SetStateAction, useMemo} from 'react'
import {Chip} from '@/components/ui/chip/Chip'
import {formatCurrency} from '@/utilities/helpers'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import {TableDataCell} from '@components/commons/table/TableDataCell/TableDataCell.tsx'
import {Invoice, InvoiceStatus} from '@/features/invoice/types.ts'
import {RevenueDropdown} from '@/features/project-revenues/components/revenues-table/RevenueDropdown.tsx'

interface RevenuesTableProps {
    data: Invoice[]
    isError: boolean
    isLoading: boolean
    setRevenueToDelete: Dispatch<SetStateAction<number | null>>
    setRevenueIdToEdit: Dispatch<SetStateAction<number | null>>
    setRevenueIdToDuplicate: Dispatch<SetStateAction<number | null>>
    setRevenueIdToView: Dispatch<SetStateAction<number | null>>
    setRevenueToUpload: Dispatch<SetStateAction<Invoice | null>>
    setIsInvoiceModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const RevenuesTable: React.FC<RevenuesTableProps> = ({
    data,
    isLoading,
    isError,
    setRevenueToDelete,
    setRevenueIdToEdit,
    setRevenueIdToView,
    setRevenueIdToDuplicate,
    setIsInvoiceModalOpen,
    setRevenueToUpload
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])

    const calcolateAmount = () => {
        let amount = 0
        data.forEach(invoice => {
            amount += invoice.details.reduce((acc, val) => acc + val.amount, 0)
        })
        return amount
    }
    const statusChipsComponents = {
        linkedSent: () => (
            <Chip
                id={'linkedSent'}
                label={t(`invoice:status:sent`)}
                variant={'success'}
                icon={<CheckIcon size={12} />}
            />
        ),
        notLinkedSent: () => (
            <Chip
                id={'notLinkedSent'}
                label={t(`invoice:status:sent`)}
                variant={'default'}
                icon={<ClockIcon size={12} />}
            />
        ),
        expected: () => (
            <Chip
                id={'notLinkedSent'}
                label={t(`invoice:status:expected`)}
                variant={'warning'}
                icon={<CalendarIcon size={12} />}
            />
        ),
        forecast: () => (
            <Chip
                id={'forecast'}
                label={t('invoice:status:forecast')}
                variant={'warning'}
                icon={<CalendarIcon size={12} />}
            />
        )
    }

    return (
        <Table
            isMobile={isMobile}
            data={data}
            isLoading={isLoading}
            isError={isError}
            emptyStateComponent={
                <EmptyResults
                    title={t('invoice:table:empty:title')}
                    subtitle={t('invoice:table:empty:subtitle')}
                    btn={{
                        onClick: () => setIsInvoiceModalOpen(true),
                        text: t('invoice:details:add_title'),
                        icon: <PlusIcon />
                    }}
                />
            }
            columns={[
                {
                    label: t('invoice:invoice'),
                    width: '15%',
                    sortName: 'invoice',
                    cellRender: item => (
                        <StyledInvoiceNumber>
                            {item.year && item.number ? `${item.year}/${item.number}` : '-'}
                        </StyledInvoiceNumber>
                    )
                },
                {
                    label: t('invoice:details:rows:description'),
                    width: '25%',
                    sortName: 'description',
                    cellRender: invoice => (
                        <TableDataCell title={invoice.details[0].description}>
                            {invoice.details[0].description}
                        </TableDataCell>
                    )
                },
                {
                    label: t('invoice:details:date'),
                    width: '15%',
                    sortName: 'date',
                    cellRender: item => new Date(item.date).toLocaleDateString()
                },
                {
                    label: t('invoice:invoice_status'),
                    width: '15%',
                    sortName: 'invoice',
                    cellRender: invoice => (
                        <TableDataCell>
                            {invoice.status === InvoiceStatus.enum.expected ? statusChipsComponents.expected() : ''}
                            {invoice.status === InvoiceStatus.Enum.forecast ? statusChipsComponents.forecast() : ''}

                            {invoice.status === InvoiceStatus.enum.sent && !invoice.ficExternalId
                                ? statusChipsComponents.notLinkedSent()
                                : ''}

                            {invoice.status === InvoiceStatus.enum.sent && invoice.ficExternalId
                                ? statusChipsComponents.linkedSent()
                                : ''}
                        </TableDataCell>
                    )
                },
                {
                    label: t('invoice:amount'),
                    width: '15%',
                    sortName: 'invoice',
                    cellRender: invoice =>
                        formatCurrency(
                            invoice.details.reduce((acc, val) => acc + val.amount, 0),
                            'EUR'
                        )
                },
                {
                    label: ' ',
                    width: '15%',
                    sortName: 'actions',
                    alignment: 'right',
                    cellRender: item => (
                        <TableDataCell>
                            <RevenueDropdown
                                setRevenueIdToView={setRevenueIdToView}
                                setRevenueToDelete={setRevenueToDelete}
                                setRevenueIdToDuplicate={setRevenueIdToDuplicate}
                                setRevenueIdToEdit={setRevenueIdToEdit}
                                setRevenueToUpload={setRevenueToUpload}
                                invoice={item}
                            />
                        </TableDataCell>
                    )
                }
            ]}
            footer={[
                {
                    width: '69%',
                    alignment: 'right',
                    isBold: true,
                    cellRender: () => t('project:revenues:table:total')
                },
                {
                    width: '22%',
                    isBold: true,
                    cellRender: () => formatCurrency(calcolateAmount(), 'EUR')
                }
            ]}
        />
    )
}
