import {z} from 'zod'
import {CostStatus} from './types'
import {HttpCostMutationRequest} from './services/cost.http'
import dayjs from 'dayjs'
import {removeCurrencyFormat} from '@/utilities/helpers'

export const COST_FORM_MODEL = {
    description: {
        name: 'description',
        label: 'costs:edit_form:description'
    },
    phase: {
        name: 'phase',
        label: 'costs:edit_form:phase'
    },
    amount: {
        name: 'amount',
        label: 'costs:edit_form:amount'
    },
    date: {
        name: 'date',
        label: 'costs:edit_form:date'
    },
    status: {
        name: 'status',
        label: 'costs:edit_form:status'
    }
} as const

export const COST_STATUS_OPTIONS = [
    {label: 'costs:status:expected', value: CostStatus.enum.expected},
    {label: 'costs:status:received', value: CostStatus.enum.received}
]

export const CostFormValidationSchema = z.object({
    description: z.string().min(1, {message: 'errors:required'}),
    phase: z
        .object({
            label: z.string(),
            value: z.coerce.string()
        })
        .required(),
    amount: z.string().min(1, {message: 'errors:required'}),
    date: z.date(),
    status: z
        .object({
            label: z.string(),
            value: z.string()
        })
        .required()
})
export type CostFormSchema = z.infer<typeof CostFormValidationSchema>

export const adaptCostToBeSaved = (data: CostFormSchema): HttpCostMutationRequest => {
    return {
        description: data.description,
        status: data.status.value as CostStatus,
        phaseId: parseInt(data.phase.value) == 0 ? null : parseInt(data.phase.value),
        date: data.date ? dayjs(data.date).format('YYYY-MM-DD') : null,
        amount: parseFloat(removeCurrencyFormat(data.amount))
    }
}
