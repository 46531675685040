import {PropsWithChildren, useEffect, useState} from 'react'
import {DefaultNamespace} from 'i18next'
import {useBlocker} from 'react-router-dom'
import {Modal} from '@/components/ui/modal/Modal'
import {StyledUnsavedModal, StyledContent, StyledFooter, StyledSaveIcon, StyledIconWrapper} from './style'
import Button from '@/components/ui/button/Button'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'

interface UnsavedBlockerProps extends PropsWithChildren {
    isDirty: boolean
    ignoreSearchParams?: boolean
    title?: string | DefaultNamespace
    message?: string | DefaultNamespace
    onSubmit?: () => void
    onDiscard?: () => void
}

export const UnsavedBlocker = ({
    isDirty,
    ignoreSearchParams = false,
    title = 'unsaved:title',
    message = 'unsaved:message',
    onSubmit,
    onDiscard,
    children
}: UnsavedBlockerProps) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const blocker = useBlocker(({currentLocation, nextLocation}) => {
        if (ignoreSearchParams && currentLocation.pathname === nextLocation.pathname) {
            return false
        }

        return isDirty
    })

    // Reset the blocker if not dirty, show the modal if is dirty
    useEffect(() => {
        if (blocker.state === 'blocked' && !isDirty) {
            blocker.reset()
        }
        if (blocker.state === 'blocked' && isDirty) {
            setIsModalOpen(true)
        }
    }, [blocker, isDirty])

    // Modal is closed without changing page
    const onCloseModal = () => setIsModalOpen(false)

    // Modal is closed and page is changed after submit
    const onSubmitModal = () => {
        if (onSubmit) onSubmit()
        setIsModalOpen(false)
    }

    // Modal is closed and page is changed without submitting
    const onDiscardModal = () => {
        if (onDiscard) onDiscard()
        setIsModalOpen(false)
        blocker.proceed?.()
    }

    return (
        <>
            {isModalOpen && (
                <Modal width={544} closeIconOffset="sm" onClose={onCloseModal}>
                    <StyledUnsavedModal>
                        <StyledContent>
                            <StyledIconWrapper>
                                <StyledSaveIcon size={18} fill={theme.palette.warning['600']} />
                            </StyledIconWrapper>
                            <div>
                                <h3>{t(title)}</h3>
                                <p>{t(message)}</p>
                            </div>
                        </StyledContent>
                        <StyledFooter>
                            <Button variant="secondary" onClick={onDiscardModal}>
                                {t('unsaved:discard')}
                            </Button>
                            <Button variant="primaryWarning" onClick={onSubmitModal}>
                                {t('unsaved:stay')}
                            </Button>
                        </StyledFooter>
                    </StyledUnsavedModal>
                </Modal>
            )}
            {children}
        </>
    )
}
UnsavedBlocker.displayName = 'UnsavedBlocker'
