import {FC, useMemo, useState} from 'react'
import {Cost} from '../types'
import {useTranslation} from 'react-i18next'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import Table from '@/components/commons/table/Table'
import {TableDataCell} from '@/components/commons/table/TableDataCell/TableDataCell'
import {formatCurrency, formatLocaleDate} from '@/utilities/helpers'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import Button from '@/components/ui/button/Button'
import {CalendarIcon, CheckIcon, Edit01Icon, SearchMdIcon} from '@/components/ui/icon'
import {Chip} from '@/components/ui/chip/Chip'
import {CostStatus} from '@/features/project-costs/types'
import {useCostsTable} from '../services/queries/useCostsTable'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import {Modal} from '@/components/ui/modal/Modal'
import {CostForm} from './CostForm'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {Link} from 'react-router-dom'
import {CostFormLoader} from './CostFormLoader'
import {adaptCostToForm} from '@/features/project-costs/utils'

type CostsTableProps = {
    handleClearSearch: () => void
    searchValue: string | boolean
}

export const CostsTable: FC<CostsTableProps> = ({searchValue, handleClearSearch}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])
    const {isLoading, isError, remappedData, fetchNextPage, isFetchingNextPage, sorter} = useCostsTable()
    const [selectedCost, setSelectedCost] = useState<Cost | null>(null)

    return (
        <>
            <Table
                isMobile={isMobile}
                data={remappedData}
                isLoading={isLoading}
                onChangePage={fetchNextPage}
                isChangingPage={isFetchingNextPage}
                sorter={sorter}
                isError={isError}
                searchValue={searchValue}
                emptySearchStateComponent={
                    <EmptySearch
                        title={t('commons:no_results')}
                        icon={<SearchMdIcon />}
                        onClearSearch={handleClearSearch}
                    />
                }
                emptyStateComponent={
                    <EmptyResults
                        title={t('costs:table:empty:title')}
                        subtitle={t('costs:table:empty:subtitle')}
                        cta={{
                            path: '/',
                            text: t('costs:navigate_projects_btn')
                        }}
                    />
                }
                columns={[
                    {
                        label: t('costs:table:labels:id'),
                        width: '5%',
                        cellRender: cost => (
                            <TableDataCell title={cost.id.toString()}>
                                <p>{cost.id}</p>
                            </TableDataCell>
                        )
                    },
                    {
                        label: t('costs:table:labels:project'),
                        width: '27%',
                        cellRender: cost => (
                            <TableDataCell isBold>
                                <Link title={cost.project.name} to={`/projects/${cost.projectId}/costs`}>
                                    {`#${cost.project.id} ${cost.project.name}`}
                                </Link>
                            </TableDataCell>
                        )
                    },
                    {
                        label: t('costs:table:labels:description'),
                        width: '27%',
                        cellRender: cost => (
                            <TableDataCell title={cost.description}>
                                <p>{cost.description}</p>
                            </TableDataCell>
                        )
                    },
                    {
                        label: t('costs:table:labels:amount'),
                        width: '10%',
                        cellRender: cost => (
                            <TableDataCell title={formatCurrency(cost.amount, 'EUR')}>
                                <p>{formatCurrency(cost.amount, 'EUR')}</p>
                            </TableDataCell>
                        )
                    },
                    {
                        label: t('costs:table:labels:date'),
                        width: '10%',
                        sortName: 'date',
                        cellRender: cost => (
                            <TableDataCell title={formatLocaleDate(new Date(cost.date))}>
                                <p>{formatLocaleDate(new Date(cost.date))}</p>
                            </TableDataCell>
                        )
                    },
                    {
                        label: t('costs:table:labels:status'),
                        width: '14%',
                        cellRender: cost => (
                            <TableDataCell size="xs" title={cost.status}>
                                <Chip
                                    id={cost.status.toString()}
                                    label={t(`costs:status:${cost.status}`)}
                                    variant={cost.status === CostStatus.Enum.expected ? 'warning' : 'success'}
                                    icon={
                                        cost.status === CostStatus.Enum.expected ? (
                                            <CalendarIcon size={12} />
                                        ) : (
                                            <CheckIcon size={12} />
                                        )
                                    }
                                />
                            </TableDataCell>
                        )
                    },
                    {
                        label: ' ',
                        width: '7%',
                        alignment: 'right',
                        cellRender: cost => (
                            <Flexbox justify="flex-end">
                                <Button onClick={() => setSelectedCost(cost)} iconOnly={true} variant={'ghost'}>
                                    <Edit01Icon size={20} />
                                </Button>
                            </Flexbox>
                        )
                    }
                ]}
            />
            {selectedCost &&
                (isLoading ? (
                    <CostFormLoader />
                ) : (
                    <Modal width={1017} autoZIndex={true} overflow="visible" onClose={() => setSelectedCost(null)}>
                        <CostForm
                            selectedCost={selectedCost}
                            setSelectedCost={setSelectedCost}
                            defaultValues={adaptCostToForm(selectedCost)}
                        />
                    </Modal>
                ))}
        </>
    )
}
