import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient.ts'
import {httpGetInvoices, HttpInvoicesParams} from '@/features/invoice/services/invoice.http.ts'
import {ResponseType} from '@/types/commons.ts'

export const useGetInvoices = (params: Omit<HttpInvoicesParams, 'page' | 'limit' | 'responseType'>) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.INVOICES, params.search, params.showSent, params.orderBy, params.orderDirection],
        queryFn: ({pageParam = 1}) =>
            httpGetInvoices({
                ...params,
                limit: 20,
                page: pageParam,
                responseType: ResponseType.Enum.extended
            }),
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })
    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData
    }
}
