import axios from '@/axiosInstance'
import {ResponseType} from '@/types/commons'
import {z} from 'zod'
import {PaginationResponse} from '@/types/commons.ts'
import {Employee, HttpEmployeesParams, Scope} from '../types.ts'

/**
 * httpGetEmployees
 * Here we will fetch all the employees data for the dash table
 */
export const HttpEmployeesResponse = z.object({
    data: z.array(Employee),
    meta: PaginationResponse
})
export type HttpEmployeesResponse = z.infer<typeof HttpEmployeesResponse>

export const httpGetEmployees = async (params: HttpEmployeesParams): Promise<HttpEmployeesResponse> => {
    const response = await axios.get(`/users`, {params})
    return HttpEmployeesResponse.parse(response.data)
}

/**
 * httpGetEmployeeDetails
 * Here we will get employee details data
 */

// Params
export const HttpGetEmployeeDetailsParams = z.object({
    urlParams: z.object({
        employeeId: z.coerce.number().int()
    }),
    responseType: ResponseType
})
export type HttpGetEmployeeDetailsParams = z.infer<typeof HttpGetEmployeeDetailsParams>

export const httpGetEmployeeDetails = async (params: HttpGetEmployeeDetailsParams): Promise<Employee> => {
    const response = await axios.get(`/users/${params.urlParams.employeeId}`, {
        params: {responseType: params.responseType}
    })
    return Employee.parse(response.data)
}

/**
 * httpCreateEmployee
 * Here we will create the employee
 */
export const HttpEmployeeRequest = z.object({
    fullName: z.string(),
    email: z.string().email(),
    teamId: z.coerce.number(),
    isActive: z.boolean(),
    role: z.string(),
    scopeId: z.coerce.number(),
    employeeType: z.string(),
    weeklyHours: z.number(),
    canAccess: z.boolean(),
    floatExternalId: z.number().optional(),
    startDate: z.string(),
    endDate: z.string().optional()
})
export type HttpEmployeeRequest = z.infer<typeof HttpEmployeeRequest>

export const httpCreateEmployee = async (data: HttpEmployeeRequest): Promise<Employee> => {
    const response = await axios.post(`/users`, {
        ...data
    })
    return Employee.parse(response.data)
}

/**
 * httpEditEmployee
 * Here we will Edit the employee
 */

export const HttpEditRequest = z.object({
    data: HttpEmployeeRequest,
    employeeId: z.number()
})
export type HttpEditRequest = z.infer<typeof HttpEditRequest>
export const httpEditEmployee = async (data: HttpEditRequest): Promise<Employee> => {
    const response = await axios.patch(`/users/${data.employeeId}`, {
        ...data.data
    })
    return Employee.parse(response.data)
}

export const httpDeleteEmployee = async (id: number) => {
    return await axios.delete(`/users/${id}`)
}

/**
 * httpGetScopes
 * Here we will fetch all the scopes that can be assigned to an employee
 */
export const HttpScopesResponse = z.object({
    data: z.array(Scope),
    meta: PaginationResponse
})
export type HttpScopesResponse = z.infer<typeof HttpScopesResponse>

export const httpGetScopes = async (): Promise<HttpScopesResponse> => {
    const response = await axios.get(`/scopes`, {
        params: {
            limit: 99
        }
    })
    return HttpScopesResponse.parse(response.data)
}

/**
 * httpGetTypes
 * Here we will fetch all the types that can be assigned to employees
 */

/**
 * httpGetRoles
 * Here we will fetch all the roles that can be assigned to employees
 */
