import {z} from 'zod'
import {CostStatus, ProjectCost} from '@/features/project-costs/types'
import dayjs from 'dayjs'
import {HttpProjectCostMutationRequest} from './services/projectCost.http'
import {formatCurrency, removeCurrencyFormat} from '@/utilities/helpers'
import {SelectValue} from '@/components/commons/select/Select'
import {t} from 'i18next'

export const COST_FORM_MODEL = {
    description: {
        name: 'description',
        label: 'project:costs:form:description'
    },
    phase: {
        name: 'phase',
        label: 'project:costs:form:phase'
    },
    amount: {
        name: 'amount',
        label: 'project:costs:form:amount'
    },
    date: {
        name: 'date',
        label: 'project:costs:form:date'
    },
    status: {
        name: 'status',
        label: 'project:costs:form:status'
    }
} as const

export const COST_STATUS_OPTIONS = [
    {label: 'project:costs:status:expected', value: CostStatus.enum.expected},
    {label: 'project:costs:status:received', value: CostStatus.enum.received}
]

export const ProjectCostFormValidationSchema = z.object({
    description: z.string().min(1, {message: 'errors:required'}),
    phase: z
        .object({
            label: z.string(),
            value: z.coerce.string()
        })
        .required(),
    amount: z.string().min(1, {message: 'errors:required'}),
    date: z.date(),
    status: z
        .object({
            label: z.string(),
            value: z.string()
        })
        .required()
})
export type ProjectCostFormSchema = z.infer<typeof ProjectCostFormValidationSchema>

export const EditCostPhaseValidationSchema = z.object({
    description: z.string(),
    phase: z
        .object({
            label: z.string(),
            value: z.coerce.string()
        })
        .required(),
    amount: z.string().min(1, {message: 'errors:required'}),
    date: z.string(),
    status: z.string()
})
export type EditCostPhaseValidationSchema = z.infer<typeof EditCostPhaseValidationSchema>

export const adaptCostPhaseToUpdate = (data: EditCostPhaseValidationSchema): HttpProjectCostMutationRequest => {
    return {
        description: data.description,
        status: data.status as CostStatus,
        phaseId: parseInt(data.phase.value) == 0 ? null : parseInt(data.phase.value),
        date: data.date ? dayjs(data.date).format('YYYY-MM-DD') : null,
        amount: parseFloat(removeCurrencyFormat(data.amount))
    }
}

export const adaptCostToForm = (data: ProjectCost) => {
    return {
        description: data.description,
        phase: data.phase
            ? {label: data.phase.name, value: data.phaseId ? data.phaseId.toString() : '0'}
            : ({label: t('project:no_phase'), value: '0'} as SelectValue),
        amount: formatCurrency(data.amount, 'EUR'),
        date: new Date(data.date),
        status: {
            label: t(`project:costs:status:${data.status}`),
            value: data.status
        }
    }
}

export const adaptCostToUpdate = (data: ProjectCostFormSchema): HttpProjectCostMutationRequest => {
    return {
        description: data.description,
        status: data.status.value as CostStatus,
        phaseId: parseInt(data.phase.value) == 0 ? null : parseInt(data.phase.value),
        date: data.date ? dayjs(data.date).format('YYYY-MM-DD') : null,
        amount: parseFloat(removeCurrencyFormat(data.amount))
    }
}

export const adaptCostToCreate = (
    data: ProjectCostFormSchema & {projectId: number}
): HttpProjectCostMutationRequest => {
    return {
        projectId: data.projectId,
        description: data.description,
        status: data.status.value as CostStatus,
        phaseId: parseInt(data.phase.value) == 0 ? null : parseInt(data.phase.value),
        date: data.date ? dayjs(data.date).format('YYYY-MM-DD') : null,
        amount: parseFloat(removeCurrencyFormat(data.amount))
    }
}
