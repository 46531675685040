import {z} from 'zod'
import {Vat} from '../types/vat'
import axios from '@/axiosInstance'

export const HttpGetVatResponse = z.array(Vat)

export type HttpGetVatResponse = z.infer<typeof HttpGetVatResponse>

export const httpGetVat = async (): Promise<HttpGetVatResponse> => {
    const response = await axios.get(`/vats`)
    return HttpGetVatResponse.parse(response.data)
}
