import Table, {TableSorter} from '@/components/commons/table/Table'
import {SalsSearchParams} from '@/features/sal/types'
import {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {FilterLinesIcon, SearchMdIcon} from '@/components/ui/icon'
import {StyledSalTableDataCell, StyledTeamLead} from './style'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import {SalsResponse} from '@/features/project-sals/services/projectSal.http'
import {Link} from 'react-router-dom'
import dayjs from 'dayjs'
import {SAL_STATUSES} from '../../utils'
import {Chip} from '@/components/ui/chip/Chip'
import {SalStatus} from '@/features/project-sals/types'
import {SalAmountCell} from './sals-table-cells/SalAmountCell'
import {SalHoursLoggedCell} from './sals-table-cells/SalHoursLoggedCell'
import {SalImpactCell} from './sals-table-cells/SalImpactCell'

interface SalsTableProps {
    data: SalsResponse[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    searchValue?: string
    filterValue?: boolean
    sorter?: TableSorter<SalsSearchParams['orderBy']> | undefined
    onResetSearchCb?: () => void
    onChangePage?: () => void
}

export const SalsTable: FC<SalsTableProps> = ({
    data,
    isLoading,
    sorter,
    isChangingPage,
    searchValue,
    filterValue,
    onChangePage,
    onResetSearchCb
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])

    return (
        <Table
            isMobile={isMobile}
            data={data}
            isLoading={isLoading}
            sorter={sorter}
            isChangingPage={isChangingPage}
            searchValue={searchValue}
            onChangePage={onChangePage}
            columns={[
                {
                    label: t('sal:table:labels:project'),
                    width: '14.5%',
                    sortName: 'projectName',
                    cellRender: sal => (
                        <StyledSalTableDataCell>
                            <Link title={sal.project.name} to={`/projects/${sal.project.id}/info`}>
                                {sal.project.name}
                            </Link>
                            <span>{sal.project.customer.business_name}</span>
                        </StyledSalTableDataCell>
                    )
                },
                {
                    label: t('sal:table:labels:name'),
                    width: '12.5%',
                    sortName: 'name',
                    cellRender: sal => <StyledSalTableDataCell>{sal.name}</StyledSalTableDataCell>
                },
                {
                    label: t('project:sals:table:labels:amount'),
                    width: '12.5%',
                    cellRender: sal => <SalAmountCell sal={sal} budget={sal.project.budget} />
                },
                {
                    label: t('project:sals:table:labels:hours_logged'),
                    width: '14.5%',
                    cellRender: sal => <SalHoursLoggedCell sal={sal} dailyRate={sal.project.dailyRate} />
                },
                {
                    label: t('project:sals:table:labels:impact'),
                    width: '12.5%',
                    cellRender: sal => <SalImpactCell sal={sal} dailyRate={sal.project.dailyRate} showDailyRate />
                },
                {
                    label: t('sal:table:labels:team'),
                    width: '10.5%',
                    sortName: 'teamLead',
                    cellRender: sal => (
                        <StyledSalTableDataCell>
                            <StyledTeamLead>{sal.project.teamLead.full_name}</StyledTeamLead>
                            <span>{sal.project.productLead.full_name}</span>
                        </StyledSalTableDataCell>
                    )
                },
                {
                    label: t('project:sals:table:labels:deliveryDate'),
                    width: '10.5%',
                    sortName: 'deliveryDate',
                    cellRender: sal => (
                        <StyledSalTableDataCell title={dayjs(sal.deliveryDate).format('MMMM YYYY')}>
                            <p>{dayjs(sal.deliveryDate).format('MMMM YYYY')}</p>
                        </StyledSalTableDataCell>
                    )
                },
                {
                    label: t('project:sals:table:labels:status'),
                    width: '12.5%',
                    sortName: 'status',
                    cellRender: sal => {
                        const label = t(SAL_STATUSES.find(item => item.value === sal.status)?.label ?? '')
                        return (
                            <StyledSalTableDataCell title={label}>
                                <Chip
                                    id={sal.status.toString()}
                                    label={label}
                                    variant={sal.status === SalStatus.Enum.scheduled ? 'warning' : 'success'}
                                    size={12}
                                    title={label}
                                />
                            </StyledSalTableDataCell>
                        )
                    }
                }
            ]}
            emptySearchStateComponent={
                <EmptySearch
                    title={t('commons:no_results')}
                    onClearSearch={() => onResetSearchCb && onResetSearchCb()}
                    icon={<SearchMdIcon />}
                />
            }
            emptyStateComponent={
                filterValue ? (
                    <EmptySearch title={t('commons:no_results')} icon={<FilterLinesIcon />} />
                ) : (
                    <EmptyResults title={t('sal:table:empty:title')} subtitle={t('sal:table:empty:subtitle')} />
                )
            }
        />
    )
}
