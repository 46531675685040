import {
    HttpCreateCustomerParams,
    HttpGetCustomerDetailsResponse,
    HttpUpdateCustomerParams
} from '@/features/customer/services/customer.http'
import {CustomerValidationSchema} from './CustomerFormModel'

export const adaptCustomerToUpdate = (id: number, data: CustomerValidationSchema): HttpUpdateCustomerParams => ({
    customerId: id,
    data: {
        floatExternalId: data.floatExternalId ? parseInt(data.floatExternalId) : null,
        ficExternalId: data.ficExternalId ? parseInt(data.ficExternalId) : null,
        vatNumber: data.vatNumber || null,
        fiscalCode: data.fiscalCode || null,
        sdi: data.sdi || null,
        pec: data.pec || null,
        businessName: data.businessName,
        legalAddress: data.legalAddress || null,
        civicNumber: data.civicNumber || null,
        city: data.city || null,
        zipCode: data.zipCode || null,
        province: data.province || null,
        country: data.country || null,
        fullnameAccounting: data.fullnameAccounting ?? null,
        email: data.email || null,
        phoneNumber: data.phoneNumber || null,
        successManagerId: data.successManager ? parseInt(data.successManager.value) : null
    }
})

export const adaptCustomerToCreate = (data: CustomerValidationSchema): HttpCreateCustomerParams => ({
    data: {
        floatExternalId: data.floatExternalId ? parseInt(data.floatExternalId) : null,
        ficExternalId: data.ficExternalId ? parseInt(data.ficExternalId) : null,
        vatNumber: data.vatNumber || null,
        fiscalCode: data.fiscalCode || null,
        sdi: data.sdi || null,
        pec: data?.pec || null,
        businessName: data.businessName,
        legalAddress: data.legalAddress || null,
        civicNumber: data.civicNumber || null,
        city: data.city || null,
        zipCode: data.zipCode || null,
        province: data.province || null,
        country: data.country || null,
        fullnameAccounting: data.fullnameAccounting ?? null,
        email: data.email || null,
        phoneNumber: data.phoneNumber || null,
        successManagerId: data.successManager?.value ? parseInt(data.successManager.value) : null
    }
})

export const adaptCustomerToForm = (data: HttpGetCustomerDetailsResponse): CustomerValidationSchema => ({
    floatExternalId: data.floatExternalId ?? null,
    ficExternalId: data.ficExternalId ?? null,
    vatNumber: data?.vatNumber ?? null,
    fiscalCode: data?.fiscalCode ?? null,
    sdi: data?.sdi ?? null,
    pec: data?.pec ?? null,
    businessName: data.businessName,
    legalAddress: data?.legalAddress ?? null,
    civicNumber: data?.civicNumber ?? null,
    city: data?.city ?? null,
    zipCode: data?.zipCode ?? null,
    province: data?.province ?? null,
    country: data?.country ?? null,
    fullnameAccounting: data?.fullnameAccounting ?? null,
    email: data?.email ?? null,
    phoneNumber: data?.phoneNumber ?? null,
    successManager: data.successManager && {
        value: data.successManager.id.toString(),
        label: data.successManager.fullName
    }
})
