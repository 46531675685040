import {QUERY_KEYS} from '@/queryClient'
import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {HttpPhasesParams, HttpPhasesResponse, httpGetPhases} from '@/features/project-phases/services/phase.http'

export const useProjectPhasesQuery = (
    params: HttpPhasesParams,
    options?: Omit<UseQueryOptions<HttpPhasesResponse>, 'queryFn' | 'queryKey'>
) => {
    const query = useQuery({
        ...options,
        queryKey: [QUERY_KEYS.PROJECT_PHASES, params.projectId, params.search, params.orderBy, params.orderDirection],
        queryFn: () => httpGetPhases(params)
    })

    return query
}
