import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {DefaultTheme, css} from 'styled-components'

type StyledFormGridRow = {
    theme: DefaultTheme
    gap?: number
    cols?: number
}

export const StyledSalModalHeader = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 6}px ${spacing * 6}px ${spacing * 5}px;
        h3 {
            ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral[900]};
        }
    `
)

export const StyledSalForm = styled.form(
    ({theme: {spacing}}) => css`
        padding: 0 ${spacing * 6}px ${spacing * 6}px ${spacing * 6}px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 8}px;
    `
)

export const StyledFormGridRow = styled.div<StyledFormGridRow>(
    ({theme: {spacing}, gap = 0, cols}) => css`
        display: grid;
        grid-template-columns: repeat(${cols}, 1fr);
        gap: ${spacing * gap}px;
        width: 100%;
    `
)
