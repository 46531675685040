import {FC} from 'react'
import {StyledPageTitle} from './style'

interface PageTitleProps {
    size?: 'sm' | 'md' | 'lg'
    text?: string
}

export const PageTitle: FC<PageTitleProps> = ({text, size = 'md'}) => (
    <StyledPageTitle size={size}>{text || ''}</StyledPageTitle>
)
