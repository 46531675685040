import {FC, ReactNode, useState} from 'react'
import {useDatePicker} from '@rehookify/datepicker'
import {CalendarsWrapper, StyledContainer} from './style'
import YearSelector from './YearSelector'
import MonthSelector from './MonthSelector'

export type CalendarProps = {
    numMonths: number
    selectedDates: Date[]
    onDatesChange: (dates: Date[]) => void
    mode: 'single' | 'multiple' | 'range'
    toggle?: boolean
    currentDay?: Date
    minDate?: Date
    maxDate?: Date
    locale?: string
    disabledDates?: Date[]
}
const Calendar: FC<CalendarProps> = ({
    numMonths,
    selectedDates,
    onDatesChange,
    mode,
    toggle = false,
    minDate,
    maxDate,
    locale = 'en',
    disabledDates
}) => {
    const {
        data: {calendars, months, years},
        propGetters: {dayButton, monthButton, yearButton, nextYearsButton, previousYearsButton}
    } = useDatePicker({
        selectedDates,
        onDatesChange,
        dates: {toggle, mode, minDate, maxDate},
        calendar: {
            startDay: 0,
            offsets: [...Array(numMonths).keys()].slice(1)
        }
    })

    const [viewState, setViewState] = useState<'month' | 'year' | ''>('month')

    const calendarContentByState: Record<'month' | 'year' | '', ReactNode> = {
        '': undefined,
        month: (
            <MonthSelector
                setViewState={setViewState}
                locale={locale}
                months={months}
                monthButton={monthButton}
                dayButton={dayButton}
                currentMonth={calendars[0].month}
                disabledDates={disabledDates}
            />
        ),
        year: (
            <YearSelector
                setViewState={setViewState}
                locale={locale}
                years={years}
                yearButton={yearButton}
                nextYearsButton={nextYearsButton}
                previousYearsButton={previousYearsButton}
                currentYear={calendars[0].year}
            />
        )
    }

    return (
        <StyledContainer>
            <CalendarsWrapper>{calendarContentByState[viewState]}</CalendarsWrapper>
        </StyledContainer>
    )
}

export default Calendar
