import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpDeleteSal} from '../projectSal.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'

type SalDeleteProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useDeleteSals = ({onSuccess, onError}: SalDeleteProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_PROJECT_SAL],
        mutationFn: httpDeleteSal,
        onSuccess: () => {
            onSuccess?.()
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECT_SALS]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SALS]})
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            onError?.(error)
        }
    })
}
