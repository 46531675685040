import {PageLayout} from '@layouts/page-layout/PageLayout.tsx'
import {useTranslation} from 'react-i18next'
import EmployeeForm from '@/features/employee/components/employees-form/EmployeeForm.tsx'
export const NewEmployee: React.FC = () => {
    const {t} = useTranslation()

    return (
        <PageLayout title={t('new_employee:title')}>
            <EmployeeForm />
        </PageLayout>
    )
}

NewEmployee.displayName = 'NewEmployee'
