import {useProjectSyncSocket} from '@/features/project/services/queries/useProjectSyncSocket'
import {SyncStatus} from '@/features/project/types'
import {useAsync} from '@/hooks/useAsync'
import {FC, useEffect, useMemo, useState} from 'react'
import {httpFloatSync} from '../../services/phase.http'
import toast from 'react-hot-toast'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {useTranslation} from 'react-i18next'
import {StyledSyncButtonWrapper} from './style'
import Button from '@/components/ui/button/Button'
import Spinner from '@/components/ui/spinner/Spinner'
import {AlertTriangleIcon, DownloadCloud01Icon} from '@/components/ui/icon'
import {useTheme} from 'styled-components'
import {formatLocaleDate} from '@/utilities/helpers'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'

interface FloatSyncButtonProps {
    projectId: number
    syncStatus: SyncStatus | null
    lastSyncAt: string | null
    showLabel?: boolean
}

export const FloatSyncButton: FC<FloatSyncButtonProps> = ({projectId, lastSyncAt, showLabel = true}) => {
    const [currentSyncStatus, setCurrentSyncStatus] = useState<SyncStatus | null>(null)
    const {run: runFloatSync, isLoading: isLoadingSync} = useAsync()
    const syncStatusEvent = useProjectSyncSocket()
    const theme = useTheme()
    const {t} = useTranslation()
    const queryClient = useQueryClient()

    const onFloatSync = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        try {
            if (!projectId) toast.error(t('customer:fic_id:invalid_vat'))
            setCurrentSyncStatus(SyncStatus.Enum.queued)
            await runFloatSync(httpFloatSync({projectId}))
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECT_SALS, projectId]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SALS]})
        } catch (e) {
            setCurrentSyncStatus(null)
            errorHandler(e)
        }
    }

    useEffect(() => {
        if (syncStatusEvent && syncStatusEvent.project_id === projectId) {
            setCurrentSyncStatus(syncStatusEvent.sync_status)
        }
    }, [syncStatusEvent])

    const isFloatSyncInProgress = useMemo<boolean>(
        () => currentSyncStatus === SyncStatus.Enum.queued || currentSyncStatus === SyncStatus.Enum.syncing,
        [isLoadingSync, currentSyncStatus]
    )

    return (
        <StyledSyncButtonWrapper direction="column" gap={2} align="center">
            <Button
                width={'wide'}
                variant="primary"
                disabled={isFloatSyncInProgress && currentSyncStatus !== SyncStatus.Enum.completed}
                onClick={onFloatSync}
            >
                {isFloatSyncInProgress && currentSyncStatus !== SyncStatus.Enum.completed ? (
                    <Spinner size={16} color={theme.palette.neutral[700]} />
                ) : (
                    <>
                        <DownloadCloud01Icon />
                        <span>{t('project:phases:sync_btn')}</span>
                    </>
                )}
            </Button>
            {showLabel &&
                (isFloatSyncInProgress ? (
                    <p>{t(`project:sync:${currentSyncStatus}`)}</p>
                ) : currentSyncStatus === SyncStatus.Enum.failed ? (
                    <Flexbox gap={1}>
                        <AlertTriangleIcon style={{color: theme.palette.danger[500]}} size={20} />
                        <p title={t(`project:sync:${currentSyncStatus}`)}>{t(`project:sync:${currentSyncStatus}`)}</p>
                    </Flexbox>
                ) : lastSyncAt ? (
                    formatLocaleDate(lastSyncAt, 'lll')
                ) : (
                    <p>{t('project:sync:no_sync')}</p>
                ))}
        </StyledSyncButtonWrapper>
    )
}
