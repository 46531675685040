import styled, {DefaultTheme, css} from 'styled-components'
import {GroupBase, StylesConfig} from 'react-select'
import {SelectValue} from '@components/commons/select/Select.tsx'

export const StyledContainer = styled.div(
    ({theme: {spacing}}) => css`
        position: relative;
        min-height: 68px;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.5}px;
    `
)
type test = {
    theme: DefaultTheme
    isError: boolean
}
//the following code should be jss for react select custom styling
export const customStyles = ({theme, isError}: test): StylesConfig<SelectValue, boolean, GroupBase<SelectValue>> => ({
    control: (baseStyles, state) => ({
        ...baseStyles,
        padding: `${theme.spacing * 1.25}px ${theme.spacing * 3.5}px`,
        width: '100%',
        minHeight: 44,
        background: 'transparent',
        border: `1px solid`,
        borderColor: state.menuIsOpen
            ? `${theme.palette.primary['300']}!important`
            : `${theme.palette.neutral['300']}!important`,
        boxShadow: state.menuIsOpen
            ? `${theme.shadows.xs}, 0px 0px 0px 4px ${theme.palette.primary['100']}!important`
            : theme.shadows.xs,
        borderRadius: '8px',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        ...(isError && {
            borderColor: theme.palette.danger['300'] + '!important'
        }),
        ...(isError &&
            state.menuIsOpen && {
                boxShadow: `${theme.shadows.xs}, 0px 0px 0px 4px ${theme.palette.danger['100']}!important`
            })
    }),
    placeholder: provided => ({
        ...provided,
        //text/md regular
        fontSize: '1rem',
        lineHeight: '24px',
        fontWeight: 400,
        margin: 0,
        color: theme.palette.neutral['400']
    }),
    valueContainer: baseStyles => ({
        ...baseStyles,
        padding: 0,
        textAlign: 'start',
        gap: `${theme.spacing}px`,
        //text/md medium,
        fontSize: '1rem',
        lineHeight: '24px',
        fontWeight: 400,
        color: theme.palette.neutral['900']
    }),
    indicatorsContainer: baseStyles => ({
        ...baseStyles,
        padding: `0 0 0 ${theme.spacing * 2}px`,
        '& svg': {
            fill: `${theme.palette.neutral['500']}`
        }
    }),

    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        padding: 0,
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.2s ease'
    }),

    indicatorSeparator: baseStyles => ({
        ...baseStyles,
        display: 'none'
    }),

    menu: baseStyles => ({
        ...baseStyles,
        borderRadius: `${theme.spacing * 2}px`,
        overflow: 'hidden',
        marginTop: theme.spacing,
        boxShadow: theme.shadows.lg,
        padding: `0 ${theme.spacing * 1.5}px`,
        border: `1px solid ${theme.palette.neutral['200']}`
    }),

    menuList: baseStyles => ({
        ...baseStyles,
        padding: `${theme.spacing}px 0`,
        cursor: 'pointer'
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        textAlign: 'start',
        //text/md medium,
        fontSize: '1rem',
        lineHeight: '24px',
        fontWeight: 500,
        textTransform: 'capitalize',
        color: theme.palette.neutral['900'],
        borderRadius: `${theme.spacing * 1.5}px`,
        background:
            state.isFocused && !state.isSelected
                ? theme.palette.neutral['50']
                : state.isSelected
                  ? theme.palette.primary['100']
                  : 'transparent',
        '&:active': {
            background: 'transparent'
        }
    }),
    multiValue: baseStyles => ({
        ...baseStyles,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `${0}px ${theme.spacing}px`,
        gap: theme.spacing,
        height: 24,
        background: theme.palette.neutral.white,
        border: `1px solid ${theme.palette.neutral['300']}`,
        borderRadius: '6px'
    }),
    multiValueLabel: baseStyles => ({
        ...baseStyles,
        //Text sm/Medium
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        textTransform: 'capitalize',
        color: theme.palette.neutral['700']
    }),
    multiValueRemove: baseStyles => ({
        ...baseStyles
    }),
    input: baseStyles => ({
        ...baseStyles,
        margin: 0
    })
})
