import {z} from 'zod'

export const PROJECTS_FILTERS_FORM_MODEL = {
    type: {
        name: 'type',
        label: 'project:filters:labels:type',
        placeholder: 'project:filters:placeholders:type'
    },
    successManager: {
        name: 'successManager',
        label: 'project:filters:labels:successManager',
        placeholder: 'project:filters:placeholders:successManager'
    },
    teamLead: {
        name: 'teamLead',
        label: 'project:filters:labels:teamLead',
        placeholder: 'project:filters:placeholders:teamLead'
    },
    productLead: {
        name: 'productLead',
        label: 'project:filters:labels:productLead',
        placeholder: 'project:filters:placeholders:productLead'
    },
    status: {
        name: 'status',
        label: 'commons:status',
        placeholder: 'project:filters:placeholders:status'
    },
    customer: {
        name: 'customer',
        label: 'project:filters:labels:customer',
        placeholder: 'project:filters:placeholders:customer'
    }
} as const

export const ProjectsFiltersValidationSchema = z.object({
    type: z
        .array(
            z.object({
                label: z.string(),
                value: z.string()
            })
        )
        .optional(),
    successManager: z
        .array(
            z.object({
                label: z.string(),
                value: z.string()
            })
        )
        .optional(),
    teamLead: z
        .array(
            z.object({
                label: z.string(),
                value: z.string()
            })
        )
        .optional(),
    productLead: z
        .array(
            z.object({
                label: z.string(),
                value: z.string()
            })
        )
        .optional(),
    customer: z
        .array(
            z.object({
                label: z.string(),
                value: z.string()
            })
        )
        .optional(),
    status: z
        .array(
            z.object({
                label: z.string(),
                value: z.string()
            })
        )
        .optional()
})

export type ProjectsFiltersValidationSchema = z.infer<typeof ProjectsFiltersValidationSchema>
