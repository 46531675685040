import {InitialConfigType, LexicalComposer} from '@lexical/react/LexicalComposer'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin'
import {HeadingNode} from '@lexical/rich-text'
import {ListPlugin} from '@lexical/react/LexicalListPlugin'
import {ListNode, ListItemNode} from '@lexical/list'
import {ToolbarPlugin} from '@components/commons/rich-textarea/plugins/ToolbarPlugin.tsx'
import {
    StyledContentEditable,
    StyledHeaderRichTextArea,
    StyledPlaceholder,
    StyledRichTextareaWrapper,
    StyledRichTextContainer,
    StyledRichTextLabel
} from '@components/commons/rich-textarea/rich-textarea/style.ts'
import {StyledLabel} from '@components/ui/label/style.ts'
import {ChangeEvent} from 'react'
import {MyOnChangePlugin} from '@components/commons/rich-textarea/plugins/MyOnChangePlugin.tsx'
import {isValidRichString} from '@components/commons/rich-textarea/utils.ts'

function onError(error: Error) {
    console.log(error)
}
interface RichTextProps {
    label: string
    placeHolder: string
    onChange: (event: string | ChangeEvent<Element> | null) => void
    value?: string | null
}

export default function RichTextarea({label, placeHolder, value, onChange}: RichTextProps): JSX.Element {
    const initialConfig: InitialConfigType = {
        namespace: 'MyEditor',
        onError,
        editorState: value ? isValidRichString(value) : null,
        editable: true,
        nodes: [HeadingNode, ListNode, ListItemNode]
    }

    return (
        <StyledRichTextContainer>
            <StyledRichTextLabel>
                <StyledLabel>{label}</StyledLabel>
            </StyledRichTextLabel>
            <LexicalComposer initialConfig={initialConfig}>
                <StyledHeaderRichTextArea>
                    <ToolbarPlugin />
                </StyledHeaderRichTextArea>
                <StyledRichTextareaWrapper>
                    <RichTextPlugin
                        contentEditable={<StyledContentEditable />}
                        placeholder={<StyledPlaceholder>{placeHolder}</StyledPlaceholder>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <MyOnChangePlugin onChange={onChange} />

                    <ListPlugin />
                </StyledRichTextareaWrapper>
            </LexicalComposer>
        </StyledRichTextContainer>
    )
}
