import {InitialConfigType, LexicalComposer} from '@lexical/react/LexicalComposer'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin'
import {HeadingNode} from '@lexical/rich-text'
import {ListPlugin} from '@lexical/react/LexicalListPlugin'
import {ListNode, ListItemNode} from '@lexical/list'
import {StyledPlaceholder, StyledRichTextContainer} from '@components/commons/rich-textarea/rich-textarea/style.ts'
import {isValidRichString} from '@components/commons/rich-textarea/utils.ts'
import {
    StyledContentEditableView,
    StyledRichTextareaViewWrapper
} from '@components/commons/rich-textarea/rich-textarea-view/style.ts'

function onError(error: Error) {
    console.log(error)
}
interface RichTextProps {
    value?: string | null
}

export default function RichTextAreaView({value}: RichTextProps): JSX.Element {
    const initialConfig: InitialConfigType = {
        namespace: 'MyEditor',
        onError,
        editorState: value ? isValidRichString(value) : null,
        editable: true,
        nodes: [HeadingNode, ListNode, ListItemNode]
    }

    return (
        <StyledRichTextContainer>
            <LexicalComposer initialConfig={initialConfig}>
                <StyledRichTextareaViewWrapper>
                    <RichTextPlugin
                        contentEditable={<StyledContentEditableView />}
                        placeholder={<StyledPlaceholder></StyledPlaceholder>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <ListPlugin />
                </StyledRichTextareaViewWrapper>
            </LexicalComposer>
        </StyledRichTextContainer>
    )
}
