import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpDeleteProject} from '@/features/project/services/project.http.ts'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {errorHandler} from '@utilities/genericErrorHandler.ts'
import toast from 'react-hot-toast'
import {t} from 'i18next'

type ProjectDeleteProps = {
    onSuccess?: () => void
}

export const useDeleteProjects = ({onSuccess}: ProjectDeleteProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_PROJECT],
        mutationFn: (id: number) => httpDeleteProject(id),
        onSuccess: async () => {
            onSuccess?.()
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECTS]})
            toast.success(t('project:delete:success'))
        },
        onError: error => {
            errorHandler(error)
        }
    })
}
