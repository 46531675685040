import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
//translations
import ar from './ar/ar.json'
import en from './en/en.json'
import it from './it/it.json'
import de from './de/de.json'
import fr from './fr/fr.json'
import es from './es/es.json'
import ja from './ja/ja.json'
import ko from './ko/ko.json'
import nl from './nl/nl.json'
import pt from './pt/pt.json'
import ru from './ru/ru.json'
import zh from './zh/zh.json'

//dayjs locales //TODO: add others locales if needded
import 'dayjs/locale/it'
import 'dayjs/locale/en'
import 'dayjs/locale/ar'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/nl'
import 'dayjs/locale/pt'
import 'dayjs/locale/ru'
import 'dayjs/locale/zh'

export const APP_LANGUAGES = [
    {label: 'English (EN)', value: 'en', flagCode: 'gb', json: en},
    {label: 'Español (ES)', value: 'es-419', flagCode: 'es', json: es},
    {label: 'العربية (AR)', value: 'ar', flagCode: 'sa', json: ar},
    {label: 'Deutsch (DE)', value: 'de', flagCode: 'de', json: de},
    {label: 'Français (FR)', value: 'fr', flagCode: 'fr', json: fr},
    {label: 'Italiano (IT)', value: 'it', flagCode: 'it', json: it},
    {label: '日本語 (JP)', value: 'ja', flagCode: 'jp', json: ja},
    {label: '한국어 (KO)', value: 'ko', flagCode: 'kr', json: ko},
    {label: 'Nederlands (NL)', value: 'nl', flagCode: 'nl', json: nl},
    {label: 'Português (PT)', value: 'pt', flagCode: 'pt', json: pt},
    {label: 'Русский (RU)', value: 'ru', flagCode: 'ru', json: ru},
    {label: '中文 (ZH)', value: 'zh', flagCode: 'cn', json: zh}
]

export const resources = {
    ar: ar,
    de: de,
    en: en,
    es: es,
    fr: fr,
    it: it,
    ja: ja,
    ko: ko,
    nl: nl,
    pt: pt,
    ru: ru,
    zh: zh
} as const

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resources,
        ns: [],
        defaultNS: '',
        keySeparator: false,
        fallbackLng: 'en',
        debug: false,
        react: {
            useSuspense: false
        },
        supportedLngs: APP_LANGUAGES.map(language => language.value),
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'i18nextLng'
        }
    })

export default i18n
