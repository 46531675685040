import {QUERY_KEYS, infiniteQueryFetchNextPage} from '@/queryClient'
import {useInfiniteQuery} from '@tanstack/react-query'
import {HttpGetProjectTypesParams, httpGetProjectTypes} from '@/features/project/services/project.http'

export const useProjectTypesQuery = (params?: HttpGetProjectTypesParams) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.PROJECT_TYPES, params],
        queryFn: () => httpGetProjectTypes(params),
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page > meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData
    }
}
