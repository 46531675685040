import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {PlusIcon, SearchLgIcon} from '@/components/ui/icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useTranslation} from 'react-i18next'
import {PageLayout} from '@/layouts/page-layout/PageLayout'
import {useEmployeesTable} from '@/features/employee/services/queries/useEmployeesTable'
import InputText from '@/components/commons/input-text/InputText'
import {EmployeesTable} from '@/features/employee/components/employees-table/EmployeesTable'
import {useRef, useState} from 'react'
import {Employee} from '@/features/employee/types.ts'
import {DeleteModal} from '@components/commons/delete-modal/DeleteModal.tsx'
import {useDeleteEmployee} from '@/features/employee/services/queries/useDeleteEmployee.ts'

export const Employees: React.FC = () => {
    const {t} = useTranslation()
    const employeesQuery = useEmployeesTable()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const [employeeToDelete, setEmployeeToDelete] = useState<Employee | null>(null)
    const {mutate: deleteMutate, isPending: deletePending} = useDeleteEmployee({
        onSuccess: () => setEmployeeToDelete(null)
    })

    const onSubmitEmployeeToDelete = () => {
        if (employeeToDelete) {
            return deleteMutate(employeeToDelete.id)
        }
    }

    return (
        <PageLayout
            title={t('employee:title')}
            sideHeading={
                <Flexbox gap={2} align="center">
                    <InputText
                        onChange={e => employeesQuery.onSearch(e.currentTarget.value)}
                        defaultValue={employeesQuery.searchValue}
                        ref={searchRef}
                        type={'text'}
                        placeholder={t('commons:search_placeholder')}
                        typeIcon={<SearchLgIcon size={18} />}
                        inputSize={'sm'}
                    />

                    <ButtonLink variant={'primary'} size={'md'} to={'/new-employee'}>
                        <PlusIcon />
                        {t('employee:new_employee')}
                    </ButtonLink>
                </Flexbox>
            }
        >
            <EmployeesTable
                data={employeesQuery.remappedData}
                isChangingPage={employeesQuery.isFetchingNextPage}
                isError={employeesQuery.isError}
                isLoading={employeesQuery.isLoading}
                onChangePage={employeesQuery.fetchNextPage}
                onResetSearchCb={() => employeesQuery.onResetSearch(searchRef)}
                searchValue={employeesQuery.searchValue}
                setEmployeeToDelete={setEmployeeToDelete}
                sorter={employeesQuery.sorter}
            />
            {employeeToDelete && (
                <DeleteModal
                    title={t('employee:delete:title')}
                    paragraph={t('employee:delete:description')}
                    isLoading={deletePending}
                    onClose={() => setEmployeeToDelete(null)}
                    onDelete={() => onSubmitEmployeeToDelete()}
                />
            )}
        </PageLayout>
    )
}

Employees.displayName = 'Employees'
