import React, {FC} from 'react'
import {DPDay, DPMonth, DPPropGetter} from '@rehookify/datepicker'
import {
    CalendarWrapper,
    StyledBackButton,
    StyledCalendarHeader,
    StyledMonthYearButton,
    StyledYearMonthGrid
} from './style'
import {capitalize} from '@utilities/helpers.ts'
import dayjs from '@utilities/dayjs.ts'

type MonthSelectorProps = {
    months: DPMonth[]
    monthButton: (month: DPMonth) => DPPropGetter
    currentMonth: string
    locale?: string
    setViewState: React.Dispatch<React.SetStateAction<'month' | 'year' | ''>>
    dayButton: (day: DPDay) => DPPropGetter
    disabledDates?: Date[]
}

const MonthSelector: FC<MonthSelectorProps> = ({
    months,
    currentMonth,
    locale = 'en',
    setViewState,
    dayButton,
    disabledDates
}) => {
    return (
        <CalendarWrapper className={'monthYearCalendar'}>
            <StyledCalendarHeader>Months</StyledCalendarHeader>
            <StyledYearMonthGrid>
                {months.map(m => {
                    const button = dayButton({
                        ...m,
                        inCurrentMonth: true,
                        day: '1',
                        range: ''
                    })
                    return (
                        <StyledMonthYearButton
                            key={m.month}
                            variant={m.month === currentMonth ? 'primary' : 'ghost'}
                            disabled={
                                disabledDates?.find(
                                    item =>
                                        item.getFullYear() === m.$date.getFullYear() &&
                                        item.getMonth() === m.$date.getMonth()
                                ) !== undefined
                            }
                            {...button}
                            onClick={e => {
                                button.onClick?.(e)
                                setViewState('')
                            }}
                        >
                            {capitalize(dayjs(m.$date).locale(locale).format('MMM')).slice(0, 3)}
                        </StyledMonthYearButton>
                    )
                })}
            </StyledYearMonthGrid>
            <StyledBackButton variant="ghost" onClick={() => setViewState('year')}>
                Years
            </StyledBackButton>
        </CalendarWrapper>
    )
}

export default MonthSelector
