import {InvoiceValidationSchema} from '@/features/project-revenues/components/invoice-modal/InvoiceFormModel.tsx'
import dayjs from 'dayjs'
import {Invoice} from '@/features/invoice/types.ts'
import {HttpInvoiceBodyRequest} from '@/features/invoice/services/invoice.http.ts'
import {INVOICE_STATUSES, VAT_PAYABILITY} from '@/features/project/utils.ts'
import {SelectValue} from '@components/commons/select/Select.tsx'
import {ProjectInvoiceData} from '@/features/project-revenues/components/invoice-modal/InvoiceForm.tsx'
import {removeCurrencyFormat} from '@utilities/helpers.ts'

interface adaptInvoiceFormValue {
    invoice: Invoice
    banks: SelectValue[]
    vats: SelectValue[]
}
export const adaptInvoiceForm = ({invoice, vats}: adaptInvoiceFormValue) => {
    const statusFinded = INVOICE_STATUSES.find(status => invoice.status == status.value)
    const vatPayabilityFinded = VAT_PAYABILITY.find(item => invoice.vatPayability == item.value)

    const result: InvoiceValidationSchema = {
        status: {
            label: statusFinded?.label || '',
            value: statusFinded?.value || ''
        },
        date: dayjs(invoice.date).toDate(),
        vatPayability: {
            label: vatPayabilityFinded?.label || '',
            value: vatPayabilityFinded?.value || ''
        },
        internalSubject: invoice.internalSubject || '',
        //bank: banks.find(bank => bank.value === invoice.bankId.toString()) as SelectValue,
        ficExternalId: invoice.ficExternalId,
        paymentDate: dayjs(invoice.paymentDate).toDate(),
        rows: invoice.details.map(detail => {
            return {
                vat: vats.find(vat => detail.vatId.toString() == vat.value) as SelectValue,
                amount: detail.amount.toString(),
                productName: detail.productName,
                description: detail.description
            }
        })
    }
    return result
}
export const adaptInvoiceDuplicateForm = ({invoice, vats}: adaptInvoiceFormValue) => {
    const statusFinded = INVOICE_STATUSES.find(status => invoice.status == status.value)
    const vatPayabilityFinded = VAT_PAYABILITY.find(item => invoice.vatPayability == item.value)

    const result: InvoiceValidationSchema = {
        status: {
            label: statusFinded?.label || '',
            value: statusFinded?.value || ''
        },
        date: dayjs(invoice.date).toDate(),
        vatPayability: {
            label: vatPayabilityFinded?.label || '',
            value: vatPayabilityFinded?.value || ''
        },
        internalSubject: invoice.internalSubject || '',
        //bank: banks.find(bank => bank.value === invoice.bankId.toString()) as SelectValue,
        ficExternalId: null,
        paymentDate: dayjs(invoice.paymentDate).toDate(),
        rows: invoice.details.map(detail => {
            return {
                vat: vats.find(vat => detail.vatId.toString() == vat.value) as SelectValue,
                amount: detail.amount.toString(),
                productName: detail.productName,
                description: detail.description
            }
        })
    }
    return result
}

export const adaptInvoiceCreateData = (formData: InvoiceValidationSchema, projectInvoiceData: ProjectInvoiceData) => {
    // TODO: remove commented fields when FIC is ready
    const result: HttpInvoiceBodyRequest = {
        status: formData.status.value,
        projectId: projectInvoiceData.projectId,
        customerId: projectInvoiceData.customerId,
        date: dayjs(formData.date).format('YYYY-MM-DD'),
        paymentDate: dayjs(formData.paymentDate).format('YYYY-MM-DD'),
        vatPayability: formData.vatPayability.value,
        internalSubject: formData.internalSubject,
        ficExternalId: formData.ficExternalId || null,
        details: formData.rows.map(row => {
            return {
                vatId: +row.vat.value,
                amount: parseFloat(removeCurrencyFormat(row.amount)),
                productName: row.productName,
                description: row.description
            }
        })
    }
    return result
}
