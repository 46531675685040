import Skeleton from '@components/ui/skeleton/Skeleton.tsx'
import {TableColumn} from '@components/commons/table/Table.tsx'
import {
    StyledTCell,
    StyledTCellColumn,
    StyledTCellValue,
    StyledTRow,
    StyledTRowWrapper
} from '@components/commons/table/MobileTBody/style.ts'

interface DesktopSkeletonProps<TData extends unknown[], TSortName extends string | undefined> {
    columns: TableColumn<TData, TSortName>[]
}

const DesktopSkeleton = <TData extends unknown[], TSortName extends string | undefined>({
    columns
}: DesktopSkeletonProps<TData, TSortName>) => {
    return (
        <StyledTRowWrapper>
            <StyledTRow>
                {columns.map((column, index) => (
                    <StyledTCell key={index}>
                        {column.label && (
                            <StyledTCellColumn>
                                <Skeleton height={14} />
                            </StyledTCellColumn>
                        )}
                        <StyledTCellValue>
                            <Skeleton height={14} />
                        </StyledTCellValue>
                    </StyledTCell>
                ))}
            </StyledTRow>
        </StyledTRowWrapper>
    )
}

DesktopSkeleton.displayName = 'TableDesktopSkeleton'
export default DesktopSkeleton
