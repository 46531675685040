import styled, {css} from 'styled-components'
import {ContentEditable} from '@lexical/react/LexicalContentEditable'

export const StyledContentEditableView = styled(ContentEditable)`
    ${({theme: {typography, palette}}) => css`
        width: 100%;
        height: 100%;
        border: none;
        outline: none;

        color: ${palette.neutral['900']};
        ${typography.textMd}
        & > * {
            all: revert;
            margin: 0;
        }
        & strong {
            all: revert;
        }
        &:focus {
            outline: none;
        }
    `}
`
export const StyledRichTextareaViewWrapper = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        position: relative;
        display: flex;
        gap: ${spacing}px;
        background: ${palette.neutral['50']};
        border: 1px solid ${palette.neutral['200']};
        border-radius: 8px;
        min-height: 92px;
        max-height: 110px;
        overflow: scroll;
        padding: ${spacing * 3}px ${spacing * 3.5}px;
        width: 100%;
        ${typography.textMd}
        &:focus {
            outline: none;
        }
    `}
`
