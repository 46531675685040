import {FC, PropsWithChildren, ReactNode} from 'react'
import PageHero from '@components/commons/page-hero/PageHero'
import {StyledPageContainer} from './style'

interface PageLayoutProps extends PropsWithChildren {
    title: string
    subtitle?: string | null
    sideTitle?: ReactNode | string | null
    sideHeading?: ReactNode
}

export const PageLayout: FC<PageLayoutProps> = ({title, subtitle, sideHeading, children, sideTitle}) => {
    return (
        <StyledPageContainer fullWidth={true}>
            <PageHero title={title} sideTitle={sideTitle} subtitle={subtitle} sideComponent={sideHeading} />
            {children}
        </StyledPageContainer>
    )
}
