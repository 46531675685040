import {TableDataCell} from '@/components/commons/table/TableDataCell/TableDataCell'
import {Chip} from '@/components/ui/chip/Chip'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledProjectTableDataCell = styled(TableDataCell)(
    ({theme: {palette, spacing}}) => css`
        color: ${palette.neutral[900]};
        font-weight: bold;
        display: grid;
        grid-template-columns: 8px 1fr auto;
        gap: ${spacing * 2}px;
        & > .projectName {
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            white-space: nowrap;
        }
        & > .needSal {
            display: flex;
            align-items: center;
        }
    `
)

type StyledProjectStatusProps = {
    theme: DefaultTheme
    $isActive: boolean
}

export const StyledProjectStatus = styled.div<StyledProjectStatusProps>(
    ({theme: {palette}, $isActive}) => css`
        width: 4px;
        height: 40px;
        border-radius: 4px;
        background-color: ${$isActive ? palette.success[500] : palette.neutral[500]};
    `
)

export const StyledNeedSalChip = styled(Chip)(
    () => css`
        cursor: pointer;
    `
)

export const StyledTeamLead = styled(TableDataCell)(
    () => css`
        display: block;
        font-weight: bold;
    `
)
