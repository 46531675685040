import {HttpCostsParams} from '../types'
import {QUERY_KEYS, infiniteQueryFetchNextPage} from '@/queryClient'
import {useInfiniteQuery} from '@tanstack/react-query'
import {httpGetCost} from '../cost.http'

export const useCostsQuery = (params: Omit<HttpCostsParams, 'page'>) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.COSTS, params.search, params.received, params.orderBy, params.orderDirection],
        queryFn: ({pageParam = 1}) =>
            httpGetCost({
                ...params,
                page: pageParam
            }),
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData
    }
}
