import InputSelect, {SelectValue} from '@/components/commons/select/Select'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledSalsFiltersForm, StyledSalsFiltersMobileHeader} from './style'
import Button from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {zodResolver} from '@hookform/resolvers/zod'
import {SALS_FILTERS_FORM_MODEL, SalsFiltersValidationSchema} from './utils'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {XCloseIcon} from '@/components/ui/icon'
import {SalsFiltersParams} from '@/features/sal/types'

interface SalsFiltersFormProps {
    filtersUrlValues: SalsFiltersValidationSchema
    filtersOptions: SalsFiltersValidationSchema
    onSubmitFilters: (filters: SalsFiltersParams) => void
    onSearchCustomerNameCb?: (searchText: string) => void
    hideModal: () => void
}

export const SalsFiltersForm: FC<SalsFiltersFormProps> = ({
    filtersUrlValues,
    filtersOptions,
    onSubmitFilters,
    hideModal
}) => {
    const {t} = useTranslation()
    const {
        control,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm<SalsFiltersValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(SalsFiltersValidationSchema),
        defaultValues: {
            ...filtersUrlValues
        }
    })

    const onSubmit: SubmitHandler<SalsFiltersValidationSchema> = data => {
        onSubmitFilters({
            teamLeadIds: data?.teamLead?.map(teamLead => parseInt(teamLead.value))
        })
    }

    return (
        <StyledSalsFiltersForm onSubmit={handleSubmit(onSubmit)}>
            <StyledSalsFiltersMobileHeader justify="space-between" align="center">
                <h3>{t('commons:filter_label')}</h3>
                <Button size="lg" iconOnly variant="ghost" onClick={() => hideModal()}>
                    <XCloseIcon />
                </Button>
            </StyledSalsFiltersMobileHeader>

            <Controller
                render={({field: {onChange, value}}) => (
                    <InputSelect
                        value={value}
                        options={filtersOptions.teamLead}
                        onChange={newValue => {
                            onChange(newValue ? (newValue as SelectValue[]) : [])
                        }}
                        size={'medium'}
                        isMulti={true}
                        name={SALS_FILTERS_FORM_MODEL.teamLead.name}
                        label={t(SALS_FILTERS_FORM_MODEL.teamLead.label)}
                        isClearable={true}
                        isSearchable={true}
                        errorMessage={t(errors.teamLead?.message || '')}
                        placeholder={t(SALS_FILTERS_FORM_MODEL.teamLead.placeholder)}
                    />
                )}
                control={control}
                name={SALS_FILTERS_FORM_MODEL.teamLead.name}
            />

            <Flexbox gap={4} justify="flex-end">
                <Button
                    variant={'secondary'}
                    onClick={() =>
                        reset({
                            teamLead: []
                        })
                    }
                >
                    {t('commons:restore')}
                </Button>
                <Button type="submit" variant={'primary'}>
                    {t('commons:apply')}
                </Button>
            </Flexbox>
        </StyledSalsFiltersForm>
    )
}
