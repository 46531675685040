import {HeaderWrapper, HeaderGrid, DropdownUserInfosItems, HeaderLogo} from '@components/commons/header/style.ts'
import Logo from '@assets/images/logo_dark.svg'
import Container from '@components/ui/container/Container.tsx'
import NavLink from '@components/ui/navlink/NavLink.tsx'
import DropdownMenu from '@components/ui/dropdown-menu/DropdownMenu.tsx'
import {FlashIcon, LogoutIcon, Settings01Icon, Users01Icon} from 'src/components/ui/icon'
import {useState} from 'react'
import {LangSelectionModal} from '@components/commons/lang-selection-modal/LangSelectionModal.tsx'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Link} from 'react-router-dom'
import {routes} from '@/utilities/constants'

export type MenuItem = {
    name: string
    path: string
}

interface HeaderProps {
    items: MenuItem[]
}

const Header = ({items}: HeaderProps) => {
    const user = useAuthStore(state => state.user)
    const [showLangModal, setShowLangModal] = useState(false)
    const logout = useAuthStore(state => state.logout)

    return (
        <HeaderWrapper>
            <Container fullWidth={true}>
                <HeaderGrid>
                    <Link to={routes.PROJECTS.path}>
                        <HeaderLogo src={Logo} alt={'logo'} height={28} />
                    </Link>
                    <Flexbox align={'center'}>
                        {items.map(({name, path}) => (
                            <NavLink path={path} name={name} key={name} />
                        ))}
                    </Flexbox>
                    <DropdownMenu
                        sideOffset={8}
                        trigger={<Settings01Icon />}
                        actions={[
                            {
                                component: (
                                    <DropdownUserInfosItems>
                                        <span>{user?.fullName ?? 'Test User'}</span>
                                    </DropdownUserInfosItems>
                                ),
                                withSeparator: true
                            },
                            {
                                component: 'Activity Log',
                                icon: <FlashIcon size={16} />,
                                disabled: true
                                //onClickCb: () => console.log('teams')
                            },
                            {
                                component: 'Teams',
                                icon: <Users01Icon size={16} />,
                                disabled: true
                                //onClickCb: () => console.log('teams')
                            },
                            {
                                component: 'Logout',
                                icon: <LogoutIcon size={16} />,
                                onClickCb: logout
                            }
                        ]}
                    />
                </HeaderGrid>
            </Container>
            {showLangModal && <LangSelectionModal closeCallback={() => setShowLangModal(false)} />}
        </HeaderWrapper>
    )
}

export default Header
