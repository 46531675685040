import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {ErrorResponseData} from '@/types/commons'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {httpCreateSal} from '@/features/project-sals/services/projectSal.http'

type UseCreateProjectSalProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useCreateProjectSal = ({onSuccess, onError}: UseCreateProjectSalProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_PROJECT_SAL],
        mutationFn: httpCreateSal,
        onSuccess: data => {
            onSuccess?.()
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECT_SALS, data.projectId]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SALS]})
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            onError?.(error)
        }
    })
}
