import {PaginationParams, PaginationResponse, ResponseType} from '@/types/commons'
import {z} from 'zod'
import {Invoice, InvoicesSearchParams} from '@/features/invoice/types'
import axios from '@/axiosInstance'

/**
 * httpGetInvoices
 * Here we will fetch all the invoices data for the dash table
 */

// Params schema
export const HttpInvoicesParams = z.object({
    ...InvoicesSearchParams.shape,
    ...PaginationParams.shape,
    responseType: ResponseType
})
export type HttpInvoicesParams = z.infer<typeof HttpInvoicesParams>

// Response
export const HttpInvoiceResponse = z.object({
    data: z.array(Invoice),
    meta: PaginationResponse
})
export type HttpInvoiceResponse = z.infer<typeof HttpInvoiceResponse>

// Http request
export const httpGetInvoices = async (params: HttpInvoicesParams): Promise<HttpInvoiceResponse> => {
    const result = await axios.get(`/invoices`, {params})
    return HttpInvoiceResponse.parse(result.data)
}

/**
 * httpGetInvoiceDetails
 * Here we will get project details data
 */

// Params schema
export const HttpGetInvoiceDetailsParams = z.object({
    invoiceId: z.coerce.number().int(),
    responseType: ResponseType
})
export type HttpGetInvoiceDetailsParams = z.infer<typeof HttpGetInvoiceDetailsParams>

// Response schema
export const HttpInvoiceDetailsResponse = z.object({
    ...Invoice.shape
})
export type HttpInvoiceDetailsResponse = z.infer<typeof HttpInvoiceDetailsResponse>

// Http request
export async function httpGetInvoiceDetails(id: number): Promise<Invoice> {
    const result: Invoice = await axios.get(`/invoices/${id}`)
    return result
}

/**
 * httpCreateInvoice
 * Here we will create a new invoice instance
 */

export const HttpInvoiceBodyRequest = z.object({
    // TODO: remove commented fields when FIC is ready
    status: z.string(),
    date: z.string(),
    projectId: z.number(),
    customerId: z.number().optional(),
    vatPayability: z.string().nullable(),
    internalSubject: z.string().nullable(),
    ficExternalId: z.number().nullable(),
    paymentDate: z.string(),
    details: z.array(
        z.object({
            vatId: z.number(),
            amount: z.number(),
            productName: z.string(),
            description: z.string()
        })
    )
})
export type HttpInvoiceBodyRequest = z.infer<typeof HttpInvoiceBodyRequest>

export const HttpCreateInvoiceParams = z.object({
    data: HttpInvoiceBodyRequest
})
export type HttpCreateInvoiceParams = z.infer<typeof HttpCreateInvoiceParams>

export const httpCreateInvoice = async (params: HttpCreateInvoiceParams): Promise<Invoice> => {
    const response = await axios.post(`/invoices`, {
        ...params.data
    })
    return response.data
}

/**
 * httpUpdateProject
 * Here we will update an invoice instance
 */

export const HttpUpdateInvoiceParams = z.object({
    data: HttpInvoiceBodyRequest,
    invoiceId: z.number()
})
export type HttpUpdateInvoiceParams = z.infer<typeof HttpUpdateInvoiceParams>

export const httpUpdateInvoice = async (params: HttpUpdateInvoiceParams): Promise<Invoice> => {
    const response = await axios.patch(`/invoices/${params.invoiceId}`, {
        ...params.data
    })
    return response.data
}

export const httpDeleteInvoice = async (id: number) => {
    return await axios.delete(`/invoices/${id}`)
}

/**
 * httpUploadInvoice
 * Here we will upload on fatture in cloud an invoice instance
 */

export const UploadInvoiceToFicResponse = z.object({
    id: z.coerce.number(),
    status: z.number()
})
export type UploadInvoiceToFicResponse = z.infer<typeof UploadInvoiceToFicResponse>

export const httpUploadInvoiceToFIC = async (params: HttpUpdateInvoiceParams): Promise<UploadInvoiceToFicResponse> => {
    const result = await axios.post(`/invoices/${params.invoiceId}/fatture-in-cloud`, params.data)
    return UploadInvoiceToFicResponse.parse({...result, id: params.invoiceId})
}
