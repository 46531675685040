import {HttpResult} from '@/types/commons'
import {Bank} from '../types/bank'

import axios from '@/axiosInstance.ts'

/*export async function httpGetBanks(name?: string) {
    const result: HttpResult<HttpListResult<Bank>> = await new Promise(resolve =>
        setTimeout(() => resolve(getMockedBanks(name)), 500)
    )
    return result.data
}*/

export async function httpGetBankDetails(id: number) {
    const result: HttpResult<Bank> = await axios.get(`/banks/${id}`)
    return result.data
}

export async function httpGetBanks() {
    const result: HttpResult<Bank[]> = await axios.get('/banks')
    return result.data
}
