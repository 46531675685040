import React from 'react'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {useTranslation} from 'react-i18next'
import {
    StyledInformationWrapper,
    StyledInvoicedModal,
    StyledResumeWrapper,
    StyledRowAmountsWrapper,
    StyledRowDescriptionsWrapper,
    StyledRowWrapper,
    StyledSeparator,
    StyledTitle
} from '@/features/project-revenues/components/invoice-modal/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useGetRevenueDetails} from '@/features/invoice/services/queries/useGetInvoiceDetails.ts'
import {ResponseType} from '@/types/commons.ts'
import {INVOICE_FORM_MODEL} from '@/features/project-revenues/components/invoice-modal/InvoiceFormModel.tsx'
import {ValueIndicator} from '@components/commons/value-indicator/ValueIndicator.tsx'
import {formatCurrency} from '@utilities/helpers.ts'
import {useVatQuery} from '@/features/vat/services/queries/useVat.ts'
import dayjs from 'dayjs'
import {InvoicesFormLoadingSkeleton} from '@/features/project-revenues/components/invoice-modal/InvoiceFormSkeleton.tsx'

interface ViewInvoiceModalProps {
    onClose: () => void
    invoiceId: number
}
export const ViewInvoiceModal: React.FC<ViewInvoiceModalProps> = ({onClose, invoiceId}) => {
    const {data: invoiceDetails, isPending: invoicePending} = useGetRevenueDetails({
        invoiceId: invoiceId,
        params: {
            responseType: ResponseType.Enum.extended
        }
    })
    const {data: vats} = useVatQuery()
    const {t} = useTranslation()

    const findVat = (id: number) => {
        if (vats) {
            return vats.find(vat => vat.id === id)?.value.toString() + '%'
        }

        return
    }

    const calcolateSubtotalRow = (amount: number, vatId: number) => {
        const vat = findVat(vatId)
        if (vat) {
            return amount + (amount * parseFloat(vat)) / 100
        }

        return
    }

    const calcolateTotal = () => {
        let total = 0

        if (invoiceDetails?.details) {
            invoiceDetails.details.forEach(value => {
                total += value.amount
                return total
            })
        }

        return total
    }

    const calcolateSubtotal = () => {
        let subtotal = 0

        if (invoiceDetails?.details) {
            invoiceDetails.details.forEach(value => {
                const vat = findVat(value.vatId)
                if (vat) {
                    return (subtotal += value.amount + (value.amount * parseFloat(vat)) / 100)
                }
            })
        }
        return subtotal
    }
    return (
        <Modal width={1000} onClose={onClose}>
            <StyledInvoicedModal>
                <Flexbox justify={'space-between'} align={'center'}>
                    <Flexbox align={'center'}>
                        <StyledTitle>{t('invoice:details:view_title')}</StyledTitle>
                    </Flexbox>
                </Flexbox>
                {invoicePending || !invoiceDetails || !vats ? (
                    <InvoicesFormLoadingSkeleton />
                ) : (
                    <>
                        <StyledInformationWrapper>
                            <ValueIndicator value={invoiceDetails.status} label={t(INVOICE_FORM_MODEL.status.label)} />
                            <ValueIndicator
                                value={dayjs(invoiceDetails.date).format('DD/MM/YYYY')}
                                label={t(INVOICE_FORM_MODEL.date.label)}
                            />
                            <ValueIndicator
                                value={t(`invoice:vat_payability:${invoiceDetails.vatPayability}`) || ''}
                                label={t(INVOICE_FORM_MODEL.vatPayability.label)}
                            />
                        </StyledInformationWrapper>
                        <StyledRowDescriptionsWrapper>
                            <ValueIndicator
                                value={invoiceDetails.internalSubject || ''}
                                label={t(INVOICE_FORM_MODEL.internalSubject.label)}
                            />
                            <ValueIndicator
                                value={invoiceDetails.ficExternalId?.toString() || ''}
                                label={t(INVOICE_FORM_MODEL.ficExternalId.label)}
                            />
                        </StyledRowDescriptionsWrapper>

                        {invoiceDetails?.details &&
                            invoiceDetails?.details.map((value, index) => (
                                <StyledRowWrapper key={index}>
                                    <StyledRowAmountsWrapper>
                                        <ValueIndicator
                                            value={findVat(value.vatId) || ''}
                                            label={t(INVOICE_FORM_MODEL.rows.vat.label)}
                                        />
                                        <ValueIndicator
                                            value={formatCurrency(value.amount, 'EUR')}
                                            label={t(INVOICE_FORM_MODEL.rows.amount.label)}
                                        />
                                    </StyledRowAmountsWrapper>
                                    <StyledRowDescriptionsWrapper>
                                        <ValueIndicator
                                            value={formatCurrency(
                                                calcolateSubtotalRow(value.amount, value.vatId),
                                                'EUR'
                                            )}
                                            label={t(INVOICE_FORM_MODEL.rows.subtotal.label)}
                                        />
                                        <ValueIndicator
                                            value={value.productName}
                                            label={t(INVOICE_FORM_MODEL.rows.productName.label)}
                                        />
                                        <ValueIndicator
                                            value={value.description}
                                            label={t(INVOICE_FORM_MODEL.rows.description.name)}
                                        />
                                    </StyledRowDescriptionsWrapper>
                                </StyledRowWrapper>
                            ))}
                        <StyledSeparator />
                        <StyledResumeWrapper>
                            <ValueIndicator
                                value={formatCurrency(calcolateTotal(), 'EUR')}
                                label={t(INVOICE_FORM_MODEL.total.label)}
                            />
                            <ValueIndicator
                                value={formatCurrency(calcolateSubtotal(), 'EUR')}
                                label={t(INVOICE_FORM_MODEL.totalVatIncluded.label)}
                            />
                            <ValueIndicator
                                value={dayjs(invoiceDetails.paymentDate).format('DD/MM/YYYY')}
                                label={t(INVOICE_FORM_MODEL.paymentDate.label)}
                            />
                        </StyledResumeWrapper>
                    </>
                )}
            </StyledInvoicedModal>
        </Modal>
    )
}
