import {Navigate, Outlet} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {useAuthStore} from '@/store/AuthStore.tsx'

const PrivateRoute = () => {
    const accessToken = useAuthStore(state => state.accessToken)

    return accessToken ? <Outlet /> : <Navigate to={routes.LOGIN.path} />
}

export default PrivateRoute
