import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {useSalsQuery} from '@/features/sal/services/queries/useSals'
import {SalsSearchParams} from '@/features/sal/types'
import {ResponseType} from '@/types/commons'
import {useInputSearch} from '@/hooks/useInputSearch'
import {useEffect} from 'react'
import {SalStatus} from '@/features/project-sals/types'

export const useSalsTable = () => {
    const multiParams = ['teamLeadIds', 'status'] as const satisfies ReadonlyArray<keyof SalsSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, SalsSearchParams)
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch(searchParams.search)
    const salsQuery = useSalsQuery({
        ...searchParams,
        status: [SalStatus.Enum.scheduled],
        limit: 20,
        responseType: ResponseType.enum.extended
    })

    useEffect(() => {
        setSearchParams({search: searchValue})
    }, [searchValue])

    const sort = (sorter: {
        orderBy: SalsSearchParams['orderBy']
        orderDirection: SalsSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...salsQuery,
        onSearch,
        onResetSearch,
        searchValue,
        searchError,
        filterValue: Object.values(searchParams)
            .filter(param => param !== 'search')
            .some(value => value !== null && value !== undefined),
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
