import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {useProjectsQuery} from '@/features/project/services/queries/useProjects'
import {CachedProjectDetails, ProjectsSearchParams} from '@/features/project/types'
import {ResponseType} from '@/types/commons'
import {useInputSearch} from '@/hooks/useInputSearch'
import {useEffect, useState} from 'react'
import {useProjectSyncSocket} from './useProjectSyncSocket'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {findCachedDataToUpdate} from '../../utils'
import dayjs from 'dayjs'

export const useProjectsTable = () => {
    const multiParams = [
        'projectTypeIds',
        'successManagerIds',
        'teamLeadIds',
        'productLeadIds',
        'status',
        'customerIds'
    ] as const satisfies ReadonlyArray<keyof ProjectsSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, ProjectsSearchParams)
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch(searchParams.search)
    const projectsQuery = useProjectsQuery({
        ...searchParams,
        limit: 20,
        responseType: ResponseType.enum.extended
    })

    const syncStatusEvent = useProjectSyncSocket()

    useEffect(() => {
        setSearchParams({search: searchValue})
    }, [searchValue])

    const [needWorkProgressStatus, setNeedWorkProgressStatus] = useState(searchParams.needWorkProgressStatus)

    useEffect(() => {
        setSearchParams({needWorkProgressStatus: needWorkProgressStatus ? 'true' : undefined})
    }, [needWorkProgressStatus])

    // Update cache after socket event
    useEffect(() => {
        if (syncStatusEvent) {
            queryClient.setQueryData(
                [
                    QUERY_KEYS.PROJECTS,
                    searchParams.orderBy,
                    searchParams.orderDirection,
                    searchParams.search,
                    searchParams.projectTypeIds,
                    searchParams.successManagerIds,
                    searchParams.teamLeadIds,
                    searchParams.status
                ],
                (cachedData: CachedProjectDetails) => {
                    const dataToUpdate = cachedData && findCachedDataToUpdate(cachedData, syncStatusEvent)

                    if (dataToUpdate && dataToUpdate.pageIndex !== null && dataToUpdate.projectIndex !== null) {
                        const {pageIndex, projectIndex} = dataToUpdate
                        const clonedCache = {...cachedData}
                        clonedCache.pages[pageIndex].data[projectIndex].syncStatus = dataToUpdate.syncStatus
                        clonedCache.pages[pageIndex].data[projectIndex].lastSyncAt = dayjs().toISOString()

                        return clonedCache
                    }
                }
            )
        }
    }, [syncStatusEvent])

    const sort = (sorter: {
        orderBy: ProjectsSearchParams['orderBy']
        orderDirection: ProjectsSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...projectsQuery,
        onSearch,
        onResetSearch,
        searchValue,
        searchError,
        filterValue: Object.values(searchParams)
            .filter(param => param !== 'search')
            .some(value => value !== null && value !== undefined),
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort},
        needWorkProgressStatus,
        setNeedWorkProgressStatus
    }
}
