import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpDeleteCost} from '../projectCost.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'

type CostDeleteProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useDeleteCosts = ({onSuccess, onError}: CostDeleteProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_COST],
        mutationFn: httpDeleteCost,
        onSuccess: () => {
            onSuccess?.()
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECT_COSTS]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.COSTS]})
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            onError?.(error)
        }
    })
}
