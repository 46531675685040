import {FC} from 'react'
import {Invoice} from '@/features/invoice/types'
import Button from '@/components/ui/button/Button'
import {Edit01Icon, EyeIcon} from '@/components/ui/icon'

interface InvoicesActionTableButtonProps {
    invoice: Invoice
    showViewInvoiceModal: () => void
    showEditInvoiceModal: () => void
}

export const InvoicesActionTableButton: FC<InvoicesActionTableButtonProps> = ({
    invoice,
    showViewInvoiceModal,
    showEditInvoiceModal
}) => {
    if (!invoice.ficExternalId) {
        return (
            <Button iconOnly={true} variant={'ghost'} onClick={() => showEditInvoiceModal()}>
                <Edit01Icon size={20} />
            </Button>
        )
    }

    return (
        <Button iconOnly={true} variant={'ghost'} onClick={() => showViewInvoiceModal()}>
            <EyeIcon size={20} />
        </Button>
    )
}
