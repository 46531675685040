import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import Button from '@components/ui/button/Button.tsx'
import i18next from 'i18next'
import {useNavigate} from 'react-router-dom'
import {AlertCircleIcon} from 'src/components/ui/icon'

export const ErrorBoundary = () => {
    const navigate = useNavigate()
    return (
        <ErrorBox
            cta={
                <Button variant="primary" onClick={() => navigate('/customers')}>
                    {i18next.t('commons:back_home')}
                </Button>
            }
            icon={<AlertCircleIcon />}
            title={i18next.t('errors:default')}
        />
    )
}
