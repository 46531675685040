import {useState} from 'react'
import {StyledProjectTabWrapper} from '../style'
import {useOutletContext} from 'react-router-dom'
import {ProjectDetails} from '@/features/project/types'
import {useTranslation} from 'react-i18next'
import {PlusIcon} from '@/components/ui/icon'
import {ProjectCostsTable} from '@/features/project-costs/components/ProjectCostsTable'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Modal} from '@/components/ui/modal/Modal'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {ProjectCostForm} from '@/features/project-costs/components/ProjectCostForm'
import {useProjectCostsTable} from '@/features/project-costs/services/queries/useProjectCostsTable'
import Button from '@/components/ui/button/Button'
import {ProjectCost} from '@/features/project-costs/types'
import toast from 'react-hot-toast'
import {useDeleteCosts} from '@/features/project-costs/services/queries/useDeleteProjectCost'
import {phasesSelectAdapter} from '@/utilities/adapters'
import {ProjectCostLoader} from '@/features/project-costs/components/ProjectCostLoader'
import {adaptCostToForm} from '@/features/project-costs/utils'
import {errorHandler} from '@/utilities/genericErrorHandler'

export const ProjectCosts: React.FC = () => {
    const {t} = useTranslation()
    const {project} = useOutletContext<{project: ProjectDetails}>()
    const [addCostModal, showAddCostModal] = useState(false)
    const [costToUpdate, setCostToUpdate] = useState<ProjectCost | null>(null)
    const [costToDelete, setCostToDelete] = useState<ProjectCost | null>(null)
    const {costs, phases, isLoading, isError} = useProjectCostsTable({
        projectId: project.id
    })

    const {mutate: deleteCostMutation} = useDeleteCosts({
        onSuccess: () => {
            toast.success(t(`commons:delete_completed`, {entity: t(`costs:singular`)}))
            setCostToDelete(null)
        },
        onError: error => {
            errorHandler(error)
            setCostToDelete(null)
        }
    })

    const onSubmitCostToDelete = () => {
        if (costToDelete) {
            return deleteCostMutation(costToDelete.id)
        }
    }

    const phasesOptions = phases ? phasesSelectAdapter(phases) : []

    return (
        <StyledProjectTabWrapper>
            <Flexbox justify={'end'}>
                <Button width={'wide'} variant="primary" onClick={() => showAddCostModal(true)}>
                    <PlusIcon />
                    <span>{t('project:costs:add_cost_btn')}</span>
                </Button>
            </Flexbox>

            <ProjectCostsTable
                projectId={project.id}
                isLoading={isLoading}
                data={costs.data || []}
                phasesOptions={phasesOptions}
                isError={isError}
                isChangingPage={false}
                setCostToUpdate={setCostToUpdate}
                setCostToDelete={setCostToDelete}
                showAddCostModal={showAddCostModal}
            />

            {addCostModal && (
                <Modal width={1017} autoZIndex={false} overflow="visible" onClose={() => showAddCostModal(false)}>
                    <ProjectCostForm
                        onClose={() => showAddCostModal(false)}
                        phasesOptions={phasesOptions}
                        setCostToUpdate={setCostToUpdate}
                        showAddCostModal={showAddCostModal}
                    />
                </Modal>
            )}

            {costToUpdate &&
                (isLoading ? (
                    <ProjectCostLoader />
                ) : (
                    <Modal width={1017} autoZIndex={false} overflow="visible" onClose={() => setCostToUpdate(null)}>
                        <ProjectCostForm
                            selectedCost={costToUpdate}
                            onClose={() => setCostToUpdate(null)}
                            phasesOptions={phasesOptions}
                            setCostToUpdate={setCostToUpdate}
                            showAddCostModal={showAddCostModal}
                            defaultValues={adaptCostToForm(costToUpdate)}
                        />
                    </Modal>
                ))}

            {costToDelete && (
                <DeleteModal
                    title={t('project:costs:delete:title')}
                    paragraph={t('project:costs:delete:description')}
                    isLoading={false}
                    onClose={() => setCostToDelete(null)}
                    onDelete={() => onSubmitCostToDelete()}
                />
            )}
        </StyledProjectTabWrapper>
    )
}
