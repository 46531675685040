import {QUERY_KEYS} from '@/queryClient.ts'
import {useQuery} from '@tanstack/react-query'
import {
    httpGetProjectRevenueDetails,
    HttpGetRevenueDetailsParams
} from '@/features/project-revenues/services/revenue.http.ts'

export const useGetRevenueDetails = ({invoiceId, params}: {invoiceId: number; params: HttpGetRevenueDetailsParams}) => {
    return useQuery({
        queryKey: [QUERY_KEYS.INVOICE_DETAILS, invoiceId],
        queryFn: () => httpGetProjectRevenueDetails(params, invoiceId)
    })
}
