import {z} from 'zod'
import {ResponseType} from '@/types/commons.ts'

export type AccountingContact = {
    name: string
    email: string
}

export type ProjectRevenuesData = {
    customer: string
    accountingContact: AccountingContact
    budget: number
    hours: number
    dailyRate: number
    markup: number
    masterAgreements: string
}

export const RevenuesURLParams = z.object({
    projectId: z.coerce.number().int(),
    responseType: ResponseType
})
export type RevenuesURLParams = z.infer<typeof RevenuesURLParams>

export const RevenueStatus = z.enum(['received', 'expected'])
export type RevenueStatus = z.infer<typeof RevenueStatus>

export const RevenuesSearchParams = z.object({
    orderBy: z.enum(['name']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional()
})
export type RevenuesSearchParams = z.infer<typeof RevenuesSearchParams>
