import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {ErrorResponseData} from '@/types/commons'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {HttpProjectSalMutationRequest, httpUpdateSal} from '../projectSal.http'

type UseUpdateProjectSalProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useUpdateProjectSal = ({onError, onSuccess}: UseUpdateProjectSalProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_PROJECT_SAL],
        mutationFn: ({id, data}: {data: HttpProjectSalMutationRequest; id: number}) => httpUpdateSal(id, data),
        onSuccess: async sal => {
            onSuccess?.()
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECT_SALS, sal.projectId]})
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SALS]})
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            onError?.(error)
        }
    })
}
