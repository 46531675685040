import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import InputText from '@components/commons/input-text/InputText.tsx'
import {CUSTOMER_FORM_MODEL, CustomerFormSchema, CustomerValidationSchema} from './CustomerFormModel'
import {useTranslation} from 'react-i18next'
import InputSelect, {SelectValue} from '@components/commons/select/Select.tsx'
import {
    StyledSectionTitle,
    StyledFirstRowWrapper,
    StyledSecondRowWrapper,
    StyledThirdRowWrapper,
    StyledFourthRowWrapper,
    StyledSeparator,
    StyledActionsWrapper
} from './style'
import Button from '@/components/ui/button/Button'
import {UnsavedBlocker} from '@/components/commons/unsaved-blocker/UnsavedBlocker'
import {httpGetEmployees} from '@/features/employee/services/employee.http'
import {employeesSelectAdapter} from '@/utilities/adapters'
import {ResponseType} from '@/types/commons'
import {EmployeeRole} from '@/features/employee/types.ts'
import Divider from '@components/ui/divider/Divider.tsx'
import {DownloadCloud02Icon} from '@components/ui/icon'
import {Tooltip} from '@components/ui/tooltip/Tooltip.tsx'
import {routes} from '@utilities/constants'
import React, {useCallback} from 'react'
import {useNavigate} from 'react-router-dom'
import {useUpdateCustomer} from '../../services/queries/useUpdateCustomer'
import {errorHandler} from '@/utilities/genericErrorHandler'
import toast from 'react-hot-toast'
import {adaptCustomerToCreate, adaptCustomerToUpdate} from './utils'
import {useCreateCustomer} from '../../services/queries/useCreateCustomer'
import {useAsync} from '@hooks/useAsync.ts'
import {httpGetCustomerFicId} from '@/features/customer/services/customer.http.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {debounce} from '@utilities/helpers.ts'
import {CallbackFunction} from '@/features/project/components/project-form/ProjectForm.tsx'
interface CustomerFormProps {
    defaultValues?: CustomerValidationSchema
    customerId?: number
}

const CustomerForm: React.FC<CustomerFormProps> = ({defaultValues, customerId}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: {errors, touchedFields, isValid, isDirty, isSubmitted}
    } = useForm<CustomerValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(CustomerFormSchema),
        defaultValues: {...defaultValues}
    })

    // mutations
    const {mutate: createCustomerMutation, isPending: isPendingCreate} = useCreateCustomer({
        onSuccess: newCustomer => {
            navigate(`${routes.CUSTOMERS.path}/${newCustomer.id}`)
            toast.success(t('customer:new_customer_success'))
        },
        onError: error => errorHandler(error)
    })

    const {mutate: updateCustomerMutation, isPending: isPendingUpdate} = useUpdateCustomer({
        onSuccess: () => {
            toast.success(t(`commons:update_completed`, {entity: t(`customer:singular`)}))
        },
        onError: () => {
            reset({...defaultValues})
        }
    })
    const {run: runFic, isLoading: isLoadingFic} = useAsync()

    const vatNumber = watch(CUSTOMER_FORM_MODEL.vatNumber.name)
    const fiscalCode = watch(CUSTOMER_FORM_MODEL.fiscalCode.name)
    const onCloudResearchVatNumber = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        if (isLoadingFic) {
            return null
        }
        try {
            if (!vatNumber) {
                return toast.error(t('customer:fic_id:invalid_vat'))
            }
            const ficId = await runFic(httpGetCustomerFicId({type: 'vatNumber', value: vatNumber}))
            setValue(CUSTOMER_FORM_MODEL.ficExternalId.name, ficId.id.toString())
            toast.success(t('customer:fic_id:success'))
        } catch (e) {
            errorHandler(e)
        }
    }
    const onCloudResearchFiscalCode = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        if (isLoadingFic) {
            return null
        }
        try {
            if (!fiscalCode) {
                return toast.error(t('customer:fic_id:invalid_fc'))
            }
            const ficId = await runFic(httpGetCustomerFicId({type: 'fiscalCode', value: fiscalCode}))
            setValue(CUSTOMER_FORM_MODEL.ficExternalId.name, ficId.id.toString())
            toast.success(t('customer:fic_id:success'))
        } catch (e) {
            errorHandler(e)
        }
    }

    // submit handler
    const onSubmit: SubmitHandler<CustomerValidationSchema> = data => {
        if (customerId) {
            updateCustomerMutation(adaptCustomerToUpdate(customerId, data))
            reset({}, {keepValues: true})
        } else {
            createCustomerMutation(adaptCustomerToCreate(data))
        }
    }
    const loadSuccessManager = async (inputValue: string | undefined, callback: CallbackFunction) => {
        try {
            const response = await httpGetEmployees({
                search: inputValue,
                roles: [EmployeeRole.Enum.success_manager, EmployeeRole.enum.admin],
                responseType: ResponseType.enum.extended,
                isActive: true
            })
            callback(employeesSelectAdapter(response))
        } catch (error) {
            errorHandler(error)
            callback([])
        }
    }

    const loadSuccessManagerOptions = useCallback(
        debounce((inputValue: string | undefined, callback: CallbackFunction) => {
            loadSuccessManager(inputValue, callback)
        }, 400),
        []
    )

    return (
        <UnsavedBlocker isDirty={isDirty && !isSubmitted}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Divider topSpacing={0} bottomSpacing={40} />
                <StyledSectionTitle>{t('customer:details:details')}</StyledSectionTitle>
                <StyledFirstRowWrapper>
                    <InputText
                        customInputTrigger={
                            <Tooltip
                                content={<p>Customer research on FIC</p>}
                                trigger={
                                    <div onClick={e => onCloudResearchVatNumber(e)}>
                                        {isLoadingFic ? (
                                            <Spinner size={20} color={'black'} />
                                        ) : (
                                            <DownloadCloud02Icon size={20} />
                                        )}
                                    </div>
                                }
                            />
                        }
                        label={`${t(CUSTOMER_FORM_MODEL.vatNumber.label)}`}
                        type={'text'}
                        touched={touchedFields.vatNumber}
                        errorMessage={t(errors.vatNumber?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.vatNumber.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.vatNumber.name))}
                    />
                    <InputText
                        customInputTrigger={
                            <Tooltip
                                content={<p>Customer research on FIC</p>}
                                trigger={
                                    <div onClick={e => onCloudResearchFiscalCode(e)}>
                                        {isLoadingFic ? (
                                            <Spinner size={20} color={'black'} />
                                        ) : (
                                            <DownloadCloud02Icon size={20} />
                                        )}
                                    </div>
                                }
                            />
                        }
                        label={`${t(CUSTOMER_FORM_MODEL.fiscalCode.label)}`}
                        type={'text'}
                        touched={touchedFields.fiscalCode}
                        errorMessage={t(errors.fiscalCode?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.fiscalCode.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.fiscalCode.name))}
                    />
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.sdi.label)}`}
                        type={'text'}
                        touched={touchedFields.sdi}
                        errorMessage={t(errors.sdi?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.sdi.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.sdi.name))}
                    />
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.pec.label)}`}
                        type={'text'}
                        touched={touchedFields.pec}
                        errorMessage={t(errors.pec?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.pec.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.pec.name))}
                    />
                </StyledFirstRowWrapper>
                <StyledSecondRowWrapper>
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.businessName.label)}*`}
                        type={'text'}
                        touched={touchedFields.businessName}
                        errorMessage={t(errors.businessName?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.businessName.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.businessName.name))}
                    />
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.ficExternalId.label)}`}
                        type={'number'}
                        touched={touchedFields.ficExternalId}
                        errorMessage={t(errors.ficExternalId?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.ficExternalId.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.ficExternalId.name))}
                    />
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.floatExternalId.label)}`}
                        type={'number'}
                        touched={touchedFields.floatExternalId}
                        errorMessage={t(errors.floatExternalId?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.floatExternalId.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.floatExternalId.name))}
                    />
                </StyledSecondRowWrapper>
                <StyledThirdRowWrapper>
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.legalAddress.label)}`}
                        type={'text'}
                        touched={touchedFields.legalAddress}
                        errorMessage={t(errors.legalAddress?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.legalAddress.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.legalAddress.name))}
                    />
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.civicNumber.label)}`}
                        type={'text'}
                        touched={touchedFields.civicNumber}
                        errorMessage={t(errors.civicNumber?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.civicNumber.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.civicNumber.name))}
                    />
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.city.label)}`}
                        type={'text'}
                        touched={touchedFields.city}
                        errorMessage={t(errors.city?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.city.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.city.name))}
                    />
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.zipCode.label)}`}
                        type={'text'}
                        touched={touchedFields.zipCode}
                        errorMessage={t(errors.zipCode?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.zipCode.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.zipCode.name))}
                    />
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.province.label)}`}
                        type={'text'}
                        touched={touchedFields.province}
                        errorMessage={t(errors.province?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.province.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.province.name))}
                    />
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.country.label)}`}
                        type={'text'}
                        touched={touchedFields.country}
                        errorMessage={t(errors.country?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.country.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.country.name))}
                    />
                </StyledThirdRowWrapper>
                <StyledSeparator />
                <StyledSectionTitle>{t('customer:details:contacts')}</StyledSectionTitle>
                <StyledFourthRowWrapper>
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <InputSelect
                                isAsync
                                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                // @ts-expect-error
                                loadOptions={loadSuccessManagerOptions}
                                value={value as SelectValue}
                                onChange={newValue => {
                                    onChange(newValue as SelectValue)
                                }}
                                size={'medium'}
                                name={CUSTOMER_FORM_MODEL.successManager.name}
                                label={`${t(CUSTOMER_FORM_MODEL.successManager.label)}`}
                                isClearable={true}
                                isCreatable={false}
                                isSearchable={true}
                                errorMessage={t(errors.successManager?.message || '')}
                                placeholder={t(CUSTOMER_FORM_MODEL.successManager.label)}
                            />
                        )}
                        control={control}
                        name={CUSTOMER_FORM_MODEL.successManager.name}
                    />
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.fullnameAccounting.label)}`}
                        type={'text'}
                        touched={touchedFields.fullnameAccounting}
                        errorMessage={t(errors.fullnameAccounting?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.fullnameAccounting.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.fullnameAccounting.name))}
                    />
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.email.label)}`}
                        type={'text'}
                        defaultValue={''}
                        touched={touchedFields.email}
                        errorMessage={t(errors.email?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.email.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.email.name))}
                    />
                    <InputText
                        label={`${t(CUSTOMER_FORM_MODEL.phoneNumber.label)}`}
                        type={'text'}
                        touched={touchedFields.phoneNumber}
                        errorMessage={t(errors.phoneNumber?.message || '')}
                        placeholder={t(CUSTOMER_FORM_MODEL.phoneNumber.label)}
                        {...register(t(CUSTOMER_FORM_MODEL.phoneNumber.name))}
                    />
                </StyledFourthRowWrapper>
                <StyledActionsWrapper gap={2} justify={'end'}>
                    <Button
                        width={'wide'}
                        onClick={() => navigate(routes.CUSTOMERS.path)}
                        variant={'tertiary'}
                        size="md"
                        disabled={isPendingUpdate || isPendingCreate}
                    >
                        {t('new_employee:cancel')}
                    </Button>
                    <Button
                        width={'wide'}
                        type="submit"
                        variant="primary"
                        size="md"
                        disabled={!isValid || isPendingUpdate || isPendingCreate || !isDirty}
                    >
                        {t('commons:save')}
                    </Button>
                </StyledActionsWrapper>
                <Divider topSpacing={0} bottomSpacing={0} />
            </form>
        </UnsavedBlocker>
    )
}

export default CustomerForm
