import DesktopTHead from '@components/commons/table/DesktopTHead'
import {TableProps} from '@components/commons/table/Table.tsx'
import {StyledDesktopTable} from '@components/commons/table/DesktopTable/style.ts'
import DesktopTBody from '@components/commons/table/DesktopTBody'
import DesktopTFoot from '../DesktopTFoot'

const DesktopTable = <TData extends unknown[], TSortName extends string | undefined>({
    columns,
    sorter,
    data,
    onChangePage,
    isError,
    isChangingPage,
    isLoading,
    subRow,
    expandedRows,
    footer
}: TableProps<TData, TSortName>) => {
    return (
        <StyledDesktopTable>
            <DesktopTHead columns={columns} sorter={sorter} />
            <DesktopTBody
                data={data}
                columns={columns}
                onChangePage={onChangePage}
                isError={isError}
                isChangingPage={isChangingPage}
                isLoading={isLoading}
                subRow={subRow}
                expandedRows={expandedRows}
            />

            {!isLoading && <DesktopTFoot columns={footer} />}
        </StyledDesktopTable>
    )
}

DesktopTable.displayName = 'DesktopTable'
export default DesktopTable
