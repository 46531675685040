import {DownloadCloud01Icon} from '@/components/ui/icon'
import styled, {css} from 'styled-components'

export const StyledConfirmationModal = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 6}px;

        & h3 {
            ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral['900']};
        }

        & p {
            ${typography.textSm};
            margin-top: ${spacing}px;
        }
    `
)

export const StyledContent = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
    `
)

export const StyledFooter = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 8}px;
        justify-content: flex-end;
    `
)

export const StyledIconWrapper = styled.div`
    ${({theme: {palette, spacing}}) => css`
        background-color: ${palette.yellow['50']};
        box-shadow:
            0 0 0 6px ${palette.yellow['25']},
            0 0 0 28px ${palette.neutral.white},
            0 0 0 29px ${palette.neutral['100']},
            0 0 0 48px ${palette.neutral.white},
            0 0 0 49px ${palette.neutral['100']},
            0 0 0 68px ${palette.neutral.white},
            0 0 0 69px ${palette.neutral['50']},
            0 0 0 88px ${palette.neutral.white},
            0 0 0 89px ${palette.neutral['25']};
        width: 40px;
        height: 40px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: ${spacing * 2}px;
    `}
`

export const StyledSaveIcon = styled(DownloadCloud01Icon)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 3}px;
        box-sizing: content-box;
    `}
`
