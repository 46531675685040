import React from 'react'
import Button from '@components/ui/button/Button.tsx'
import {LoginBox, LoginWrapper} from '@pages/login/style.ts'
import Logo from '@assets/images/logo_dark.svg'
import {GoogleIcon} from '@/components/ui/icon'
import {useTranslation} from 'react-i18next'
import {useGoogleLogin} from '@react-oauth/google'
import {useAuthStore} from '@/store/AuthStore'

export const Login: React.FC = () => {
    const {t} = useTranslation()
    const login = useAuthStore(store => store.login)

    const loginGoogle = useGoogleLogin({
        onSuccess: response =>
            login({
                accessToken: response.access_token,
                tokenType: response.token_type,
                expiresIn: response.expires_in,
                scope: response.scope,
                authuser: '0',
                hd: response.hd,
                prompt: response.prompt
            }),
        flow: 'implicit',
        scope: 'email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        prompt: 'consent'
    })

    return (
        <LoginWrapper>
            <LoginBox>
                <img src={Logo} alt={'logo'} height={48} />
                <Button variant={'secondary'} size={'md'} onClick={() => loginGoogle()}>
                    <GoogleIcon />
                    {t('login:google_login')}
                </Button>
            </LoginBox>
        </LoginWrapper>
    )
}

Login.displayName = 'Login'
