import styled, {css, DefaultTheme} from 'styled-components'
import {spacing} from '@/theme'
import Button from '@components/ui/button/Button.tsx'

export const StyledOverlay = styled.div(
    ({
        autoZIndex,
        overlayVariant,
        theme: {palette, zIndex}
    }: {
        theme: DefaultTheme
        overlayVariant?: 'filled' | 'gradient'
        autoZIndex?: boolean
    }) => css`
        position: fixed;
        inset: 0;
        background: ${overlayVariant === 'gradient'
            ? `linear-gradient(to top, ${palette.neutral['600']}, transparent)`
            : palette.neutral['600']};
        opacity: ${overlayVariant === 'gradient' ? '1' : '0.6'};
        z-index: ${autoZIndex ? 'auto' : zIndex.modalOverlay};
    `
)

interface StyledModalProps {
    width?: number
    autoZIndex?: boolean
    overflow?: 'visible' | 'auto'
    theme: DefaultTheme
}

export const StyledModal = styled.div(
    ({autoZIndex, overflow, width, theme: {palette, zIndex, shadows}}: StyledModalProps) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: ${autoZIndex ? 'auto' : zIndex.modal};
        border-radius: 12px;
        background: ${palette.neutral.white};
        width: ${width}px;
        max-height: calc(100% - ${spacing * 6}px);
        max-width: calc(100% - ${spacing * 6}px);
        overflow-y: ${overflow};
        box-shadow: ${shadows.lg};
    `
)

export const StyledCloseButton = styled(Button)`
    ${({
        $closeIconOffset,
        theme: {spacing},
        dir
    }: {
        dir: 'ltr' | 'rtl'
        theme: DefaultTheme
        $closeIconOffset?: 'sm' | 'md' | 'lg'
    }) => css`
        position: absolute;
        top: ${$closeIconOffset == 'sm' ? spacing * 3 : $closeIconOffset == 'lg' ? spacing * 6 : spacing * 4}px;
        ${dir === 'ltr'
            ? `right: ${
                  $closeIconOffset == 'sm' ? spacing * 3 : $closeIconOffset == 'lg' ? spacing * 6 : spacing * 4
              }px;`
            : `left: ${
                  $closeIconOffset == 'sm' ? spacing * 3 : $closeIconOffset == 'lg' ? spacing * 6 : spacing * 4
              }px;`}
    `}
`
