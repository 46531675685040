import {QUERY_KEYS} from '@/queryClient'
import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {HttpGetProjectDetailsParams, HttpGetProjectDetailsResponse, httpGetProjectDetails} from '../project.http'

export const useProjectDetails = (
    params: HttpGetProjectDetailsParams,
    options?: Omit<UseQueryOptions<HttpGetProjectDetailsResponse>, 'queryFn' | 'queryKey'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.PROJECT_DETAILS, params.projectId],
        queryFn: () => httpGetProjectDetails(params)
    })
}
