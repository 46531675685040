import i18next from 'i18next'
import {CachedProjectDetails, SyncStatusEvent} from './types'

export const PROJECT_STATUSES = [
    {
        value: 'active',
        label: 'project:status:active'
    },
    {
        value: 'completed',
        label: 'project:status:completed'
    }
]

export const INVOICE_STATUSES = [
    {
        value: 'expected',
        label: i18next.t('invoice:status:expected')
    },
    {
        value: 'sent',
        label: i18next.t('invoice:status:sent')
    },
    {
        value: 'forecast',
        label: i18next.t('invoice:status:forecast')
    }
]
export const VAT_PAYABILITY = [
    {
        value: 'immediate',
        label: i18next.t('invoice:vat_payability:immediate')
    },
    {
        value: 'postponed',
        label: i18next.t('invoice:vat_payability:postponed')
    },
    {
        value: 'nonElectronic',
        label: i18next.t('invoice:vat_payability:nonElectronic')
    },
    {
        value: 'splitPayment',
        label: i18next.t('invoice:vat_payability:splitPayment')
    }
]

type ActiveFilters = {[key: string]: number[] | string[]}
export function getActiveFilters(filters: ActiveFilters): string[] {
    return Object.keys(filters).filter(key => Array.isArray(filters[key]) && filters[key].length > 0)
}

export const findCachedDataToUpdate = (cachedData: CachedProjectDetails, dataEvent: SyncStatusEvent) => {
    const pages = cachedData.pages

    if (pages.length === 0) {
        return null
    }

    let pageIndex = 0
    let projectIndex = null

    while (pageIndex < pages.length && projectIndex === null) {
        projectIndex =
            pages[pageIndex].data.findIndex(prj => prj.id === dataEvent.project_id) !== -1
                ? pages[pageIndex].data.findIndex(prj => prj.id === dataEvent.project_id)
                : null

        pageIndex++
    }

    return {
        pageIndex: projectIndex !== null ? pageIndex - 1 : null,
        projectIndex,
        syncStatus: dataEvent.sync_status
    }
}
