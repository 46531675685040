import {useEffect, useState} from 'react'
import {projectSocket, projectSocketEvents} from '../ProjectSocket'
import {SyncStatus, SyncStatusEvent} from '../../types'
import {QUERY_KEYS} from '@/queryClient'
import {useQueryClient} from '@tanstack/react-query'

export const useProjectSyncSocket = () => {
    const [syncStatusEvent, setSyncStatusEvent] = useState<SyncStatusEvent | null>(null)
    const queryClient = useQueryClient()

    useEffect(() => {
        projectSocket.connect()
        // projectSocket.on('connect', () => {
        //     console.log('Socket connection enstablished', projectSocket.connected)
        // })

        projectSocket.on(projectSocketEvents.SYNC_PROJECT, async data => {
            setSyncStatusEvent(data)

            if (data.sync_status === SyncStatus.enum.completed) {
                await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECT_DETAILS, data.project_id]})
                await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECT_PHASES, data.project_id]})
            }
        })

        return () => {
            projectSocket.off(projectSocketEvents.SYNC_PROJECT, () => {
                setSyncStatusEvent(null)
            })

            projectSocket.disconnect()
        }
    }, [])

    return syncStatusEvent
}
