import {z} from 'zod'

export const EMPLOYEE_FORM_MODEL = {
    fullName: {
        name: 'fullName',
        label: 'new_employee:details:full_name'
    },
    email: {
        name: 'email',
        label: 'new_employee:details:email'
    },
    team: {
        name: 'team',
        label: 'new_employee:details:team'
    },
    status: {
        name: 'status',
        label: 'new_employee:details:status'
    },
    role: {
        name: 'role',
        label: 'new_employee:details:role'
    },
    scope: {
        name: 'scope',
        label: 'new_employee:details:scope'
    },
    type: {
        name: 'type',
        label: 'new_employee:details:type'
    },
    weeklyHours: {
        name: 'weeklyHours',
        label: 'new_employee:details:weekly_hours'
    },
    access: {
        name: 'access',
        label: 'new_employee:details:access'
    },
    floatId: {
        name: 'floatId',
        label: 'new_employee:details:float_id'
    },
    startDate: {
        name: 'startDate',
        label: 'new_employee:details:start_date'
    },
    endDate: {
        name: 'endDate',
        label: 'new_employee:details:end_date'
    }
} as const

export const EmployeeFormSchema = z.object({
    fullName: z.string().min(1, {message: 'errors:required'}),
    email: z.string().email({message: 'errors:email_valid'}).min(1, {message: 'errors:email'}),
    team: z.object({
        label: z.string(),
        value: z.string()
    }),
    status: z.object({
        label: z.string(),
        value: z.string()
    }),
    role: z.object({
        label: z.string(),
        value: z.string()
    }),
    scope: z.object({
        label: z.string(),
        value: z.string()
    }),
    type: z.object({
        label: z.string(),
        value: z.string()
    }),
    weeklyHours: z.coerce.number().int().nonnegative().min(1, {message: 'errors:required'}),
    access: z.object({
        label: z.string(),
        value: z.string()
    }),
    floatId: z.coerce.number().optional(),
    startDate: z.date(),
    endDate: z.date().nullish()
})

export type EmployeeValidationSchema = z.infer<typeof EmployeeFormSchema>
