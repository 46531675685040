import {Dispatch, FC, SetStateAction} from 'react'
import {StyledFormGridRow, StyledSalForm, StyledSalModalHeader} from './style'
import {zodResolver} from '@hookform/resolvers/zod'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import InputText from '@/components/commons/input-text/InputText'
import {useTranslation} from 'react-i18next'
import InputSelect, {SelectValue} from '@/components/commons/select/Select'
import dayjs from 'dayjs'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Sal, SalStatus} from '../../types'
import {useOutletContext} from 'react-router-dom'
import {ProjectDetails} from '@/features/project/types'
import Button from '@/components/ui/button/Button'
import InputNumber from '@/components/commons/input-number/InputNumber'
import {CurrencyEuroIcon} from '@/components/ui/icon'
import {errorHandler} from '@/utilities/genericErrorHandler'
import toast from 'react-hot-toast'
import {useUpdateProjectSal} from '../../services/queries/useUpdateProjectSal'
import {useCreateProjectSal} from '../../services/queries/useCreateProjectSal'
import {
    ProjectSalFormSchema,
    ProjectSalFormValidationSchema,
    SAL_FORM_MODEL,
    adaptSalToCreate,
    adaptSalToUpdate
} from './utils'
import {SAL_STATUSES} from '@/features/sal/utils'
import MonthPicker from '@/components/commons/month-picker/MonthPicker'
import {SalsResponse} from '../../services/projectSal.http'

interface ProjectSalFormProps {
    selectedSal?: Sal
    setSalToUpdate: Dispatch<SetStateAction<Sal | null>>
    defaultValues?: ProjectSalFormSchema
    onClose: () => void
    setIsAddSalModalOpen: Dispatch<SetStateAction<boolean>>
    sals: SalsResponse[]
}

export const ProjectSalForm: FC<ProjectSalFormProps> = ({
    selectedSal,
    setSalToUpdate,
    defaultValues,
    setIsAddSalModalOpen,
    sals
}) => {
    const {t} = useTranslation()
    const {project} = useOutletContext<{project: ProjectDetails}>()

    const defaultStatus = SAL_STATUSES.find(item => item.value === SalStatus.Enum.scheduled)

    const {
        control,
        register,
        handleSubmit,
        formState: {errors, touchedFields, isValid, isDirty}
    } = useForm<ProjectSalFormSchema>({
        mode: 'onBlur',
        resolver: zodResolver(ProjectSalFormValidationSchema),
        defaultValues: selectedSal
            ? {
                  ...defaultValues
              }
            : {status: {...defaultStatus, label: t(defaultStatus?.label ?? '')}}
    })

    const {mutate: updateSalMutation} = useUpdateProjectSal({
        onSuccess: () => {
            toast.success(t(`commons:update_completed`, {entity: t(`sals:singular`)}))
        },
        onError: error => {
            errorHandler(error), setSalToUpdate(null)
        }
    })

    const {mutate: createSalMutation} = useCreateProjectSal({
        onSuccess: () => {
            toast.success(t(`commons:creation_completed`, {entity: t(`sals:singular`)}))
        },
        onError: error => {
            errorHandler(error), setIsAddSalModalOpen(false)
        }
    })

    const onSubmit: SubmitHandler<ProjectSalFormSchema> = data => {
        selectedSal
            ? updateSalMutation({id: selectedSal.id ?? 0, data: adaptSalToUpdate(data)})
            : createSalMutation(
                  adaptSalToCreate({
                      ...data,
                      projectId: project.id
                  })
              )
        setSalToUpdate(null)
        setIsAddSalModalOpen(false)
    }

    return (
        <>
            <StyledSalModalHeader align="center" justify="space-between">
                <h3>{selectedSal ? t('project:sals:edit_sal_title') : t('project:sals:add_sal_title')}</h3>
            </StyledSalModalHeader>

            <StyledSalForm onSubmit={handleSubmit(onSubmit)}>
                <Flexbox direction="column" gap={5}>
                    <StyledFormGridRow cols={2} gap={4}>
                        <InputText
                            label={`${t('project:sals:form:name')} *`}
                            placeholder={`${t(SAL_FORM_MODEL.name.label)}`}
                            type={'text'}
                            touched={touchedFields.name}
                            errorMessage={t(errors.name?.message || '')}
                            {...register(t(SAL_FORM_MODEL.name.name))}
                        />

                        <Controller
                            render={({field: {onChange, value}}) => (
                                <InputNumber
                                    decimalSeparator={'.'}
                                    value={value}
                                    thousandSeparator={','}
                                    decimalScale={2}
                                    onChange={onChange}
                                    allowNegative={false}
                                    typeIcon={<CurrencyEuroIcon size={16} />}
                                    label={`${t(SAL_FORM_MODEL.amount.label)} *`}
                                    touched={touchedFields.amount}
                                    errorMessage={t(errors.amount?.message || '')}
                                    placeholder={`${t(SAL_FORM_MODEL.amount.label)}`}
                                />
                            )}
                            control={control}
                            name={SAL_FORM_MODEL.amount.name}
                        />
                    </StyledFormGridRow>
                </Flexbox>

                <Flexbox direction="column" gap={5}>
                    <StyledFormGridRow cols={2} gap={4}>
                        <Controller
                            control={control}
                            name={SAL_FORM_MODEL.deliveryDate.name}
                            render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                                <MonthPicker
                                    toggle
                                    formatDateFn={date => dayjs(date).format('MMMM YYYY')}
                                    numMonths={1}
                                    mode={'single'}
                                    currentDay={new Date()}
                                    selectedDates={value ? [value] : []}
                                    onDatesChange={dates => onChange(dates[0])}
                                    onBlur={onBlur}
                                    triggerProps={{
                                        label: `${t(SAL_FORM_MODEL.deliveryDate.label)} *`,
                                        placeholder: `${t(SAL_FORM_MODEL.deliveryDate.label)}`,
                                        errorMessage: t(error?.message || '')
                                    }}
                                    disabledDates={sals
                                        .filter(item => item.id !== selectedSal?.id)
                                        .map(item => new Date(item.deliveryDate))}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name={SAL_FORM_MODEL.status.name}
                            render={({field: {onChange, value}}) => (
                                <InputSelect
                                    value={value}
                                    options={SAL_STATUSES.map(item => ({...item, label: t(item.label)}))}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={SAL_FORM_MODEL.status.name}
                                    label={`${t(SAL_FORM_MODEL.status.label)} *`}
                                    placeholder={t(SAL_FORM_MODEL.status.label)}
                                    isClearable={true}
                                    isSearchable={true}
                                    errorMessage={t(errors.status?.message || '')}
                                />
                            )}
                        />
                    </StyledFormGridRow>
                </Flexbox>

                <Flexbox gap={2} justify={'end'}>
                    <Button
                        width="wide"
                        onClick={() => {
                            selectedSal ? setSalToUpdate(null) : setIsAddSalModalOpen(false)
                        }}
                        variant={'tertiary'}
                    >
                        <p>{t('new_employee:cancel')}</p>
                    </Button>
                    <Button width="wide" type="submit" variant="primary" disabled={!isValid || !isDirty}>
                        {selectedSal ? t('project:sals:form:update_btn') : t('commons:create')}
                    </Button>
                </Flexbox>
            </StyledSalForm>
        </>
    )
}
