import {SearchLgIcon} from '@/components/ui/icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useTranslation} from 'react-i18next'
import {PageLayout} from '@/layouts/page-layout/PageLayout'
import {useInvoicesTable} from '@/features/invoice/services/queries/useInvoicesTable'
import InputText from '@/components/commons/input-text/InputText'
import {InvoicesTable} from '@/features/invoice/components/invoices-table/InvoicesTable'
import {Switch} from '@/components/ui/switch/Switch'
import React, {useEffect, useRef, useState} from 'react'
import EditDuplicateInvoiceModal from '@/features/project-revenues/components/invoice-modal/EditDuplicateInvoiceModal.tsx'
import {ViewInvoiceModal} from '@/features/invoice/components/view-invoice-modal/ViewInvoiceModal.tsx'
import {Invoice} from '@/features/invoice/types.ts'
import {UploadInvoiceModal} from '@/features/invoice/components/upload-invoice-modal/UploadInvoiceModal.tsx'
import {useUploadInvoice} from '@/features/invoice/services/queries/useUploadInvoice.ts'
import toast from 'react-hot-toast'
import {errorHandler} from '@/utilities/genericErrorHandler'

export const Invoices: React.FC = () => {
    const {t} = useTranslation()
    const [invoiceIdToEdit, setInvoiceIdToEdit] = useState<number | null>(null)
    const [invoiceIdToView, setInvoiceIdToView] = useState<number | null>(null)
    const [invoiceToUpdate, setInvoiceToUpdate] = useState<Invoice | null>(null)
    const {
        onSearch,
        onResetSearch,
        searchParams,
        setSearchParams,
        searchValue,
        remappedData,
        isError,
        isLoading,
        sorter,
        toggleFicSent,
        isFetchingNextPage,
        fetchNextPage
    } = useInvoicesTable()

    const {mutate: uploadInvoice, isPending: isloadingUpdate} = useUploadInvoice({
        onSuccess: () => {
            toast.success(t('invoice:upload_modal:upload_success'))
            setInvoiceToUpdate(null)
        },
        onError: error => {
            errorHandler(error)
            setInvoiceToUpdate(null)
        }
    })
    const searchRef = useRef<HTMLInputElement | null>(null)
    const onCloseInvoiceIdToEdit = () => {
        setInvoiceIdToEdit(null)
    }
    const onCloseInvoiceIdToView = () => {
        setInvoiceIdToView(null)
    }
    const onCloseInvoiceToUpdate = () => {
        setInvoiceToUpdate(null)
    }
    const onUploadInvoice = () => {
        if (invoiceToUpdate) {
            uploadInvoice({invoiceId: invoiceToUpdate.id, data: invoiceToUpdate})
        }
    }

    useEffect(() => {
        setSearchParams({orderBy: 'date', orderDirection: 'asc'})
    }, [])

    return (
        <PageLayout
            title={t('invoice:title')}
            sideHeading={
                <Flexbox gap={4} align="center">
                    <InputText
                        onChange={e => onSearch(e.currentTarget.value)}
                        defaultValue={searchValue}
                        ref={searchRef}
                        type={'text'}
                        placeholder={t('commons:search_placeholder')}
                        typeIcon={<SearchLgIcon size={18} />}
                        inputSize={'sm'}
                    />

                    <Switch
                        label={t('invoice:fic_sent')}
                        defaultChecked={searchParams.showSent === 'true'}
                        onChange={value => {
                            toggleFicSent(value)
                        }}
                    />
                </Flexbox>
            }
        >
            <InvoicesTable
                data={remappedData}
                isChangingPage={isFetchingNextPage}
                isError={isError}
                isLoading={isLoading}
                onChangePage={fetchNextPage}
                searchValue={searchValue}
                onResetSearch={() => onResetSearch(searchRef)}
                setInvoiceIdToEdit={setInvoiceIdToEdit}
                setInvoiceIdToView={setInvoiceIdToView}
                sorter={sorter}
                setInvoiceToUpdate={setInvoiceToUpdate}
            />

            {invoiceIdToEdit && (
                <EditDuplicateInvoiceModal invoiceId={invoiceIdToEdit} onClose={onCloseInvoiceIdToEdit} />
            )}
            {invoiceIdToView && <ViewInvoiceModal onClose={onCloseInvoiceIdToView} invoiceId={invoiceIdToView} />}
            {invoiceToUpdate && (
                <UploadInvoiceModal
                    onClose={onCloseInvoiceToUpdate}
                    isLoading={isloadingUpdate}
                    onSubmit={onUploadInvoice}
                />
            )}
        </PageLayout>
    )
}

Invoices.displayName = 'Invoices'
