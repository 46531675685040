import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {ErrorResponseData} from '@/types/commons'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {httpCreateCost} from '@/features/project-costs/services/projectCost.http'

type UseCreateProjectCostProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useCreateProjectCost = ({onSuccess, onError}: UseCreateProjectCostProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_PROJECT_COST],
        mutationFn: httpCreateCost,
        onSuccess: data => {
            onSuccess?.()
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECT_COSTS, data.projectId]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.COSTS]})
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            onError?.(error)
        }
    })
}
