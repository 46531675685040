import Table, {TableSorter} from '@/components/commons/table/Table'
import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {Link, generatePath} from 'react-router-dom'
import {Invoice, InvoicesSearchParams} from '@/features/invoice/types'
import {TableDataCell} from '@/components/commons/table/TableDataCell/TableDataCell'
import {SearchMdIcon} from '@/components/ui/icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {routes} from '@/utilities/constants'
import {formatCurrency, formatLocaleDate} from '@/utilities/helpers'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {InvoiceStatusTable} from './InvoiceStatusTable'
import {InvoiceFICButton} from './InvoiceFICButton'
import {InvoicesActionTableButton} from './InvoiceActionTableButton'
import 'dayjs/locale/en'

interface InvoicesTableProps {
    data: Invoice[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    searchValue?: string
    onChangePage?: () => void
    onResetSearch?: () => void
    sorter?: TableSorter<InvoicesSearchParams['orderBy']> | undefined
    setInvoiceIdToEdit: Dispatch<SetStateAction<number | null>>
    setInvoiceIdToView: Dispatch<SetStateAction<number | null>>
    setInvoiceToUpdate: Dispatch<SetStateAction<Invoice | null>>
}

export const InvoicesTable: FC<InvoicesTableProps> = ({
    data,
    isLoading,
    sorter,
    setInvoiceIdToEdit,
    searchValue,
    onChangePage,
    onResetSearch,
    setInvoiceIdToView,
    setInvoiceToUpdate
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])

    return (
        <Table
            onChangePage={onChangePage}
            isMobile={isMobile}
            data={data}
            isLoading={isLoading}
            sorter={sorter}
            searchValue={searchValue}
            columns={[
                {
                    label: t('invoice:table:labels:id'),
                    width: '4%',
                    overflow: 'visible',
                    cellRender: invoice => <TableDataCell size="sm">{invoice.id}</TableDataCell>
                },
                {
                    label: t('invoice:table:labels:number'),
                    width: '8%',
                    cellRender: invoice => (
                        <TableDataCell size="sm">
                            <p>{invoice.number && invoice.year ? `#${invoice.year}/${invoice.number}` : '-'}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('invoice:table:labels:customer'),
                    width: '12%',
                    cellRender: invoice => (
                        <TableDataCell isBold={true} size="sm">
                            <Link to={generatePath(routes.EDIT_CUSTOMERS.path, {id: invoice.customerId})}>
                                {invoice.customer.businessName}
                            </Link>
                        </TableDataCell>
                    )
                },
                {
                    label: t('invoice:table:labels:project'),
                    width: '15%',
                    cellRender: invoice => (
                        <TableDataCell isBold={true} size="sm">
                            <Link to={`${generatePath(routes.PROJECT_DETAIL.path, {id: invoice.projectId})}/info`}>
                                {`#${invoice.project.id} ${invoice.project.name}`}
                            </Link>
                        </TableDataCell>
                    )
                },
                {
                    label: t('invoice:table:labels:description'),
                    width: '20%',
                    cellRender: invoice => (
                        <TableDataCell title={invoice.details[0]?.description}>
                            <p>{invoice.details[0]?.description ?? '-'}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('invoice:table:labels:amount'),
                    width: '9%',
                    cellRender: invoice => (
                        <TableDataCell>
                            {formatCurrency(
                                invoice.details.reduce((acc, val) => acc + val.amount, 0),
                                'EUR'
                            )}
                        </TableDataCell>
                    )
                },
                {
                    label: t('invoice:table:labels:date'),
                    width: '10%',
                    sortName: 'date',
                    cellRender: invoice => formatLocaleDate(invoice.date, 'll')
                },
                {
                    label: t('invoice:table:labels:status'),
                    overflow: 'visible',
                    width: '12%',
                    cellRender: invoice => (
                        <TableDataCell>
                            <InvoiceStatusTable status={invoice.status} invoiceFic={invoice.ficExternalId} />
                        </TableDataCell>
                    )
                },
                {
                    label: ' ',
                    width: '10%',
                    alignment: 'right',
                    cellRender: invoice => (
                        <Flexbox justify="flex-end" align="center">
                            <InvoiceFICButton invoice={invoice} setInvoiceToUpdate={setInvoiceToUpdate} />

                            <InvoicesActionTableButton
                                invoice={invoice}
                                showViewInvoiceModal={() => setInvoiceIdToView(invoice.id)}
                                showEditInvoiceModal={() => setInvoiceIdToEdit(invoice.id)}
                            />
                        </Flexbox>
                    )
                }
            ]}
            emptyStateComponent={
                <EmptyResults
                    title={t('invoice:table:empty:title')}
                    subtitle={t('invoice:table:empty:subtitle')}
                    cta={{
                        path: '/projects',
                        text: t('project:title')
                    }}
                />
            }
            emptySearchStateComponent={
                <EmptySearch
                    title={t('commons:no_results')}
                    onClearSearch={() => onResetSearch && onResetSearch()}
                    icon={<SearchMdIcon />}
                />
            }
        />
    )
}
