import {z} from 'zod'

// sal filters schema
export const SalsFiltersParams = z.object({
    teamLeadIds: z.number().array().optional()
})
export type SalsFiltersParams = z.infer<typeof SalsFiltersParams>

// sal search params schema
export const SalsSearchParams = z.object({
    orderBy: z.enum(['name', 'projectName', 'teamLead', 'deliveryDate', 'status']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional(),
    teamLeadIds: z.coerce.number().array().optional(),
    status: z.string().array().optional()
})
export type SalsSearchParams = z.infer<typeof SalsSearchParams>
