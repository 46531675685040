import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {debounce} from '@/utilities/helpers'
import {RevenuesSearchParams, RevenuesURLParams} from '../../types'
import {useGetProjectRevenues} from './useGetProjectRevenues'

export const useProjectRevenuesTable = (params: RevenuesURLParams) => {
    const multiParams = [] as const satisfies ReadonlyArray<keyof RevenuesSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, RevenuesSearchParams)
    const revenuesQuery = useGetProjectRevenues({
        ...params,
        ...searchParams
    })

    const search = debounce((value: string) => {
        setSearchParams({search: value})
    }, 750)

    const sort = (sorter: {
        orderBy: RevenuesSearchParams['orderBy']
        orderDirection: RevenuesSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        revenues: revenuesQuery,
        isLoading: revenuesQuery.isLoading,
        isSuccess: revenuesQuery.isSuccess,
        isError: revenuesQuery.isError,
        search,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
