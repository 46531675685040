import {z} from 'zod'

export const SalsURLParams = z.object({
    projectId: z.coerce.number().int()
})
export type SalsURLParams = z.infer<typeof SalsURLParams>

export const Sal = z.object({
    id: z.coerce.number().nullable(),
    projectId: z.coerce.number(),
    name: z.string(),
    amount: z.number(),
    deliveryDate: z.string(),
    status: z.string()
})
export type Sal = z.infer<typeof Sal>

export const SalsSearchParams = z.object({
    orderBy: z.enum(['name', 'amount', 'deliveryDate']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional()
})
export type SalsSearchParams = z.infer<typeof SalsSearchParams>

export const SalStatus = z.enum(['scheduled', 'delivered'])
export type SalStatus = z.infer<typeof SalStatus>
