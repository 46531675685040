import Button from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledSalsFilters = styled.div(
    () => css`
        position: relative;
    `
)

export const StyledSalsFiltersTrigger = styled(Button)(
    ({theme: {spacing, mediaQueries}}) => css`
        z-index: 10;
        b {
            margin-left: ${spacing}px;
        }
        ${mediaQueries.m} {
            z-index: 0;
        }
    `
)

export const StyledSalsFiltersMobileHeader = styled(Flexbox)(
    ({theme: {mediaQueries, typography, palette, spacing}}) => css`
        display: none;
        ${mediaQueries.m} {
            display: flex;
            h3 {
                ${typography.textXl};
                font-weight: 700;
                color: ${palette.neutral['900']};
                flex: 1;
                margin-left: ${spacing * 2}px;
            }
        }
    `
)

export const StyledSalsFiltersForm = styled.form(
    ({theme: {spacing, palette, mediaQueries}}) => css`
        position: absolute;
        display: grid;
        width: 450px;
        padding: ${spacing * 4}px;
        grid-row-gap: ${spacing * 4}px;
        background-color: ${palette.neutral.white};
        right: 0;
        transform: translateY(${spacing * 2}px);
        border-radius: 8px;
        z-index: 10;
        ${mediaQueries.m} {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: calc(100% - ${spacing * 6}px);
            max-height: calc(100% - ${spacing * 6}px);
        }
    `
)

export const StyledSalFiltersOverlay = styled.div(
    ({theme: {palette}}) => css`
        position: fixed;
        inset: 0;
        background: ${palette.neutral['600']};
        opacity: 0.6;
        z-index: 1;
    `
)
