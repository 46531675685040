import {Modal} from '@/components/ui/modal/Modal'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import Button from '@/components/ui/button/Button'
import {FC} from 'react'
import {StyledConfirmationModal, StyledContent, StyledFooter, StyledIconWrapper, StyledSaveIcon} from './style'
import Spinner from '@components/ui/spinner/Spinner.tsx'

interface UploadInvoiceModalProps {
    onClose: () => void
    onSubmit: () => void
    isLoading: boolean
}

export const UploadInvoiceModal: FC<UploadInvoiceModalProps> = ({onClose, onSubmit, isLoading}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <Modal width={544} closeIconOffset="sm" onClose={onClose}>
            <StyledConfirmationModal>
                <StyledContent>
                    <StyledIconWrapper>
                        <StyledSaveIcon size={18} fill={theme.palette.warning['600']} />
                    </StyledIconWrapper>
                    <div>
                        <h3>{t('invoice:upload_modal:title')}</h3>
                        <p>{t('invoice:upload_modal:message')}</p>
                    </div>
                </StyledContent>
                <StyledFooter>
                    <Button variant="secondary" onClick={onClose} disabled={isLoading}>
                        {t('invoice:upload_modal:cancel_btn')}
                    </Button>
                    <Button variant="primaryWarning" onClick={onSubmit} disabled={isLoading}>
                        {t('invoice:upload_modal:upload_btn')}
                        {isLoading && <Spinner size={16} />}
                    </Button>
                </StyledFooter>
            </StyledConfirmationModal>
        </Modal>
    )
}
