import styled, {css, DefaultTheme} from 'styled-components'
import {
    DESKTOP_TABLE_COLUMNS_PADDING,
    DESKTOP_TABLE_SCROLLBAR_WIDTH
} from '@components/commons/table/DesktopTable/style.ts'
import {Percentage} from '@/types/commons.ts'

export const StyledTFoot = styled.div(
    ({theme: {palette}}: {theme: DefaultTheme}) => css`
        display: flex;
        min-height: 72px;
        align-items: center;
        border-top: 1px solid ${palette.neutral[200]};
        color: ${palette.neutral[600]};
        padding-right: ${DESKTOP_TABLE_SCROLLBAR_WIDTH};
    `
)

interface StyledTDProps {
    width: Percentage
    isBold: boolean
    alignment?: 'left' | 'center' | 'right'
}
export const StyledTDFoot = styled.div(
    ({width, alignment, isBold}: StyledTDProps) => css`
        width: ${width};
        flex: none;
        display: flex;
        justify-content: ${alignment == 'right' ? 'flex-end' : alignment == 'center' ? 'center' : 'flex-start'};
        font-weight: ${isBold ? '700' : '400'};
        align-items: center;
        padding: 0 ${DESKTOP_TABLE_COLUMNS_PADDING};
    `
)
