import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

type StyledInputWrapperProps = {
    touched: boolean
    hasError: boolean
    visibilityToggle?: boolean
}

export const StyledInputWrapper = styled(Flexbox)<StyledInputWrapperProps>`
    ${({visibilityToggle, hasError, theme: {palette, shadows}}) => css`
        position: relative;
        background: ${palette.neutral.white};
        border: 1px solid ${palette.neutral['300']};
        border-radius: ${visibilityToggle ? '8px 0 0 8px' : '8px'};
        width: 100%;
        transition: ease-in-out 200ms;
        cursor: pointer;

        &:has(input:disabled) {
            background: ${palette.neutral['100']};
            color: ${palette.neutral['500']};
            cursor: not-allowed;
        }

        &:not(&:has(input:disabled)):hover {
            border: 1px solid ${hasError ? palette.danger['300'] : palette.primary['300']};
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${
                hasError ? palette.danger['100'] : palette.primary['100']
            }`};
        }

        ${hasError &&
        css`
            border: 1px solid ${palette.danger['300']};
        `};
    `}
`
