import {z} from 'zod'

export const PhasesURLParams = z.object({
    projectId: z.coerce.number().int()
})
export type PhasesURLParams = z.infer<typeof PhasesURLParams>

export const PhaseServiceLine = z.union([
    z.literal('digital_experience'),
    z.literal('web_app'),
    z.literal('mobile_app'),
    z.literal('others')
])
export type PhaseServiceLine = z.infer<typeof PhaseServiceLine>

export const Phase = z.object({
    id: z.coerce.number().nullable(),
    projectId: z.coerce.number(),
    name: z.string(),
    serviceLine: PhaseServiceLine.nullable(),
    description: z.string().nullable(),
    hours: z.number().nullable(),
    loggedAmount: z.number(),
    futureSchedulesAmount: z.number()
})
export type Phase = z.infer<typeof Phase>

export const PhasesSearchParams = z.object({
    orderBy: z.enum(['name']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional()
})
export type PhasesSearchParams = z.infer<typeof PhasesSearchParams>
