import {
    StyledFooter,
    StyledInformationWrapper,
    StyledResumeWrapper,
    StyledRowActionsWrapper,
    StyledRowAmountsWrapper,
    StyledRowDescriptionsRoWrapper,
    StyledRowDescriptionsWrapper,
    StyledRowsActionsWrapper,
    StyledRowWrapper,
    StyledSeparator
} from '@/features/project-revenues/components/invoice-modal/style.ts'
import {CurrencyEuroIcon, PlusCircleIcon, Trash01Icon} from '@components/ui/icon'
import {Controller, SubmitHandler, useFieldArray, useForm} from 'react-hook-form'
import InputSelect, {SelectValue} from '@components/commons/select/Select.tsx'
import {
    INVOICE_FORM_MODEL,
    InvoiceFormSchema,
    InvoiceValidationSchema
} from '@/features/project-revenues/components/invoice-modal/InvoiceFormModel.tsx'
import {INVOICE_STATUSES, VAT_PAYABILITY} from '@/features/project/utils.ts'
import DatePicker from '@components/commons/date-picker/DatePicker.tsx'
import dayjs from 'dayjs'
import Button from '@components/ui/button/Button.tsx'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {zodResolver} from '@hookform/resolvers/zod'
import {adaptInvoiceCreateData} from '@/features/project-revenues/utils.ts'
import {useCreateInvoiceMutation} from '@/features/invoice/services/queries/useCreateInvoiceMutation.ts'
import {useUpdateInvoiceMutation} from '@/features/invoice/services/queries/useUpdateInvoiceMutation.ts'
import {ValueIndicator} from '@components/commons/value-indicator/ValueIndicator.tsx'
import InputText from '@components/commons/input-text/InputText.tsx'
import toast from 'react-hot-toast'
import {formatCurrency, removeCurrencyFormat} from '@utilities/helpers.ts'
import InputNumber from '@components/commons/input-number/InputNumber.tsx'
import {errorHandler} from '@/utilities/genericErrorHandler'

type FormOptions = {
    vats: SelectValue[]
    banks: SelectValue[]
}
export type ProjectInvoiceData = {
    projectId: number
    customerId: number
}
interface InvoiceModalProps {
    invoiceDefaultValues?: InvoiceValidationSchema
    isDuplicate?: boolean
    onClose: () => void
    invoiceId?: number
    formOptions: FormOptions
    projectInvoiceData: ProjectInvoiceData
}
export const InvoiceForm: React.FC<InvoiceModalProps> = ({
    invoiceDefaultValues,
    isDuplicate,
    onClose,
    invoiceId,
    formOptions,
    projectInvoiceData
}: InvoiceModalProps) => {
    const {t} = useTranslation()
    const {mutate: createInvoice} = useCreateInvoiceMutation({
        onSuccess: () => {
            toast.success(t('commons:creation_completed', {entity: t('invoice:singular')}))
            onClose()
        },
        onError: error => {
            errorHandler(error)
            onClose()
        }
    })
    const {mutate: updateInvoice} = useUpdateInvoiceMutation({
        onSuccess: () => {
            toast.success(t('commons:update_completed', {entity: t('invoice:singular')}))
            onClose()
        },
        onError: error => {
            errorHandler(error)
            onClose()
        }
    })
    const {
        control,
        register,
        handleSubmit,
        watch,
        formState: {errors, touchedFields, isValid, isSubmitting}
    } = useForm<InvoiceValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(InvoiceFormSchema),
        defaultValues: {
            ...(invoiceDefaultValues || {
                rows: [
                    {
                        amount: undefined,
                        vat: undefined,
                        productName: undefined,
                        description: undefined
                    }
                ]
            })
        }
    })

    const {
        fields: rows,
        append: appendRow,
        remove: removeRow
    } = useFieldArray({
        control,
        name: 'rows'
    })

    const onSubmit: SubmitHandler<InvoiceValidationSchema> = data => {
        if (isDuplicate) {
            return createInvoice({data: adaptInvoiceCreateData(data, projectInvoiceData)})
        }
        if (invoiceDefaultValues && invoiceId) {
            return updateInvoice({data: adaptInvoiceCreateData(data, projectInvoiceData), invoiceId})
        }
        return createInvoice({data: adaptInvoiceCreateData(data, projectInvoiceData)})
    }

    const onClickDeleteRow = (index: number) => {
        removeRow(index)
    }

    const onClickAppendRow = () => {
        appendRow({
            amount: '0.00',
            vat: formOptions.vats[0],
            productName: '',
            description: ''
        })
    }
    const calcolateSubtotalRow = (index: number) => {
        const watchAmount = parseFloat(removeCurrencyFormat(watch(`rows.${index}.amount`)))
        const watchVat = watch(`rows.${index}.vat`)
        if (watchAmount && watchVat) {
            return watchAmount + (watchAmount * parseFloat(watchVat.label.replace('%', ''))) / 100
        }
        return 0
    }

    const calcolateTotal = () => {
        let total = 0
        rows.forEach((_, index) => {
            const watchAmount = parseFloat(removeCurrencyFormat(watch(`rows.${index}.amount`)))
            if (watchAmount) {
                total += watchAmount
                return total
            }
        })
        return total
    }

    const calcolateSubtotal = () => {
        let subtotal = 0
        rows.forEach((_, index) => {
            const watchAmount = parseFloat(removeCurrencyFormat(watch(`rows.${index}.amount`)))
            const watchVat = watch(`rows.${index}.vat`)
            if (watchAmount && watchVat) {
                return (subtotal += watchAmount + (watchAmount * parseFloat(watchVat.label.replace('%', ''))) / 100)
            }
            return ''
        })
        return subtotal
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledInformationWrapper>
                <Controller
                    render={({field: {onChange, value}}) => (
                        <InputSelect
                            value={value}
                            onChange={newValue => {
                                onChange(newValue as SelectValue)
                            }}
                            size={'medium'}
                            name={INVOICE_FORM_MODEL.status.name}
                            label={`${t(INVOICE_FORM_MODEL.status.label)}*`}
                            isClearable={true}
                            errorMessage={t(errors.status?.message || '')}
                            placeholder={t(INVOICE_FORM_MODEL.status.label)}
                            options={INVOICE_STATUSES.map(item => ({value: item.value, label: t(item.label)}))}
                        />
                    )}
                    control={control}
                    name={INVOICE_FORM_MODEL.status.name}
                />
                <Controller
                    control={control}
                    name={INVOICE_FORM_MODEL.date.name}
                    render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                        <DatePicker
                            toggle
                            formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                            numMonths={1}
                            mode={'single'}
                            selectedDates={value ? [value] : []}
                            currentDay={new Date()}
                            onDatesChange={dates => onChange(dates[0])}
                            onBlur={onBlur}
                            triggerProps={{
                                label: `${t(INVOICE_FORM_MODEL.date.label)}*`,
                                errorMessage: t(error?.message || ''),
                                placeholder: t(INVOICE_FORM_MODEL.date.label)
                            }}
                        />
                    )}
                />

                <Controller
                    render={({field: {onChange, value}}) => (
                        <InputSelect
                            value={value}
                            onChange={newValue => {
                                onChange(newValue as SelectValue)
                            }}
                            size={'medium'}
                            name={INVOICE_FORM_MODEL.vatPayability.name}
                            label={`${t(INVOICE_FORM_MODEL.vatPayability.label)}*`}
                            isClearable={true}
                            errorMessage={t(errors.vatPayability?.message || '')}
                            placeholder={t(INVOICE_FORM_MODEL.vatPayability.label)}
                            options={VAT_PAYABILITY.map(item => ({value: item.value, label: t(item.label)}))}
                        />
                    )}
                    control={control}
                    name={INVOICE_FORM_MODEL.vatPayability.name}
                />
            </StyledInformationWrapper>
            <StyledRowDescriptionsWrapper>
                <InputText
                    label={`${t(INVOICE_FORM_MODEL.internalSubject.label)}*`}
                    type={'text'}
                    touched={touchedFields.internalSubject}
                    errorMessage={t(errors.internalSubject?.message || '')}
                    placeholder={t(INVOICE_FORM_MODEL.internalSubject.label)}
                    {...register(t(INVOICE_FORM_MODEL.internalSubject.name))}
                />
                <InputText
                    label={`${t(INVOICE_FORM_MODEL.ficExternalId.label)}`}
                    type={'number'}
                    touched={touchedFields.ficExternalId}
                    errorMessage={t(errors.ficExternalId?.message || '')}
                    placeholder={t(INVOICE_FORM_MODEL.ficExternalId.label)}
                    {...register(t(INVOICE_FORM_MODEL.ficExternalId.name))}
                />
            </StyledRowDescriptionsWrapper>

            {rows.map((field, index) => (
                <StyledRowWrapper key={field.id}>
                    <StyledRowAmountsWrapper>
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <InputSelect
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    value={value}
                                    size={'medium'}
                                    options={formOptions.vats}
                                    name={INVOICE_FORM_MODEL.rows.vat.name}
                                    label={`${t(INVOICE_FORM_MODEL.rows.vat.label)}*`}
                                    isSearchable={true}
                                    errorMessage={t(errors?.rows?.[index]?.vat?.message || '')}
                                    placeholder={t(INVOICE_FORM_MODEL.rows.vat.label)}
                                />
                            )}
                            control={control}
                            name={`rows.${index}.vat`}
                        />
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <InputNumber
                                    decimalSeparator={'.'}
                                    value={value}
                                    thousandSeparator={','}
                                    decimalScale={2}
                                    onChange={onChange}
                                    allowNegative={false}
                                    typeIcon={<CurrencyEuroIcon size={16} />}
                                    label={`${t(INVOICE_FORM_MODEL.rows.amount.label)}*`}
                                    touched={touchedFields?.rows?.[index]?.amount}
                                    errorMessage={t(errors.rows?.[index]?.amount?.message || '')}
                                    placeholder={t(INVOICE_FORM_MODEL.rows.amount.label)}
                                />
                            )}
                            control={control}
                            name={`rows.${index}.amount`}
                        />
                    </StyledRowAmountsWrapper>
                    <StyledRowDescriptionsRoWrapper>
                        <InputText
                            label={`${t(INVOICE_FORM_MODEL.rows.productName.label)}*`}
                            type={'text'}
                            touched={touchedFields?.rows?.[index]?.productName}
                            errorMessage={t(errors.rows?.[index]?.productName?.message || '')}
                            placeholder={t(INVOICE_FORM_MODEL.rows.productName.label)}
                            {...register(`rows.${index}.productName`)}
                        />
                        <InputText
                            label={`${t(INVOICE_FORM_MODEL.rows.description.label)}*`}
                            type={'text'}
                            touched={touchedFields?.rows?.[index]?.description}
                            errorMessage={t(errors.rows?.[index]?.description?.message || '')}
                            placeholder={t(INVOICE_FORM_MODEL.rows.description.label)}
                            {...register(`rows.${index}.description`)}
                        />
                        <ValueIndicator
                            value={formatCurrency(calcolateSubtotalRow(index), 'EUR')}
                            label={t(INVOICE_FORM_MODEL.rows.subtotal.label)}
                        />
                    </StyledRowDescriptionsRoWrapper>
                    {rows.length > 1 && (
                        <StyledRowActionsWrapper justify={'flex-end'}>
                            <Button variant="ghost" onClick={() => onClickDeleteRow(index)}>
                                <Trash01Icon />
                                {t('commons:delete')}
                            </Button>
                        </StyledRowActionsWrapper>
                    )}
                </StyledRowWrapper>
            ))}
            <StyledRowsActionsWrapper>
                <Button variant="ghost" onClick={onClickAppendRow}>
                    <PlusCircleIcon />
                    {t('invoice:details:add_row')}
                </Button>
            </StyledRowsActionsWrapper>
            <StyledSeparator />
            <StyledResumeWrapper>
                <ValueIndicator
                    value={formatCurrency(calcolateTotal(), 'EUR')}
                    label={t(INVOICE_FORM_MODEL.total.label)}
                />
                <ValueIndicator
                    value={formatCurrency(calcolateSubtotal(), 'EUR')}
                    label={t(INVOICE_FORM_MODEL.totalVatIncluded.label)}
                />
                <Controller
                    control={control}
                    name={INVOICE_FORM_MODEL.paymentDate.name}
                    render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                        <DatePicker
                            toggle
                            formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                            numMonths={1}
                            mode="single"
                            currentDay={new Date()}
                            selectedDates={value ? [value] : []}
                            onDatesChange={dates => onChange(dates[0])}
                            onBlur={onBlur}
                            triggerProps={{
                                label: `${t(INVOICE_FORM_MODEL.paymentDate.label)}*`,
                                errorMessage: t(error?.message || ''),
                                placeholder: t(INVOICE_FORM_MODEL.paymentDate.label)
                            }}
                        />
                    )}
                />
            </StyledResumeWrapper>
            <StyledFooter>
                <Button variant="tertiary" onClick={onClose} width={'wide'}>
                    {t('commons:cancel')}
                </Button>
                {invoiceDefaultValues && !isDuplicate ? (
                    <Button variant="primary" type="submit" disabled={!isValid || isSubmitting} width={'wide'}>
                        {t('commons:save')}
                    </Button>
                ) : (
                    <Button variant="primary" type="submit" disabled={!isValid || isSubmitting} width={'wide'}>
                        {t('commons:create')}
                    </Button>
                )}
            </StyledFooter>
        </form>
    )
}
