import {z} from 'zod'

export const SALS_FILTERS_FORM_MODEL = {
    teamLead: {
        name: 'teamLead',
        label: 'sal:filters:labels:teamLead',
        placeholder: 'sal:filters:placeholders:teamLead'
    }
} as const

export const SalsFiltersValidationSchema = z.object({
    teamLead: z
        .array(
            z.object({
                label: z.string(),
                value: z.string()
            })
        )
        .optional()
})

export type SalsFiltersValidationSchema = z.infer<typeof SalsFiltersValidationSchema>
