import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {z} from 'zod'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {useTranslation} from 'react-i18next'
import {AlertTriangleIcon} from 'src/components/ui/icon'
import {useEffect} from 'react'

export const EntityDetailsURLParams = z.object({
    id: z.coerce.number().int()
})
export type EntityDetailsURLParams = z.infer<typeof EntityDetailsURLParams>

const EntityRoute = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const urlParams = useURLParsedParams(EntityDetailsURLParams)
    const navigate = useNavigate()

    useEffect(() => {
        if (location.pathname.includes('projects') && location.pathname.split('/').length <= 3) {
            navigate(`${location.pathname}/info`)
        }
    }, [location.pathname])

    return urlParams.success ? (
        <Outlet context={{urlParams: urlParams.data}} />
    ) : (
        <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
    )
}

export default EntityRoute
