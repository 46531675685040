import {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {useEmployeesQuery} from '@/features/employee/services/queries/useEmployees'
import {EmployeeRole} from '@/features/employee/types.ts'
import {ResponseType} from '@/types/commons'
import {SelectValue} from '@/components/commons/select/Select'
import {SalsFiltersParams, SalsSearchParams} from '../../types'
import {FilterLinesIcon} from '@/components/ui/icon'
import {StyledSalFiltersOverlay, StyledSalsFilters, StyledSalsFiltersTrigger} from './style'
import {getActiveFilters} from '../../utils'
import {SalsFiltersValidationSchema} from './utils'
import {SalsFiltersForm} from './SalsFiltersForm'

export const SalsFilters = () => {
    const {t} = useTranslation()
    const multiParams = ['teamLeadIds'] as const satisfies ReadonlyArray<keyof SalsSearchParams>

    const [visibility, setVisibility] = useState(false)

    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, SalsSearchParams)

    const teamLeadsQuery = useEmployeesQuery({
        limit: 100,
        roles: [EmployeeRole.Enum.team_lead],
        responseType: ResponseType.Enum.small,
        linkedToFloat: true
    })

    const teamLeadOptions = useMemo<SelectValue[]>(
        () =>
            teamLeadsQuery.remappedData.map(item => ({value: item.id.toString(), label: item.fullName}) as SelectValue),
        [teamLeadsQuery.remappedData]
    )

    // Get active filters
    const activeFilterKeys = getActiveFilters({
        teamLeadIds: searchParams?.teamLeadIds || []
    })

    // Get filters values
    const filtersUrlValues = useMemo<SalsFiltersValidationSchema>(
        () => ({
            teamLead: teamLeadOptions.filter(item => searchParams.teamLeadIds?.includes(parseInt(item.value)))
        }),
        [searchParams]
    )

    const onSubmitFilters = (filters: SalsFiltersParams) => {
        setSearchParams(filters)
        setVisibility(false)
    }

    return (
        <>
            <StyledSalsFilters>
                {visibility && <StyledSalFiltersOverlay onClick={() => setVisibility(false)} />}

                <StyledSalsFiltersTrigger variant={'secondary'} size={'md'} onClick={() => setVisibility(true)}>
                    <span>
                        {t('commons:filter_label')}
                        {activeFilterKeys.length ? <b>({activeFilterKeys.length})</b> : null}
                    </span>
                    <FilterLinesIcon />
                </StyledSalsFiltersTrigger>

                {visibility && (
                    <SalsFiltersForm
                        hideModal={() => setVisibility(false)}
                        onSubmitFilters={onSubmitFilters}
                        filtersUrlValues={filtersUrlValues}
                        filtersOptions={{
                            teamLead: teamLeadOptions
                        }}
                    />
                )}
            </StyledSalsFilters>
        </>
    )
}
