import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledSectionTitle = styled.span`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textMd}
        font-weight: bold;
        color: ${palette.neutral['900']};
        display: block;
        margin: ${spacing * 8}px 0;
    `}
`

export const StyledFirstRowWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 4}px;
    `}
`

export const StyledSecondRowWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 4}px;
    `}
`

export const StyledThirdRowWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr 130px 200px 130px 200px 200px;
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 4}px;
    `}
`

export const StyledFourthRowWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 4}px;
    `}
`

export const StyledSeparator = styled.div`
    ${({theme: {spacing, palette}}) => css`
        margin-top: ${spacing * 8}px;
        margin-bottom: ${spacing * 8}px;
        border-top: solid 1px ${palette.neutral['300']};
    `}
`

export const StyledActionsWrapper = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 8}px 0;
    `}
`
