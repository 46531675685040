import {StyledDivider} from '@components/ui/divider/style.ts'

interface ContainerProps {
    width?: number
    height?: number
    topSpacing?: number
    bottomSpacing?: number
    background?: string
}

const Divider = ({width, height = 1, topSpacing = 20, bottomSpacing = 20, background}: ContainerProps) => (
    <StyledDivider
        width={width}
        height={height}
        background={background}
        topSpacing={topSpacing}
        bottomSpacing={bottomSpacing}
    />
)

export default Divider

Divider.displayName = 'Divider'
