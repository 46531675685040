import * as RPopover from '@radix-ui/react-popover'
import styled, {css} from 'styled-components'

export const StyledPopoverTrigger = styled(RPopover.Trigger)`
    cursor: pointer;
`

export const StyledPopoverContent = styled(RPopover.Content)`
    ${({theme: {palette, shadows, zIndex}}) => css`
        background-color: ${palette.neutral.white};
        border-radius: 4px;
        box-shadow: ${shadows.md};
        z-index: ${zIndex.datePicker};
    `}
`
