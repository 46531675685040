import React from 'react'
import {useOutletContext} from 'react-router-dom'
import {ResponseType} from '@/types/commons'
import {z} from 'zod'
import {PageLayout} from '@/layouts/page-layout/PageLayout'
import CustomerForm from '@/features/customer/components/customer-form/CustomerForm'
import {useGetCustomerDetails} from '@/features/customer/services/queries/useGetCustomerDetails'
import {adaptCustomerToForm} from '@/features/customer/components/customer-form/utils'

export const CustomerDetailsURLParams = z.object({
    id: z.coerce.number().int()
})
export type CustomerDetailsURLParams = z.infer<typeof CustomerDetailsURLParams>

export const EditCustomer: React.FC = () => {
    const {urlParams} = useOutletContext<{urlParams: CustomerDetailsURLParams}>()
    const customerQuery = useGetCustomerDetails({
        customerId: urlParams.id,
        responseType: ResponseType.enum.extended
    })

    return (
        <PageLayout title={customerQuery.data?.businessName || ''}>
            {customerQuery.data && (
                <CustomerForm defaultValues={adaptCustomerToForm(customerQuery.data)} customerId={urlParams.id} />
            )}
        </PageLayout>
    )
}

EditCustomer.displayName = 'EditCustomer'
