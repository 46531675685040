import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {PlusIcon, SearchLgIcon} from '@/components/ui/icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useTranslation} from 'react-i18next'
import {PageLayout} from '@/layouts/page-layout/PageLayout'
import {useCustomersTable} from '@/features/customer/services/queries/useCustomersTable'
import InputText from '@/components/commons/input-text/InputText'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {CustomersTable} from '@/features/customer/components/customers-table/CustomersTable'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {Customer} from '@/features/customer/types'
import {useEffect, useRef, useState} from 'react'
import {useDeleteCustomer} from '@/features/customer/services/queries/useDeleteCustomer'
import toast from 'react-hot-toast'

export const Customers: React.FC = () => {
    const {t} = useTranslation()
    const customersQuery = useCustomersTable()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const [deleteCustomer, setDeleteCustomer] = useState<Customer | null>(null)

    const {mutate, isError, error} = useDeleteCustomer()

    useEffect(() => {
        if (isError) toast.error(error.message)
    }, [isError])

    const onSubmitDeleteCustomer = () => {
        if (deleteCustomer?.id) mutate(deleteCustomer.id)
        setDeleteCustomer(null)
    }

    return (
        <>
            <PageLayout
                title={t('customer:title')}
                sideHeading={
                    <Flexbox gap={2} align="center">
                        <InputText
                            onChange={e => customersQuery.onSearch(e.currentTarget.value)}
                            defaultValue={customersQuery.searchValue}
                            type={'text'}
                            placeholder={t('commons:search_placeholder')}
                            typeIcon={<SearchLgIcon size={18} />}
                            inputSize={'sm'}
                            ref={searchRef}
                        />

                        <ButtonLink variant={'primary'} size={'md'} to={'/new-customer'}>
                            <PlusIcon />
                            {t('customer:new_customer')}
                        </ButtonLink>
                    </Flexbox>
                }
            >
                <CustomersTable
                    data={customersQuery.remappedData}
                    isChangingPage={customersQuery.isFetchingNextPage}
                    isError={customersQuery.isError}
                    isLoading={customersQuery.isLoading}
                    setDeleteCustomer={setDeleteCustomer}
                    onChangePage={customersQuery.fetchNextPage}
                    searchValue={customersQuery.searchValue}
                    onResetSearchCb={() => customersQuery.onResetSearch(searchRef)}
                    emptySearchStateComponent={<EmptySearch title={t('commons:no_results')} icon={<SearchLgIcon />} />}
                    sorter={customersQuery.sorter}
                />
            </PageLayout>
            {deleteCustomer && (
                <DeleteModal
                    title={t('customer:delete:title')}
                    paragraph={t('customer:delete:description')}
                    isLoading={false}
                    onClose={() => setDeleteCustomer(null)}
                    onDelete={() => onSubmitDeleteCustomer()}
                />
            )}
        </>
    )
}

Customers.displayName = 'Customers'
