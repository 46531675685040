export const SAL_STATUSES = [
    {
        value: 'scheduled',
        label: 'project:sals:status:scheduled'
    },
    {
        value: 'delivered',
        label: 'project:sals:status:delivered'
    }
]

type ActiveFilters = {[key: string]: number[] | string[]}
export function getActiveFilters(filters: ActiveFilters): string[] {
    return Object.keys(filters).filter(key => Array.isArray(filters[key]) && filters[key].length > 0)
}
