import i18n from '@/translations/i18n'
import dayjs, {ConfigType} from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(LocalizedFormat)

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

type ReactSelect = {
    label: string
    value: string | number
}
export const retrieveSelectSingleValue = (options: ReactSelect[], value: string) => {
    return options.find(option => option.value.toString() === value)
}

export const retrieveSelectMultiValues = (options: ReactSelect[], values: [string]) => {
    return options.filter(option => values.find(value => value.toString() === option.value.toString()))
}

export const getInitials = (name: string) => {
    return name.match(/\b\w/g) || []
}

/*date time helpers*/
export const formatLocaleDate = (date: NonNullable<ConfigType>, formatType?: string) =>
    dayjs(date)
        .locale(i18n.language)
        .format(formatType || 'll')

export const formatDate = (date: string, formatType = 'YYYY-MM-DD') => (date ? dayjs(date).format(formatType) : '-')

export const isPasteDate = (date: string): boolean => dayjs(date)?.valueOf() < dayjs(new Date()).valueOf()

export const getTimezone = (): string => {
    dayjs.extend(utc)
    dayjs.extend(timezone)

    return dayjs.tz.guess()
}

export const megabytesToBytes = (megabytes: number) => megabytes * 1_000_000

//events helpers
export const debounce = <T extends (...args: Parameters<T>) => ReturnType<T>>(
    callback: T,
    delay: number
): ((...args: Parameters<T>) => void) => {
    let timeout: ReturnType<typeof setTimeout>

    return (...args: Parameters<T>) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            callback(...args)
        }, delay)
    }
}

/**
 * Function that given an amount and currency will return the properly formatted string
 * @param amount
 * @param currency
 */
export const formatCurrency = (amount = 0, currency?: string) =>
    new Intl.NumberFormat('en', {
        style: 'currency',
        currency: currency ?? 'USD'
    }).format(amount)

// round values helpers
export function round(value: number, precision?: number) {
    const multiplier = Math.pow(10, precision || 0)
    return Math.round(value * multiplier) / multiplier
}

/**
 * Function that given a currency-formatted text removes the formatting
 * @param text
 */
export const removeCurrencyFormat = (text?: string): string => (text ? text.replace(/[^\d.-]/g, '') : '')

/**
 * Function that format a number to have up to 2 decimal points
 * @param number
 */
export const formatRoundNumber = (num: number): string => {
    if (num % 1 === 0) {
        return num.toString()
    } else {
        const formatted = num.toFixed(2)
        return parseFloat(formatted).toString()
    }
}

/**
 * Function that format a number to have thousands separators
 * @param number
 */
export const formaThousandsNumber = (num: number): string => {
    return num.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
}
