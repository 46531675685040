import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {ErrorResponseData} from '@/types/commons'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {httpUpdateProject} from '@/features/project/services/project.http'

type UseUpdateProjectProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useUpdateProject = ({onError, onSuccess}: UseUpdateProjectProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_PROJECT],
        mutationFn: httpUpdateProject,
        onSuccess: async project => {
            onSuccess?.()
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECT_DETAILS, project.id]})
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECTS]})
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            onError?.(error)
        }
    })
}
