import {SalsResponse} from '@/features/project-sals/services/projectSal.http'
import {FC} from 'react'
import {TableDataCell} from '@/components/commons/table/TableDataCell/TableDataCell'
import {StyledSalImpact, StyledSalImpactDailyRate} from './style'
import {formatRoundNumber} from '@/utilities/helpers'
import {useTranslation} from 'react-i18next'

interface SalImpactCellProps {
    sal: SalsResponse
    dailyRate: number
    showDailyRate?: boolean
}

export const SalImpactCell: FC<SalImpactCellProps> = ({sal, dailyRate, showDailyRate}) => {
    const budgetInHours = (sal.currentAmount ?? 0) / (dailyRate / 8)
    const logTotal = (sal.totalFutureSchedulesAmount ?? 0) + (sal.totalLogsAmount ?? 0)
    const impact = ((budgetInHours - logTotal) / budgetInHours) * 100
    const {t} = useTranslation()
    return (
        <TableDataCell>
            <StyledSalImpact $isPositive={(impact ?? 0) > 0}>{formatRoundNumber(impact)}%</StyledSalImpact>
            {showDailyRate && (
                <StyledSalImpactDailyRate>
                    {formatRoundNumber(dailyRate)}€/{t('commons:day')}
                </StyledSalImpactDailyRate>
            )}
        </TableDataCell>
    )
}
