import React, {useState} from 'react'
import {ProjectDetails} from '@/features/project/types'
import {useOutletContext} from 'react-router-dom'
import {ProjectSalsHeader} from '@/features/project-sals/components/project-sals-header/ProjectSalsHeader'
import {ProjectSalsTable} from '@/features/project-sals/components/project-sals-table/ProjectSalsTable'
import {StyledProjectTabWrapper} from '@/pages/project-details/style'
import {useProjectSalsTable} from '@/features/project-sals/services/queries/useProjectSalsTable'
import {Modal} from '@/components/ui/modal/Modal'
import {Sal} from '@/features/project-sals/types'
import {ProjectSalForm} from '@/features/project-sals/components/project-sal-form/ProjectSalForm'
import {ProjectSalLoader} from '@/features/project-sals/components/project-sal-loader/ProjectSalLoader'
import {adaptSalToForm} from '@/features/project-sals/components/project-sal-form/utils'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {useDeleteSals} from '@/features/project-sals/services/queries/useDeleteProjectSal'
import {useProjectCostsQuery} from '@/features/project-costs/services/queries/useProjectCosts'

export const ProjectSals: React.FC = () => {
    const {t} = useTranslation()
    const {project} = useOutletContext<{project: ProjectDetails}>()
    const {sals, isLoading, isError, sorter} = useProjectSalsTable({
        projectId: project.id
    })
    const {data: costs} = useProjectCostsQuery({projectId: project.id})
    const [isAddSalModalOpen, setIsAddSalModalOpen] = useState(false)
    const [salToUpdate, setSalToUpdate] = useState<Sal | null>(null)
    const [salToDelete, setSalToDelete] = useState<Sal | null>(null)

    const {mutate: deleteSalMutation} = useDeleteSals({
        onSuccess: () => {
            toast.success(t(`commons:delete_completed`, {entity: t(`sals:singular`)}))
            setSalToDelete(null)
        },
        onError: error => {
            errorHandler(error)
            setSalToDelete(null)
        }
    })

    const onClickAddSal = () => setIsAddSalModalOpen(true)

    const onClickCloseAddSal = () => setIsAddSalModalOpen(false)

    const onSubmitSalToDelete = () => {
        if (salToDelete) {
            return deleteSalMutation(salToDelete?.id ?? 0)
        }
    }

    return (
        <StyledProjectTabWrapper>
            <ProjectSalsHeader sals={sals?.data || []} onClickAddSal={onClickAddSal} costs={costs ?? []} />

            <ProjectSalsTable
                isLoading={isLoading}
                data={sals?.data || []}
                isError={isError}
                onClickAddSal={onClickAddSal}
                setSalToUpdate={setSalToUpdate}
                setSalToDelete={setSalToDelete}
                project={project}
                sorter={sorter}
            />

            {isAddSalModalOpen && (
                <Modal autoZIndex={false} overflow="visible" onClose={onClickCloseAddSal}>
                    <ProjectSalForm
                        onClose={onClickCloseAddSal}
                        setSalToUpdate={setSalToUpdate}
                        setIsAddSalModalOpen={setIsAddSalModalOpen}
                        sals={sals?.data || []}
                    />
                </Modal>
            )}

            {salToUpdate &&
                (isLoading ? (
                    <ProjectSalLoader />
                ) : (
                    <Modal autoZIndex={false} overflow="visible" onClose={() => setSalToUpdate(null)}>
                        <ProjectSalForm
                            selectedSal={salToUpdate}
                            onClose={() => setSalToUpdate(null)}
                            setSalToUpdate={setSalToUpdate}
                            setIsAddSalModalOpen={setIsAddSalModalOpen}
                            defaultValues={adaptSalToForm(salToUpdate)}
                            sals={sals?.data || []}
                        />
                    </Modal>
                ))}

            {salToDelete && (
                <DeleteModal
                    title={t('project:sals:delete:title')}
                    paragraph={t('project:sals:delete:description')}
                    isLoading={false}
                    onClose={() => setSalToDelete(null)}
                    onDelete={() => onSubmitSalToDelete()}
                />
            )}
        </StyledProjectTabWrapper>
    )
}
