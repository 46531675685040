import {StyledButton} from './style'
import {ButtonHTMLAttributes, forwardRef, ReactNode} from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode
    className?: string
    iconOnly?: boolean
    size?: 'sm' | 'md' | 'lg' | 'xl'
    width?: 'fullWidth' | 'wide' | 'auto'
    variant: 'primary' | 'primaryDanger' | 'primaryWarning' | 'secondary' | 'tertiary' | 'ghost' | 'tertiaryInfo'
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {children, className, disabled, width, iconOnly, size = 'md', type = 'button', variant = 'primary', ...rest},
        ref
    ) => (
        <StyledButton
            className={className}
            disabled={disabled}
            width={width}
            iconOnly={iconOnly}
            ref={ref}
            size={size}
            type={type}
            variant={variant}
            {...rest}
        >
            {children}
        </StyledButton>
    )
)

export default Button

Button.displayName = 'Button'
