import styled, {DefaultTheme, css} from 'styled-components'

type StyledPageTitleProps = {
    theme: DefaultTheme
    size: 'sm' | 'md' | 'lg'
}

export const StyledPageTitle = styled.h1<StyledPageTitleProps>(
    ({theme, size}) => css`
        font-weight: 600;
        color: ${theme.palette.neutral['900']};
        ${() => SizeStyles({theme})[size]};
    `
)

type SizeStyleProps = {
    theme: DefaultTheme
}

const SizeStyles = ({theme: {typography}}: SizeStyleProps) => ({
    sm: css`
        ${typography.displayXs}
    `,
    md: css`
        ${typography.displaySm}
    `,
    lg: css`
        ${typography.displayLg}
    `
})
