import {z} from 'zod'

export const PROJECT_FORM_MODEL = {
    name: {
        name: 'name',
        label: 'project:details:name'
    },
    type: {
        name: 'type',
        label: 'project:details:type'
    },
    status: {
        name: 'status',
        label: 'project:details:status'
    },
    startDate: {
        name: 'startDate',
        label: 'project:details:startDate'
    },
    endDate: {
        name: 'endDate',
        label: 'project:details:endDate'
    },
    brief: {
        name: 'brief',
        label: 'project:details:brief'
    },
    successManager: {
        name: 'successManager',
        label: 'project:details:successManager'
    },
    teamLead: {
        name: 'teamLead',
        label: 'project:details:teamLead'
    },
    productLead: {
        name: 'productLead',
        label: 'project:details:productLead'
    },
    customer: {
        name: 'customer',
        label: 'project:details:customer'
    },
    budget: {
        name: 'budget',
        label: 'project:details:budget'
    },
    hours: {
        name: 'hours',
        label: 'project:details:hours'
    },
    dailyRate: {
        name: 'dailyRate',
        label: 'project:details:dailyRate'
    },
    masterAgreements: {
        name: 'masterAgreements',
        label: 'project:details:masterAgreements'
    },
    salesFolder: {
        name: 'salesFolder',
        label: 'project:details:salesFolder'
    },
    operationFolder: {
        name: 'operationFolder',
        label: 'project:details:operationFolder'
    }
} as const

export const ProjectFormSchema = z.object({
    name: z.string().min(1, {message: 'errors:required'}),
    type: z.object({
        label: z.string(),
        value: z.coerce.string()
    }),
    status: z.object({
        label: z.string(),
        value: z.string()
    }),
    startDate: z.string().nullable(),
    endDate: z.string().nullable(),
    brief: z.string().nullish(),
    successManager: z.object({
        label: z.string(),
        value: z.coerce.string()
    }),
    teamLead: z.object({
        label: z.string(),
        value: z.coerce.string()
    }),
    productLead: z.object({
        label: z.string(),
        value: z.coerce.string()
    }),
    customer: z.object({
        label: z.string(),
        value: z.coerce.string()
    }),
    budget: z.string().min(1, {message: 'errors:required'}),
    hours: z.string().min(1, {message: 'errors:required'}),
    dailyRate: z.string().min(1, {message: 'errors:required'}),
    masterAgreements: z.string().nullish(),
    salesFolder: z.string().url().or(z.literal('')).nullish(),
    operationFolder: z.string().url().or(z.literal('')).nullish()
})
export type ProjectValidationSchema = z.infer<typeof ProjectFormSchema>
