import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Switch} from '@/components/ui/switch/Switch'
import styled, {css} from 'styled-components'

export const StyledPhasesHeader = styled(Flexbox)(
    ({theme: {mediaQueries}}) => css`
        ${mediaQueries.l} {
            flex-direction: column;
        }
    `
)

export const StyledLoggedHoursWrapper = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 2fr 1fr;
        width: 100%;
        align-items: start;
        gap: ${spacing * 4.5}px;
        ${mediaQueries.m} {
            grid-template-columns: 1fr;
        }
    `
)

export const StyledLoggedHours = styled(Flexbox)(
    ({theme: {typography}}) => css`
        h4 {
            ${typography.textSm};
            font-weight: 600;
        }
    `
)

export const StyledLoggedHour = styled(Flexbox)`
    flex: 1;
    width: 100%;
`

export const StyledLoggedHourProgress = styled.div(
    ({theme: {spacing, palette}}) => css`
        display: block;
        width: 100%;
        padding: ${spacing * 4}px;
        background-color: ${palette.secondary[50]};
        border-radius: ${spacing * 3}px;
    `
)

export const StyledSwitchConvertCosts = styled(Switch)(
    ({theme: {spacing, mediaQueries}}) => css`
        margin-top: ${spacing * 7}px;
        ${mediaQueries.m} {
            margin-top: ${spacing * 2}px;
        }
    `
)
