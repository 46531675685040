import {FC, InputHTMLAttributes, ReactElement} from 'react'
import {StyledChip} from './style'
import {DefaultNamespace} from 'i18next'

export interface ChipProps extends InputHTMLAttributes<HTMLDivElement> {
    id: string
    label: string | DefaultNamespace
    variant: 'success' | 'warning' | 'default'
    labelPosition?: 'left' | 'right'
    placeholder?: string
    icon?: ReactElement
}

export const Chip: FC<ChipProps> = ({
    id,
    label,
    labelPosition = 'right',
    variant = 'default',
    onClick,
    icon,
    ...rest
}) => {
    return (
        <StyledChip id={id} labelPosition={labelPosition} variant={variant} onClick={onClick} {...rest}>
            {icon}
            <span>{label}</span>
        </StyledChip>
    )
}
