import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {httpUpdatePhaseServiceLine} from '@/features/project-phases/services/phase.http'

type UseUpdatePhaseServiceLineProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useUpdatePhaseServiceLine = ({onError, onSuccess}: UseUpdatePhaseServiceLineProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_PHASE_SERVICE_LINE],
        mutationFn: httpUpdatePhaseServiceLine,
        onSuccess: () => {
            onSuccess?.()
            client.invalidateQueries({queryKey: [QUERY_KEYS.PROJECT_PHASES]})
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            onError?.(error)
        }
    })
}
