import {FC} from 'react'
import {InvoiceStatus} from '@/features/invoice/types'
import {CalendarIcon, CheckIcon, ClockIcon} from '@/components/ui/icon'
import {Chip} from '@/components/ui/chip/Chip'
import {useTranslation} from 'react-i18next'

interface InvoiceStatusTableProps {
    status: InvoiceStatus
    invoiceFic: number | null
}

export const InvoiceStatusTable: FC<InvoiceStatusTableProps> = ({status, invoiceFic}) => {
    const {t} = useTranslation()

    if (status === InvoiceStatus.enum.expected || status === InvoiceStatus.enum.forecast) {
        const label = status === InvoiceStatus.enum.expected ? 'invoice:status:expected' : 'invoice:status:forecast'
        return <Chip id={'notLinkedSent'} label={t(label)} variant={'warning'} icon={<CalendarIcon size={12} />} />
    }

    if (status === InvoiceStatus.enum.sent && !invoiceFic) {
        return (
            <Chip
                id={'notLinkedSent'}
                label={t(`invoice:status:sent`)}
                variant={'default'}
                icon={<ClockIcon size={12} />}
            />
        )
    }

    if (status === InvoiceStatus.enum.sent && invoiceFic) {
        return (
            <Chip
                id={'linkedSent'}
                label={t(`invoice:status:sent`)}
                variant={'success'}
                icon={<CheckIcon size={12} />}
            />
        )
    }

    return <p> - </p>
}
