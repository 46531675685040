import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetProjectRevenues, HttpGetRevenuesParams} from '@/features/project-revenues/services/revenue.http.ts'

export const useGetProjectRevenues = (params: HttpGetRevenuesParams) => {
    return useQuery({
        queryKey: [QUERY_KEYS.PROJECT_REVENUES, params],
        queryFn: () => httpGetProjectRevenues(params)
    })
}
