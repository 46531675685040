import {z} from 'zod'

export const ServiceLine = z.enum(['digital_experience', 'web_app', 'mobile_app', 'others'])
export type ServiceLine = z.infer<typeof ServiceLine>

export const Team = z.object({
    id: z.number(),
    floatExternalId: z.coerce.string(),
    name: z.string(),
    isActive: z.boolean(),
    internalDailyRate: z.number(),
    teamLeadId: z.number(),
    serviceLine: ServiceLine
})
export type Team = z.infer<typeof Team>
