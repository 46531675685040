import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {ProjectCost, CostStatus} from '@/features/project-costs/types'
import Table from '@/components/commons/table/Table'
import {useTranslation} from 'react-i18next'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {Chip} from '@/components/ui/chip/Chip'
import {CalendarIcon, CheckIcon, DotsVerticalIcon, Edit01Icon, PlusIcon, Trash01Icon} from '@/components/ui/icon'
import {formatCurrency, formatLocaleDate} from '@/utilities/helpers'
import {SelectValue} from '@/components/commons/select/Select'
import {TableDataCell} from '@/components/commons/table/TableDataCell/TableDataCell'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import DropdownMenu from '@/components/ui/dropdown-menu/DropdownMenu'
import {CostPhasesSelect} from './CostPhasesSelect'

interface ProjectCostsTableProps {
    projectId: number
    data: ProjectCost[]
    phasesOptions: SelectValue[]
    showAddCostModal: Dispatch<SetStateAction<boolean>>
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    onChangePage?: () => void
    costToUpdate?: ProjectCost
    setCostToUpdate: Dispatch<SetStateAction<ProjectCost | null>>
    setCostToDelete: Dispatch<SetStateAction<ProjectCost | null>>
}

export const ProjectCostsTable: FC<ProjectCostsTableProps> = ({
    data,
    isLoading,
    isError,
    setCostToUpdate,
    setCostToDelete,
    showAddCostModal
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])

    return (
        <Table
            isMobile={isMobile}
            data={data}
            isLoading={isLoading}
            isError={isError}
            emptyStateComponent={
                <EmptyResults
                    title={t('project:costs:table:empty:title')}
                    subtitle={t('project:costs:table:empty:subtitle')}
                    btn={{
                        onClick: () => showAddCostModal(true),
                        text: t('project:costs:add_cost_btn'),
                        icon: <PlusIcon />
                    }}
                />
            }
            columns={[
                {
                    label: t('project:costs:table:labels:description'),
                    width: '36%',
                    sortName: 'name',
                    cellRender: cost => (
                        <TableDataCell title={cost.description}>
                            <p>{cost.description}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('project:costs:table:labels:date'),
                    width: '12%',
                    sortName: 'date',
                    cellRender: cost => (
                        <TableDataCell title={formatLocaleDate(new Date(cost.date))}>
                            <p>{formatLocaleDate(new Date(cost.date))}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('project:costs:table:labels:status'),
                    width: '12%',
                    sortName: 'status',
                    overflow: 'visible',
                    cellRender: cost => (
                        <TableDataCell size="xs" title={cost.status}>
                            <Chip
                                id={cost.status.toString()}
                                label={t(`project:costs:status:${cost.status}`)}
                                variant={cost.status === CostStatus.Enum.expected ? 'warning' : 'success'}
                                icon={
                                    cost.status === CostStatus.Enum.expected ? (
                                        <CalendarIcon size={12} />
                                    ) : (
                                        <CheckIcon size={12} />
                                    )
                                }
                            />
                        </TableDataCell>
                    )
                },
                {
                    label: t('project:costs:table:labels:phases'),
                    width: '16%',
                    overflow: 'visible',
                    cellRender: cost => (
                        <CostPhasesSelect
                            cost={cost}
                            inputPhase={
                                cost.phaseId && cost.phase?.name
                                    ? {label: cost.phase.name, value: cost.phaseId.toString()}
                                    : {label: t('project:no_phase'), value: '0'}
                            }
                        />
                    )
                },
                {
                    label: t('project:costs:table:labels:amount'),
                    width: '10%',
                    sortName: 'amount',
                    cellRender: cost => (
                        <TableDataCell title={formatCurrency(cost.amount, 'EUR')}>
                            <p>{formatCurrency(cost.amount, 'EUR')}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: ' ',
                    width: '14%',
                    sortName: 'action',
                    alignment: 'right',
                    cellRender: cost => (
                        <DropdownMenu
                            sideOffset={8}
                            trigger={<DotsVerticalIcon />}
                            actions={[
                                {
                                    component: t('commons:edit'),
                                    icon: <Edit01Icon size={16} />,
                                    onClickCb: () => setCostToUpdate(cost)
                                },
                                {
                                    component: t('commons:delete'),
                                    icon: <Trash01Icon size={16} />,
                                    onClickCb: () => {
                                        setCostToDelete(cost)
                                    }
                                }
                            ]}
                        />
                    )
                }
            ]}
            footer={[
                {
                    width: '78%',
                    alignment: 'right',
                    isBold: true,
                    cellRender: () => t('project:table:total')
                },
                {
                    width: '22%',
                    isBold: true,
                    cellRender: () =>
                        formatCurrency(
                            data.reduce((acc, val) => acc + val.amount, 0),
                            'EUR'
                        )
                }
            ]}
        />
    )
}
