import {QUERY_KEYS} from '@/queryClient'
import {useQuery} from '@tanstack/react-query'
import {httpGetVat} from '../vat.http'

export const useVatQuery = () => {
    const query = useQuery({
        queryKey: [QUERY_KEYS.VAT],
        queryFn: httpGetVat
    })

    return query
}
