import {FC, useMemo} from 'react'
import {
    StyledSalValue,
    StyledSalValueProgress,
    StyledSalValues,
    StyledSalValuesWrapper,
    StyledSalsHeader,
    StyledLastSyncLabel,
    StyledNotSyncLabel
} from './style'
import {Progress} from '@/components/ui/progress/Progress'
import {useTranslation} from 'react-i18next'
import {ProjectDetails} from '@/features/project/types'
import {useOutletContext} from 'react-router-dom'
import Button from '@/components/ui/button/Button'
import {PlusIcon} from '@/components/ui/icon'
import {SalsResponse} from '../../services/projectSal.http'
import {formaThousandsNumber, formatLocaleDate, round} from '@/utilities/helpers'
import {ProjectCost} from '@/features/project-costs/types'

interface SalsHeaderProps {
    sals: SalsResponse[]
    costs: ProjectCost[]
    onClickAddSal: () => void
}

export const ProjectSalsHeader: FC<SalsHeaderProps> = ({sals, onClickAddSal, costs}) => {
    const {project} = useOutletContext<{project: ProjectDetails}>()
    const {t} = useTranslation()

    const projectTotal = useMemo(
        () => project.budget - costs.reduce((total, current) => total + current.amount, 0),
        [project, costs]
    )

    return (
        <StyledSalsHeader justify="space-between" align="end">
            <StyledSalValuesWrapper>
                <StyledSalValues gap={8}>
                    <StyledSalValue gap={2} direction="column">
                        <h4>{t('project:sals:total_value')}</h4>
                        <StyledSalValueProgress>
                            <Progress
                                total={projectTotal}
                                progress={sals.reduce((total, current) => total + current.amount, 0)}
                                label={t('project:sals:total_value_euro')}
                                showValues
                                showPercentage
                                formatFunction={formaThousandsNumber}
                            />
                        </StyledSalValueProgress>
                    </StyledSalValue>

                    <StyledSalValue gap={2} direction="column">
                        <h4>{t('project:sals:total_logged_project')}</h4>
                        <StyledSalValueProgress>
                            <Progress
                                total={projectTotal / (project.dailyRate / 8)}
                                progress={sals.reduce((total, current) => total + (current?.logsAmount ?? 0), 0)}
                                label={t('project:sals:hours_label')}
                                showValues
                                showPercentage
                                formatFunction={value => round(value, 2).toString()}
                            />
                        </StyledSalValueProgress>
                    </StyledSalValue>
                </StyledSalValues>
            </StyledSalValuesWrapper>
            {project.lastSyncAt ? (
                <StyledLastSyncLabel>
                    {t('commons:last_sync_at')} {formatLocaleDate(project.lastSyncAt, 'lll')}
                </StyledLastSyncLabel>
            ) : (
                <StyledNotSyncLabel>{t('commons:no_sync_yet')}</StyledNotSyncLabel>
            )}
            {
                <Button variant="primary" onClick={onClickAddSal}>
                    <PlusIcon />
                    {t('project:sals:table:empty:cta')}
                </Button>
            }
        </StyledSalsHeader>
    )
}
