import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {httpDeleteEmployee} from '@/features/employee/services/employee.http.ts'
import {useMutation} from '@tanstack/react-query'
import toast from 'react-hot-toast'
import {errorHandler} from '@utilities/genericErrorHandler.ts'
import {useTranslation} from 'react-i18next'

export const useDeleteEmployee = ({onSuccess}: {onSuccess: () => void}) => {
    const client = queryClient
    const {t} = useTranslation()

    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_EMPLOYEE],
        mutationFn: (id: number) => httpDeleteEmployee(id),
        onSuccess: () => {
            toast.success(t('commons:delete_completed', {entity: t('employee:singular')}))
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.EMPLOYEES]})
        },
        onError: e => errorHandler(e)
    })
}
