import {LinkProps} from 'react-router-dom'
import {StyledButtonLink} from './style'

interface ButtonLinkProps extends LinkProps {
    disabled?: boolean
    fullWidth?: boolean
    width?: 'fullWidth' | 'wide' | 'auto'
    iconOnly?: boolean
    size?: 'sm' | 'md' | 'lg'
    variant?: 'primary' | 'secondary' | 'tertiary' | 'ghost'
    padding?: 0 | 1
}

export const ButtonLink = ({
    children,
    width = 'auto',
    disabled = false,
    iconOnly = false,
    size = 'md',
    variant = 'primary',
    padding = 1,
    ...rest
}: ButtonLinkProps) => (
    <StyledButtonLink
        $disabled={disabled}
        $width={width}
        $iconOnly={iconOnly}
        $size={size}
        $variant={variant}
        $padding={padding}
        {...rest}
    >
        {children}
    </StyledButtonLink>
)

ButtonLink.displayName = 'ButtonLink'
