import {SalsResponse} from '@/features/project-sals/services/projectSal.http'
import {FC} from 'react'
import {TableDataCell} from '@/components/commons/table/TableDataCell/TableDataCell'
import {useTranslation} from 'react-i18next'
import {StyledSalHours, StyledSalHoursCircle, StyledSalHoursTotal, StyledSalHoursWrapper} from './style'
import {Tooltip} from '@/components/ui/tooltip/Tooltip'
import {InfoIcon} from '@/components/ui/icon'
import {round} from '@/utilities/helpers'

interface SalHoursLoggedCellProps {
    sal: SalsResponse
    dailyRate: number
}

export const SalHoursLoggedCell: FC<SalHoursLoggedCellProps> = ({sal, dailyRate}) => {
    const {t} = useTranslation()

    return (
        <TableDataCell>
            <StyledSalHoursWrapper>
                <div>
                    <StyledSalHoursCircle $isGray={(sal?.futureSchedulesAmount ?? 0) > 0} />
                </div>
                <div>
                    <StyledSalHours>
                        {t('project:sals:table:labels:hours_total')}{' '}
                        {round((sal?.totalFutureSchedulesAmount ?? 0) + (sal?.totalLogsAmount ?? 0), 2)}/
                        {round((sal?.currentAmount ?? 0) / (dailyRate / 8), 2)}
                    </StyledSalHours>
                    <StyledSalHoursTotal>
                        <span>
                            {round((sal.futureSchedulesAmount ?? 0) + (sal?.logsAmount ?? 0), 2)}/
                            {round(sal.amount / (dailyRate / 8), 2)}
                        </span>
                        <Tooltip
                            trigger={<InfoIcon fill="none" size={17} viewBox="0 0 17 16" />}
                            content={t('project:sals:table:labels:hours_logged_tooltip')}
                        />
                    </StyledSalHoursTotal>
                </div>
            </StyledSalHoursWrapper>
        </TableDataCell>
    )
}
