import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@/components/ui/modal/Modal'
import {StyledInvoicedModal, StyledTitle} from './style'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {InvoiceForm} from '@/features/project-revenues/components/invoice-modal/InvoiceForm.tsx'
import {useGetBanks} from '@/features/bank/services/query/useGetBank.ts'
import {useVatQuery} from '@/features/vat/services/queries/useVat.ts'
import {SelectValue} from '@components/commons/select/Select.tsx'
import {useOutletContext} from 'react-router-dom'
import {ProjectDetails} from '@/features/project/types.ts'
import {InvoicesFormLoadingSkeleton} from '@/features/project-revenues/components/invoice-modal/InvoiceFormSkeleton.tsx'
interface InvoiceModalProps {
    onClose: () => void
}

export const CreateInvoiceModal: React.FC<InvoiceModalProps> = ({onClose}) => {
    const {t} = useTranslation()
    const {project} = useOutletContext<{project: ProjectDetails}>()
    const {data: banks, isPending: isPendingBanks} = useGetBanks()
    const {data: vats, isPending: isPendingVats} = useVatQuery()
    const banksOptions = useMemo<SelectValue[]>(
        () => (banks ? banks.map(item => ({value: item.id.toString(), label: item.name}) as SelectValue) : []),
        [banks]
    )
    const vatsOptions = useMemo<SelectValue[]>(
        () =>
            vats
                ? vats.map(
                      item =>
                          ({
                              value: item.id.toString(),
                              label: `${item.value}%  ${item.description ? item.description : ''}`
                          }) as SelectValue
                  )
                : [],
        [vats]
    )

    return (
        <Modal width={1000} closeIconOffset="sm" onClose={onClose}>
            <StyledInvoicedModal>
                <Flexbox align={'center'}>
                    <StyledTitle>{t('invoice:details:add_title')}</StyledTitle>
                </Flexbox>
                {(isPendingBanks && isPendingVats) || !project ? (
                    <InvoicesFormLoadingSkeleton />
                ) : (
                    <InvoiceForm
                        onClose={onClose}
                        formOptions={{banks: banksOptions, vats: vatsOptions}}
                        projectInvoiceData={{projectId: project.id, customerId: project.customer.id}}
                    />
                )}
            </StyledInvoicedModal>
        </Modal>
    )
}

export default CreateInvoiceModal
