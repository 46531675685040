import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetBanks} from '@/features/bank/services/bank.http.ts'

export const useGetBanks = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.BANK],
        queryFn: () => httpGetBanks()
    })
}
