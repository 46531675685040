import {StyledContainer, StyledNotFoundWrapper} from '@pages/not-found/style.ts'
import {useNavigate} from 'react-router-dom'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {SearchLgIcon} from '@components/ui/icon'
import Button from '@components/ui/button/Button.tsx'

export const NotFound = () => {
    const navigate = useNavigate()

    return (
        <StyledContainer fullHeight={true}>
            <StyledNotFoundWrapper>
                <IconContainer>
                    <SearchLgIcon />
                </IconContainer>
                <h1>Page not found</h1>
                <p>The page you are looking for doesn't exist.</p>
                <Button variant={'primary'} size={'lg'} onClick={() => navigate('/')}>
                    Take me home
                </Button>
            </StyledNotFoundWrapper>
        </StyledContainer>
    )
}

NotFound.displayName = 'NotFound'
