import {Navigate, Outlet} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {useAuthStore} from '@/store/AuthStore.tsx'

const PublicRoute = () => {
    const accessToken = useAuthStore(state => state.accessToken)

    return accessToken ? <Navigate to={routes.HOME.path} /> : <Outlet />
}

export default PublicRoute
