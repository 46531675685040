import {PaginationResponse} from '@/types/commons'
import {CostStatus, Cost} from '../types'
import {z} from 'zod'
import {HttpCostsParams} from './types'
import axios from '@/axiosInstance'

// Response
export const HttpGetCostsResponse = z.object({
    meta: PaginationResponse,
    data: z.array(Cost)
})
export type HttpGetCostsResponse = z.infer<typeof HttpGetCostsResponse>

export const httpGetCost = async (params: HttpCostsParams): Promise<HttpGetCostsResponse> => {
    const response = await axios.get(`/costs`, {params})
    return HttpGetCostsResponse.parse(response.data)
}

// create and update
export const HttpCostMutationRequest = z.object({
    description: z.string(),
    phaseId: z.number().nullable(),
    amount: z.number(),
    date: z.string().nullable(),
    status: CostStatus
})
export type HttpCostMutationRequest = z.infer<typeof HttpCostMutationRequest>
