import {FC} from 'react'
import {
    StyledProgressIndicator,
    StyledProgressLabel,
    StyledProgressRoot,
    StyledProgressValues,
    StyledProgressPercentage
} from './style'
import {Flexbox} from '../flexbox/FlexBox'
import {round} from '@utilities/helpers.ts'

interface ProgressProps {
    label?: string
    showValues?: boolean
    showPercentage?: boolean
    total?: number
    progress?: number
    secondaryProgress?: number
    disabled?: boolean
    formatFunction?: (value: number) => string
}

export const Progress: FC<ProgressProps> = ({
    total = 100,
    progress = 0,
    secondaryProgress = 0,
    label,
    showValues = false,
    showPercentage = false,
    disabled,
    formatFunction
}) => {
    return (
        <Flexbox width={'100%'} direction="column" gap={2}>
            <Flexbox width={'100%'} justify="space-between">
                {label && <StyledProgressLabel>{label}</StyledProgressLabel>}
                {showValues && !disabled && (
                    <StyledProgressValues>
                        {formatFunction
                            ? `${formatFunction(round(secondaryProgress + progress, 2))}/${formatFunction(total)}`
                            : `${round(secondaryProgress + progress, 2)}/${total}`}
                    </StyledProgressValues>
                )}
            </Flexbox>
            <Flexbox align="center" width={'100%'} direction="row" gap={2}>
                <StyledProgressRoot value={progress}>
                    {(secondaryProgress + progress || progress) <= total ? (
                        <>
                            <StyledProgressIndicator
                                style={{width: `${((secondaryProgress + progress) / total) * 100}%`}}
                                variant="secondary"
                            />

                            <StyledProgressIndicator
                                style={{width: `${(progress / total) * 100}%`}}
                                variant="primary"
                            />
                        </>
                    ) : (
                        <StyledProgressIndicator style={{width: `100%`}} variant="danger" />
                    )}
                </StyledProgressRoot>
                {showPercentage && (
                    <StyledProgressPercentage>{round((progress / total) * 100, 2)}%</StyledProgressPercentage>
                )}
            </Flexbox>
        </Flexbox>
    )
}
