import styled, {DefaultTheme, css} from 'styled-components'

export const StyledSalValue = styled.div(
    ({theme: {typography}}) => css`
        ${typography.textLg}
    `
)

export const StyledSalValueTotal = styled.div(() => css``)

export const StyledSalHoursWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 8px 1fr;
        gap: ${spacing * 3}px;
        & > div:first-child {
            display: flex;
            align-items: center;
        }
    `
)

type StyledSalHoursCircleProps = {
    theme: DefaultTheme
    $isGray: boolean
}

export const StyledSalHoursCircle = styled.div<StyledSalHoursCircleProps>(
    ({theme: {palette}, $isGray}) => css`
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: ${$isGray ? palette.neutral[300] : palette.success[500]};
    `
)

export const StyledSalHours = styled.div(
    ({theme: {typography, palette}}) => css`
        ${typography.textLg}
        font-weight: 600;
        color: ${palette.secondary[400]};
    `
)

export const StyledSalHoursTotal = styled.div(
    ({theme: {spacing, palette}}) => css`
        color: ${palette.secondary[400]};
        display: flex;
        align-items: center;
        gap: ${spacing}px;
    `
)

type StyledSalImpactProps = {
    theme: DefaultTheme
    $isPositive: boolean
}

export const StyledSalImpact = styled.div<StyledSalImpactProps>(
    ({theme: {typography, palette}, $isPositive}) => css`
        ${typography.textLg}
        color: ${$isPositive ? palette.success[600] : palette.danger[600]};
    `
)

export const StyledSalImpactDailyRate = styled.div(
    ({theme: {palette}}) => css`
        color: ${palette.secondary[400]};
    `
)
