import {SelectValue} from '@/components/commons/select/Select'
import TableSelect from '@/components/commons/table/TableSelect/TableSelect'
import {FC, useEffect, useState} from 'react'
import {ProjectCost} from '../types'
import {formatCurrency} from '@/utilities/helpers'
import {adaptCostPhaseToUpdate} from '../utils'
import {useUpdateProjectCost} from '../services/queries/useUpdateProjectCost'
import {phasesSelectAdapter} from '@/utilities/adapters'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {useProjectCostsTable} from '../services/queries/useProjectCostsTable'
import Spinner from '@/components/ui/spinner/Spinner'
import {useTheme} from 'styled-components'

interface SelectPhaseProps {
    cost: ProjectCost
    inputPhase: SelectValue
}

export const CostPhasesSelect: FC<SelectPhaseProps> = ({cost, inputPhase}) => {
    const theme = useTheme()
    const [phase, setPhase] = useState(inputPhase)

    const {phases} = useProjectCostsTable({
        projectId: cost.projectId
    })
    const phasesOptions = phases ? phasesSelectAdapter(phases) : []

    const {mutate: updateProjectCost, isPending} = useUpdateProjectCost({
        onError: error => {
            errorHandler(error)
            setPhase(inputPhase)
        }
    })

    const updatePhase = (newValue: SelectValue, cost: ProjectCost) => {
        setPhase(newValue)
        const updatedPhase = {
            ...cost,
            amount: formatCurrency(cost.amount, 'EUR'),
            phase: {
                label: newValue.label,
                value: newValue.value
            }
        }

        updateProjectCost({id: cost.id, data: adaptCostPhaseToUpdate(updatedPhase)})
    }

    useEffect(() => {
        setPhase(inputPhase)
    }, [inputPhase])

    return !isPending ? (
        <TableSelect
            value={phase}
            onChange={selectValue => updatePhase(selectValue as SelectValue, cost)}
            options={phasesOptions}
        />
    ) : (
        <Spinner size={16} color={theme.palette.neutral[500]} />
    )
}
