import React, {useEffect, useState} from 'react'
import EmployeeForm from '@/features/employee/components/employees-form/EmployeeForm.tsx'
import {useGetEmployeeDetails} from '@/features/employee/services/queries/useEmployeeDetails.ts'
import {PageLayout} from '@layouts/page-layout/PageLayout.tsx'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {z} from 'zod'
import {composeEmployeeFormData} from '@/features/employee/utils.ts'
import {EmployeeValidationSchema} from '@/features/employee/components/employees-form/EmployeeFormModel.ts'
import EmployeeFormLoadingSkeleton from '@/features/employee/components/employees-form/employee-form-loading-skeleton/EmployeeFormLoadingSkeleton.tsx'

export const EmployeeDetailsURLParams = z.object({
    id: z.coerce.number().int()
})
export type EmployeeDetailsURLParams = z.infer<typeof EmployeeDetailsURLParams>
export const EditEmployee: React.FC = () => {
    const [formData, setFormData] = useState<EmployeeValidationSchema | null>(null)
    const urlParams = useURLParsedParams(EmployeeDetailsURLParams)
    if (!urlParams.success) {
        throw 'invalid URL'
    }
    const {data: employeeDetails, isPending: employeePending} = useGetEmployeeDetails({
        responseType: 'extended',
        urlParams: {
            employeeId: urlParams.data.id
        }
    })

    //TODO: Need to add Role, Type and Team API request
    useEffect(() => {
        if (!employeePending && employeeDetails) {
            const newFormData = composeEmployeeFormData(employeeDetails)
            setFormData(newFormData)
        }
    }, [employeeDetails])

    return (
        <>
            <PageLayout title={formData?.fullName || ''}>
                {employeePending || !formData ? (
                    <>
                        <EmployeeFormLoadingSkeleton />
                    </>
                ) : (
                    <EmployeeForm employeeDefaultValue={formData} employeeId={employeeDetails?.id} />
                )}
            </PageLayout>
        </>
    )
}
EditEmployee.displayName = 'EditEmployee'
