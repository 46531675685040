import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledSalsHeader = styled(Flexbox)(
    ({theme: {mediaQueries}}) => css`
        ${mediaQueries.l} {
            flex-direction: column;
        }
    `
)

export const StyledSalValuesWrapper = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 2fr 1fr;
        width: 100%;
        align-items: start;
        gap: ${spacing * 4.5}px;
        ${mediaQueries.m} {
            grid-template-columns: 1fr;
        }
    `
)

export const StyledSalValues = styled(Flexbox)(
    ({theme: {typography}}) => css`
        h4 {
            ${typography.textSm};
            font-weight: 600;
        }
    `
)

export const StyledSalValue = styled(Flexbox)`
    flex: 1;
    width: 100%;
`

export const StyledLastSyncLabel = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: block;
        white-space: nowrap;
        margin: 0 ${spacing * 2}px;
        ${mediaQueries.m} {
            margin: ${spacing * 2}px;
        }
    `
)

export const StyledNotSyncLabel = styled.div(
    ({theme: {spacing, mediaQueries, typography}}) => css`
        ${typography.textSm};
        font-weight: 400;
        font-style: italic;
        display: block;
        white-space: nowrap;
        margin: 0 ${spacing * 2}px;
        ${mediaQueries.m} {
            margin: ${spacing * 2}px;
        }
    `
)

export const StyledSalValueProgress = styled.div(
    ({theme: {spacing, palette}}) => css`
        display: block;
        width: 100%;
        padding: ${spacing * 4}px;
        background-color: ${palette.secondary[50]};
        border-radius: ${spacing * 3}px;
    `
)
