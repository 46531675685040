import i18next from 'i18next'
import toast from 'react-hot-toast'
import {isAxiosError} from 'axios'

/**
 * Generic errorHandler function
 * @param error
 */
export const errorHandler = (error: unknown) => {
    if (isAxiosError(error)) {
        const message =
            error.response?.data.code && error.status != 500
                ? i18next.t(`errors:${error.response.data.code}`)
                : i18next.t('errors:default')
        return toast.error(message)
    }

    toast.error(i18next.t('errors:default'))
    return error
}
