import Container from '@components/ui/container/Container.tsx'
import toast from 'react-hot-toast'
import Button from '@components/ui/button/Button.tsx'
import HomeForm from '@pages/form-showcase/components/HomeForm'
import PageHeader from '@components/commons/page-hero/PageHero.tsx'
import {ArchiveIcon, ChevronRightIcon} from 'src/components/ui/icon'

export const FormShowcase = () => {
    return (
        <Container>
            <PageHeader
                title={'Form Showcase'}
                subtitle={'subtitle here'}
                sideComponent={
                    <Button size={'sm'} variant={'primary'} onClick={() => toast.success('Here is your toast.')}>
                        <ArchiveIcon data-color />
                        Make me a toast
                        <ChevronRightIcon data-color />
                    </Button>
                }
            />
            {/* <Calendar numMonths={1} selectedDates={[new Date()]} onDatesChange={dates => console.log(dates)}/>*!/*/}
            <HomeForm />
        </Container>
    )
}

FormShowcase.displayName = 'Home'
