import {SalsResponse} from '@/features/project-sals/services/projectSal.http'
import {formatCurrency, formatRoundNumber} from '@/utilities/helpers'
import {FC} from 'react'
import {TableDataCell} from '@/components/commons/table/TableDataCell/TableDataCell'
import {useTranslation} from 'react-i18next'
import {StyledSalValue, StyledSalValueTotal} from './style'

interface SalAmountCellProps {
    sal: SalsResponse
    budget: number
}

export const SalAmountCell: FC<SalAmountCellProps> = ({sal, budget}) => {
    const {t} = useTranslation()

    return (
        <TableDataCell>
            <StyledSalValue>{formatCurrency(sal.amount, 'EUR')}</StyledSalValue>
            <StyledSalValueTotal>
                {t('project:sals:table:labels:amount_total')} {formatCurrency(sal.currentAmount, 'EUR')} (+
                {formatRoundNumber((sal.amount / budget) * 100)}%)
            </StyledSalValueTotal>
        </TableDataCell>
    )
}
