import {z} from 'zod'

export const Vat = z.object({
    id: z.coerce.number(),
    ficExternalId: z.number(),
    value: z.number(),
    description: z.string().nullable(),
    notes: z.string().nullable(),
    createdAt: z.string(),
    updatedAt: z.string().nullable()
})
export type Vat = z.infer<typeof Vat>
