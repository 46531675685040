import {create} from 'zustand'
import {persist} from 'zustand/middleware'
import {queryClient} from '@/queryClient'
import {User} from '@/features/sso/types'
import {LoginParams} from '@services/auth.http'

type AuthStore = {
    accessToken: string | null
    refreshToken: string | null
    user: User | null
    error: string | null
    isError: boolean
    isLoading: boolean
    login: (params: LoginParams) => Promise<void>
    logout: () => Promise<boolean>
    userMe: () => Promise<void>
    setAccessToken: (accessToken: string | null) => void
    setRefreshToken: (refreshToken: string | null) => void
    reset: () => void
}

type ErrorSetterParams = {
    error: string | undefined
    isError: boolean
}

const setError = (set: (params: ErrorSetterParams) => void, error: string): void => set({error, isError: true})

const resetError = (set: (params: ErrorSetterParams) => void): void => set({error: undefined, isError: false})

export const useAuthStore = create<AuthStore>()(
    persist(
        (set, get) => ({
            accessToken: null,
            refreshToken: null,
            user: null,
            error: null,
            isError: false,
            isLoading: false,
            login: async (params: LoginParams) => {
                resetError(set)
                try {
                    set({isLoading: true})
                    const {httpLogin} = await import('@services/auth.http')
                    const response = await httpLogin(params)
                    set({
                        user: response.data.user,
                        accessToken: response.data.accessToken,
                        refreshToken: response.data.refreshToken
                    })
                } catch (error) {
                    setError(set, 'Login error')
                } finally {
                    set({isLoading: false})
                }
            },
            logout: async (): Promise<boolean> => {
                resetError(set)
                try {
                    set({isLoading: true})
                    const {httpLogout} = await import('@services/auth.http')
                    await httpLogout()
                    queryClient.clear()
                    set({user: null, accessToken: null, refreshToken: null})
                    set({isLoading: false})
                } catch (error) {
                    setError(set, 'Logout error')
                    set({isLoading: false})
                    return false
                }
                return true
            },
            userMe: async () => {
                try {
                    resetError(set)
                    set({isLoading: true})
                    const {httpUserMe} = await import('@services/auth.http')
                    const response = await httpUserMe(get().accessToken ?? '')
                    set({user: response.data})
                } catch (error) {
                    setError(set, 'Profile error')
                } finally {
                    set({isLoading: false})
                }
            },
            setAccessToken: (accessToken: string | null) => {
                set({accessToken})
            },
            setRefreshToken: (refreshToken: string | null) => {
                set({refreshToken})
            },
            reset: () => set({user: undefined, accessToken: undefined, refreshToken: undefined})
        }),
        {
            name: 'auth-storage'
        }
    )
)
