import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {PlusIcon, SearchLgIcon} from '@/components/ui/icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useTranslation} from 'react-i18next'
import {PageLayout} from '@/layouts/page-layout/PageLayout'
import {useProjectsTable} from '@/features/project/services/queries/useProjectsTable'
import {ProjectsTable} from '@/features/project/components/projects-table/ProjectsTable'
import {ProjectsFilters} from '@/features/project/components/projects-filters/ProjectsFilters'
import React, {useRef, useState} from 'react'
import InputText from '@/components/commons/input-text/InputText'
import {DeleteModal} from '@components/commons/delete-modal/DeleteModal.tsx'
import {useDeleteProjects} from '@/features/project/services/queries/useDeleteProjects.ts'
import {Switch} from '@/components/ui/switch/Switch'

export const Projects: React.FC = () => {
    const {t} = useTranslation()
    const projectsQuery = useProjectsTable()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const [projectToDelete, setProjectToDelete] = useState<number | null>(null)

    const {mutate: deleteProject, isPending: isDeleteProjectPending} = useDeleteProjects({
        onSuccess: () => {
            setProjectToDelete(null)
        }
    })

    const onSubmitProjectToDelete = () => {
        if (projectToDelete) {
            return deleteProject(projectToDelete)
        }
    }

    return (
        <PageLayout
            title={`${t('project:title')}`}
            sideTitle={`${projectsQuery.data ? `(${projectsQuery.data.pages[0].meta.total})` : ''}`}
            sideHeading={
                <Flexbox gap={2} align="center">
                    <Switch
                        label={t('project:filters:labels:need_sal')}
                        onChange={value => projectsQuery.setNeedWorkProgressStatus(value ? 'true' : undefined)}
                        defaultChecked={projectsQuery.needWorkProgressStatus === 'true'}
                    />

                    <InputText
                        defaultValue={projectsQuery.searchValue}
                        onChange={e => projectsQuery.onSearch(e.currentTarget.value)}
                        ref={searchRef}
                        type={'text'}
                        placeholder={t('commons:search_placeholder')}
                        typeIcon={<SearchLgIcon size={18} />}
                        inputSize={'sm'}
                    />

                    <ProjectsFilters />

                    <ButtonLink variant={'primary'} size={'md'} to={'/new-project'}>
                        <PlusIcon />
                        {t('project:new_project')}
                    </ButtonLink>
                </Flexbox>
            }
        >
            <ProjectsTable
                data={projectsQuery.remappedData}
                isChangingPage={projectsQuery.isFetchingNextPage}
                isError={projectsQuery.isError}
                isLoading={projectsQuery.isLoading}
                onResetSearchCb={() => projectsQuery.onResetSearch(searchRef)}
                onChangePage={projectsQuery.fetchNextPage}
                sorter={projectsQuery.sorter}
                searchValue={projectsQuery.searchValue}
                filterValue={projectsQuery.filterValue}
                setProjectToDelete={setProjectToDelete}
            />

            {projectToDelete && (
                <DeleteModal
                    title={t('project:delete:title')}
                    paragraph={t('project:delete:description')}
                    isLoading={isDeleteProjectPending}
                    onClose={() => setProjectToDelete(null)}
                    onDelete={() => onSubmitProjectToDelete()}
                />
            )}
        </PageLayout>
    )
}

Projects.displayName = 'Projects'
