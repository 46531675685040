import styled, {css, DefaultTheme} from 'styled-components'
import {
    DESKTOP_TABLE_COLUMNS_PADDING,
    DESKTOP_TABLE_SCROLLBAR_WIDTH
} from '@components/commons/table/DesktopTable/style.ts'
import {Virtuoso} from 'react-virtuoso'
import {Percentage} from '@/types/commons.ts'

export const StyledDesktopTBody = styled(Virtuoso)`
    ${({theme: {palette}}) => css`
        overflow-y: scroll !important;

        &::-webkit-scrollbar {
            width: ${DESKTOP_TABLE_SCROLLBAR_WIDTH};
            background-color: ${palette.neutral[50]};
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${palette.neutral[300]};
            border-radius: 3px;

            &:hover {
                background-color: ${palette.neutral[400]};
            }
        }
    `}
`

export const StyledTRowWrapper = styled.div(
    ({theme: {palette}}: {theme: DefaultTheme}) => css`
        border-bottom: 1px solid ${palette.neutral[200]};
        transition: ease-in-out 300ms;
        &:hover {
            background: ${palette.neutral['50']};
        }
        &:has(.fill-row) {
            background: ${palette.neutral['200']};
        }
    `
)

export const StyledTRow = styled.div`
    display: flex;
`

interface StyledTCellProps {
    width: Percentage
    alignment?: 'left' | 'center' | 'right'
    overflow?: 'visible' | 'hidden'
}
export const StyledTCell = styled.div(
    ({width, alignment, overflow, theme: {typography, palette}}: StyledTCellProps & {theme: DefaultTheme}) => css`
        width: ${width};
        min-height: 72px;
        display: flex;
        align-items: center;
        justify-content: ${alignment == 'right' ? 'flex-end' : alignment == 'center' ? 'center' : 'flex-start'};
        padding: 0 ${DESKTOP_TABLE_COLUMNS_PADDING};
        ${typography.textSm};
        color: ${palette.neutral[600]};
        white-space: nowrap;

        & > div {
            overflow: ${overflow === 'visible' ? 'visible' : 'hidden'};
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
        }
    `
)
