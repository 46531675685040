import {
    CSSProperties,
    forwardRef,
    HTMLInputTypeAttribute,
    InputHTMLAttributes,
    ReactElement,
    ReactNode,
    useState
} from 'react'
import {StyledInput, StyledInputWrapper, StyledInputTrigger} from '@components/commons/input-text/style.ts'
import {EyeIcon, EyeOffIcon} from 'src/components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {DefaultNamespace} from 'i18next'
import Label from '@components/ui/label/Label.tsx'
import InputStatusIcon from '@components/ui/input-status-icon/InputStatusIcon.tsx'
import InputHelpText from '@components/ui/input-help-text/InputHelpText.tsx'

export interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string
    label?: DefaultNamespace | ReactNode
    name?: string
    type: HTMLInputTypeAttribute
    typeIcon?: ReactNode
    touched?: boolean
    /**
     * how to use errorMessage with translations
     *  errorMessage={t(errors.text?.message || '')} this avoid undefined value problems
     */
    errorMessage?: string | DefaultNamespace
    visibilityToggle?: boolean
    helpText?: string | DefaultNamespace
    placeholder?: string
    inputSize?: 'sm' | 'md' | 'lg'
    width?: CSSProperties['width']
    customInputTrigger?: ReactElement
}

const InputText = forwardRef<HTMLInputElement, InputTextProps>(
    (
        {
            label,
            name,
            type,
            typeIcon,
            touched = false,
            errorMessage,
            helpText,
            className,
            disabled,
            placeholder,
            visibilityToggle = false,
            inputSize = 'md',
            width = '100%',
            customInputTrigger,
            ...rest
        },
        ref
    ) => {
        const [eyeVisible, setEyeVisible] = useState(false)

        return (
            <Flexbox className={className} width={width} direction="column" gap={1.5}>
                {label && <Label htmlFor={name}>{label}</Label>}
                <Flexbox align="center" width="100%">
                    <StyledInputWrapper
                        touched={touched}
                        hasError={!!errorMessage}
                        size={inputSize}
                        isInputTrigger={visibilityToggle || !!customInputTrigger}
                    >
                        {typeIcon}
                        <StyledInput
                            ref={ref}
                            name={name}
                            type={eyeVisible ? 'text' : type}
                            onWheel={e => e.currentTarget.blur()}
                            placeholder={placeholder}
                            disabled={disabled}
                            step={type === 'number' ? '.01' : 'any'}
                            onKeyDown={e =>
                                type === 'number' ? ['-', 'e', 'E', '+'].includes(e.key) && e.preventDefault() : null
                            }
                            {...rest}
                        />
                        <InputStatusIcon touched={touched} hasError={!!errorMessage} />
                    </StyledInputWrapper>

                    {/*input action toggle*/}
                    {visibilityToggle && (
                        <StyledInputTrigger
                            size={inputSize}
                            disabled={disabled}
                            onClick={() => setEyeVisible(!eyeVisible)}
                        >
                            {eyeVisible ? <EyeIcon size={20} data-color /> : <EyeOffIcon size={20} data-color />}
                        </StyledInputTrigger>
                    )}

                    {!visibilityToggle && customInputTrigger && (
                        <StyledInputTrigger size={inputSize}>{customInputTrigger}</StyledInputTrigger>
                    )}
                </Flexbox>
                <InputHelpText error={errorMessage} helpText={helpText} />
            </Flexbox>
        )
    }
)

export default InputText

InputText.displayName = 'InputText'
