import {useProjectPhasesQuery} from '@/features/project-phases/services/queries/useProjectPhases'
import {useProjectCostsQuery} from '@/features/project-costs/services/queries/useProjectCosts'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {CostsURLParams, CostsSearchParams} from '@/features/project-costs/types'
import {ResponseType} from '@/types/commons'

export const useProjectCostsTable = (params: CostsURLParams) => {
    const multiParams = [] as const satisfies ReadonlyArray<keyof CostsSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, CostsSearchParams)
    const costsQuery = useProjectCostsQuery({...params, ...searchParams, responseType: ResponseType.Enum.extended})
    const phasesQuery = useProjectPhasesQuery(params)

    const sort = (sorter: {
        orderBy: CostsSearchParams['orderBy']
        orderDirection: CostsSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        costs: costsQuery,
        phases: phasesQuery.data,
        isLoading: phasesQuery.isLoading || costsQuery.isLoading,
        isSuccess: phasesQuery.isSuccess,
        isError: phasesQuery.isError,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
