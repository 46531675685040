import React, {useEffect} from 'react'
import {UnsavedBlocker} from '@/components/commons/unsaved-blocker/UnsavedBlocker'
import {useTranslation} from 'react-i18next'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import InputText from '@components/commons/input-text/InputText.tsx'
import {
    EMPLOYEE_FORM_MODEL,
    EmployeeFormSchema,
    EmployeeValidationSchema
} from '@/features/employee/components/employees-form/EmployeeFormModel.ts'
import {httpGetScopes} from '@/features/employee/services/employee.http.ts'
import InputSelect, {SelectValue} from '@components/commons/select/Select.tsx'
import {
    composeEditEmployeeObject,
    composeNewEmployeeObject,
    EMPLOYEE_ACCESS,
    EMPLOYEE_ROLES,
    EMPLOYEE_STATUSES,
    EMPLOYEE_TYPE
} from '@/features/employee/utils.ts'
import DatePicker from '@components/commons/date-picker/DatePicker.tsx'
import dayjs from 'dayjs'
import {
    StyledFormWrapper,
    StyledPrimaryDateWrapper,
    StyledSecondaryDateWrapper
} from '@/features/employee/components/employees-form/style.ts'
import Divider from '@components/ui/divider/Divider.tsx'
import {generatePath, useNavigate} from 'react-router-dom'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Button from '@components/ui/button/Button.tsx'
import {CalendarIcon} from '@components/ui/icon'
import {useUpdateEmployee} from '@/features/employee/services/queries/useUpdateEmployee.ts'
import {useCreateEmployee} from '@/features/employee/services/queries/useCreateEmployee.ts'
import {routes} from '@utilities/constants'
import {teamsSelectAdapter} from '@utilities/adapters.ts'
import {httpGetTeam} from '@/features/teams/services/teams.http.ts'
interface EmployeeFormProps {
    employeeDefaultValue?: EmployeeValidationSchema
    employeeId?: number
}

const EmployeeForm: React.FC<EmployeeFormProps> = ({employeeDefaultValue, employeeId}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {mutate: updateEmployee} = useUpdateEmployee({
        onSuccess: () => {
            reset({}, {keepValues: true})
        },
        onError: () => {
            reset({...employeeDefaultValue})
        }
    })
    const {mutate: createEmployee} = useCreateEmployee({
        onSuccess: data => {
            navigate(
                generatePath(routes.EDIT_EMPLOYEES.path, {
                    id: data.id
                })
            )
        },
        onError: () => {
            reset({...employeeDefaultValue})
        }
    })
    const {
        control,
        register,
        handleSubmit,
        resetField,
        reset,
        watch,
        formState: {errors, touchedFields, isValid, isDirty, isSubmitting, isSubmitted}
    } = useForm<EmployeeValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(EmployeeFormSchema),
        defaultValues: {...employeeDefaultValue}
    })

    const watchStartDate = watch(EMPLOYEE_FORM_MODEL.startDate.name)
    const watchEndDate = watch(EMPLOYEE_FORM_MODEL.endDate.name)

    useEffect(() => {
        if (watchStartDate && watchEndDate && watchStartDate > watchEndDate) {
            register(EMPLOYEE_FORM_MODEL.endDate.name)
            resetField(EMPLOYEE_FORM_MODEL.endDate.name)
        }
    }, [watchStartDate, watchEndDate])

    const onSubmit: SubmitHandler<EmployeeValidationSchema> = data => {
        if (employeeDefaultValue && employeeId) {
            return updateEmployee(composeEditEmployeeObject(data, employeeId))
        }
        return createEmployee(composeNewEmployeeObject(data))
    }

    return (
        <UnsavedBlocker isDirty={isDirty && !isSubmitted}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Divider topSpacing={0} bottomSpacing={40} />
                <StyledFormWrapper>
                    <StyledPrimaryDateWrapper>
                        <InputText
                            label={`${t(EMPLOYEE_FORM_MODEL.fullName.label)}*`}
                            type={'text'}
                            touched={touchedFields.fullName}
                            errorMessage={t(errors.fullName?.message || '')}
                            placeholder={t(EMPLOYEE_FORM_MODEL.fullName.label)}
                            {...register(t(EMPLOYEE_FORM_MODEL.fullName.name))}
                        />
                        <InputText
                            label={`${t(EMPLOYEE_FORM_MODEL.email.label)}*`}
                            type={'text'}
                            touched={touchedFields.email}
                            errorMessage={t(errors.email?.message || '')}
                            placeholder={t(EMPLOYEE_FORM_MODEL.email.label)}
                            {...register(t(EMPLOYEE_FORM_MODEL.email.name))}
                        />
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <InputSelect
                                    isAsync={true}
                                    loadOptions={async () => {
                                        const result = await httpGetTeam()
                                        return teamsSelectAdapter(result)
                                    }}
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={EMPLOYEE_FORM_MODEL.team.name}
                                    label={`${t(EMPLOYEE_FORM_MODEL.team.label)}*`}
                                    isClearable={false}
                                    isCreatable={false}
                                    errorMessage={t(errors.team?.message || '')}
                                    placeholder={t(EMPLOYEE_FORM_MODEL.team.label)}
                                />
                            )}
                            control={control}
                            name={EMPLOYEE_FORM_MODEL.team.name}
                        />
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <InputSelect
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={EMPLOYEE_FORM_MODEL.status.name}
                                    label={`${t(EMPLOYEE_FORM_MODEL.status.label)}*`}
                                    isClearable={false}
                                    isSearchable={false}
                                    errorMessage={t(errors.status?.message || '')}
                                    placeholder={t(EMPLOYEE_FORM_MODEL.status.label)}
                                    options={EMPLOYEE_STATUSES.map(item => ({
                                        value: item.value,
                                        label: t(item.label)
                                    }))}
                                />
                            )}
                            control={control}
                            name={EMPLOYEE_FORM_MODEL.status.name}
                        />
                    </StyledPrimaryDateWrapper>
                    <StyledSecondaryDateWrapper>
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <InputSelect
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={EMPLOYEE_FORM_MODEL.role.name}
                                    label={`${t(EMPLOYEE_FORM_MODEL.role.label)}*`}
                                    isClearable={false}
                                    isSearchable={false}
                                    errorMessage={t(errors.role?.message || '')}
                                    placeholder={t(EMPLOYEE_FORM_MODEL.role.label)}
                                    options={EMPLOYEE_ROLES.map(item => ({
                                        value: item.value,
                                        label: t(item.label)
                                    }))}
                                />
                            )}
                            control={control}
                            name={EMPLOYEE_FORM_MODEL.role.name}
                        />
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <InputSelect
                                    isAsync
                                    loadOptions={async () => {
                                        const scopes = await httpGetScopes()
                                        return scopes.data.map(item => ({
                                            value: `${item.id}`,
                                            label: t(`scopes:${item.name}`)
                                        }))
                                    }}
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={EMPLOYEE_FORM_MODEL.scope.name}
                                    label={`${t(EMPLOYEE_FORM_MODEL.scope.label)}*`}
                                    isClearable={true}
                                    errorMessage={t(errors.scope?.message || '')}
                                    placeholder={t(EMPLOYEE_FORM_MODEL.scope.label)}
                                />
                            )}
                            control={control}
                            name={EMPLOYEE_FORM_MODEL.scope.name}
                        />
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <InputSelect
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={EMPLOYEE_FORM_MODEL.type.name}
                                    label={`${t(EMPLOYEE_FORM_MODEL.type.label)}*`}
                                    isClearable={true}
                                    isSearchable={false}
                                    errorMessage={t(errors.type?.message || '')}
                                    placeholder={t(EMPLOYEE_FORM_MODEL.type.label)}
                                    options={EMPLOYEE_TYPE.map(item => ({value: item.value, label: t(item.label)}))}
                                />
                            )}
                            control={control}
                            name={EMPLOYEE_FORM_MODEL.type.name}
                        />
                        <InputText
                            type={'number'}
                            min={1}
                            label={`${t(EMPLOYEE_FORM_MODEL.weeklyHours.label)}*`}
                            touched={touchedFields.weeklyHours}
                            errorMessage={t(errors.weeklyHours?.message || '')}
                            placeholder={t(EMPLOYEE_FORM_MODEL.weeklyHours.label)}
                            {...register(t(EMPLOYEE_FORM_MODEL.weeklyHours.name))}
                        />
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <InputSelect
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={EMPLOYEE_FORM_MODEL.access.name}
                                    label={`${t(EMPLOYEE_FORM_MODEL.access.label)}*`}
                                    isClearable={true}
                                    isSearchable={false}
                                    errorMessage={t(errors.access?.message || '')}
                                    placeholder={t(EMPLOYEE_FORM_MODEL.access.label)}
                                    options={EMPLOYEE_ACCESS.map(item => ({
                                        value: item.value,
                                        label: t(item.label)
                                    }))}
                                />
                            )}
                            control={control}
                            name={EMPLOYEE_FORM_MODEL.access.name}
                        />
                        <InputText
                            min={0}
                            label={`${t(EMPLOYEE_FORM_MODEL.floatId.label)}`}
                            type={'number'}
                            touched={touchedFields.floatId}
                            errorMessage={t(errors.floatId?.message || '')}
                            placeholder={t(EMPLOYEE_FORM_MODEL.floatId.label)}
                            {...register(t(EMPLOYEE_FORM_MODEL.floatId.name))}
                        />
                        <Controller
                            control={control}
                            name={EMPLOYEE_FORM_MODEL.startDate.name}
                            render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                                <DatePicker
                                    toggle
                                    formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                                    numMonths={1}
                                    mode={'single'}
                                    currentDay={new Date()}
                                    selectedDates={value ? [value] : []}
                                    onDatesChange={dates => onChange(dates[0])}
                                    onBlur={onBlur}
                                    triggerProps={{
                                        label: `${t(EMPLOYEE_FORM_MODEL.startDate.label)}*`,
                                        errorMessage: t(error?.message || ''),
                                        placeholder: t(EMPLOYEE_FORM_MODEL.startDate.label),
                                        typeIcon: <CalendarIcon size={20} />
                                    }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name={EMPLOYEE_FORM_MODEL.endDate.name}
                            render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                                <DatePicker
                                    toggle
                                    formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                                    numMonths={1}
                                    mode={'single'}
                                    minDate={watchStartDate}
                                    currentDay={new Date()}
                                    selectedDates={value ? [value] : []}
                                    onDatesChange={dates => onChange(dates[0])}
                                    onBlur={onBlur}
                                    triggerProps={{
                                        label: `${t(EMPLOYEE_FORM_MODEL.endDate.label)}`,
                                        errorMessage: t(error?.message || ''),
                                        placeholder: t(EMPLOYEE_FORM_MODEL.endDate.label),
                                        typeIcon: <CalendarIcon size={20} />
                                    }}
                                />
                            )}
                        />
                    </StyledSecondaryDateWrapper>
                </StyledFormWrapper>
                <Divider topSpacing={40} bottomSpacing={40} />
                <Flexbox justify={'end'} gap={2}>
                    <Button
                        width="wide"
                        onClick={() => {
                            navigate(routes.EMPLOYEES.path)
                        }}
                        variant={'tertiary'}
                    >
                        <p>{t('new_employee:cancel')}</p>
                    </Button>
                    <Button width="wide" type="submit" variant="primary" size="md" disabled={!isValid && !isSubmitting}>
                        {t('commons:save')}
                    </Button>
                </Flexbox>
            </form>
        </UnsavedBlocker>
    )
}
export default EmployeeForm
