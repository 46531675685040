import {TableDataCell} from '@/components/commons/table/TableDataCell/TableDataCell'
import styled, {css} from 'styled-components'

export const StyledSalTableDataCell = styled(TableDataCell)(
    ({theme: {palette}}) => css`
        a {
            color: ${palette.neutral[900]};
            font-weight: bold;
        }
    `
)

export const StyledTeamLead = styled(TableDataCell)(
    () => css`
        display: block;
        font-weight: bold;
    `
)
