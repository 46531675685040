import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {HttpGetInternalDailyRateResponse, httpInternalDailyRate} from '../project.http'

export const useInternalDailyRate = (
    options?: Omit<UseQueryOptions<HttpGetInternalDailyRateResponse>, 'queryFn' | 'queryKey'>
) => {
    const query = useQuery({
        ...options,
        queryKey: [QUERY_KEYS.INTERNAL_DAILY_RATE],
        queryFn: () => httpInternalDailyRate()
    })

    return {
        ...query,
        internalDailyRate: query.data?.internalDailyRate
    }
}
