import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpCreateInvoice, HttpCreateInvoiceParams} from '../invoice.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'

type UseCreateInvoiceProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useCreateInvoiceMutation = ({onSuccess, onError}: UseCreateInvoiceProps) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_INVOICE],
        mutationFn: (params: HttpCreateInvoiceParams) => httpCreateInvoice(params),
        onSuccess: data => {
            onSuccess?.()
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECT_REVENUES]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.INVOICES, data.id]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.INVOICES]})
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            onError?.(error)
        }
    })
}
