import Table, {TableSorter} from '@/components/commons/table/Table'
import {ProjectDetails, ProjectsSearchParams} from '@/features/project/types'
import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {formatCurrency, formatLocaleDate, round} from '@/utilities/helpers'
import {
    ArrowRightIcon,
    DotsVerticalIcon,
    Edit01Icon,
    FilterLinesIcon,
    PlusIcon,
    SearchMdIcon,
    Trash01Icon
} from '@/components/ui/icon'
import {generatePath, Link, useNavigate} from 'react-router-dom'
import {TableDataCell} from '@/components/commons/table/TableDataCell/TableDataCell'
import {StyledNeedSalChip, StyledProjectStatus, StyledProjectTableDataCell, StyledTeamLead} from './style'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import DropdownMenu from '@/components/ui/dropdown-menu/DropdownMenu'
import {ProjectSyncStatus} from './ProjectSyncStatus'
import {routes} from '@utilities/constants'
import {
    StyledCustomerLink,
    StyledItemDescription
} from '@/features/project-revenues/components/revenues-header/style.ts'
import dayjs from 'dayjs'

interface ProjectsTableProps {
    data: ProjectDetails[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    searchValue?: string
    filterValue?: boolean
    sorter?: TableSorter<ProjectsSearchParams['orderBy']> | undefined
    onResetSearchCb?: () => void
    onChangePage?: () => void
    setProjectToDelete: Dispatch<SetStateAction<number | null>>
}

export const ProjectsTable: FC<ProjectsTableProps> = ({
    data,
    isLoading,
    sorter,
    isChangingPage,
    searchValue,
    filterValue,
    onChangePage,
    onResetSearchCb,
    setProjectToDelete
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])
    const navigate = useNavigate()
    const now = dayjs()

    return (
        <Table
            isMobile={isMobile}
            data={data}
            isLoading={isLoading}
            sorter={sorter}
            isChangingPage={isChangingPage}
            searchValue={searchValue}
            onChangePage={onChangePage}
            columns={[
                {
                    label: t('project:table:labels:title'),
                    width: '33%',
                    sortName: 'name',
                    cellRender: project => (
                        <StyledProjectTableDataCell>
                            <StyledProjectStatus $isActive={project.status === 'active'} />
                            <div className="projectName">
                                <Link title={project.name} to={`/projects/${project.id}/info`}>
                                    {`#${project.id} ${project.name}`}
                                </Link>
                                <StyledCustomerLink
                                    to={generatePath(routes.EDIT_CUSTOMERS.path, {
                                        id: project.customer.id
                                    })}
                                    target="_blank"
                                >
                                    <StyledItemDescription>{project.customer.businessName}</StyledItemDescription>
                                </StyledCustomerLink>
                            </div>
                            <div className="needSal">
                                {project.needWorkProgressStatuses && (
                                    <StyledNeedSalChip
                                        id={project.id.toString()}
                                        label={t('project:table:labels:need_sal')}
                                        variant="warning"
                                        size={12}
                                        title={t('project:table:labels:need_sal')}
                                        icon={<ArrowRightIcon size={15} />}
                                        labelPosition="left"
                                        onClick={() => navigate(`/projects/${project.id}/sal`)}
                                    />
                                )}
                            </div>
                        </StyledProjectTableDataCell>
                    )
                },
                {
                    label: t('project:table:labels:type'),
                    width: '7%',
                    sortName: 'type',
                    cellRender: project => (
                        <TableDataCell
                            size="xs"
                            title={project?.type?.name ? t(`project:types:${project?.type?.name}`) : ''}
                        >
                            <p>{project?.type?.name ? t(`project:types:${project?.type?.name}`) : '-'}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('project:table:labels:budget'),
                    width: '9%',
                    sortName: 'budget',
                    cellRender: project => (
                        <TableDataCell title={formatCurrency(project.budget, 'EUR')}>
                            <p>{formatCurrency(project.budget, 'EUR')}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('project:table:labels:used_hours'),
                    width: '8%',
                    sortName: 'usedHours',
                    cellRender: project => {
                        return (
                            <TableDataCell
                                size="xs"
                                isWarn={(project?.loggedAmount || 0) > project.hours}
                                title={`${project?.loggedAmount || 0}/${project.hours}`}
                            >
                                <p>
                                    {round(project?.loggedAmount) || 0}/{project.hours}
                                </p>
                            </TableDataCell>
                        )
                    }
                },
                {
                    label: t('project:table:labels:success_manager'),
                    width: '10%',
                    sortName: 'successManager',
                    cellRender: project => (
                        <TableDataCell title={project?.successManager?.fullName || ''}>
                            <p>{project?.successManager?.fullName || '-'}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('project:table:labels:team'),
                    width: '10%',
                    sortName: 'teamLead',
                    cellRender: project => (
                        <TableDataCell title={project?.teamLead?.fullName || ''}>
                            <StyledTeamLead>{project?.teamLead?.fullName || '-'}</StyledTeamLead>
                            <span>{project?.productLead?.fullName || '-'}</span>
                        </TableDataCell>
                    )
                },
                {
                    label: t('project:table:labels:end_date'),
                    width: '8%',
                    sortName: 'endDate',
                    cellRender: project => (
                        <TableDataCell
                            title={project.endDate ? formatLocaleDate(project.endDate, 'll') : ''}
                            isWarn={now.isAfter(dayjs(project.endDate))}
                            isDanger={dayjs(project.endDate).isBefore(now.add(15, 'd'))}
                        >
                            {project?.endDate ? <p>{formatLocaleDate(project.endDate, 'll')}</p> : '-'}
                        </TableDataCell>
                    )
                },
                {
                    label: t('project:table:labels:last_sync_at'),
                    width: '11%',
                    sortName: 'lastSyncAt',
                    cellRender: project => (
                        <TableDataCell>
                            <ProjectSyncStatus syncStatus={project.syncStatus} lastSyncAt={project.lastSyncAt} />
                        </TableDataCell>
                    )
                },
                {
                    label: ' ',
                    width: '3%',
                    overflow: 'visible',
                    alignment: 'center',
                    cellRender: project => (
                        <TableDataCell>
                            <DropdownMenu
                                sideOffset={8}
                                trigger={<DotsVerticalIcon />}
                                actions={[
                                    {
                                        component: 'Edit',
                                        icon: <Edit01Icon size={16} />,
                                        onClickCb: () => navigate(`/projects/${project.id}/info`)
                                    },
                                    {
                                        component: 'Delete',
                                        icon: <Trash01Icon size={16} />,
                                        onClickCb: () => {
                                            setProjectToDelete(project.id)
                                        }
                                    }
                                ]}
                            />
                        </TableDataCell>
                    )
                }
            ]}
            emptySearchStateComponent={
                <EmptySearch
                    title={t('commons:no_results')}
                    onClearSearch={() => onResetSearchCb && onResetSearchCb()}
                    icon={<SearchMdIcon />}
                />
            }
            emptyStateComponent={
                filterValue ? (
                    <EmptySearch title={t('commons:no_results')} icon={<FilterLinesIcon />} />
                ) : (
                    <EmptyResults
                        title={t('project:table:empty:title')}
                        subtitle={t('project:table:empty:subtitle')}
                        cta={{
                            path: '/new-project',
                            text: t('project:new_project'),
                            icon: <PlusIcon />
                        }}
                    />
                )
            }
        />
    )
}
