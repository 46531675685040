import {Link} from 'react-router-dom'
import styled, {css} from 'styled-components'

export const StyledInformationWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: ${spacing * 4}px;
    `}
`

export const StyledItemTitle = styled.span`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textSm}
        font-weight: bold;
        color: ${palette.neutral['900']};
        display: block;
        margin-bottom: ${spacing}px;
    `}
`

export const StyledItemDescription = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm}
        color: ${palette.neutral['500']};
        display: block;
    `}
`

export const StyledAgreementsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 4}px;
        & > div:last-child {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }
    `}
`

export const StyledAgreementsTitle = styled.span`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textSm}
        color: ${palette.neutral['700']};
        display: block;
        margin-bottom: ${spacing}px;
    `}
`

export const StyledAgreementsDescription = styled.div`
    ${() => css`
        width: 100%;
    `}
`

export const StyledCustomerLink = styled(Link)`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`
