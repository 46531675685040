import Table from '@/components/commons/table/Table'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {FC, useMemo} from 'react'
import {Phase} from '@/features/project-phases/types'
import {useTranslation} from 'react-i18next'
import {StyledPhaseDataCostsTHead} from './style'
import {ProjectCost, CostStatus} from '@/features/project-costs/types'
import {TableDataCell} from '@/components/commons/table/TableDataCell/TableDataCell'
import {round} from '@/utilities/helpers'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import {FloatSyncButton} from '@/features/project-phases/components/float-syc-button/FloatSyncButton'
import {useOutletContext} from 'react-router-dom'
import {ProjectDetails} from '@/features/project/types'
import {SelectServiceLine} from './SelectServiceLine'
import {
    PHASE_SERVICE_LINE,
    totalHours,
    totalLeft,
    totalScheduled,
    totalUsed
} from '@/features/project-phases/components/phases-table/utils.ts'

interface PhasesTableProps {
    data: Phase[]
    costs: ProjectCost[]
    dailyRate: number
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    isConvertCosts: boolean
    onChangePage?: () => void
}

type GetHoursCosts = {
    hours: number
    phaseId: number | null
    costStatus: CostStatus
}

export const PhasesTable: FC<PhasesTableProps> = ({data, costs, dailyRate, isLoading, isError, isConvertCosts}) => {
    const {project} = useOutletContext<{project: ProjectDetails}>()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])
    const {t} = useTranslation()

    const getHoursCosts = ({hours, phaseId, costStatus}: GetHoursCosts) => {
        if (isConvertCosts) {
            const hourlyCost = round(dailyRate / 8, 2)
            const phasesCosts = round(
                costs
                    .filter(cost => cost.phaseId === phaseId)
                    .filter(cost => cost.status === costStatus)
                    .reduce((acc, val) => acc + val.amount, 0),
                2
            )

            const costToHours = round(phasesCosts / hourlyCost, 2)
            const total = hours + costToHours

            return round(total, 2)
        } else {
            return hours
        }
    }

    return (
        <Table
            isMobile={isMobile}
            data={data}
            isLoading={isLoading}
            isError={isError}
            columns={[
                {
                    label: t('project:phases:table:labels:phase'),
                    width: '32%',
                    sortName: 'name',
                    cellRender: phase => (
                        <TableDataCell isBold={!phase.id} className={!phase.id ? 'fill-row' : ''}>
                            <p title={phase.name}>{phase.name}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('project:phases:table:labels:service_line'),
                    width: '14%',
                    sortName: 'serviceLine',
                    overflow: 'visible',
                    cellRender: phase => {
                        return !phase.id ? null : isMobile ? (
                            <TableDataCell>
                                {phase.serviceLine
                                    ? t(PHASE_SERVICE_LINE.find(item => item.value === phase.serviceLine)?.label ?? '')
                                    : ''}
                            </TableDataCell>
                        ) : (
                            <SelectServiceLine inputServiceLine={phase.serviceLine} phaseId={phase.id} />
                        )
                    }
                },
                {
                    label: t('project:phases:table:labels:description'),
                    width: '17%',
                    sortName: 'description',
                    cellRender: phase => (
                        <TableDataCell title={phase.description ?? ''}>
                            <p>{phase.description}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('project:phases:table:labels:total_hours'),
                    width: '8%',
                    alignment: 'center',
                    sortName: 'total_hours',
                    cellRender: phase => (
                        <TableDataCell>
                            <p>{t('commons:hours_val', {hours: phase.hours ?? 0})}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: isConvertCosts ? (
                        <StyledPhaseDataCostsTHead>
                            <p>{t('project:phases:table:labels:used_costs_hours')}</p>
                        </StyledPhaseDataCostsTHead>
                    ) : (
                        t('project:phases:table:labels:used_hours')
                    ),
                    width: '9%',
                    alignment: 'center',
                    cellRender: phase => {
                        const hours = getHoursCosts({
                            hours: phase.loggedAmount,
                            phaseId: phase?.id || null,
                            costStatus: CostStatus.Enum.received
                        })

                        return (
                            <TableDataCell isWarn={hours < 0}>
                                <p>{t('commons:hours_val', {hours})}</p>
                            </TableDataCell>
                        )
                    }
                },
                {
                    label: isConvertCosts ? (
                        <StyledPhaseDataCostsTHead>
                            {t('project:phases:table:labels:scheduled_costs')}
                        </StyledPhaseDataCostsTHead>
                    ) : (
                        t('project:phases:table:labels:scheduled')
                    ),
                    width: '12%',
                    alignment: 'center',
                    cellRender: phase => {
                        const hours = getHoursCosts({
                            hours: phase.futureSchedulesAmount,
                            phaseId: phase?.id,
                            costStatus: CostStatus.Enum.expected
                        })
                        return (
                            <TableDataCell isWarn={hours < 0}>
                                <p>{t('commons:hours_val', {hours})}</p>
                            </TableDataCell>
                        )
                    }
                },
                {
                    label: t('project:phases:table:labels:left'),
                    width: '8%',
                    alignment: 'center',
                    cellRender: phase => {
                        const logged = getHoursCosts({
                            hours: phase.loggedAmount,
                            phaseId: phase?.id,
                            costStatus: CostStatus.Enum.received
                        })

                        const scheduled = getHoursCosts({
                            hours: phase.futureSchedulesAmount,
                            phaseId: phase?.id,
                            costStatus: CostStatus.Enum.expected
                        })

                        const hours = (phase?.hours || 0) - (logged + scheduled)
                        return (
                            <TableDataCell isWarn={hours < 0}>
                                <p>{t('commons:hours_val', {hours: round(hours, 2)})}</p>
                            </TableDataCell>
                        )
                    }
                }
            ]}
            footer={[
                {
                    width: '63%',
                    isBold: true,
                    alignment: 'right',
                    cellRender: () => t('project:revenues:table:total')
                },
                {
                    width: '8%',
                    isBold: true,
                    alignment: 'center',
                    cellRender: () => totalHours(data) + 'h'
                },
                {
                    width: '9%',
                    isBold: true,
                    alignment: 'center',
                    cellRender: () => totalUsed(data, getHoursCosts) + 'h'
                },
                {
                    width: '12%',
                    isBold: true,
                    alignment: 'center',
                    cellRender: () => totalScheduled(data, getHoursCosts) + 'h'
                },
                {
                    width: '8%',
                    isBold: true,
                    alignment: 'center',
                    cellRender: () => totalLeft(data, getHoursCosts) + 'h'
                }
            ]}
            emptyStateComponent={
                <EmptyResults
                    title={t('project:phases:table:empty:title')}
                    subtitle={t('project:phases:table:empty:subtitle')}
                >
                    <div>
                        <FloatSyncButton
                            projectId={project.id}
                            syncStatus={project.syncStatus}
                            lastSyncAt={project.lastSyncAt}
                            showLabel={false}
                        />
                    </div>
                </EmptyResults>
            }
        />
    )
}
