import {TableFooter} from '@components/commons/table/Table.tsx'
import {StyledTFoot, StyledTDFoot} from '@components/commons/table/DesktopTFoot/style.ts'

interface DesktopTFootProps {
    columns?: TableFooter[]
}

const DesktopTFoot = ({columns}: DesktopTFootProps) => {
    if (!columns) return null
    return (
        <StyledTFoot>
            {columns.map((column, index) => {
                return (
                    <StyledTDFoot
                        isBold={!!column.isBold}
                        width={column.width}
                        alignment={column.alignment}
                        key={index}
                    >
                        {column.cellRender()}
                    </StyledTDFoot>
                )
            })}
        </StyledTFoot>
    )
}

export default DesktopTFoot
