import {FC} from 'react'
import Calendar, {CalendarProps} from 'src/components/commons/calendar'
import InputText, {InputTextProps} from '@components/commons/input-text/InputText.tsx'
import Select, {SelectProps} from '@components/commons/select/Select.tsx'
import Popover from '@components/ui/popover/Popover.tsx'

type BaseDatePickerProps = {
    formatDateFn: (date: Date) => string
    onBlur?: () => void
    disabled?: boolean
    currentDay?: Date
    errorMessage?: string
} & Omit<CalendarProps, 'mode'>

type InputDatePickerProps = BaseDatePickerProps & {
    mode: 'single' | 'range'
    triggerProps?: Omit<InputTextProps, 'type' | 'readonly' | 'value'>
}

type SelectDatePickerProps = BaseDatePickerProps & {mode: 'multiple'; triggerProps: Omit<SelectProps, 'value'>}

type DatePickerProps = InputDatePickerProps | SelectDatePickerProps

const TriggerByMode = {
    single: InputText,
    multiple: Select,
    range: InputText
} as const

type FormatDateFnArgs = {
    dates: Date[]
    formatFn: (date: Date) => string
}
const formatFnByMode = {
    single: ({dates, formatFn}: FormatDateFnArgs) => {
        const date = dates[0]
        return date ? formatFn(date) : ''
    },
    multiple: ({dates, formatFn}: FormatDateFnArgs) => {
        return dates.map(date => formatFn(date)).map(val => ({label: val, value: val}))
    },
    range: ({dates, formatFn}: FormatDateFnArgs) => {
        const [start, end] = dates
        if (!start && !end) {
            return ''
        }
        const formattedStart = start ? formatFn(start) : ''
        const formattedEnd = end ? formatFn(end) : ''

        return `${formattedStart} - ${formattedEnd}`
    }
} as const

const DatePicker: FC<DatePickerProps> = ({
    mode,
    selectedDates,
    triggerProps,
    formatDateFn,
    currentDay,
    onBlur,
    disabled,
    errorMessage,
    ...props
}) => {
    let instanciatedTrigger
    if (mode === 'multiple') {
        const Trigger = TriggerByMode[mode]
        const formatFn = formatFnByMode[mode]
        triggerProps
        instanciatedTrigger = (
            <Trigger
                disabled={disabled}
                {...triggerProps}
                value={formatFn({dates: selectedDates, formatFn: formatDateFn})}
                isMulti
                readOnly
                errorMessage={errorMessage}
            />
        )
    } else {
        const Trigger = TriggerByMode[mode]
        const formatFn = formatFnByMode[mode]
        instanciatedTrigger = (
            <Trigger
                disabled={disabled}
                {...triggerProps}
                type="text"
                readOnly
                value={formatFn({dates: selectedDates, formatFn: formatDateFn})}
                errorMessage={errorMessage}
            />
        )
    }

    return (
        <Popover
            rootProps={{
                onOpenChange: open => {
                    if (!open) {
                        onBlur?.()
                    }
                },
                open: disabled ? false : undefined
            }}
            trigger={instanciatedTrigger}
            triggerProps={{asChild: true}}
        >
            <Calendar {...props} mode={mode} selectedDates={selectedDates} currentDay={currentDay} />
        </Popover>
    )
}

export default DatePicker
