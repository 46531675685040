import {z} from 'zod'
import {HttpProjectSalMutationRequest} from '../../services/projectSal.http'
import {formatCurrency, removeCurrencyFormat} from '@/utilities/helpers'
import {Sal, SalStatus} from '../../types'
import dayjs from 'dayjs'
import {t} from 'i18next'

export const SAL_FORM_MODEL = {
    name: {
        name: 'name',
        label: 'project:sals:form:name'
    },
    amount: {
        name: 'amount',
        label: 'project:sals:form:amount'
    },
    deliveryDate: {
        name: 'deliveryDate',
        label: 'project:sals:form:deliveryDate'
    },
    status: {
        name: 'status',
        label: 'project:sals:form:status'
    }
} as const

export const ProjectSalFormValidationSchema = z.object({
    name: z.string().min(1, {message: 'errors:required'}),
    amount: z.string().min(1, {message: 'errors:required'}),
    deliveryDate: z.date(),
    status: z
        .object({
            label: z.string(),
            value: z.string()
        })
        .required()
})
export type ProjectSalFormSchema = z.infer<typeof ProjectSalFormValidationSchema>

export const adaptSalToForm = (data: Sal) => {
    return {
        name: data.name,
        amount: formatCurrency(data.amount, 'EUR'),
        deliveryDate: new Date(data.deliveryDate),
        status: {
            label: t(`project:sals:status:${data.status}`),
            value: data.status
        }
    }
}

export const adaptSalToUpdate = (data: ProjectSalFormSchema): HttpProjectSalMutationRequest => {
    return {
        name: data.name,
        amount: parseFloat(removeCurrencyFormat(data.amount)),
        deliveryDate: data.deliveryDate ? dayjs(data.deliveryDate).format('YYYY-MM') : '',
        status: data.status.value as SalStatus
    }
}

export const adaptSalToCreate = (data: ProjectSalFormSchema & {projectId: number}): HttpProjectSalMutationRequest => {
    return {
        projectId: data.projectId,
        name: data.name,
        amount: parseFloat(removeCurrencyFormat(data.amount)),
        deliveryDate: data.deliveryDate ? dayjs(data.deliveryDate).format('YYYY-MM') : '',
        status: data.status.value as SalStatus
    }
}
