import {ResponseType} from '@/types/commons'
import {z} from 'zod'
import {Customer} from '@/features/customer/types.ts'
import {ProjectEmployee, ProjectStatus, ProjectType, SyncStatus} from '@/features/project/types.ts'

export const InvoiceProject = z.object({
    id: z.coerce.number(),
    customerId: z.number(),
    floatExternalId: z.coerce.number().nullable(),
    name: z.string().optional(),
    status: ProjectStatus,
    type: ProjectType.optional(),
    endDate: z.string().nullable(),
    successManager: ProjectEmployee.optional(),
    teamLead: ProjectEmployee.optional(),
    budget: z.number(),
    hours: z.number(),
    updatedAt: z.string().nullable(),
    lastSyncAt: z.string().nullable(),
    syncStatus: SyncStatus.nullish(),
    salesFolder: z.string().url().or(z.literal('')).nullish(),
    operationFolder: z.string().url().or(z.literal('')).nullish()
})
export type InvoiceProject = z.infer<typeof InvoiceProject>

export const InvoiceStatus = z.enum(['expected', 'forecast', 'sent'])
export type InvoiceStatus = z.infer<typeof InvoiceStatus>

export const Invoice = z.object({
    id: z.number(),
    status: InvoiceStatus,
    date: z.string(),
    projectId: z.number(),
    project: InvoiceProject,
    customerId: z.number(),
    customer: Customer,
    paymentDate: z.string(),
    year: z.number().nullish(),
    number: z.number().nullish(),
    vatPayability: z.string().nullable(),
    internalSubject: z.string().nullable(),
    bankId: z.number().nullable(),
    ficExternalId: z.number().nullable(),
    details: z.array(
        z.object({
            vatId: z.number(),
            amount: z.number(),
            productName: z.string(),
            description: z.string()
        })
    ),
    ficViewUrl: z.string().nullish()
})
export type Invoice = z.infer<typeof Invoice>

export const InvoicesSearchParams = z.object({
    orderBy: z.enum(['date']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional(),
    showSent: z.union([z.literal('true'), z.literal('false')]).optional()
})
export type InvoicesSearchParams = z.infer<typeof InvoicesSearchParams>

export const HttpInvoicesParams = z.object({
    ...InvoicesSearchParams.shape,
    responseType: ResponseType,
    page: z.number().nullable()
})
export type HttpInvoicesParams = z.infer<typeof HttpInvoicesParams>
