import InputSelect, {SelectValue} from '@/components/commons/select/Select'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledProjectsFiltersForm, StyledProjectsFiltersMobileHeader} from './style'
import Button from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {zodResolver} from '@hookform/resolvers/zod'
import {PROJECTS_FILTERS_FORM_MODEL, ProjectsFiltersValidationSchema} from './utils'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {XCloseIcon} from '@/components/ui/icon'
import {ProjectsFiltersParams, ProjectStatus} from '@/features/project/types'

interface ProjectsFiltersFormProps {
    filtersUrlValues: ProjectsFiltersValidationSchema
    filtersOptions: ProjectsFiltersValidationSchema
    onSubmitFilters: (filters: ProjectsFiltersParams) => void
    onSearchCustomerNameCb?: (searchText: string) => void
    hideModal: () => void
}

export const ProjectsFiltersForm: FC<ProjectsFiltersFormProps> = ({
    filtersUrlValues,
    filtersOptions,
    onSubmitFilters,
    hideModal
}) => {
    const {t} = useTranslation()
    const {
        control,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm<ProjectsFiltersValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(ProjectsFiltersValidationSchema),
        defaultValues: {
            ...filtersUrlValues
        }
    })

    const onSubmit: SubmitHandler<ProjectsFiltersValidationSchema> = data => {
        onSubmitFilters({
            projectTypeIds: data?.type?.map(type => parseInt(type.value)),
            successManagerIds: data?.successManager?.map(successManager => parseInt(successManager.value)),
            teamLeadIds: data?.teamLead?.map(teamLead => parseInt(teamLead.value)),
            productLeadIds: data?.productLead?.map(productLead => parseInt(productLead.value)),
            status: data?.status?.map(status => status.value as ProjectStatus),
            customerIds: data?.customer?.map(customer => parseInt(customer.value))
        })
    }

    return (
        <StyledProjectsFiltersForm onSubmit={handleSubmit(onSubmit)}>
            <StyledProjectsFiltersMobileHeader justify="space-between" align="center">
                <h3>{t('commons:filter_label')}</h3>
                <Button size="lg" iconOnly variant="ghost" onClick={() => hideModal()}>
                    <XCloseIcon />
                </Button>
            </StyledProjectsFiltersMobileHeader>

            <Controller
                render={({field: {onChange, value}}) => (
                    <InputSelect
                        value={value}
                        onChange={newValue => {
                            onChange(newValue ? (newValue as SelectValue[]) : [])
                        }}
                        size={'medium'}
                        isMulti={true}
                        name={PROJECTS_FILTERS_FORM_MODEL.type.name}
                        label={t(PROJECTS_FILTERS_FORM_MODEL.type.label)}
                        placeholder={t(PROJECTS_FILTERS_FORM_MODEL.type.placeholder)}
                        options={filtersOptions.type}
                        isClearable={true}
                        isSearchable={true}
                        errorMessage={t(errors.type?.message || '')}
                    />
                )}
                control={control}
                name={PROJECTS_FILTERS_FORM_MODEL.type.name}
            />

            <Controller
                render={({field: {onChange, value}}) => (
                    <InputSelect
                        value={value}
                        options={filtersOptions.successManager}
                        onChange={newValue => {
                            onChange(newValue ? (newValue as SelectValue[]) : [])
                        }}
                        size={'medium'}
                        isMulti={true}
                        name={PROJECTS_FILTERS_FORM_MODEL.successManager.name}
                        label={t(PROJECTS_FILTERS_FORM_MODEL.successManager.label)}
                        isClearable={true}
                        isSearchable={true}
                        errorMessage={t(errors.successManager?.message || '')}
                        placeholder={t(PROJECTS_FILTERS_FORM_MODEL.successManager.placeholder)}
                    />
                )}
                control={control}
                name={PROJECTS_FILTERS_FORM_MODEL.successManager.name}
            />

            <Controller
                render={({field: {onChange, value}}) => (
                    <InputSelect
                        value={value}
                        options={filtersOptions.teamLead}
                        onChange={newValue => {
                            onChange(newValue ? (newValue as SelectValue[]) : [])
                        }}
                        size={'medium'}
                        isMulti={true}
                        name={PROJECTS_FILTERS_FORM_MODEL.teamLead.name}
                        label={t(PROJECTS_FILTERS_FORM_MODEL.teamLead.label)}
                        isClearable={true}
                        isSearchable={true}
                        errorMessage={t(errors.teamLead?.message || '')}
                        placeholder={t(PROJECTS_FILTERS_FORM_MODEL.teamLead.placeholder)}
                    />
                )}
                control={control}
                name={PROJECTS_FILTERS_FORM_MODEL.teamLead.name}
            />
            <Controller
                render={({field: {onChange, value}}) => (
                    <InputSelect
                        value={value}
                        options={filtersOptions.productLead}
                        onChange={newValue => {
                            onChange(newValue ? (newValue as SelectValue[]) : [])
                        }}
                        size={'medium'}
                        isMulti={true}
                        name={PROJECTS_FILTERS_FORM_MODEL.productLead.name}
                        label={t(PROJECTS_FILTERS_FORM_MODEL.productLead.label)}
                        isClearable={true}
                        isSearchable={true}
                        errorMessage={t(errors.productLead?.message || '')}
                        placeholder={t(PROJECTS_FILTERS_FORM_MODEL.productLead.placeholder)}
                    />
                )}
                control={control}
                name={PROJECTS_FILTERS_FORM_MODEL.productLead.name}
            />

            <Controller
                render={({field: {onChange, value}}) => (
                    <InputSelect
                        value={value}
                        options={filtersOptions.status}
                        onChange={newValue => {
                            onChange(newValue ? ([newValue] as SelectValue[]) : [])
                        }}
                        size={'medium'}
                        isMulti={false}
                        name={PROJECTS_FILTERS_FORM_MODEL.status.name}
                        label={`${t(PROJECTS_FILTERS_FORM_MODEL.status.label)}`}
                        isClearable={true}
                        isSearchable={true}
                        errorMessage={t(errors.status?.message || '')}
                        placeholder={t(PROJECTS_FILTERS_FORM_MODEL.status.placeholder)}
                    />
                )}
                control={control}
                name={PROJECTS_FILTERS_FORM_MODEL.status.name}
            />

            <Controller
                render={({field: {onChange, value}}) => (
                    <InputSelect
                        value={value}
                        options={filtersOptions.customer}
                        onChange={newValue => {
                            onChange(newValue ? (newValue as SelectValue[]) : [])
                        }}
                        size={'medium'}
                        isMulti={true}
                        name={PROJECTS_FILTERS_FORM_MODEL.customer.name}
                        label={t(PROJECTS_FILTERS_FORM_MODEL.customer.label)}
                        isClearable={true}
                        isSearchable={true}
                        errorMessage={t(errors.customer?.message || '')}
                        placeholder={t(PROJECTS_FILTERS_FORM_MODEL.customer.placeholder)}
                    />
                )}
                control={control}
                name={PROJECTS_FILTERS_FORM_MODEL.customer.name}
            />

            <Flexbox gap={4} justify="flex-end">
                <Button
                    variant={'secondary'}
                    onClick={() =>
                        reset({
                            type: [],
                            successManager: [],
                            teamLead: [],
                            productLead: [],
                            status: [],
                            customer: []
                        })
                    }
                >
                    {t('commons:restore')}
                </Button>
                <Button type="submit" variant={'primary'}>
                    {t('commons:apply')}
                </Button>
            </Flexbox>
        </StyledProjectsFiltersForm>
    )
}
