import React from 'react'
import CustomerForm from '@/features/customer/components/customer-form/CustomerForm'
import {PageLayout} from '@/layouts/page-layout/PageLayout'
import {useTranslation} from 'react-i18next'

export const NewCustomer: React.FC = () => {
    const {t} = useTranslation()

    return (
        <PageLayout title={t('customer:new_customer')}>
            <CustomerForm />
        </PageLayout>
    )
}

NewCustomer.displayName = 'NewCustomer'
