import {AlertCircleIcon, CheckCircleIcon} from 'src/components/ui/icon'
import {InputStatusIconWrapper} from '@components/ui/input-status-icon/style.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {useTheme} from 'styled-components'

interface InputStatusIconProps {
    hasError?: boolean
    touched?: boolean
    isLoading?: boolean
}
const InputStatusIcon = ({hasError, touched, isLoading}: InputStatusIconProps) => {
    const {palette} = useTheme()

    return (
        <InputStatusIconWrapper hasError={hasError}>
            {isLoading && <Spinner size={16} color={palette.neutral['600']} />}
            {hasError && !isLoading && <AlertCircleIcon size={16} data-color />}
            {touched && !hasError && !isLoading && <CheckCircleIcon size={16} data-color />}
        </InputStatusIconWrapper>
    )
}

export default InputStatusIcon

InputStatusIcon.displayName = 'InputStatusIcon'
