import styled, {css} from 'styled-components'
import Container from '@components/ui/container/Container.tsx'
import {NavLink} from 'react-router-dom'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

export const StyledContainer = styled(Container)`
    display: grid;
    grid-template-rows: auto auto 1fr;
`

export const StyledProjectTabWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto 1fr;
        gap: ${spacing * 10}px;
        height: 100%;
    `
)

export const StyledTabsWrapper = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        position: sticky;
        padding-top: ${spacing * 10}px;
        top: -${spacing * 10}px;
        background: rgb(255 255 255 / 90%);
        border-bottom: 1px solid ${palette.neutral[100]};
        backdrop-filter: blur(${spacing}px);
        z-index: 1;
    `
)

export const StyledTabContent = styled.div(
    ({theme: {spacing}}) => css`
        padding-top: ${spacing * 10}px;
    `
)

export const StyledNavLink = styled(NavLink)`
    ${({theme: {typography, palette}}) => css`
        height: 32px;
        ${typography.textSm};
        font-weight: 600;
        color: ${palette.neutral['500']};
        cursor: pointer;

        &.active {
            color: ${palette.primary['700']};
            border-bottom: 2px solid ${palette.primary['700']};
        }

        &:hover {
            color: ${palette.primary['700']};
        }
    `}
`
