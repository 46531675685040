import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetEmployeeDetails, HttpGetEmployeeDetailsParams} from '@/features/employee/services/employee.http.ts'
import {Employee} from '@/features/employee/types.ts'

export const useGetEmployeeDetails = (
    params: HttpGetEmployeeDetailsParams,
    options?: Omit<UseQueryOptions<Employee>, 'queryFn' | 'queryKey'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.EMPLOYEE_DETAILS, params.urlParams.employeeId],
        queryFn: () => httpGetEmployeeDetails(params)
    })
}
