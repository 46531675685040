import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledSectionTitle = styled.h3`
    ${({theme: {typography, palette}}) => css`
        ${typography.textMd}
        font-weight: bold;
        color: ${palette.neutral['900']};
        display: block;
    `}
`

export const StyledNameWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        width: 100%;
        max-width: 900px;
        margin-top: ${spacing * 4}px;
    `}
`

export const StyledInformationWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 4}px;
        ${mediaQueries.l} {
            grid-template-columns: repeat(2, 1fr);
        }
        ${mediaQueries.m} {
            grid-template-columns: repeat(1, 1fr);
        }
    `}
`

export const StyledBriefWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 4}px;
        max-width: 900px;
        width: 100%;
    `}
`
export const StyledSeparator = styled.div`
    ${({theme: {spacing, palette}}) => css`
        margin-top: ${spacing * 8}px;
        margin-bottom: ${spacing * 6}px;
        border-top: solid 1px ${palette.neutral['300']};
    `}
`

export const StyledManagementWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 4}px;
        ${mediaQueries.m} {
            grid-template-columns: repeat(1, 1fr);
        }
    `}
`

export const StyledAccountingWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(4, 1fr) 150px;
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 4}px;
        ${mediaQueries.l} {
            grid-template-columns: repeat(2, 1fr);
        }
        ${mediaQueries.m} {
            grid-template-columns: repeat(1, 1fr);
        }
    `}
`

export const StyledAgreementWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 4}px;
        max-width: 900px;
        width: 100%;
    `}
`

export const StyledActionsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin: ${spacing * 4}px 0;
        display: flex;
        justify-content: flex-end;
    `}
`

export const StyledFoldersWrapper = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: ${spacing * 4}px;
        ${mediaQueries.m} {
            grid-template-columns: repeat(1, 1fr);
        }
    `}
`
export const StyledMarkupIndicator = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        gap: ${spacing}px;
        flex-direction: column;
    `
)

export const StyledIndicatorMarkupValue = styled.div(
    ({theme: {palette, typography}}) => css`
        ${typography.textMd}
        cursor: not-allowed;
        color: ${palette.neutral['400']};
        backgroundcolor: ${palette.neutral[300]};
    `
)
