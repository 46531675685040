import {z} from 'zod'
import {PaginationParams, PaginationResponse, ResponseType} from '@/types/commons'
import axios from '@/axiosInstance'
import {ProjectCost, CostStatus} from '../types'

/**
 * httpGetProjectCosts
 * Here we will fetch all the costs data for the dash table
 */
// Params

export const HttpGetProjectCostsParams = z.object({
    projectId: z.coerce.number().int()
})
export type HttpGetProjectCostsParams = z.infer<typeof HttpGetProjectCostsParams>

export const HttpProjectCostsParams = z.object({
    ...HttpGetProjectCostsParams.shape,
    ...PaginationParams.shape,
    responseType: ResponseType.optional()
})
export type HttpProjectCostsParams = z.infer<typeof HttpProjectCostsParams>

// Response
export const HttpGetProjectCostsResponse = z.object({
    data: z.array(ProjectCost),
    meta: PaginationResponse.optional()
})
export type HttpGetProjectCostsResponse = z.infer<typeof HttpGetProjectCostsResponse>

// Http request
export const httpGetProjectCosts = async (params: HttpProjectCostsParams): Promise<HttpGetProjectCostsResponse> => {
    const response = await axios.get(`/projects/${params.projectId}/costs`, {params})
    return HttpGetProjectCostsResponse.parse(response)
}

// create and update
export const HttpProjectCostMutationRequest = z.object({
    description: z.string(),
    projectId: z.number().optional(),
    phaseId: z.number().nullable(),
    amount: z.number(),
    date: z.string().nullable(),
    status: CostStatus
})
export type HttpProjectCostMutationRequest = z.infer<typeof HttpProjectCostMutationRequest>

export const httpCreateCost = async (data: HttpProjectCostMutationRequest): Promise<ProjectCost> => {
    const response = await axios.post(`/costs`, data)
    return response.data
}

export const httpUpdateCost = async (id: number, data: HttpProjectCostMutationRequest): Promise<ProjectCost> => {
    const response = await axios.patch(`/costs/${id}`, data)
    return response.data
}

export const httpDeleteCost = async (id: number) => {
    return await axios.delete(`/costs/${id}`)
}
