import {Dispatch, FC, SetStateAction} from 'react'
import {zodResolver} from '@hookform/resolvers/zod'
import {
    COST_STATUS_OPTIONS,
    COST_FORM_MODEL,
    CostFormSchema,
    CostFormValidationSchema,
    adaptCostToBeSaved
} from '@/features/costs/utils'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import InputText from '@/components/commons/input-text/InputText'
import {useTranslation} from 'react-i18next'
import InputSelect, {SelectValue} from '@/components/commons/select/Select'
import DatePicker from '@/components/commons/date-picker/DatePicker'
import dayjs from 'dayjs'
import Divider from '@/components/ui/divider/Divider'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledCostForm, StyledCostModalHeader, StyledFormGridRow} from '@/features/costs/components/style'
import {useProjectPhasesQuery} from '@/features/project-phases/services/queries/useProjectPhases'
import {Cost} from '../types'
import {phasesSelectAdapter} from '@/utilities/adapters'
import Button from '@/components/ui/button/Button'
import {useUpdateProjectCost} from '@/features/project-costs/services/queries/useUpdateProjectCost'
import InputNumber from '@/components/commons/input-number/InputNumber'
import {CurrencyEuroIcon} from '@/components/ui/icon'
import {ProjectCostFormSchema} from '@/features/project-costs/utils'
import {errorHandler} from '@/utilities/genericErrorHandler'
import toast from 'react-hot-toast'

interface EditCostProps {
    selectedCost: Cost
    setSelectedCost: Dispatch<SetStateAction<Cost | null>>
    defaultValues: ProjectCostFormSchema
}

export const CostForm: FC<EditCostProps> = ({selectedCost, setSelectedCost, defaultValues}) => {
    const {t} = useTranslation()
    const {data} = useProjectPhasesQuery({projectId: selectedCost.projectId})
    const phasesOptions = data ? phasesSelectAdapter(data) : []

    const {
        control,
        register,
        handleSubmit,
        formState: {errors, touchedFields, isValid}
    } = useForm<CostFormSchema>({
        mode: 'onBlur',
        resolver: zodResolver(CostFormValidationSchema),
        defaultValues: {
            ...defaultValues
        }
    })

    const {mutate: editCostMutation, isPending} = useUpdateProjectCost({
        onSuccess: () => {
            toast.success(t(`commons:update_completed`, {entity: t(`costs:singular`)}))
        },
        onError: error => {
            errorHandler(error)
        }
    })

    const onSubmit: SubmitHandler<CostFormSchema> = data => {
        editCostMutation({id: selectedCost.id, data: adaptCostToBeSaved(data)})
        setSelectedCost(null)
    }

    return (
        <>
            <StyledCostModalHeader align="center" justify="space-between">
                <h3>{t('costs:edit_form:title')}</h3>
            </StyledCostModalHeader>
            <StyledCostForm onSubmit={handleSubmit(onSubmit)}>
                <Flexbox direction="column" gap={5}>
                    <StyledFormGridRow cols={2} gap={4}>
                        <InputText
                            label={`${t('costs:edit_form:description')} *`}
                            placeholder={`${t(COST_FORM_MODEL.description.label)}`}
                            type={'text'}
                            touched={touchedFields.description}
                            errorMessage={t(errors.description?.message || '')}
                            {...register(t(COST_FORM_MODEL.description.name))}
                        />

                        <Controller
                            control={control}
                            name={COST_FORM_MODEL.phase.name}
                            render={({field: {onChange, value}}) => (
                                <InputSelect
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    options={phasesOptions}
                                    size={'medium'}
                                    name={COST_FORM_MODEL.phase.name}
                                    label={`${t(COST_FORM_MODEL.phase.label)} *`}
                                    placeholder={t(COST_FORM_MODEL.phase.label)}
                                    isClearable={true}
                                    isSearchable={true}
                                    errorMessage={t(errors.phase?.message || '')}
                                />
                            )}
                        />
                    </StyledFormGridRow>

                    <Divider topSpacing={0} bottomSpacing={0} />

                    <StyledFormGridRow gap={2} cols={3}>
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <InputNumber
                                    decimalSeparator={'.'}
                                    value={value}
                                    thousandSeparator={','}
                                    decimalScale={2}
                                    onChange={onChange}
                                    allowNegative={false}
                                    typeIcon={<CurrencyEuroIcon size={16} />}
                                    label={`${t(COST_FORM_MODEL.amount.label)} *`}
                                    touched={touchedFields.amount}
                                    errorMessage={t(errors.amount?.message || '')}
                                    placeholder={`${t(COST_FORM_MODEL.amount.label)}`}
                                />
                            )}
                            control={control}
                            name={COST_FORM_MODEL.amount.name}
                        />

                        <Controller
                            control={control}
                            name={COST_FORM_MODEL.date.name}
                            render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                                <DatePicker
                                    toggle
                                    formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                                    numMonths={1}
                                    mode={'single'}
                                    currentDay={new Date()}
                                    selectedDates={value ? [value] : []}
                                    onDatesChange={dates => onChange(dates[0])}
                                    onBlur={onBlur}
                                    triggerProps={{
                                        label: `${t(COST_FORM_MODEL.date.label)} *`,
                                        placeholder: `${t(COST_FORM_MODEL.date.label)}`,
                                        errorMessage: t(error?.message || '')
                                    }}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name={COST_FORM_MODEL.status.name}
                            render={({field: {onChange, value}}) => (
                                <InputSelect
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={COST_FORM_MODEL.status.name}
                                    label={`${t(COST_FORM_MODEL.status.label)} *`}
                                    isClearable={true}
                                    errorMessage={t(errors.status?.message || '')}
                                    placeholder={t(COST_FORM_MODEL.status.label)}
                                    options={COST_STATUS_OPTIONS.map(item => ({
                                        value: item.value,
                                        label: t(item.label)
                                    }))}
                                />
                            )}
                        />
                    </StyledFormGridRow>
                </Flexbox>
                <Flexbox gap={2} justify={'end'}>
                    <Button width="wide" onClick={() => setSelectedCost(null)} variant={'tertiary'}>
                        <p>{t('new_employee:cancel')}</p>
                    </Button>
                    <Button width="wide" type="submit" variant="primary" disabled={!isValid || isPending}>
                        {t('costs:edit_form:save_btn')}
                    </Button>
                </Flexbox>
            </StyledCostForm>
        </>
    )
}
