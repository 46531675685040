import {phoneRegex} from '@/utilities/constants/common'
import {z} from 'zod'

export const CUSTOMER_FORM_MODEL = {
    vatNumber: {
        name: 'vatNumber',
        label: 'customer:details:vat_number'
    },
    fiscalCode: {
        name: 'fiscalCode',
        label: 'customer:details:fiscal_code'
    },
    sdi: {
        name: 'sdi',
        label: 'customer:details:sdi'
    },
    pec: {
        name: 'pec',
        label: 'customer:details:pec'
    },
    businessName: {
        name: 'businessName',
        label: 'customer:details:business_name'
    },
    ficExternalId: {
        name: 'ficExternalId',
        label: 'customer:details:fic_id'
    },
    floatExternalId: {
        name: 'floatExternalId',
        label: 'customer:details:float_id'
    },
    legalAddress: {
        name: 'legalAddress',
        label: 'customer:details:legal_address'
    },
    civicNumber: {
        name: 'civicNumber',
        label: 'customer:details:civic_no'
    },
    city: {
        name: 'city',
        label: 'customer:details:city'
    },
    zipCode: {
        name: 'zipCode',
        label: 'customer:details:zip_code'
    },
    province: {
        name: 'province',
        label: 'customer:details:province'
    },
    country: {
        name: 'country',
        label: 'customer:details:country'
    },
    successManager: {
        name: 'successManager',
        label: 'customer:details:success_manager'
    },
    fullnameAccounting: {
        name: 'fullnameAccounting',
        label: 'customer:details:accounting_contact'
    },
    email: {
        name: 'email',
        label: 'customer:details:email'
    },
    phoneNumber: {
        name: 'phoneNumber',
        label: 'customer:details:phone_number'
    }
} as const

const optionalTextInput = (schema: z.ZodString, message?: string) =>
    z.union([z.string(), z.undefined()]).refine(val => !val || schema.safeParse(val).success, {message: message})

export const CustomerFormSchema = z.object({
    vatNumber: z.string().nullable(),
    fiscalCode: z.string().nullable(),
    sdi: z.string().nullable(),
    pec: optionalTextInput(z.string().email(), 'errors:email_valid').nullable(),
    businessName: z.string().min(1, {message: 'errors:required'}),
    ficExternalId: z.coerce.string().nullable(),
    floatExternalId: z.coerce.string().nullable(),
    legalAddress: z.string().nullable(),
    civicNumber: z.string().nullable(),
    city: z.string().nullable(),
    zipCode: z.string().nullable(),
    province: z.string().nullable(),
    country: z.string().nullable(),
    successManager: z
        .object({
            value: z.string(),
            label: z.string()
        })
        .nullish(),
    fullnameAccounting: z.string().nullable(),
    email: optionalTextInput(z.string().email({message: 'errors:email_valid'}), 'errors:email_valid').nullable(),
    phoneNumber: optionalTextInput(z.string().regex(phoneRegex), 'errors:phone_number').nullable()
})

export type CustomerValidationSchema = z.infer<typeof CustomerFormSchema>
