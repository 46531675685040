import {SelectValue} from '@/components/commons/select/Select'
import TableSelect from '@/components/commons/table/TableSelect/TableSelect'
import {FC, useEffect, useMemo, useState} from 'react'
import {useUpdatePhaseServiceLine} from '../../services/queries/useUpdatePhaseServiceLine'
import {errorHandler} from '@/utilities/genericErrorHandler'
import Spinner from '@/components/ui/spinner/Spinner'
import {useTheme} from 'styled-components'
import {PhaseServiceLine} from '../../types'
import {PHASE_SERVICE_LINE} from './utils'
import {useTranslation} from 'react-i18next'

interface SelectServiceLineProps {
    phaseId: number
    inputServiceLine: PhaseServiceLine | null
}

export const SelectServiceLine: FC<SelectServiceLineProps> = ({phaseId, inputServiceLine}) => {
    const theme = useTheme()
    const [serviceLine, setServiceLine] = useState(inputServiceLine)
    const {t} = useTranslation()

    const {mutate: updatePhaseServiceLine, isPending} = useUpdatePhaseServiceLine({
        onError: error => {
            errorHandler(error)
            setServiceLine(inputServiceLine)
        }
    })

    const onUpdateServiceLine = (selectValue: SelectValue, phaseId: number | null) => {
        if (!phaseId) return null

        setServiceLine(selectValue.value as PhaseServiceLine)
        updatePhaseServiceLine({
            phaseId: phaseId,
            data: {
                serviceLine: selectValue.value as PhaseServiceLine
            }
        })
    }

    useEffect(() => {
        setServiceLine(inputServiceLine)
    }, [inputServiceLine])

    const selectedValue = useMemo(
        () => PHASE_SERVICE_LINE.find(item => (item?.value ?? '') === serviceLine),
        [serviceLine]
    )

    return !isPending ? (
        <TableSelect
            value={{value: selectedValue?.value ?? '', label: t(selectedValue?.label ?? t('commons:select'))}}
            onChange={selectValue => onUpdateServiceLine(selectValue as SelectValue, phaseId)}
            options={PHASE_SERVICE_LINE.map(item => ({...item, label: t(item.label)}))}
        />
    ) : (
        <Spinner size={16} color={theme.palette.neutral[500]} />
    )
}
