import {useInfiniteQuery} from '@tanstack/react-query'
import {QUERY_KEYS, infiniteQueryFetchNextPage} from '@/queryClient'
import {HttpSalsParams, httpGetSals} from '@/features/sal/services/sal.http'

export const useSalsQuery = (params: Omit<HttpSalsParams, 'page'>) => {
    const query = useInfiniteQuery({
        queryKey: [
            QUERY_KEYS.SALS,
            params.orderBy,
            params.orderDirection,
            params.search,
            params.teamLeadIds,
            params.status
        ],
        queryFn: ({pageParam = 1}) =>
            httpGetSals({
                ...params,
                page: pageParam
            }),
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData
    }
}
