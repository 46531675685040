import styled, {css} from 'styled-components'

export const StyledSecondaryDateWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: ${spacing * 4}px;
    `}
`

export const StyledPrimaryDateWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: ${spacing * 4}px;
    `}
`

export const StyledFormWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        gap: ${spacing * 8}px;
        display: grid;
        padding: ${spacing * 2}px ${spacing}px ${spacing * 2}px ${spacing}px;
    `}
`
