import {QUERY_KEYS, infiniteQueryFetchNextPage} from '@/queryClient'
import {useInfiniteQuery} from '@tanstack/react-query'
import {HttpProjectCostsParams, httpGetProjectCosts} from '@/features/project-costs/services/projectCost.http'
import {ResponseType} from '@/types/commons'

export const useProjectCostsQuery = (params: Omit<HttpProjectCostsParams, 'page' | 'limit'>) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.PROJECT_COSTS, params.projectId],
        queryFn: () =>
            httpGetProjectCosts({
                ...params,
                responseType: ResponseType.Enum.extended
            }),
        initialPageParam: 1,
        getNextPageParam: ({meta}) => meta && (meta.current_page > meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        data: remappedData
    }
}
