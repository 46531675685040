import * as RProgress from '@radix-ui/react-progress'
import styled, {DefaultTheme, css} from 'styled-components'

export const StyledProgressRoot = styled(RProgress.Root)(
    ({theme: {palette}}) => css`
        position: relative;
        overflow: hidden;
        background-color: ${palette.secondary[100]};
        border-radius: 16px;
        height: 8px;
        width: 100%;
    `
)

type StyledProgressIndicatorProps = {
    variant: 'primary' | 'secondary' | 'danger'
} & RProgress.ProgressIndicatorProps

export const StyledProgressIndicator = styled(RProgress.Indicator)<StyledProgressIndicatorProps>`
    ${({theme: {palette}, variant}) => css`
        background-color: ${variant === 'secondary' ? palette.secondary[300] : palette.secondary[400]};
        height: 100%;
        border-radius: 16px;
        position: absolute;
        transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
    `}
    ${({variant, theme}) => ColorStyles(theme)[variant]};
`

const ColorStyles = ({palette}: DefaultTheme) => ({
    primary: css`
        background-color: ${palette.secondary[400]};
    `,
    secondary: css`
        background-color: ${palette.secondary[300]};
    `,
    danger: css`
        background-color: ${palette.danger[500]};
    `
})

export const StyledProgressLabel = styled.label(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 500;
        color: ${palette.secondary[700]};
    `
)

export const StyledProgressValues = styled.div(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        font-weight: 700;
        color: ${palette.secondary[700]};
    `
)

export const StyledProgressPercentage = styled.div(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        font-weight: 700;
        color: ${palette.secondary[700]};
    `
)
