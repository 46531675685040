import React from 'react'
import ProjectForm from '@/features/project/components/project-form/ProjectForm'
import {PageLayout} from '@/layouts/page-layout/PageLayout'
import {useTranslation} from 'react-i18next'
import {useInternalDailyRate} from '@/features/project/services/queries/useInternalDailyRate'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {AlertTriangleIcon} from '@/components/ui/icon'
import {ProjectSectionLoader} from '@/features/project/components/project-form/ProjectSectionLoader'
import {ProjectStatus} from '@/features/project/types'
import {useProjectTypesQuery} from '@/features/project/services/queries/useProjectTypes'

export const NewProject: React.FC = () => {
    const {t} = useTranslation()
    const {internalDailyRate, isError, isPending} = useInternalDailyRate()
    const {remappedData} = useProjectTypesQuery()
    const defaultType = remappedData.find(item => item.name === 'standard')

    if (!internalDailyRate || isPending) {
        return <ProjectSectionLoader />
    }

    if (isError || !internalDailyRate) {
        return <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
    }

    const defaultValues = defaultType
        ? {
              type: {
                  label: t('project:types:standard'),
                  value: defaultType.id.toString()
              },
              status: {
                  label: t('project:status:active'),
                  value: ProjectStatus.Enum.active
              }
          }
        : {}

    return (
        <PageLayout title={t('project:new_project')}>
            <ProjectForm internalDailyRate={internalDailyRate} defaultValues={defaultValues} />
        </PageLayout>
    )
}

NewProject.displayName = 'NewProject'
