import {PaginationResponse} from '@/types/commons'
import {z} from 'zod'
import {Customer} from '@/features/customer/types.ts'

// type of project
export const ProjectType = z.object({
    id: z.coerce.number(),
    name: z.string(),
    isActive: z.boolean(),
    isBillable: z.boolean(),
})
export type ProjectType = z.infer<typeof ProjectType>

// customer schema
export const ProjectCustomer = z.object({
    id: z.coerce.number(),
    businessName: z.string()
})
export type ProjectCustomer = z.infer<typeof ProjectCustomer>

// employee schema
export const ProjectEmployee = z.object({
    id: z.coerce.number(),
    fullName: z.string()
})
export type ProjectEmployee = z.infer<typeof ProjectEmployee>

// project status
export const ProjectStatus = z.enum(['active', 'completed'])
export type ProjectStatus = z.infer<typeof ProjectStatus>

// project sync status
export const SyncStatus = z.enum(['queued', 'syncing', 'completed', 'failed'])

export type SyncStatus = z.infer<typeof SyncStatus>

// amount in hours and minutes

// project schema for dash table
export const Project = z.object({
    id: z.coerce.number(),
    floatExternalId: z.number().nullable(),
    name: z.string(),
    status: ProjectStatus,
    typeId: z.number(),
    endDate: z.string().nullable(),
    successManagerId: z.number(),
    teamLeadId: z.number(),
    productLeadId: z.number(),
    customerId: z.number(),
    budget: z.number(),
    hours: z.number(),
    updatedAt: z.string().nullable(),
    lastSyncAt: z.string().nullable(),
    syncStatus: SyncStatus.nullable(),
    salesFolder: z.string().url().or(z.literal('')).nullable(),
    operationFolder: z.string().url().or(z.literal('')).nullable()
})
export type Project = z.infer<typeof Project>

// project schema for details page
export const ProjectDetails = z.object({
    id: z.coerce.number(),
    floatExternalId: z.number().nullable(),
    name: z.string(),
    status: ProjectStatus,
    type: ProjectType,
    endDate: z.string(),
    successManager: ProjectEmployee,
    teamLead: ProjectEmployee,
    productLead: ProjectEmployee,
    customer: Customer,
    budget: z.number(),
    hours: z.number(),
    dailyRate: z.number(),
    masterAgreements: z.string().nullable(),
    salesFolder: z.string().url().nullable(),
    operationFolder: z.string().url().nullable(),
    updatedAt: z.string().nullable(),
    lastSyncAt: z.string().nullable(),
    startDate: z.string(),
    brief: z.string().nullable(),
    syncStatus: SyncStatus.nullable(),
    loggedAmount: z.coerce.number(),
    scheduledAmount: z.coerce.number(),
    allocationPhasesAmount: z.coerce.number(),
    futureSchedulesAmount: z.coerce.number(),
    floatUrl: z.string().url().nullable(),
    needWorkProgressStatuses: z.boolean().nullable()
})
export type ProjectDetails = z.infer<typeof ProjectDetails>

// project filters schema
export const ProjectsFiltersParams = z.object({
    projectTypeIds: z.number().array().optional(),
    successManagerIds: z.number().array().optional(),
    teamLeadIds: z.number().array().optional(),
    productLeadIds: z.number().array().optional(),
    status: z.string().array().optional(),
    customerIds: z.number().array().optional()
})
export type ProjectsFiltersParams = z.infer<typeof ProjectsFiltersParams>

// project search params schema
export const ProjectsSearchParams = z.object({
    orderBy: z
        .enum([
            'name',
            'type',
            'budget',
            'usedHours',
            'successManager',
            'teamLead',
            'productLead',
            'endDate',
            'lastSyncAt',
            'status'
        ])
        .optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional(),
    projectTypeIds: z.coerce.number().array().optional(),
    successManagerIds: z.coerce.number().array().optional(),
    teamLeadIds: z.coerce.number().array().optional(),
    productLeadIds: z.coerce.number().array().optional(),
    status: z.string().array().optional(),
    customerIds: z.coerce.number().array().optional(),
    needWorkProgressStatus: z.string().optional()
})
export type ProjectsSearchParams = z.infer<typeof ProjectsSearchParams>

// Socket sync status event
export const SyncStatusEvent = z.object({
    project_id: z.number(),
    sync_status: SyncStatus
})
export type SyncStatusEvent = z.infer<typeof SyncStatusEvent>

// Cached projects list query
const CachedProjectDetails = z.object({
    pageParams: z.number().array(),
    pages: z
        .object({
            data: ProjectDetails.array(),
            meta: PaginationResponse
        })
        .array()
})
export type CachedProjectDetails = z.infer<typeof CachedProjectDetails>
