import Table, {TableSorter} from '@/components/commons/table/Table'
import {Dispatch, FC, ReactElement, SetStateAction, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {Link, useNavigate} from 'react-router-dom'
import {Customer, CustomersSearchParams} from '@/features/customer/types'
import {TableDataCell} from '@/components/commons/table/TableDataCell/TableDataCell'
import 'dayjs/locale/en'
import {
    DotsVerticalIcon,
    Edit01Icon,
    Link03Icon,
    LinkBroken01Icon,
    PlusIcon,
    SearchMdIcon,
    Trash01Icon
} from '@/components/ui/icon'
import {Chip} from '@/components/ui/chip/Chip'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import DropdownMenu from '@/components/ui/dropdown-menu/DropdownMenu'

interface CustomersTableProps {
    data: Customer[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    searchValue?: string
    emptySearchStateComponent?: ReactElement
    sorter?: TableSorter<CustomersSearchParams['orderBy']> | undefined
    onChangePage?: () => void
    onResetSearchCb?: () => void
    setDeleteCustomer: Dispatch<SetStateAction<Customer | null>>
}

export const CustomersTable: FC<CustomersTableProps> = ({
    data,
    isLoading,
    sorter,
    searchValue,
    setDeleteCustomer,
    onChangePage,
    onResetSearchCb
}) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])
    return (
        <Table
            onChangePage={onChangePage}
            isMobile={isMobile}
            data={data}
            isLoading={isLoading}
            sorter={sorter}
            searchValue={searchValue}
            columns={[
                {
                    label: t('customer:table:labels:business_name'),
                    width: '23%',
                    sortName: 'businessName',
                    cellRender: customer => (
                        <TableDataCell isBold={true} size="sm">
                            <Link to={`/customers/${customer.id}`}>{customer.businessName}</Link>
                        </TableDataCell>
                    )
                },
                {
                    label: t('customer:table:labels:success_manager'),
                    width: '13%',
                    cellRender: customer => (
                        <TableDataCell title={customer.successManager?.fullName ?? '-'}>
                            <p>{customer.successManager?.fullName ?? '-'}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('customer:table:labels:accounting_contact'),
                    width: '13%',
                    cellRender: customer => (
                        <TableDataCell title={customer.fullnameAccounting ?? '-'}>
                            <p>{customer.fullnameAccounting ?? '-'}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('customer:table:labels:email'),
                    width: '15%',
                    cellRender: customer => (
                        <TableDataCell title={customer.email ?? '-'}>
                            <p>{customer.email ?? '-'}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('customer:table:labels:vat_number'),
                    width: '13%',
                    cellRender: customer => (
                        <TableDataCell title={customer.vatNumber ?? '-'}>
                            <p>{customer.vatNumber ?? '-'}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('customer:table:labels:fic_id'),
                    width: '9%',
                    overflow: 'visible',
                    cellRender: customer => (
                        <TableDataCell size="xs">
                            <Chip
                                id={customer.ficExternalId ? customer.ficExternalId.toString() : 'unlinked'}
                                label={
                                    customer.ficExternalId
                                        ? t('customer:ficStatus:linked')
                                        : t('customer:ficStatus:unlinked')
                                }
                                variant={customer.ficExternalId ? 'success' : 'warning'}
                                icon={
                                    customer.ficExternalId ? <Link03Icon size={12} /> : <LinkBroken01Icon size={12} />
                                }
                                size={12}
                            />
                        </TableDataCell>
                    )
                },
                {
                    label: t('customer:table:labels:float_id'),
                    width: '9%',
                    overflow: 'visible',
                    cellRender: customer => (
                        <TableDataCell size="xs">
                            <Chip
                                id={customer.floatExternalId ? customer.floatExternalId.toString() : 'unlinked'}
                                label={
                                    customer.floatExternalId
                                        ? t('customer:floatStatus:linked')
                                        : t('customer:floatStatus:unlinked')
                                }
                                variant={customer.floatExternalId ? 'success' : 'warning'}
                                icon={
                                    customer.floatExternalId ? <Link03Icon size={12} /> : <LinkBroken01Icon size={12} />
                                }
                                size={12}
                            />
                        </TableDataCell>
                    )
                },
                {
                    label: ' ',
                    width: '4%',
                    overflow: 'visible',
                    alignment: 'center',
                    cellRender: customer => (
                        <TableDataCell>
                            <DropdownMenu
                                sideOffset={8}
                                trigger={<DotsVerticalIcon />}
                                actions={[
                                    {
                                        component: 'Edit',
                                        icon: <Edit01Icon size={16} />,
                                        onClickCb: () => navigate(`/customers/${customer.id}`)
                                    },
                                    {
                                        component: 'Delete',
                                        icon: <Trash01Icon size={16} />,
                                        onClickCb: () => {
                                            setDeleteCustomer(customer)
                                        }
                                    }
                                ]}
                            />
                        </TableDataCell>
                    )
                }
            ]}
            emptyStateComponent={
                <EmptyResults
                    title={t('customer:table:empty:title')}
                    subtitle={t('customer:table:empty:subtitle')}
                    cta={{
                        path: '/new-customer',
                        text: t('customer:new_customer'),
                        icon: <PlusIcon />
                    }}
                />
            }
            emptySearchStateComponent={
                <EmptySearch
                    title={t('commons:no_results')}
                    onClearSearch={() => onResetSearchCb && onResetSearchCb()}
                    icon={<SearchMdIcon />}
                />
            }
        />
    )
}
