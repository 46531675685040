import axios from '@/axiosInstance'
import {z} from 'zod'
import {Sal, SalStatus, SalsSearchParams} from '@/features/project-sals/types'
import {ResponseType} from '@/types/commons'

/**
 * httpGetSals
 * Here we will fetch all the sals data for the dash table
 */

// Params
export const HttpGetProjectSalsUrlParams = z.object({
    projectId: z.coerce.number().int().nullable()
})
export type HttpGetProjectSalsUrlParams = z.infer<typeof HttpGetProjectSalsUrlParams>

export const HttpSalsParams = z.object({
    ...HttpGetProjectSalsUrlParams.shape,
    ...SalsSearchParams.shape,
    responseType: ResponseType
})
export type HttpSalsParams = z.infer<typeof HttpSalsParams>

// Response
export const SalsResponse = z.object({
    id: z.coerce.number().nullable(),
    projectId: z.coerce.number(),
    name: z.string(),
    amount: z.number(),
    deliveryDate: z.string(),
    status: z.string(),
    project: z.object({
        id: z.number(),
        name: z.string(),
        dailyRate: z.number(),
        budget: z.number(),
        customer: z.object({
            id: z.number(),
            business_name: z.string()
        }),
        teamLead: z.object({
            id: z.number(),
            full_name: z.string()
        }),
        productLead: z.object({
            id: z.number(),
            full_name: z.string()
        })
    }),
    futureSchedulesAmount: z.number().optional(),
    totalFutureSchedulesAmount: z.number().optional(),
    logsAmount: z.number().optional(),
    totalLogsAmount: z.number().optional(),
    currentAmount: z.number().optional()
})
export type SalsResponse = z.infer<typeof SalsResponse>

export const HttpSalsResponse = z.object({
    data: z.array(SalsResponse)
})
export type HttpSalsResponse = z.infer<typeof HttpSalsResponse>

// Http request
export const httpGetSals = async (params: HttpSalsParams): Promise<HttpSalsResponse> => {
    const response = await axios.get(`/projects/${params.projectId}/work-progress-statuses`, {params})
    return HttpSalsResponse.parse(response)
}

// create and update
export const HttpProjectSalMutationRequest = z.object({
    projectId: z.number().optional(),
    name: z.string(),
    amount: z.number(),
    deliveryDate: z.string(),
    status: SalStatus
})
export type HttpProjectSalMutationRequest = z.infer<typeof HttpProjectSalMutationRequest>

export const httpCreateSal = async (data: HttpProjectSalMutationRequest): Promise<Sal> => {
    const response = await axios.post(`/work-progress-statuses`, data)
    return response.data
}

export const httpUpdateSal = async (id: number, data: HttpProjectSalMutationRequest): Promise<Sal> => {
    const response = await axios.patch(`/work-progress-statuses/${id}`, data)
    return response.data
}

export const httpDeleteSal = async (id: number) => {
    return await axios.delete(`/work-progress-statuses/${id}`)
}
