import styled, {css} from 'styled-components'

export const StyledPageHeaderContainer = styled.div`
    display: grid;
    align-items: start;
    grid-template-columns: 1fr auto;
`

export const StyledPageHeaderText = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        & h1 {
            color: ${palette.neutral['900']};
            ${typography.displaySm};
            font-weight: 600;
            margin-bottom: ${spacing}px;
        }
        & h2 {
            color: ${palette.neutral['900']};
            ${typography.displaySm};
            ${typography.textXl}
        }
        & p {
            color: ${palette.neutral['600']};
            ${typography.textMd}
        }
    `}
`
