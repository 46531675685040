import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@/components/ui/modal/Modal'
import {StyledInvoicedModal, StyledTitle} from './style'
import {InvoiceValidationSchema} from './InvoiceFormModel'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useGetBanks} from '@/features/bank/services/query/useGetBank.ts'
import {useVatQuery} from '@/features/vat/services/queries/useVat.ts'
import {useGetRevenueDetails} from '@/features/invoice/services/queries/useGetInvoiceDetails.ts'
import {adaptInvoiceDuplicateForm} from '@/features/project-revenues/utils.ts'
import {InvoiceForm} from '@/features/project-revenues/components/invoice-modal/InvoiceForm.tsx'
import {ResponseType} from '@/types/commons.ts'
import {SelectValue} from '@components/commons/select/Select.tsx'
import {InvoicesFormLoadingSkeleton} from '@/features/project-revenues/components/invoice-modal/InvoiceFormSkeleton.tsx'

interface InvoiceModalProps {
    onClose: () => void
    isDuplicate?: boolean
    invoiceId: number
}

export const EditDuplicateInvoiceModal: React.FC<InvoiceModalProps> = ({onClose, invoiceId, isDuplicate}) => {
    const {t} = useTranslation()
    const [formData, setFormData] = useState<InvoiceValidationSchema>()

    const {data: invoiceDetails} = useGetRevenueDetails({
        invoiceId: invoiceId,
        params: {
            responseType: ResponseType.Enum.extended
        }
    })
    const {data: banks, isPending: isPendingBanks} = useGetBanks()
    const {data: vats, isPending: isPendingVats} = useVatQuery()

    useEffect(() => {
        if (banks && vats && invoiceDetails) {
            const newFormData = adaptInvoiceDuplicateForm({
                invoice: invoiceDetails,
                banks: banksOptions,
                vats: vatsOptions
            })
            setFormData(newFormData)
        }
    }, [banks, vats, invoiceDetails])

    const banksOptions = useMemo<SelectValue[]>(
        () => (banks ? banks.map(item => ({value: item.id.toString(), label: item.name}) as SelectValue) : []),
        [banks]
    )
    const vatsOptions = useMemo<SelectValue[]>(
        () =>
            vats
                ? vats.map(
                      item =>
                          ({
                              value: item.id.toString(),
                              label: `${item.value}%  ${item.description ? item.description : ''}`
                          }) as SelectValue
                  )
                : [],
        [vats]
    )
    return (
        <Modal width={1000} closeIconOffset="sm" onClose={onClose}>
            <StyledInvoicedModal>
                <Flexbox align={'center'}>
                    {!isDuplicate ? (
                        <StyledTitle>{t('invoice:details:update_title')}</StyledTitle>
                    ) : (
                        <StyledTitle>{t('invoice:details:add_title')}</StyledTitle>
                    )}
                </Flexbox>

                {(isPendingBanks && isPendingVats) || !formData || !invoiceDetails ? (
                    <InvoicesFormLoadingSkeleton />
                ) : (
                    <InvoiceForm
                        onClose={onClose}
                        invoiceDefaultValues={formData}
                        invoiceId={invoiceId}
                        isDuplicate={isDuplicate}
                        formOptions={{banks: banksOptions, vats: vatsOptions}}
                        projectInvoiceData={{
                            projectId: invoiceDetails.projectId,
                            customerId: invoiceDetails.customerId
                        }}
                    />
                )}
            </StyledInvoicedModal>
        </Modal>
    )
}

export default EditDuplicateInvoiceModal
