import axios from '@/axiosInstance'
import {PaginationResponse, PaginationParams, ResponseType} from '@/types/commons'
import {SalsSearchParams} from '@/features/sal/types'
import {z} from 'zod'
import {SalsResponse} from '@/features/project-sals/services/projectSal.http'

/**
 * httpGetSals
 * Here we will fetch all the sals data for the dash table
 */

// Params schema
export const HttpSalsParams = z.object({
    ...SalsSearchParams.shape,
    ...PaginationParams.shape,
    responseType: ResponseType
})
export type HttpSalsParams = z.infer<typeof HttpSalsParams>

// Response schema
export const HttpSalsResponse = z.object({
    data: z.array(SalsResponse),
    meta: PaginationResponse
})
export type HttpSalsResponse = z.infer<typeof HttpSalsResponse>

// Http request
export const httpGetSals = async (params: HttpSalsParams): Promise<HttpSalsResponse> => {
    const response = await axios.get(`/work-progress-statuses`, {params})
    return HttpSalsResponse.parse(response.data)
}

export const HttpSalsAnalyzeParams = z.object({
    accessToken: z.string(),
    tokenType: z.string(),
    scope: z.string()
})
export type HttpSalsAnalyzeParams = z.infer<typeof HttpSalsAnalyzeParams>

export const HttpSalsAnalyzeResponse = z.object({
    spreadsheetId: z.string(),
    spreadsheetUrl: z.string()
})
export type HttpSalsAnalyzeResponse = z.infer<typeof HttpSalsAnalyzeResponse>

export const httpSalsAnalyze = async (params: HttpSalsAnalyzeParams): Promise<HttpSalsAnalyzeResponse> => {
    const response = await axios.post(`/work-progress-statuses/analytic`, params)
    return HttpSalsAnalyzeResponse.parse(response.data)
}
