import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {ChangeEvent, useEffect} from 'react'

interface MyOnChangePluginProps {
    onChange: (event: string | ChangeEvent<Element> | null) => void
}

export function MyOnChangePlugin({onChange}: MyOnChangePluginProps) {
    const [editor] = useLexicalComposerContext()
    useEffect(() => {
        return editor.registerUpdateListener(({editorState}) => {
            onChange(JSON.stringify(editorState))
        })
    }, [editor, onChange])
    return null
}
