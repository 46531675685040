import {z} from 'zod'

export const CostStatus = z.enum(['received', 'expected'])
export type CostStatus = z.infer<typeof CostStatus>

export const Cost = z.object({
    id: z.number(),
    status: CostStatus,
    description: z.string(),
    projectId: z.number(),
    project: z.object({
        id: z.number(),
        name: z.string()
    }),
    phaseId: z.number().nullable(),
    phase: z
        .object({
            name: z.string()
        })
        .nullable(),
    amount: z.number(),
    date: z.string(),
    updatedAt: z.string().nullable(),
    deletedAt: z.string().nullable(),
    createdAt: z.string()
})
export type Cost = z.infer<typeof Cost>
