import Skeleton from '@components/ui/skeleton/Skeleton.tsx'

export const ProjectSectionLoader = () => {
    return (
        <>
            <Skeleton height={30} width={200} />
            <Skeleton height={20} />

            <Skeleton height={213} />
            <Skeleton height={213} />

            <Skeleton height={30} width={200} />
            <Skeleton height={20} />
        </>
    )
}

ProjectSectionLoader.displayName = 'ProjectSectionLoader'
