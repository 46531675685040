import MobileTHead from '@components/commons/table/MobileTHead'
import {TableProps} from '@components/commons/table/Table.tsx'
import MobileTBody from '@components/commons/table/MobileTBody'
import {StyledMobileTable} from '@components/commons/table/MobileTable/style.ts'

const MobileTable = <TData extends unknown[], TSortName extends string | undefined>({
    columns,
    data,
    isError,
    expandedRows,
    isChangingPage,
    isLoading,
    onChangePage,
    sorter,
    subRow
}: TableProps<TData, TSortName>) => {
    return (
        <StyledMobileTable isSorter={!!sorter}>
            {sorter && <MobileTHead columns={columns} sorter={sorter} />}
            <MobileTBody
                columns={columns}
                data={data}
                isError={isError}
                expandedRows={expandedRows}
                isChangingPage={isChangingPage}
                isLoading={isLoading}
                onChangePage={onChangePage}
                subRow={subRow}
            />
        </StyledMobileTable>
    )
}

MobileTable.displayName = 'MobileTable'
export default MobileTable
