import {PaginationParams, PaginationResponse, ResponseType} from '@/types/commons'
import {z} from 'zod'
import {Team} from '../types'
import axios from '@/axiosInstance'

/**
 * httpGetTeam
 * Here we will get Teams data
 */

export const HttpGetTeamResponse = z.object({
    meta: PaginationResponse,
    data: z.array(Team)
})
export type HttpGetTeamResponse = z.infer<typeof HttpGetTeamResponse>

export const HttpTeamsParams = z.object({
    teamId: z.number(),
    ...PaginationParams.shape,
    responseType: ResponseType
})
export type HttpTeamsParams = z.infer<typeof HttpTeamsParams>

export const httpGetTeam = async (): Promise<HttpGetTeamResponse> => {
    const response = await axios.get('/teams')
    return HttpGetTeamResponse.parse(response.data)
}
