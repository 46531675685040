import {EmployeeValidationSchema} from '@/features/employee/components/employees-form/EmployeeFormModel.ts'
import {HttpEditRequest, HttpEmployeeRequest} from '@/features/employee/services/employee.http.ts'
import dayjs from 'dayjs'
import i18next from 'i18next'
import {Employee} from '@/features/employee/types.ts'

export const EMPLOYEE_STATUSES = [
    {
        value: '1',
        label: 'employee:status:active'
    },
    {
        value: '0',
        label: 'employee:status:disabled'
    }
]

export const EMPLOYEE_ROLES = [
    {
        value: 'employee',
        label: 'employee:roles:employee'
    },
    {
        value: 'admin',
        label: 'employee:roles:admin'
    },
    {
        value: 'team_lead',
        label: 'employee:roles:team_lead'
    },
    {
        value: 'success_manager',
        label: 'employee:roles:success_manager'
    },
    {
        value: 'accounting',
        label: 'employee:roles:accounting'
    }
]

export const EMPLOYEE_TYPE = [
    {
        value: 'full_time',
        label: 'employee:type:full_time'
    },
    {
        value: 'part_time',
        label: 'employee:type:part_time'
    },
    {
        value: 'external_fixed',
        label: 'employee:type:external_fixed'
    },
    {
        value: 'external',
        label: 'employee:type:external'
    }
]
export const EMPLOYEE_ACCESS = [
    {
        value: '1',
        label: 'employee:access:yes'
    },
    {
        value: '0',
        label: 'employee:access:no'
    }
]

export const composeEmployeeFormData = (employee: Employee): EmployeeValidationSchema => {
    return {
        fullName: employee.fullName,
        email: employee.email,
        team: {
            label: employee.team?.name || '',
            value: employee.team?.id.toString() || ''
        },
        status: {
            label: employee.isActive ? 'Active' : 'Disabled',
            value: employee.isActive ? '1' : '0'
        },
        role: {
            label: i18next.t(`employee:roles:${employee.role}` || null),
            value: employee.role
        },
        scope: {
            label: i18next.t(`scopes:${employee.scope?.name}` || null),
            value: employee.scope?.id.toString() || ''
        },
        type: {
            label: i18next.t(`employee:type:${employee.employeeType}`),
            value: employee.employeeType
        },
        weeklyHours: employee.weeklyHours,
        access: {
            label: employee.canAccess ? 'Yes' : 'No',
            value: employee.canAccess ? '1' : '0'
        },
        floatId: employee.floatExternalId || undefined,
        startDate: dayjs(employee.startDate).toDate(),
        endDate: employee.endDate ? dayjs(employee.endDate).toDate() : undefined
    }
}

export const composeNewEmployeeObject = (formData: EmployeeValidationSchema) => {
    const result: HttpEmployeeRequest = {
        fullName: formData.fullName,
        email: formData.email,
        teamId: +formData.team.value,
        isActive: !!parseInt(formData.status.value),
        role: formData.role.value,
        scopeId: +formData.scope.value,
        employeeType: formData.type.value,
        weeklyHours: formData.weeklyHours,
        canAccess: !!parseInt(formData.access.value),
        floatExternalId: formData.floatId || undefined,
        startDate: dayjs(formData.startDate).format('YYYY-MM-DD'),
        endDate: formData.endDate ? dayjs(formData.endDate).format('YYYY-MM-DD') : undefined
    }
    return HttpEmployeeRequest.parse(result)
}
export const composeEditEmployeeObject = (formData: EmployeeValidationSchema, employeeId: number) => {
    const result: HttpEditRequest = {
        data: {
            fullName: formData.fullName,
            email: formData.email,
            teamId: +formData.team.value,
            isActive: !!parseInt(formData.status.value),
            role: formData.role.value,
            scopeId: +formData.scope.value,
            employeeType: formData.type.value,
            weeklyHours: formData.weeklyHours,
            canAccess: !!parseInt(formData.access.value),
            floatExternalId: formData.floatId || undefined,
            startDate: dayjs(formData.startDate).format('YYYY-MM-DD'),
            endDate: formData.endDate ? dayjs(formData.endDate).format('YYYY-MM-DD') : undefined
        },
        employeeId: employeeId
    }
    return HttpEditRequest.parse(result)
}
