import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpDeleteCustomer} from '../customer.http'
import toast from 'react-hot-toast'
import i18next from 'i18next'

export const useDeleteCustomer = () => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_CUSTOMER],
        mutationFn: (id: number) => httpDeleteCustomer(id),
        onSuccess: () => {
            toast.success(i18next.t('customer:delete_customer'))
            client.invalidateQueries({queryKey: [QUERY_KEYS.CUSTOMERS]})
        }
    })
}
