import Container from '@/components/ui/container/Container'
import styled, {css} from 'styled-components'

export const StyledPageContainer = styled(Container)(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto 1fr;
        padding-bottom: ${spacing * 4}px;
        gap: ${spacing * 10}px;
    `
)
