import {envVars} from '@/envVars.ts'
import {io} from 'socket.io-client'

export const projectSocket = io(`${envVars.VITE_API_ENDPOINT}`, {
    autoConnect: false,
    transports: ['websocket']
})

export const projectSocketEvents = {
    SYNC_PROJECT: 'syncProject'
} as const
