import {PaginationParams, ResponseType} from '@/types/commons'
import {z} from 'zod'

export const CostsSearchParams = z.object({
    orderBy: z.enum(['date']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional(),
    received: z.string().optional()
})
export type CostsSearchParams = z.infer<typeof CostsSearchParams>

export const HttpCostsParams = z.object({
    ...CostsSearchParams.shape,
    ...PaginationParams.shape,
    responseType: ResponseType
})
export type HttpCostsParams = z.infer<typeof HttpCostsParams>
