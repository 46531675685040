import {ReactNode} from 'react'
import {StyledPageHeaderContainer, StyledPageHeaderText} from '@components/commons/page-hero/style.ts'
import {PageTitle} from '@/components/ui/page-title/PageTitle'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

interface PageHeaderProps {
    title: string
    subtitle?: string | null
    sideTitle?: ReactNode | string | null
    sideComponent?: ReactNode
}

const PageHero = ({title, subtitle, sideComponent, sideTitle}: PageHeaderProps) => {
    return (
        <StyledPageHeaderContainer>
            <StyledPageHeaderText>
                <Flexbox align={'center'} gap={2}>
                    <PageTitle text={title} />
                    {sideTitle && <h2>{sideTitle}</h2>}
                </Flexbox>
                {subtitle && <p>{subtitle}</p>}
            </StyledPageHeaderText>
            {sideComponent && <div>{sideComponent}</div>}
        </StyledPageHeaderContainer>
    )
}

export default PageHero

PageHero.displayName = 'PageHero'
