import Table, {TableSorter} from '@/components/commons/table/Table'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {Dispatch, FC, SetStateAction, useMemo} from 'react'
import {Sal, SalStatus, SalsSearchParams} from '@/features/project-sals/types'
import {useTranslation} from 'react-i18next'
import {TableDataCell} from '@/components/commons/table/TableDataCell/TableDataCell'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import Button from '@/components/ui/button/Button'
import {DotsVerticalIcon, Edit01Icon, PlusIcon, Trash01Icon} from '@/components/ui/icon'
import dayjs from 'dayjs'
import {Chip} from '@/components/ui/chip/Chip'
import {SalsResponse} from '../../services/projectSal.http'
import DropdownMenu from '@/components/ui/dropdown-menu/DropdownMenu'
import {SAL_STATUSES} from '@/features/sal/utils'
import {ProjectDetails} from '@/features/project/types'
import {SalAmountCell} from '@/features/sal/components/sals-table/sals-table-cells/SalAmountCell'
import {SalHoursLoggedCell} from '@/features/sal/components/sals-table/sals-table-cells/SalHoursLoggedCell'
import {SalImpactCell} from '@/features/sal/components/sals-table/sals-table-cells/SalImpactCell'

interface SalsTableProps {
    data: SalsResponse[]
    isError: boolean
    isLoading: boolean
    onClickAddSal: () => void
    setSalToUpdate: Dispatch<SetStateAction<Sal | null>>
    setSalToDelete: Dispatch<SetStateAction<Sal | null>>
    project: ProjectDetails
    sorter?: TableSorter<SalsSearchParams['orderBy']> | undefined
}

export const ProjectSalsTable: FC<SalsTableProps> = ({
    data,
    isLoading,
    isError,
    onClickAddSal,
    setSalToUpdate,
    setSalToDelete,
    project,
    sorter
}) => {
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])
    const {t} = useTranslation()

    return (
        <Table
            isMobile={isMobile}
            data={data}
            isLoading={isLoading}
            isError={isError}
            sorter={sorter}
            columns={[
                {
                    label: t('project:sals:table:labels:name'),
                    width: '19.25%',
                    sortName: 'name',
                    cellRender: sal => (
                        <TableDataCell isBold={!sal.id} className={!sal.id ? 'fill-row' : ''}>
                            <p title={sal.name}>{sal.name}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('project:sals:table:labels:amount'),
                    width: '14.25%',
                    sortName: 'amount',
                    cellRender: sal => <SalAmountCell sal={sal} budget={project.budget} />
                },
                {
                    label: t('project:sals:table:labels:hours_logged'),
                    width: '19.25%',
                    cellRender: sal => <SalHoursLoggedCell sal={sal} dailyRate={project.dailyRate} />
                },
                {
                    label: t('project:sals:table:labels:impact'),
                    width: '14.25%',
                    cellRender: sal => <SalImpactCell sal={sal} dailyRate={project.dailyRate} />
                },
                {
                    label: t('project:sals:table:labels:deliveryDate'),
                    width: '14.25%',
                    sortName: 'deliveryDate',
                    cellRender: sal => (
                        <TableDataCell title={dayjs(sal.deliveryDate).format('MMMM YYYY')}>
                            <p>{dayjs(sal.deliveryDate).format('MMMM YYYY')}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('project:sals:table:labels:status'),
                    width: '14.25%',
                    cellRender: sal => {
                        const label = t(SAL_STATUSES.find(item => item.value === sal.status)?.label ?? '')
                        return (
                            <TableDataCell title={label}>
                                <Chip
                                    id={sal.status.toString()}
                                    label={label}
                                    variant={sal.status === SalStatus.Enum.scheduled ? 'warning' : 'success'}
                                    size={12}
                                    title={label}
                                />
                            </TableDataCell>
                        )
                    }
                },
                {
                    label: ' ',
                    width: '4.25%',
                    alignment: 'right',
                    cellRender: sal => (
                        <DropdownMenu
                            sideOffset={8}
                            trigger={<DotsVerticalIcon />}
                            actions={[
                                {
                                    component: t('commons:edit'),
                                    icon: <Edit01Icon size={16} />,
                                    onClickCb: () => setSalToUpdate(sal)
                                },
                                {
                                    component: t('commons:delete'),
                                    icon: <Trash01Icon size={16} />,
                                    onClickCb: () => {
                                        setSalToDelete(sal)
                                    }
                                }
                            ]}
                        />
                    )
                }
            ]}
            emptyStateComponent={
                <EmptyResults
                    title={t('project:sals:table:empty:title')}
                    subtitle={t('project:sals:table:empty:subtitle')}
                >
                    {
                        <Button variant="primary" onClick={onClickAddSal}>
                            <PlusIcon />
                            {t('project:sals:table:empty:cta')}
                        </Button>
                    }
                </EmptyResults>
            }
        />
    )
}
