import {z} from 'zod'

export const INVOICE_FORM_MODEL = {
    // TODO: remove decommented fields when FIC is ready
    status: {
        name: 'status',
        label: 'invoice:details:status'
    },
    date: {
        name: 'date',
        label: 'invoice:details:date'
    },
    vatPayability: {
        name: 'vatPayability',
        label: 'invoice:details:vat_payability'
    },
    internalSubject: {
        name: 'internalSubject',
        label: 'invoice:details:internal_subject'
    },
    // bank: {
    //     name: 'bank',
    //     label: 'invoice:details:bank'
    // },
    ficExternalId: {
        name: 'ficExternalId',
        label: 'invoice:details:fic_id'
    },
    total: {
        name: 'total',
        label: 'invoice:details:total'
    },
    totalVatIncluded: {
        name: 'subtotal',
        label: 'invoice:details:totalVatIncluded'
    },
    paymentDate: {
        name: 'paymentDate',
        label: 'invoice:details:payment_date'
    },
    rows: {
        vat: {
            name: 'vat',
            label: 'invoice:details:rows:vat'
        },
        amount: {
            name: 'amount',
            label: 'invoice:details:rows:amount'
        },
        subtotal: {
            name: 'amount',
            label: 'invoice:details:rows:subtotal'
        },
        productName: {
            name: 'productName',
            label: 'invoice:details:rows:product_name'
        },
        description: {
            name: 'description',
            label: 'invoice:details:rows:description'
        }
    }
} as const

export const InvoiceFormSchema = z.object({
    status: z.object({
        label: z.string(),
        value: z.string()
    }),
    date: z.date(),
    vatPayability: z.object({
        label: z.string(),
        value: z.string()
    }),
    internalSubject: z.string().min(1, {message: 'errors:required'}),
    // bank: z.object({
    //     label: z.string(),
    //     value: z.string()
    // }),
    ficExternalId: z.coerce.number().nullable(),
    paymentDate: z.date(),
    rows: z.array(
        z.object({
            vat: z.object({
                label: z.string(),
                value: z.string()
            }),
            amount: z.string().min(1, {message: 'errors:required'}),
            productName: z.string().min(1, {message: 'errors:required'}),
            description: z.string().min(1, {message: 'errors:required'})
        })
    )
})

export type InvoiceValidationSchema = z.infer<typeof InvoiceFormSchema>
