import styled, {css, DefaultTheme} from 'styled-components'
import {Link} from 'react-router-dom'

type StyledButtonLinkProps = {
    $disabled: boolean
    $width: 'fullWidth' | 'wide' | 'auto'
    $iconOnly: boolean
    $variant: 'primary' | 'secondary' | 'tertiary' | 'ghost'
    $size: 'sm' | 'md' | 'lg' | 'xl'
    $padding: 0 | 1
}

const iconWidthBySize = {
    sm: 36,
    md: 40,
    lg: 44,
    xl: 60
} as const

type IconWidth = keyof typeof iconWidthBySize

export const StyledButtonLink = styled(Link)<StyledButtonLinkProps>`
    ${({theme: {spacing}, $padding}) => css`
        display: flex;
        justify-content: center;
        align-items: ${$padding ? 'center' : 'inherit'};
        gap: ${spacing * 2}px;
        border: none;
        border-radius: 8px;
        transition: ease-in-out 200ms;
        cursor: pointer;
        font-weight: 600;
        word-break: break-word;
        white-space: nowrap;
        & svg {
            width: 20px;
        }
    `}
    ${({$variant, $disabled, theme}) => ColorStyles({theme, $disabled})[$variant]};
    ${({$size, $iconOnly, $padding, theme}) => SizeStyles({theme: theme, $iconOnly, $padding})[$size]};
    ${({$size, $iconOnly, $width}) => WidthStyles({$iconOnly, $size})[$width]};
`

type ColorsProps = {
    theme: DefaultTheme
    $disabled: boolean
}

const ColorStyles = ({theme: {palette, shadows}, $disabled}: ColorsProps) => ({
    primary: css`
        color: ${palette.neutral.white};
        background: ${$disabled ? palette.primary['200'] : palette.primary['600']};
        border: 1px solid ${$disabled ? palette.primary['200'] : palette.primary['600']};
        box-shadow: ${shadows.xs};
        pointer-events: ${$disabled ? 'none' : 'auto'};
        ${!$disabled &&
        `
                &:hover {
                    background: ${palette.primary['700']};
                    border: 1px solid ${palette.primary['700']};
                }
            `}
        &:focus {
            box-shadow: ${!$disabled && `${shadows.xs}, 0px 0px 0px 4px ${palette.neutral['100']}`};
        }
    `,
    secondary: css`
        background: ${$disabled ? palette.neutral[200] : palette.neutral.white};
        color: ${$disabled ? palette.neutral['300'] : palette.neutral['700']};
        border: 1px solid ${palette.neutral['300']};
        box-shadow: ${shadows.xs};
        pointer-events: ${$disabled ? 'none' : 'auto'};
        ${!$disabled &&
        `
                &:hover {
                    background: ${palette.neutral['50']};
                }
            `}
        &:focus {
            box-shadow: ${$disabled ? 'none' : `${shadows.xs}, 0px 0px 0px 4px ${palette.neutral['100']}`};
        }
    `,
    ghost: css`
        background: ${$disabled ? palette.neutral.white : 'transparent'};
        color: ${$disabled ? palette.neutral['300'] : palette.neutral['700']};
        pointer-events: ${$disabled ? 'none' : 'auto'};
        ${!$disabled &&
        `
                &:hover {
                    background: ${palette.neutral['50']};
                }
            `}
    `,
    tertiary: css`
        background: ${$disabled ? palette.neutral.white : 'transparent'};
        color: ${$disabled ? palette.neutral['300'] : palette.neutral['600']};
        font-weight: 500;
        text-decoration: underline;
        pointer-events: ${$disabled ? 'none' : 'auto'};
    `
})

type SizesProps = {
    $iconOnly: boolean
    $padding: 0 | 1
    theme: DefaultTheme
}

const SizeStyles = ({theme: {spacing, typography}, $padding, $iconOnly}: SizesProps) => ({
    sm: css`
        height: 36px;
        ${!$iconOnly &&
        css`
            padding: 0 ${spacing * 3.5 * $padding}px;
        `};
        ${typography.textSm}
    `,
    md: css`
        height: 40px;
        ${!$iconOnly &&
        css`
            padding: 0 ${spacing * 4 * $padding}px;
        `};
        ${typography.textSm}
    `,
    lg: css`
        height: 44px;
        ${$iconOnly &&
        css`
            width: 44px;
        `}
        ${!$iconOnly &&
        css`
            padding: 0 ${spacing * 4.5 * $padding}px;
        `};
        ${typography.textMd}
    `,
    xl: css`
        height: 60px;
        ${!$iconOnly &&
        css`
            padding: 0 ${spacing * 7 * $padding}px;
        `};
        ${typography.textLg}
    `
})

type WidthStylesProps = {
    $iconOnly?: boolean
    $size: IconWidth
}

const WidthStyles = ({$iconOnly, $size}: WidthStylesProps) => ({
    fullWidth: css`
        width: ${$iconOnly ? `${iconWidthBySize[$size]}` : '100%'};
    `,
    wide: css`
        width: ${$iconOnly ? `${iconWidthBySize[$size]}` : '150px'};
    `,
    auto: css`
        width: ${$iconOnly ? `${iconWidthBySize[$size]}` : 'auto'};
    `
})
