import {t} from 'i18next'
import {SelectValue} from '@/components/commons/select/Select'
import {HttpEmployeesResponse} from '@/features/employee/services/employee.http'
import {HttpPhasesResponse} from '@/features/project-phases/services/phase.http'
import {HttpProjectTypesResponse} from '@/features/project/services/project.http'
import {HttpCustomersResponse} from '@/features/customer/services/customer.http.ts'
import {HttpGetTeamResponse} from '@/features/teams/services/teams.http.ts'

const phasesDefaultValue = '0' //phases default value, selectValue does not accept string | undefined
export const phasesSelectAdapter = (response: HttpPhasesResponse): SelectValue[] =>
    response.data.map(item => ({value: item.id?.toString() ?? phasesDefaultValue, label: item.name}))

export const employeesSelectAdapter = (response: HttpEmployeesResponse): SelectValue[] =>
    response.data.map(item => ({value: item.id.toString(), label: item.fullName}))

export const teamsSelectAdapter = (response: HttpGetTeamResponse): SelectValue[] =>
    response.data.map(item => ({value: item.id.toString(), label: item.name}))

export const customersSelectAdapter = (response: HttpCustomersResponse): SelectValue[] =>
    response.data.map(item => ({value: item.id.toString(), label: item.businessName}))

export const projectTypesSelectAdapter = (response: HttpProjectTypesResponse): SelectValue[] =>
    response.data.map(item => ({value: item.id.toString(), label: t(`project:types:${item.name}`)}))
