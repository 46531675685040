import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {httpEditEmployee} from '@/features/employee/services/employee.http.ts'
import {errorHandler} from '@utilities/genericErrorHandler.ts'
import toast from 'react-hot-toast'
import {Employee} from '@/features/employee/types.ts'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons.ts'
import {useTranslation} from 'react-i18next'

type UseUpdateEmployeeProps = {
    onSuccess?: (data: Employee) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}
export const useUpdateEmployee = (options?: UseUpdateEmployeeProps) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_EMPLOYEE],
        mutationFn: httpEditEmployee,
        onSuccess: employee => {
            toast.success(t('commons:update_completed', {entity: t('employee:singular')}))
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EMPLOYEE_DETAILS, employee.id]})
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EMPLOYEES]})
            options?.onSuccess?.(employee)
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            errorHandler(error)
            options?.onError?.(error)
        }
    })
}
