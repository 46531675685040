import styled, {css} from 'styled-components'
import {Flexbox} from '../flexbox/FlexBox'
import * as RSwitch from '@radix-ui/react-switch'

export const StyledSwitchWrapper = styled(Flexbox)(
    ({theme: {typography}}) => css`
        white-space: nowrap;
        label {
            font-weight: 600;
            ${typography.textSm};
        }
    `
)

export const StyledSwitchRoot = styled(RSwitch.Root)(
    ({theme: {palette}}) => css`
        width: 44px;
        height: 24px;
        background-color: ${palette.neutral[100]};
        border-radius: 48px;
        position: relative;
        transition: background-color 100ms;
        will-change: background-color;
        cursor: pointer;
        &[data-state='checked'] {
            background-color: ${palette.tertiary[600]};
        }
    `
)

export const StyledSwitchThumb = styled(RSwitch.Thumb)`
    display: block;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 100%;
    transition: transform 100ms;
    transform: translateX(2px);
    will-change: transform;
    box-shadow: 0px 1px 3px 0px #1018281a;
    &[data-state='checked'] {
        transform: translateX(22px);
    }
`
