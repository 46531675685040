import React, {FC} from 'react'
import {
    CalendarWrapper,
    StyledBackButton,
    StyledCalendarHeader,
    StyledMonthYearButton,
    StyledYearMonthGrid
} from './style.ts'
import {DPPropGetter, DPYear} from '@rehookify/datepicker'
import dayjs from '@utilities/dayjs.ts'
import {ChevronLeftIcon, ChevronRightIcon} from 'src/components/ui/icon'

type YearSelectorProps = {
    years: DPYear[]
    yearButton: (year: DPYear) => DPPropGetter
    nextYearsButton: () => DPPropGetter
    previousYearsButton: () => DPPropGetter
    currentYear: string
    locale?: string
    setViewState: React.Dispatch<React.SetStateAction<'month' | 'year' | ''>>
}
const YearSelector: FC<YearSelectorProps> = ({
    years,
    yearButton,
    nextYearsButton,
    previousYearsButton,
    currentYear,
    setViewState,
    locale = 'en'
}) => {
    return (
        <CalendarWrapper>
            <StyledCalendarHeader>
                <button className={'prevButton yearButton'} {...previousYearsButton()}>
                    <ChevronLeftIcon size={20} />
                </button>
                Years
                <button className={'nextButton yearButton'} {...nextYearsButton()}>
                    <ChevronRightIcon size={20} />
                </button>
            </StyledCalendarHeader>
            <StyledYearMonthGrid>
                {years.map(y => {
                    const button = yearButton(y)
                    return (
                        <StyledMonthYearButton
                            key={y.year}
                            variant={y.year.toString() === currentYear ? 'primary' : 'ghost'}
                            {...button}
                            onClick={e => {
                                button.onClick?.(e)
                                setViewState('month')
                            }}
                        >
                            {dayjs(y.$date).locale(locale).format('YYYY')}
                        </StyledMonthYearButton>
                    )
                })}
            </StyledYearMonthGrid>
            <StyledBackButton variant="ghost" onClick={() => setViewState('month')}>
                Months
            </StyledBackButton>
        </CalendarWrapper>
    )
}

export default YearSelector
