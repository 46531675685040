import React from 'react'
import Skeleton from '@components/ui/skeleton/Skeleton.tsx'
import Divider from '@components/ui/divider/Divider.tsx'
import {
    StyledPrimarySkeleton,
    StyledSecondaryKeleton,
    StyledSkeletonWrapper
} from '@/features/employee/components/employees-form/employee-form-loading-skeleton/style.ts'

const EmployeeFormLoadingSkeleton: React.FC = () => {
    return (
        <div>
            <Divider topSpacing={0} bottomSpacing={40} />
            <StyledSkeletonWrapper>
                <StyledPrimarySkeleton>
                    <div>
                        <Skeleton height={33} width={88} />
                        <Skeleton height={44} />
                    </div>
                    <div>
                        <Skeleton height={33} width={88} />
                        <Skeleton height={44} />
                    </div>
                    <div>
                        <Skeleton height={33} width={88} />
                        <Skeleton height={44} />
                    </div>
                    <div>
                        <Skeleton height={33} width={88} />
                        <Skeleton height={44} />
                    </div>
                </StyledPrimarySkeleton>
                <StyledSecondaryKeleton>
                    <div>
                        <Skeleton height={33} width={88} />
                        <Skeleton height={44} />
                    </div>
                    <div>
                        <Skeleton height={33} width={88} />
                        <Skeleton height={44} />
                    </div>
                    <div>
                        <Skeleton height={33} width={88} />
                        <Skeleton height={44} />
                    </div>
                    <div>
                        <Skeleton height={33} width={88} />
                        <Skeleton height={44} />
                    </div>
                    <div>
                        <Skeleton height={33} width={88} />
                        <Skeleton height={44} />
                    </div>
                    <div>
                        <Skeleton height={33} width={88} />
                        <Skeleton height={44} />
                    </div>
                    <div>
                        <Skeleton height={33} width={88} />
                        <Skeleton height={44} />
                    </div>
                    <div>
                        <Skeleton height={33} width={88} />
                        <Skeleton height={44} />
                    </div>
                </StyledSecondaryKeleton>
            </StyledSkeletonWrapper>
            <Divider topSpacing={40} bottomSpacing={40} />
        </div>
    )
}

export default EmployeeFormLoadingSkeleton
