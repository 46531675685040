import Button from '@/components/ui/button/Button'
import styled, {css} from 'styled-components'

export const RangeInputWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing}px;
    `}
`

export const StyledInput = styled.input`
    ${({theme: {palette, spacing}}) => css`
        background-color: ${palette.neutral.white};
        padding: ${spacing}px;
        border-radius: 4px;
        border: 1px solid ${palette.neutral[400]};
    `}
`

export const MultiInputWrapper = styled.div`
    ${({theme: {palette, spacing}}) => css`
        display: flex;
        min-height: 40px;
        min-width: 160px;
        flex-wrap: wrap;
        gap: ${spacing}px;
        padding: ${spacing}px;
        background-color: ${palette.neutral.white};
        border-radius: 4px;

        .dateChip {
            padding: ${spacing}px;
            display: grid;
            place-items: center;
            border: 1px solid ${palette.neutral['400']};
            border-radius: 4px;
        }
    `}
`

export const StyledContainer = styled.div`
    display: inline-block;
`

export const CalendarsWrapper = styled.div`
    ${({theme: {spacing, mediaQueries, palette}}) => css`
        display: flex;
        flex-direction: row;

        border-bottom: 1px solid ${palette.neutral['200']};

        ${mediaQueries.m} {
            flex-direction: column;
            gap: ${spacing * 2}px;
        }
    `}
`

export const CalendarWrapper = styled.div`
    ${({theme: {spacing, palette, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 6}px;
        padding: ${spacing * 5}px;
        height: 392px;
        width: 320px;

        &:not(:last-child) {
            border-right: 1px solid ${palette.neutral['200']};
            ${mediaQueries.m} {
                border-right: unset;
            }
        }
    `}
`
export const StyledCalendarHeader = styled.div`
    ${({theme: {palette}}) => css`
        display: grid;
        place-items: center;
        position: relative;
        width: 100%;
        font-weight: 800;

        font-size: 18px;
        line-height: 24px;
        color: ${palette.neutral.black};

        .clickableText {
            cursor: pointer;
        }

        .clickableText:hover {
            color: ${palette.primary['600']};
        }

        .dayButton {
            width: 40px;
        }

        .yearButton {
            width: 90px;
        }
        .prevButton {
            left: 0;
            position: absolute;
            display: grid;
            place-items: center;
            cursor: pointer;
        }

        .nextButton {
            right: 0;
            position: absolute;
            display: grid;
            place-items: center;
            cursor: pointer;
        }
    `}
`

export const StyledYearMonthGrid = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 80px);
        row-gap: ${spacing * 5}px;
        column-gap: ${spacing * 5}px;
    `}
`

export const StyledMonthYearButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        cursor: pointer;
        padding: ${spacing * 2}px;
        text-align: center;
    `}
`

export const StyledBackButton = styled(Button)`
    ${({theme: {palette}}) => css`
        color: ${palette.primary['600']};
        margin-left: auto;
        margin-top: auto;
    `}
`
