import axios from '@/axiosInstance'
import {z} from 'zod'
import {PaginationResponse, ResponseType} from '@/types/commons.ts'
import {Customer} from '../types'
import {CustomersSearchParams} from '@/features/customer/types'

/**
 * httpGetCustomers
 * Here we will fetch all the customers data for the dash table
 */

// Params schema
export const HttpCustomersParams = z.object({
    ...CustomersSearchParams.shape,
    responseType: ResponseType,
    page: z.number().nullable(),
    limit: z.number().nullish()
})
export type HttpCustomersParams = z.infer<typeof HttpCustomersParams>

// Response
export const HttpCustomersResponse = z.object({
    data: z.array(Customer),
    meta: PaginationResponse
})
export type HttpCustomersResponse = z.infer<typeof HttpCustomersResponse>

// Http request
export const httpGetCustomers = async (params: HttpCustomersParams): Promise<HttpCustomersResponse> => {
    const response = await axios.get(`/customers`, {params})
    return HttpCustomersResponse.parse(response.data)
}

/**
 * httpGetCustomerDetails
 * Here we will get Customer details data
 */

// Params
export const HttpGetCustomerDetailsParams = z.object({
    customerId: z.coerce.number(),
    responseType: ResponseType
})
export type HttpGetCustomerDetailsParams = z.infer<typeof HttpGetCustomerDetailsParams>

// Response
export const HttpGetCustomerDetailsResponse = z.object({
    id: z.number(),
    floatExternalId: z.coerce.string().nullable(),
    ficExternalId: z.coerce.string().nullable(),
    vatNumber: z.string().nullable(),
    fiscalCode: z.string().nullable(),
    sdi: z.string().nullable(),
    pec: z.string().email().nullable(),
    businessName: z.string(),
    legalAddress: z.string().nullable(),
    civicNumber: z.string().nullable(),
    city: z.string().nullable(),
    zipCode: z.string().nullable(),
    province: z.string().nullable(),
    country: z.string().nullable(),
    fullnameAccounting: z.string().nullable(),
    email: z.string().email().nullable(),
    phoneNumber: z.string().nullable(),
    successManagerId: z.number().nullable(),
    successManager: z
        .object({
            id: z.coerce.number(),
            fullName: z.string()
        })
        .nullish()
})
export type HttpGetCustomerDetailsResponse = z.infer<typeof HttpGetCustomerDetailsResponse>

// Http request
export const httpGetCustomerDetails = async (
    params: HttpGetCustomerDetailsParams
): Promise<HttpGetCustomerDetailsResponse> => {
    const response = await axios.get(`/customers/${params.customerId}`, {
        params: {
            responseType: params.responseType
        }
    })
    return HttpGetCustomerDetailsResponse.parse(response.data)
}

/**
 * httpCreateCustomer
 * Here we will create a new customer instance
 */
export const HttpCustomerBodyRequest = z.object({
    floatExternalId: z.number().nullish(),
    ficExternalId: z.number().nullish(),
    vatNumber: z.string().nullish(),
    fiscalCode: z.string().nullish(),
    sdi: z.string().nullish(),
    pec: z.string().email().nullish(),
    businessName: z.string(),
    legalAddress: z.string().nullish(),
    civicNumber: z.string().nullish(),
    city: z.string().nullish(),
    zipCode: z.string().nullish(),
    province: z.string().nullish(),
    country: z.string().nullish(),
    fullnameAccounting: z.string().nullish(),
    email: z.string().email().nullish(),
    phoneNumber: z.string().nullish(),
    successManagerId: z.number().nullish()
})
export type HttpCustomerBodyRequest = z.infer<typeof HttpCustomerBodyRequest>

export const HttpCreateCustomerParams = z.object({
    data: HttpCustomerBodyRequest
})
export type HttpCreateCustomerParams = z.infer<typeof HttpCreateCustomerParams>

export const httpCreateCustomer = async (params: HttpCreateCustomerParams): Promise<HttpGetCustomerDetailsResponse> => {
    const response = await axios.post(`/customers`, {
        ...params.data
    })
    return response.data
}

/**
 * httpUpdateCustomer
 * Here we will update a customer instance
 */
export const HttpUpdateCustomerParams = z.object({
    customerId: z.coerce.number(),
    data: HttpCustomerBodyRequest
})
export type HttpUpdateCustomerParams = z.infer<typeof HttpUpdateCustomerParams>

export const httpUpdateCustomer = async (params: HttpUpdateCustomerParams) => {
    const response = await axios.patch(`/customers/${params.customerId}`, {...params.data})
    return HttpGetCustomerDetailsResponse.parse(response.data)
}

/**
 * httpDeleteCustomer
 * Here we will delete a customer instance
 */

export const httpDeleteCustomer = async (id: number): Promise<void> => {
    await axios.delete(`/customers/${id}`)
}

/**
 * httpGetCustomerFicId
 * Here we will get Customer ficId
 */

export const HttpGetCustomerFicIdResponse = z.object({
    id: z.number(),
    name: z.string(),
    taxCode: z.string(),
    vatNumber: z.string()
})
export type HttpGetCustomerFicIdResponse = z.infer<typeof HttpGetCustomerFicIdResponse>
export const HttpGetCustomerFicIdParams = z.object({
    type: z.enum(['vatNumber', 'fiscalCode']),
    value: z.string()
})
export type HttpGetCustomerFicIdParams = z.infer<typeof HttpGetCustomerFicIdParams>

export const httpGetCustomerFicId = async (
    params: HttpGetCustomerFicIdParams
): Promise<HttpGetCustomerFicIdResponse> => {
    const response = await axios.get('/fatture-in-cloud/clients/search', {params})
    return HttpGetCustomerFicIdResponse.parse(response.data)
}
