import {Outlet} from 'react-router-dom'
import {StyledMain, StyledPrivateLayout} from './styles'
import React from 'react'
import Header from '@components/commons/header/Header.tsx'

const ITEMS = [
    {name: 'Projects', path: '/projects'},
    {name: 'Recap SAL', path: '/sal?orderBy=deliveryDate&orderDirection=asc'},
    {name: 'Customers', path: '/customers'},
    {name: 'Invoices', path: '/invoices'},
    {name: 'Costs', path: '/costs'},
    {name: 'Employees', path: '/employees'}
]
type PrivateLayoutProps = {
    withHeader?: boolean
}
const PrivateLayout: React.FC<PrivateLayoutProps> = ({withHeader}) => {
    return (
        <StyledPrivateLayout>
            {withHeader && <Header items={ITEMS} />}
            <StyledMain>
                <Outlet />
            </StyledMain>
        </StyledPrivateLayout>
    )
}

export default PrivateLayout
