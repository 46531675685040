import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {ErrorResponseData} from '@/types/commons'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {httpCreateProject} from '@/features/project/services/project.http'
import {ProjectDetails} from '../../types'

type CreateProjectProps = {
    onSuccess?: (newProject: ProjectDetails) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useCreateProject = ({onError, onSuccess}: CreateProjectProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_PROJECT],
        mutationFn: httpCreateProject,
        onSuccess: newProject => {
            onSuccess?.(newProject)
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PROJECTS]})
            return newProject
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            onError?.(error)
        }
    })
}
