import {StyledIconContainer} from '@components/ui/icon-container/style.ts'
import {FC, ReactNode} from 'react'

interface IconContainerProps {
    children: ReactNode
    size?: 'md' | 'lg'
}
export const IconContainer: FC<IconContainerProps> = ({children, size = 'md'}) => {
    return <StyledIconContainer size={size}>{children}</StyledIconContainer>
}

IconContainer.displayName = 'IconContainer'
