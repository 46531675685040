import axios from '@/axiosInstance'
import {z} from 'zod'
import {Phase, PhasesSearchParams} from '@/features/project-phases/types'

/**
 * httpGetPhases
 * Here we will fetch all the phases data for the dash table
 */

// Params
export const HttpGetProjectPhasesUrlParams = z.object({
    projectId: z.coerce.number().int().nullable()
})
export type HttpGetProjectPhasesUrlParams = z.infer<typeof HttpGetProjectPhasesUrlParams>

export const HttpPhasesParams = z.object({
    ...HttpGetProjectPhasesUrlParams.shape,
    ...PhasesSearchParams.shape
})
export type HttpPhasesParams = z.infer<typeof HttpPhasesParams>

// Response
export const HttpPhasesResponse = z.object({
    data: z.array(Phase)
})
export type HttpPhasesResponse = z.infer<typeof HttpPhasesResponse>

// Http request
export const httpGetPhases = async (params: HttpPhasesParams): Promise<HttpPhasesResponse> => {
    const response = await axios.get(`/projects/${params.projectId}/phases`, {params})
    return HttpPhasesResponse.parse(response)
}

/**
 * httpFloatSync
 * Here we will synchronize project data from float
 */

// Params
export const HttpFloatSyncParams = z.object({
    projectId: z.coerce.number()
})
export type HttpFloatSyncParams = z.infer<typeof HttpFloatSyncParams>

// Response
export const HttpFloatSyncResponse = z.object({
    status: z.string(),
    message: z.string().nullable()
})
export type HttpFloatSyncResponse = z.infer<typeof HttpFloatSyncResponse>

// Http request
export const httpFloatSync = async (params: HttpFloatSyncParams): Promise<HttpFloatSyncResponse> => {
    const response = await axios.post(`/projects/${params.projectId}/sync`)
    return response.data
}

/**
 * httpUpdatePhaseServiceLine
 * Here we will update the service line of a phase
 */

export const HttpUpdatePhaseServiceLineParams = z.object({
    phaseId: z.coerce.number(),
    data: z.object({
        serviceLine: z.string()
    })
})
export type HttpUpdatePhaseServiceLineParams = z.infer<typeof HttpUpdatePhaseServiceLineParams>

export const httpUpdatePhaseServiceLine = async (params: HttpUpdatePhaseServiceLineParams) => {
    const response = await axios.patch(`/phases/${params.phaseId}`, {
        ...params.data
    })

    return response.data
}
