import React, {useState} from 'react'
import {ProjectDetails} from '@/features/project/types'
import {useOutletContext} from 'react-router-dom'
import {PhasesHeader} from '@/features/project-phases/components/phases-header/PhasesHeader'
import {PhasesTable} from '@/features/project-phases/components/phases-table/PhasesTable'
import {StyledProjectTabWrapper} from '@/pages/project-details/style'
import {useProjectPhasesTable} from '@/features/project-phases/services/queries/useProjectPhasesTable'

export const ProjectPhases: React.FC = () => {
    const {project} = useOutletContext<{project: ProjectDetails}>()
    const [isConvertCosts, setIsConvertCosts] = useState<boolean>(true)
    const {phases, costs, isLoading, isError} = useProjectPhasesTable({
        projectId: project.id
    })

    return (
        <StyledProjectTabWrapper>
            <PhasesHeader
                setIsConvertCosts={setIsConvertCosts}
                costs={costs.data}
                isConvertCosts={isConvertCosts}
                phases={phases?.data || []}
            />

            <PhasesTable
                isLoading={isLoading}
                isConvertCosts={isConvertCosts}
                dailyRate={project.dailyRate}
                costs={costs.data}
                data={phases?.data || []}
                isError={isError}
                isChangingPage={false}
            />
        </StyledProjectTabWrapper>
    )
}
