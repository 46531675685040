import styled, {css, DefaultTheme} from 'styled-components'

export const StyledPrivateLayout = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden;
`

export const StyledMain = styled.div(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        overflow-y: auto;
        height: 100%;
        padding: ${spacing * 10}px 0;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 5}px;
    `
)
