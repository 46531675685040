import {Dispatch, FC, SetStateAction} from 'react'
import {Invoice, InvoiceStatus} from '@/features/invoice/types'
import {Link03Icon, UploadCloud01Icon} from '@/components/ui/icon'
import Button from '@/components/ui/button/Button'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'

interface InvoiceFICButtonProps {
    invoice: Invoice
    setInvoiceToUpdate: Dispatch<SetStateAction<Invoice | null>>
}

export const InvoiceFICButton: FC<InvoiceFICButtonProps> = ({invoice, setInvoiceToUpdate}) => {
    const onUploadInvoice = () => {
        setInvoiceToUpdate(invoice)
    }

    if (invoice.status === InvoiceStatus.enum.sent && !invoice.ficExternalId) {
        return (
            <Button variant="ghost" iconOnly onClick={onUploadInvoice} disabled={!invoice.customer.ficExternalId}>
                <UploadCloud01Icon size={20} />
            </Button>
        )
    }

    if (invoice.status === InvoiceStatus.enum.sent && invoice.ficExternalId && invoice.ficViewUrl) {
        return (
            <ButtonLink size={'lg'} variant="ghost" to={invoice.ficViewUrl} target="_blank" iconOnly padding={1}>
                <Link03Icon size={20} />
            </ButtonLink>
        )
    }

    return null
}
