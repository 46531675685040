import React, {useState} from 'react'
import RevenuesHeader from '@/features/project-revenues/components/revenues-header/RevenuesHeader'
import {RevenuesTable} from '@/features/project-revenues/components/revenues-table/RevenuesTable'
import {useProjectRevenuesTable} from '@/features/project-revenues/services/queries/useProjectRevenuesTable'
import {useOutletContext} from 'react-router-dom'
import {ProjectDetails} from '@/features/project/types'
import {useTranslation} from 'react-i18next'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {StyledProjectTabWrapper} from '../style'
import {ResponseType} from '@/types/commons.ts'
import CreateInvoiceModal from '@/features/project-revenues/components/invoice-modal/CreateinvoiceModal.tsx'
import EditDuplicateInvoiceModal from '@/features/project-revenues/components/invoice-modal/EditDuplicateInvoiceModal.tsx'
import {useDeleteInvoiceMutation} from '@/features/invoice/services/queries/useDeleteInvoiceMutation.ts'
import toast from 'react-hot-toast'
import {ViewInvoiceModal} from '@/features/invoice/components/view-invoice-modal/ViewInvoiceModal.tsx'
import {UploadInvoiceModal} from '@/features/invoice/components/upload-invoice-modal/UploadInvoiceModal.tsx'
import {useUploadInvoice} from '@/features/invoice/services/queries/useUploadInvoice.ts'
import {Invoice} from '@/features/invoice/types.ts'
import {errorHandler} from '@/utilities/genericErrorHandler'

export const ProjectRevenues: React.FC = () => {
    const {t} = useTranslation()
    const {project} = useOutletContext<{project: ProjectDetails}>()
    const [revenueToDelete, setRevenueToDelete] = useState<number | null>(null)
    const [revenueIdToEdit, setRevenueIdToEdit] = useState<number | null>(null)
    const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false)
    const [revenueIdToDuplicate, setRevenueIdToDuplicate] = useState<number | null>(null)
    const [invoiceIdToView, setInvoiceIdToView] = useState<number | null>(null)
    const [invoiceToUpload, setInvoiceToUpload] = useState<Invoice | null>(null)
    const {revenues, isLoading, isError} = useProjectRevenuesTable({
        projectId: project.id,
        responseType: ResponseType.Enum.extended
    })
    const {mutate: deleteInvoice} = useDeleteInvoiceMutation({
        onSuccess: () => {
            toast.success(t('commons:delete_completed', {entity: t('invoice:singular')}))
        },
        onError: error => {
            errorHandler(error)
            setRevenueToDelete(null)
        }
    })
    const {mutate: upload, isPending: uploadPending} = useUploadInvoice({
        onSuccess: () => {
            toast.success('success')
            onCloseInvoiceIdToUpload()
        }
    })
    const uploadInvoice = () => {
        if (invoiceToUpload) {
            upload({invoiceId: invoiceToUpload.id, data: invoiceToUpload})
        }
    }

    const onCloseAddInvoice = () => setIsInvoiceModalOpen(false)
    const onSubmitRevenueToDelete = () => {
        revenueToDelete && deleteInvoice(revenueToDelete)
        setRevenueToDelete(null)
    }

    const onCloseRevenueIdToEdit = () => {
        setRevenueIdToEdit(null)
    }

    const onCloseRevenueIdToDuplicate = () => {
        setRevenueIdToDuplicate(null)
    }
    const onCloseInvoiceIdToView = () => {
        setInvoiceIdToView(null)
    }
    const onCloseInvoiceIdToUpload = () => {
        setInvoiceToUpload(null)
    }
    return (
        <>
            <StyledProjectTabWrapper>
                <RevenuesHeader setIsInvoiceModalOpen={setIsInvoiceModalOpen} />
                <RevenuesTable
                    data={revenues.data || []}
                    isLoading={isLoading}
                    isError={isError}
                    setRevenueIdToEdit={setRevenueIdToEdit}
                    setRevenueToDelete={setRevenueToDelete}
                    setRevenueIdToDuplicate={setRevenueIdToDuplicate}
                    setIsInvoiceModalOpen={setIsInvoiceModalOpen}
                    setRevenueIdToView={setInvoiceIdToView}
                    setRevenueToUpload={setInvoiceToUpload}
                />
            </StyledProjectTabWrapper>

            {revenueToDelete && (
                <DeleteModal
                    title={t('project:revenues:delete:title')}
                    paragraph={t('project:revenues:delete:description')}
                    isLoading={false}
                    onClose={() => setRevenueToDelete(null)}
                    onDelete={() => onSubmitRevenueToDelete()}
                />
            )}
            {isInvoiceModalOpen && <CreateInvoiceModal onClose={onCloseAddInvoice} />}

            {revenueIdToEdit && (
                <EditDuplicateInvoiceModal invoiceId={revenueIdToEdit} onClose={onCloseRevenueIdToEdit} />
            )}
            {revenueIdToDuplicate && (
                <EditDuplicateInvoiceModal
                    invoiceId={revenueIdToDuplicate}
                    onClose={onCloseRevenueIdToDuplicate}
                    isDuplicate={true}
                />
            )}
            {invoiceIdToView && <ViewInvoiceModal onClose={onCloseInvoiceIdToView} invoiceId={invoiceIdToView} />}
            {invoiceToUpload && (
                <UploadInvoiceModal
                    onClose={onCloseInvoiceIdToUpload}
                    onSubmit={uploadInvoice}
                    isLoading={uploadPending}
                />
            )}
        </>
    )
}
