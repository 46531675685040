import {z} from 'zod'

export type SerializedSearchParam = {
    [key: string]: string[] | null
}

export type Percentage = `${number}%`

export interface ListResultMeta {
    total: number
    per_page: number
    current_page: number
    last_page: number
    first_page: number
    first_page_url: string
    last_page_url: string
    next_page_url: string
    previous_page_url: string | null
}

export type HttpListResult<Type> = {
    data: Type[]
    meta: ListResultMeta
}

export type HttpResult<Type> = {
    data: Type
}

export type HttpEntityResult<Type> = {
    data: Type
}

export const ResponseType = z.enum(['small', 'extended'])
export type ResponseType = z.infer<typeof ResponseType>

export const PaginationParams = z.object({
    page: z.number().optional(),
    limit: z.number().optional()
})
export type PaginationParams = z.infer<typeof PaginationParams>

export const PaginationResponse = z.object({
    total: z.number(),
    last_page: z.number(),
    per_page: z.number(),
    current_page: z.number(),
    next_page_url: z.string().nullish()
})
export type PaginationResponse = z.infer<typeof PaginationResponse>

export const MockPaginationParams = z.object({
    current_page: z.number(),
    last_page: z.number(),
    per_page: z.number()
})
export type MockPaginationParams = z.infer<typeof MockPaginationParams>

export type ErrorResponseData = {
    message: string
}
