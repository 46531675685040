import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {useGetInvoices} from '@/features/invoice/services/queries/useGetInvoices.ts'
import {InvoicesSearchParams} from '@/features/invoice/types'
import {useInputSearch} from '@/hooks/useInputSearch'
import {useEffect} from 'react'

export const useInvoicesTable = () => {
    const multiParams = [] as const satisfies ReadonlyArray<keyof InvoicesSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, InvoicesSearchParams)
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch(searchParams.search)

    const invoicesQuery = useGetInvoices({
        ...searchParams
    })

    const toggleFicSent = (showSent: boolean) => {
        setSearchParams({showSent: showSent ? 'true' : 'false'})
    }

    useEffect(() => {
        setSearchParams({search: searchValue})
    }, [searchValue])

    const sort = (sorter: {
        orderBy: InvoicesSearchParams['orderBy']
        orderDirection: InvoicesSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...invoicesQuery,
        onSearch,
        onResetSearch,
        searchParams,
        setSearchParams,
        searchValue,
        searchError,
        toggleFicSent,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
