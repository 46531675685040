import styled, {css} from 'styled-components'

export const StyledInvoiceNumber = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm}
        font-weight: bold;
        color: ${palette.neutral['900']};
        display: block;
    `}
`
