import styled, {css} from 'styled-components'
import {ContentEditable} from '@lexical/react/LexicalContentEditable'
import Button from '@components/ui/button/Button.tsx'

type StyledTextareaButtonProps = {
    isActive?: boolean
}

export const StyledContentEditable = styled(ContentEditable)`
    ${({theme: {typography, palette}}) => css`
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        user-modify: read-write;
        color: ${palette.neutral['900']};
        ${typography.textMd}
        user-modify: read-write;
        & > * {
            all: revert;
            user-modify: read-write;
            margin: 0;
        }
        & strong {
            all: revert;
        }
        &:focus {
            outline: none;
        }
    `}
`

export const StyledPlaceholder = styled.div`
    ${({theme: {palette}}) => css`
        overflow: hidden;
        position: absolute;
        text-overflow: ellipsis;
        top: 15px;
        left: 15px;
        font-size: 15px;
        user-select: none;
        display: inline-block;
        color: ${palette.neutral[400]};
        pointer-events: none;
    `}
`

export const StyledRichTextareaWrapper = styled.div`
    ${({theme: {spacing, palette, shadows, typography}}) => css`
        position: relative;
        display: flex;
        min-height: 92px;
        max-height: 110px;
        overflow: scroll;
        gap: ${spacing}px;
        background: ${palette.neutral.white};
        border: 1px solid ${palette.neutral['300']};
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: ${spacing * 3}px ${spacing * 3.5}px;
        width: 100%;
        transition: ease-in-out 200ms;
        cursor: pointer;
        ${typography.textMd}
        &:focus {
            outline: none;
        }

            :hover {
                border: 1px solid ${palette.primary['300']};
                box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.primary['100']};
                `}
        `}
`
export const StyledHeaderRichTextArea = styled.div`
    ${({theme: {palette, spacing}}) => css`
        padding: ${spacing}px;
        background: ${palette.neutral.white};
        border: 1px solid ${palette.primary['300']};
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        border-bottom: none;
    `}
`

export const StyledHeaderRichTextAreaButton = styled(Button)<StyledTextareaButtonProps>`
    ${({isActive, theme: {palette}}) => css`
        ${isActive &&
        css`
            background: ${palette.neutral['200']};
        `};
    `}
`
export const StyledRichTextContainer = styled.div`
    ${() => css`
        width: 100%;
    `}
`
export const StyledRichTextLabel = styled.div`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 1.5}px;
    `}
`
