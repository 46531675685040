import {z} from 'zod'

export const Customer = z.object({
    id: z.coerce.number(),
    floatExternalId: z.number().nullable(),
    ficExternalId: z.number().nullable(),
    vatNumber: z.string().nullable(),
    fiscalCode: z.string().nullable(),
    sdi: z.string().nullable(),
    pec: z.string().nullable(),
    businessName: z.string(),
    legalAddress: z.string().nullable(),
    civicNumber: z.string().nullable(),
    city: z.string().nullable(),
    zipCode: z.string().nullable(),
    province: z.string().nullable(),
    country: z.string().nullable(),
    fullnameAccounting: z.string().nullable(),
    email: z.string().nullable(),
    phoneNumber: z.string().nullable(),
    successManagerId: z.coerce.number().nullish(),
    successManager: z
        .object({
            id: z.coerce.number(),
            fullName: z.string()
        })
        .nullish()
})
export type Customer = z.infer<typeof Customer>

export const CustomersSearchParams = z.object({
    orderBy: z.enum(['businessName']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional()
})
export type CustomersSearchParams = z.infer<typeof CustomersSearchParams>
