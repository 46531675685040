import {ChangeEvent, useCallback, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import {Controller, SubmitHandler, useForm, useWatch} from 'react-hook-form'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import InputText from '@components/commons/input-text/InputText.tsx'
import InputSelect, {SelectValue} from '@components/commons/select/Select.tsx'
import DatePicker from '@components/commons/date-picker/DatePicker.tsx'
import Button from '@/components/ui/button/Button'
import {UnsavedBlocker} from '@/components/commons/unsaved-blocker/UnsavedBlocker'
import {EmployeeRole} from '@/features/employee/types.ts'
import {PROJECT_STATUSES} from '@/features/project/utils'
import {CurrencyEuroIcon, PlusIcon} from '@/components/ui/icon'
import {ResponseType} from '@/types/commons'
import {routes} from '@/utilities/constants'
import {useCreateProject} from '../../services/queries/useCreateProject'
import {useUpdateProject} from '../../services/queries/useUpdateProject'
import {httpGetEmployees} from '@/features/employee/services/employee.http'
import {httpGetProjectTypes} from '@/features/project/services/project.http'
import {httpGetCustomers} from '@/features/customer/services/customer.http'
import {customersSelectAdapter, employeesSelectAdapter, projectTypesSelectAdapter} from '@/utilities/adapters'
import {debounce, removeCurrencyFormat, round} from '@/utilities/helpers'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {adaptProjectToCreate, adaptProjectToUpdate} from './utils'
import {PROJECT_FORM_MODEL, ProjectFormSchema, ProjectValidationSchema} from './ProjectFormModel'
import {
    StyledNameWrapper,
    StyledInformationWrapper,
    StyledBriefWrapper,
    StyledSeparator,
    StyledSectionTitle,
    StyledManagementWrapper,
    StyledAccountingWrapper,
    StyledAgreementWrapper,
    StyledActionsWrapper,
    StyledFoldersWrapper
} from './style'
import {ValueIndicator} from '@/components/commons/value-indicator/ValueIndicator'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useCreateCustomer} from '@/features/customer/services/queries/useCreateCustomer.ts'
import InputNumber from '@/components/commons/input-number/InputNumber'
import RichTextarea from '@components/commons/rich-textarea/rich-textarea/RichTextarea.tsx'

type FormInputData = z.input<typeof ProjectFormSchema>
type FormOutputData = z.output<typeof ProjectFormSchema>
export type CallbackFunction = (options: SelectValue[]) => void

interface ProjectFormProps {
    internalDailyRate: number
    projectId?: number
    defaultValues?: Partial<FormInputData>
}

const ProjectForm: React.FC<ProjectFormProps> = ({internalDailyRate, projectId, defaultValues}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {
        control,
        register,
        handleSubmit,
        resetField,
        formState: {errors, touchedFields, isValid, isDirty, isSubmitted},
        setValue
    } = useForm<FormInputData, string, FormOutputData>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        resolver: zodResolver(ProjectFormSchema),
        defaultValues: {...defaultValues}
    })

    // mutations
    const {mutate: updateProjectMutation, isPending: isPendingUpdate} = useUpdateProject({
        onSuccess: () => toast.success(t(`commons:update_completed`, {entity: t(`project:singular`)})),
        onError: error => errorHandler(error)
    })

    const {mutate: createProjectMutation, isPending: isPendingCreate} = useCreateProject({
        onSuccess: newProject => {
            toast.success(t(`commons:creation_completed`, {entity: t(`project:singular`)}))
            navigate(`${routes.PROJECTS.path}/${newProject.id}/info`)
        },
        onError: error => errorHandler(error)
    })
    const {mutate: createCustomer} = useCreateCustomer({
        onSuccess: data => {
            setValue(PROJECT_FORM_MODEL.customer.name, {label: data.businessName, value: data.id.toString()})
            toast.success(t('customer:new_customer_success'))
        }
    })

    // watch changes on fields
    const startDate = useWatch({control, name: PROJECT_FORM_MODEL.startDate.name})
    const endDate = useWatch({control, name: PROJECT_FORM_MODEL.endDate.name})
    const watchBudget = useWatch({control, name: PROJECT_FORM_MODEL.budget.name})
    const watchDailytRate = useWatch({control, name: PROJECT_FORM_MODEL.dailyRate.name})
    const budget = parseFloat(removeCurrencyFormat(watchBudget))
    const dailyRate = parseFloat(removeCurrencyFormat(watchDailytRate))

    // trigger side effects
    useEffect(() => {
        if (startDate && endDate && startDate > endDate) {
            register(PROJECT_FORM_MODEL.endDate.name)
            resetField(PROJECT_FORM_MODEL.endDate.name, {keepDirty: true, defaultValue: ''})
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (!budget) {
            register(PROJECT_FORM_MODEL.hours.name)
            resetField(PROJECT_FORM_MODEL.hours.name, {keepDirty: true, defaultValue: ''})
            register(PROJECT_FORM_MODEL.dailyRate.name)
            resetField(PROJECT_FORM_MODEL.dailyRate.name, {keepDirty: true, defaultValue: ''})
        }
    }, [budget])

    // custom fields
    const hoursField = register(PROJECT_FORM_MODEL.hours.name)
    const dailyRateField = register(PROJECT_FORM_MODEL.dailyRate.name)
    const budgetField = register(PROJECT_FORM_MODEL.budget.name)

    // custom behaviours
    const onChangeBudget = () => {
        resetField(PROJECT_FORM_MODEL.dailyRate.name, {defaultValue: ''})
        resetField(PROJECT_FORM_MODEL.hours.name, {defaultValue: ''})
    }

    const onChangeDailyRate = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value ? parseFloat(event.target.value.replace(/,/g, '')) : null
        if (budget && value) {
            setValue(PROJECT_FORM_MODEL.hours.name, round(budget / (value / 8)).toString())
        } else {
            register(PROJECT_FORM_MODEL.hours.name)
            resetField(PROJECT_FORM_MODEL.hours.name, {defaultValue: ''})
        }
    }

    const onChangeHours = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value ? parseInt(event.target.value) : null
        if (budget && value) {
            if (value < 8) {
                setValue(PROJECT_FORM_MODEL.dailyRate.name, budget.toString())
            } else {
                setValue(PROJECT_FORM_MODEL.dailyRate.name, round(budget / (value / 8)).toString())
            }
        } else {
            register(PROJECT_FORM_MODEL.dailyRate.name)
            resetField(PROJECT_FORM_MODEL.dailyRate.name, {defaultValue: ''})
        }
    }

    // submit handler
    const onSubmit: SubmitHandler<ProjectValidationSchema> = data => {
        if (projectId) {
            updateProjectMutation(adaptProjectToUpdate(projectId, data))
        } else {
            createProjectMutation(adaptProjectToCreate(data))
        }
    }

    const loadSuccessManager = async (inputValue: string | undefined, callback: CallbackFunction) => {
        try {
            const response = await httpGetEmployees({
                search: inputValue,
                roles: [EmployeeRole.Enum.success_manager, EmployeeRole.Enum.admin],
                isActive: true,
                responseType: ResponseType.enum.extended
            })
            callback(employeesSelectAdapter(response))
        } catch (error) {
            errorHandler(error)
            callback([])
        }
    }

    const loadSuccessManagerOption = useCallback(
        debounce((inputValue: string | undefined, callback: CallbackFunction) => {
            loadSuccessManager(inputValue, callback)
        }, 400),
        []
    )
    const loadTeamLead = async (inputValue: string | undefined, callback: CallbackFunction) => {
        try {
            const response = await httpGetEmployees({
                search: inputValue,
                roles: [EmployeeRole.Enum.team_lead],
                linkedToFloat: true,
                isActive: true,
                responseType: ResponseType.enum.extended
            })
            callback(employeesSelectAdapter(response))
        } catch (error) {
            errorHandler(error)
            callback([])
        }
    }

    const loadTeamLeadOption = useCallback(
        debounce((inputValue: string | undefined, callback: CallbackFunction) => {
            loadTeamLead(inputValue, callback)
        }, 400),
        []
    )
    const loadProductLead = async (inputValue: string | undefined, callback: CallbackFunction) => {
        try {
            const response = await httpGetEmployees({
                search: inputValue,
                isActive: true,
                responseType: ResponseType.enum.extended
            })
            callback(employeesSelectAdapter(response))
        } catch (error) {
            errorHandler(error)
            callback([])
        }
    }

    const loadProductLeadOptions = useCallback(
        debounce((inputValue: string | undefined, callback: CallbackFunction) => {
            loadProductLead(inputValue, callback)
        }, 400),
        []
    )
    const loadCustomer = async (inputValue: string | undefined, callback: CallbackFunction) => {
        try {
            const response = await httpGetCustomers({
                search: inputValue,
                responseType: ResponseType.enum.extended,
                page: 1,
                limit: 100
            })
            callback(customersSelectAdapter(response))
        } catch (error) {
            errorHandler(error)
            callback([])
        }
    }

    const loadCustomerOptions = useCallback(
        debounce((inputValue: string | undefined, callback: CallbackFunction) => {
            loadCustomer(inputValue, callback)
        }, 400),
        []
    )

    return (
        <UnsavedBlocker isDirty={isDirty && !isSubmitted}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <StyledSectionTitle>{t('project:details:details')}</StyledSectionTitle>
                <StyledNameWrapper>
                    <InputText
                        label={`${t(PROJECT_FORM_MODEL.name.label)}*`}
                        type={'text'}
                        touched={touchedFields.name}
                        errorMessage={t(errors.name?.message || '')}
                        placeholder={t(PROJECT_FORM_MODEL.name.label)}
                        {...register(t(PROJECT_FORM_MODEL.name.name))}
                    />
                </StyledNameWrapper>
                <StyledInformationWrapper>
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <InputSelect
                                isAsync
                                loadOptions={async () => {
                                    const projectTypes = await httpGetProjectTypes()
                                    return projectTypesSelectAdapter(projectTypes)
                                }}
                                value={value}
                                onChange={newValue => {
                                    onChange(newValue as SelectValue)
                                }}
                                size={'medium'}
                                name={PROJECT_FORM_MODEL.type.name}
                                label={`${t(PROJECT_FORM_MODEL.type.label)}*`}
                                isClearable={true}
                                isSearchable={true}
                                errorMessage={t(errors.type?.message || '')}
                                placeholder={t(PROJECT_FORM_MODEL.type.label)}
                            />
                        )}
                        control={control}
                        name={PROJECT_FORM_MODEL.type.name}
                    />
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <InputSelect
                                value={value}
                                onChange={newValue => {
                                    onChange(newValue as SelectValue)
                                }}
                                size={'medium'}
                                name={PROJECT_FORM_MODEL.status.name}
                                label={`${t(PROJECT_FORM_MODEL.status.label)}*`}
                                isClearable={true}
                                errorMessage={t(errors.status?.message || '')}
                                placeholder={t(PROJECT_FORM_MODEL.status.label)}
                                options={PROJECT_STATUSES.map(item => ({value: item.value, label: t(item.label)}))}
                            />
                        )}
                        control={control}
                        name={PROJECT_FORM_MODEL.status.name}
                    />
                    <Controller
                        control={control}
                        name={PROJECT_FORM_MODEL.startDate.name}
                        render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                            <DatePicker
                                toggle
                                formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                                numMonths={1}
                                mode={'single'}
                                currentDay={new Date()}
                                selectedDates={value ? [new Date(value)] : []}
                                onDatesChange={dates => onChange(dates[0].toISOString())}
                                onBlur={onBlur}
                                triggerProps={{
                                    label: `${t(PROJECT_FORM_MODEL.startDate.label)}*`,
                                    errorMessage: t(error?.message || ''),
                                    placeholder: t(PROJECT_FORM_MODEL.startDate.label)
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PROJECT_FORM_MODEL.endDate.name}
                        render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                            <DatePicker
                                toggle
                                minDate={startDate ? new Date(startDate) : new Date()}
                                formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                                numMonths={1}
                                mode={'single'}
                                currentDay={new Date()}
                                selectedDates={value ? [new Date(value)] : []}
                                onDatesChange={dates => onChange(dates[0].toISOString())}
                                onBlur={onBlur}
                                triggerProps={{
                                    label: `${t(PROJECT_FORM_MODEL.endDate.label)}*`,
                                    errorMessage: t(error?.message || ''),
                                    placeholder: t(PROJECT_FORM_MODEL.endDate.label)
                                }}
                            />
                        )}
                    />
                </StyledInformationWrapper>
                <StyledBriefWrapper>
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <RichTextarea
                                onChange={onChange}
                                placeHolder={t(PROJECT_FORM_MODEL.brief.label)}
                                label={`${t(PROJECT_FORM_MODEL.brief.label)}`}
                                value={value}
                            />
                        )}
                        control={control}
                        name={PROJECT_FORM_MODEL.brief.name}
                    />
                </StyledBriefWrapper>
                <StyledSeparator />
                <StyledSectionTitle>{t('project:details:management')}</StyledSectionTitle>
                <StyledManagementWrapper>
                    <Controller
                        render={({field: {onChange, value}}) => {
                            return (
                                <InputSelect
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    // @ts-expect-error
                                    loadOptions={loadSuccessManagerOption}
                                    isAsync
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={PROJECT_FORM_MODEL.successManager.name}
                                    label={`${t(PROJECT_FORM_MODEL.successManager.label)}*`}
                                    isClearable={true}
                                    isCreatable={false}
                                    isSearchable={true}
                                    errorMessage={t(errors.successManager?.message || '')}
                                    placeholder={t(PROJECT_FORM_MODEL.successManager.label)}
                                />
                            )
                        }}
                        control={control}
                        name={PROJECT_FORM_MODEL.successManager.name}
                    />
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <InputSelect
                                isAsync
                                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                // @ts-expect-error
                                loadOptions={loadTeamLeadOption}
                                value={value}
                                onChange={newValue => {
                                    onChange(newValue as SelectValue)
                                }}
                                size={'medium'}
                                name={PROJECT_FORM_MODEL.teamLead.name}
                                label={`${t(PROJECT_FORM_MODEL.teamLead.label)}*`}
                                isClearable={true}
                                isSearchable={true}
                                errorMessage={t(errors.teamLead?.message || '')}
                                placeholder={t(PROJECT_FORM_MODEL.teamLead.label)}
                            />
                        )}
                        control={control}
                        name={PROJECT_FORM_MODEL.teamLead.name}
                    />
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <InputSelect
                                isAsync
                                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                // @ts-expect-error
                                loadOptions={loadProductLeadOptions}
                                value={value}
                                onChange={newValue => {
                                    onChange(newValue as SelectValue)
                                }}
                                size={'medium'}
                                name={PROJECT_FORM_MODEL.productLead.name}
                                label={`${t(PROJECT_FORM_MODEL.productLead.label)}*`}
                                isClearable={true}
                                isSearchable={true}
                                errorMessage={t(errors.productLead?.message || '')}
                                placeholder={t(PROJECT_FORM_MODEL.productLead.label)}
                            />
                        )}
                        control={control}
                        name={PROJECT_FORM_MODEL.productLead.name}
                    />
                </StyledManagementWrapper>
                <StyledSeparator />
                <StyledSectionTitle>{t('project:details:accounting')}</StyledSectionTitle>
                <StyledAccountingWrapper>
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <InputSelect
                                isAsync
                                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                // @ts-expect-error
                                loadOptions={loadCustomerOptions}
                                value={value}
                                onChange={newValue => {
                                    onChange(newValue as SelectValue)
                                }}
                                size={'medium'}
                                name={PROJECT_FORM_MODEL.customer.name}
                                label={`${t(PROJECT_FORM_MODEL.customer.label)}*`}
                                isClearable={true}
                                isSearchable={true}
                                errorMessage={t(errors.customer?.message || '')}
                                placeholder={t(PROJECT_FORM_MODEL.customer.label)}
                                isCreatable={true}
                                formatCreateLabel={label => (
                                    <Flexbox
                                        align={'center'}
                                        gap={4}
                                        onClick={() => createCustomer({data: {businessName: label}})}
                                    >
                                        <PlusIcon />
                                        {t('customer:new_customer')}
                                    </Flexbox>
                                )}
                            />
                        )}
                        control={control}
                        name={PROJECT_FORM_MODEL.customer.name}
                    />

                    <Controller
                        render={({field: {onChange, ...rest}}) => (
                            <InputNumber
                                typeIcon={<CurrencyEuroIcon size={16} />}
                                label={`${t(PROJECT_FORM_MODEL.budget.label)}*`}
                                touched={touchedFields.budget}
                                errorMessage={t(errors.budget?.message || '')}
                                placeholder={t(PROJECT_FORM_MODEL.budget.label)}
                                decimalScale={2}
                                decimalSeparator="."
                                thousandSeparator=","
                                allowNegative={false}
                                {...budgetField}
                                onChange={event => {
                                    onChange(event)
                                    onChangeBudget()
                                }}
                                {...rest}
                            />
                        )}
                        control={control}
                        name={PROJECT_FORM_MODEL.budget.name}
                    />

                    <Controller
                        render={({field: {onChange, ...rest}}) => (
                            <InputNumber
                                label={`${t(PROJECT_FORM_MODEL.hours.label)}*`}
                                touched={touchedFields.hours}
                                errorMessage={t(errors.hours?.message || '')}
                                placeholder={t(PROJECT_FORM_MODEL.hours.label)}
                                disabled={!budget}
                                decimalScale={1}
                                allowNegative={false}
                                {...hoursField}
                                onChange={event => {
                                    onChange(event)
                                    onChangeHours(event)
                                }}
                                {...rest}
                            />
                        )}
                        control={control}
                        name={PROJECT_FORM_MODEL.hours.name}
                    />

                    <Controller
                        render={({field: {onChange, ...rest}}) => (
                            <InputNumber
                                typeIcon={<CurrencyEuroIcon size={16} />}
                                label={`${t(PROJECT_FORM_MODEL.dailyRate.label)}*`}
                                touched={touchedFields.dailyRate}
                                errorMessage={t(errors.dailyRate?.message || '')}
                                placeholder={t(PROJECT_FORM_MODEL.dailyRate.label)}
                                decimalScale={2}
                                decimalSeparator="."
                                thousandSeparator=","
                                disabled={!budget}
                                allowNegative={false}
                                {...dailyRateField}
                                onChange={event => {
                                    onChange(event)
                                    onChangeDailyRate(event)
                                }}
                                {...rest}
                            />
                        )}
                        control={control}
                        name={PROJECT_FORM_MODEL.dailyRate.name}
                    />

                    <ValueIndicator
                        label={t(`project:details:markup`)}
                        value={
                            dailyRate && internalDailyRate
                                ? `${round(((dailyRate - internalDailyRate) / internalDailyRate) * 100)}%`
                                : `0%`
                        }
                    />
                </StyledAccountingWrapper>
                <StyledAgreementWrapper>
                    <Controller
                        control={control}
                        name={PROJECT_FORM_MODEL.masterAgreements.name}
                        render={({field: {onChange, value}}) => (
                            <RichTextarea
                                onChange={onChange}
                                placeHolder={t(PROJECT_FORM_MODEL.masterAgreements.label)}
                                value={value}
                                label={`${t(PROJECT_FORM_MODEL.masterAgreements.label)}`}
                            />
                        )}
                    />
                </StyledAgreementWrapper>
                <StyledSeparator />
                <StyledFoldersWrapper>
                    <InputText
                        {...register(t(PROJECT_FORM_MODEL.salesFolder.name))}
                        label={`${t(PROJECT_FORM_MODEL.salesFolder.label)}`}
                        type={'text'}
                        touched={touchedFields.salesFolder}
                        errorMessage={t(errors.salesFolder?.message || '')}
                        placeholder={t(PROJECT_FORM_MODEL.salesFolder.label)}
                    />
                    <InputText
                        {...register(t(PROJECT_FORM_MODEL.operationFolder.name))}
                        label={`${t(PROJECT_FORM_MODEL.operationFolder.label)}`}
                        type={'text'}
                        touched={touchedFields.operationFolder}
                        errorMessage={t(errors.operationFolder?.message || '')}
                        placeholder={t(PROJECT_FORM_MODEL.operationFolder.label)}
                    />
                </StyledFoldersWrapper>
                <StyledSeparator />

                <StyledActionsWrapper>
                    <Button
                        width={'wide'}
                        onClick={() => navigate(routes.PROJECTS.path)}
                        variant="tertiary"
                        size="md"
                        disabled={isPendingUpdate || isPendingCreate}
                    >
                        {t('commons:cancel')}
                    </Button>

                    <Button
                        width={'wide'}
                        type="submit"
                        variant="primary"
                        size="md"
                        disabled={!isValid || isPendingUpdate || isPendingCreate || !isDirty}
                    >
                        {t('commons:save')}
                    </Button>
                </StyledActionsWrapper>
            </form>
        </UnsavedBlocker>
    )
}

export default ProjectForm
