import axios from '@/axiosInstance'
import {PaginationResponse, PaginationParams, ResponseType} from '@/types/commons'
import {ProjectType, ProjectsSearchParams, ProjectDetails, ProjectStatus} from '@/features/project/types'
import {z} from 'zod'

/**
 * httpGetProjects
 * Here we will fetch all the projects data for the dash table
 */

// Params schema
export const HttpProjectsParams = z.object({
    ...ProjectsSearchParams.shape,
    ...PaginationParams.shape,
    responseType: ResponseType
})
export type HttpProjectsParams = z.infer<typeof HttpProjectsParams>

// Response schema
export const HttpProjectsResponse = z.object({
    data: z.array(ProjectDetails),
    meta: PaginationResponse
})
export type HttpProjectsResponse = z.infer<typeof HttpProjectsResponse>

// Http request
export const httpGetProjects = async (params: HttpProjectsParams): Promise<HttpProjectsResponse> => {
    const response = await axios.get(`/projects`, {params})
    return HttpProjectsResponse.parse(response.data)
}

/**
 * httpGetProjectTypes
 * Here we will fetch all the types that can be assigned to a project
 */

// Params schema
export const HttpGetProjectTypesParams = z.object({
    limit: z.coerce.number().int().optional(),
    page: z.coerce.number().int().optional()
})
export type HttpGetProjectTypesParams = z.infer<typeof HttpGetProjectTypesParams>

// Response schema
export const HttpProjectTypesResponse = z.object({
    data: z.array(ProjectType),
    meta: PaginationResponse
})
export type HttpProjectTypesResponse = z.infer<typeof HttpProjectTypesResponse>

// Http request
export const httpGetProjectTypes = async (params?: HttpGetProjectTypesParams): Promise<HttpProjectTypesResponse> => {
    const response = await axios.get(`/project-types`, {params})
    return HttpProjectTypesResponse.parse(response.data)
}

/**
 * httpGetProjectDetails
 * Here we will get project details data
 */

// Params schema
export const HttpGetProjectDetailsParams = z.object({
    projectId: z.coerce.number().int(),
    responseType: ResponseType
})
export type HttpGetProjectDetailsParams = z.infer<typeof HttpGetProjectDetailsParams>

// Response schema
export const HttpGetProjectDetailsResponse = z.object({
    ...ProjectDetails.shape
})
export type HttpGetProjectDetailsResponse = z.infer<typeof HttpGetProjectDetailsResponse>

// Http request
export const httpGetProjectDetails = async (
    params: HttpGetProjectDetailsParams
): Promise<HttpGetProjectDetailsResponse> => {
    const response = await axios.get(`/projects/${params.projectId}`, {params})
    return HttpGetProjectDetailsResponse.parse(response.data)
}

/**
 * httpCreateProject
 * Here we will create a new project instance
 */
export const HttpProjectBodyRequest = z.object({
    name: z.string(),
    status: ProjectStatus,
    typeId: z.number().nullable(),
    startDate: z.string().nullable(),
    endDate: z.string().nullable(),
    successManagerId: z.number().nullable(),
    teamLeadId: z.number().nullable(),
    productLeadId: z.number().nullable(),
    customerId: z.number().nullable(),
    budget: z.number(),
    hours: z.number(),
    dailyRate: z.number(),
    masterAgreements: z.string().nullable(),
    salesFolder: z.string().url().or(z.literal('')).nullish(),
    operationFolder: z.string().url().or(z.literal('')).nullish(),
    brief: z.string().nullable()
})
export type HttpProjectBodyRequest = z.infer<typeof HttpProjectBodyRequest>

export const HttpCreateProjectParams = z.object({
    data: HttpProjectBodyRequest
})
export type HttpCreateProjectParams = z.infer<typeof HttpCreateProjectParams>

export const httpCreateProject = async (params: HttpCreateProjectParams): Promise<ProjectDetails> => {
    const response = await axios.post(`/projects`, {
        ...params.data
    })

    return response.data
}

/**
 * httpUpdateProject
 * Here we will update a project instance
 */

export const HttpUpdateProjectParams = z.object({
    projectId: z.coerce.number(),
    data: HttpProjectBodyRequest
})
export type HttpUpdateProjectParams = z.infer<typeof HttpUpdateProjectParams>

export const httpUpdateProject = async (params: HttpUpdateProjectParams) => {
    const response = await axios.patch(`/projects/${params.projectId}`, {
        ...params.data
    })

    return response.data
}

/**
 * httpDeleteProject
 * Here we will delete a project instance
 */

export const httpDeleteProject = async (id: number) => {
    return await axios.delete(`/projects/${id}`)
}

/**
 * httpInternalDailyRate
 * Here we will get the interla daily rate for all the projects
 */

export const HttpGetInternalDailyRateResponse = z.object({
    internalDailyRate: z.number()
})
export type HttpGetInternalDailyRateResponse = z.infer<typeof HttpGetInternalDailyRateResponse>

export const httpInternalDailyRate = async (): Promise<HttpGetInternalDailyRateResponse> => {
    const response = await axios.get(`/projects/internal-daily-rate`)
    return HttpGetInternalDailyRateResponse.parse(response.data)
}
