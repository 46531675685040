import {FC} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {SyncStatus} from '../../types'
import {formatLocaleDate} from '@/utilities/helpers'
import {AlertTriangleIcon, DownloadCloud01Icon, LayersThree01Icon} from '@/components/ui/icon'
import {useTranslation} from 'react-i18next'

interface ProjectSyncStatusProps {
    syncStatus: SyncStatus | null
    lastSyncAt: string | null
}

export const ProjectSyncStatus: FC<ProjectSyncStatusProps> = ({syncStatus, lastSyncAt}) => {
    const {t} = useTranslation()

    if (syncStatus === SyncStatus.enum.queued) {
        return (
            <Flexbox gap={2} justify="start" align="center">
                <LayersThree01Icon size={16} />
                <p title={t(`project:sync:queued`)}>{t(`project:sync:queued`)}</p>
            </Flexbox>
        )
    }

    if (syncStatus === SyncStatus.enum.syncing) {
        return (
            <Flexbox gap={2} justify="start" align="center">
                <DownloadCloud01Icon size={20} />
                <p title={t(`project:sync:syncing`)}>{t(`project:sync:syncing`)}</p>
            </Flexbox>
        )
    }

    if (syncStatus === SyncStatus.enum.failed) {
        return (
            <Flexbox gap={2} justify="start" align="center">
                <AlertTriangleIcon size={20} />
                <p title={t(`project:sync:failed`)}>{t(`project:sync:failed`)}</p>
            </Flexbox>
        )
    }

    return (
        <p title={lastSyncAt ? formatLocaleDate(lastSyncAt, 'lll') : ''}>
            {lastSyncAt ? formatLocaleDate(lastSyncAt, 'lll') : '-'}
        </p>
    )
}
